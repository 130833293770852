import { z } from "zod";

const productEnum = z.enum(["GENERICO", "SIMILAR", "REFERENCIA", "PATENTE", "OUTRO"]);
export { productEnum };
export const productType = z.array(productEnum);
export type TProductType = z.infer<typeof productType>;

// include params
const includeParam = z.enum(["GENERICO", "", "SIMILAR", "REFERENCIA", "MARCA", "PATENTE", "OUTROS"]);
export type CommercialList = z.infer<typeof includeParam>;

// exclude params
const excludeParams = z.object({
  associated_pathologies: z.string().optional(),
  ean: z.string().optional(),
  anvisa_code: z.string().optional(),
  abc_farma_code: z.string().optional(),
  ans_code_tt: z.string().optional(),
  lab_name: z.string().optional(),
  product_name: z.string().optional(),
  stripe: z.string().optional(),
  pack_quantity: z.string().optional(),
  active_ingredient: z.string().optional(),
  product_type: z.string().optional(),
  products_included: z.string().optional(),
});

const AcquisitionExcludeParams = excludeParams.extend({});

const VaryingRefundExcludeParams = excludeParams.extend({});

const FixedRefundExcludeParams = excludeParams.extend({});

export type ListExemptionsTypes = {
  General: z.infer<typeof includeParam>;
  Acquisition: z.infer<typeof AcquisitionExcludeParams>;
  VaryingRefund: z.infer<typeof VaryingRefundExcludeParams>;
  FixedRefund: z.infer<typeof FixedRefundExcludeParams>;
  [key: string]: any;
};
const boxesNumber = z.enum(["Padrao Bula", "Free", "Custom"]);
const accountStatus = z.enum(["Ativa", "Cancelada", "Bloqueada"]);

const excludeList = z.object({
  ean: z.string().optional(),
  associated_pathologies: z.string().optional(),
  anvisa_code: z.string().optional(),
  abc_farma_code: z.string().optional(),
  ans_code_tt: z.string().optional(),
  product_type: productType.optional(),
  stripe: z.string().optional(),
  lab_name: z.string().optional(),
  pack_quantity: z.string().optional(),
  active_ingredient: z.string().optional(),
});

const acquisitionExcludeList = excludeList.extend({});
const varyingRefundExcludeList = excludeList.extend({});
const fixedRefundExcludeList = excludeList.extend({});

const AddPlanSchema = z.object({
  currentStep: z.number(),
  activePlansCode: z.string(),
  descriptionOrReference: z.string(),
  globalLimitPerEnterprise: z.string(),
  individualLimit: z.string(),
  boxesNumber: z.string(),
  accountStatus: accountStatus,
  acquisitionAllowed: z.boolean(),
  authorizedList: productType.optional(),
  acquisitionExcludeList: acquisitionExcludeList.optional(),
  varyingReinbursementAllowed: z.boolean(),
  varyingReinbursementAuthorizedList: productType.optional(),
  varyingReinbursementPercentage: z.number({ coerce: true }),
  varyingRefundExcludeList: varyingRefundExcludeList.optional(),
  fixatedReinbursementAllowed: z.boolean(),
  fixatedReinbursementAuthorizedList: productType.optional(),
  fixatedReinbursementValue: z.number({ coerce: true }),
  fixedRefundExcludeList: fixedRefundExcludeList.optional(),
});

export type TBoxesNumber = z.infer<typeof boxesNumber>;
export type TAccountStatus = z.infer<typeof accountStatus>;
export type TAddPlan = z.infer<typeof AddPlanSchema>;

export type TAction = {
  type: TAddPlanAction;
  payload: any;
};

const AddPlanAction = z.enum([
  "setCurrentStep",
  "setActivePlansCode",
  "setDescriptionOrReference",
  "setGlobalLimitPerEnterprise",
  "setIndividualLimit",
  "setBoxesNumber",
  "setAccountStatus",
  "setAcquisitionAllowed",
  "setAuthorizedList",
  "setAcquisitionExcludeList",
  "setVaryingReinbursementAllowed",
  "setVaryingReinbursementAuthorizedList",
  "setVaryingReinbursementPercentage",
  "setVaryingRefundExcludeList",
  "setFixatedReinbursementAllowed",
  "setFixatedReinbursementAuthorizedList",
  "setFixatedReinbursementValue",
  "setFixedRefundExcludeList",
]);

export const actionMap = {
  activePlansCode: AddPlanAction.enum.setActivePlansCode,
  descriptionOrReference: AddPlanAction.enum.setDescriptionOrReference,
  globalLimitPerEnterprise: AddPlanAction.enum.setGlobalLimitPerEnterprise,
  individualLimit: AddPlanAction.enum.setIndividualLimit,
  boxesNumber: AddPlanAction.enum.setBoxesNumber,
  accountStatus: AddPlanAction.enum.setAccountStatus,
  acquisitionAllowed: AddPlanAction.enum.setAcquisitionAllowed,
  authorizedList: AddPlanAction.enum.setAuthorizedList,
  acquisitionExcludeList: AddPlanAction.enum.setAcquisitionExcludeList,
  varyingReinbursementAllowed: AddPlanAction.enum.setVaryingReinbursementAllowed,
  varyingReinbursementAuthorizedList: AddPlanAction.enum.setVaryingReinbursementAuthorizedList,
  varyingReinbursementPercentage: AddPlanAction.enum.setVaryingReinbursementPercentage,
  varyingRefundExcludeList: AddPlanAction.enum.setVaryingRefundExcludeList,
  fixatedReinbursementAllowed: AddPlanAction.enum.setFixatedReinbursementAllowed,
  fixatedReinbursementAuthorizedList: AddPlanAction.enum.setFixatedReinbursementAuthorizedList,
  fixatedReinbursementValue: AddPlanAction.enum.setFixatedReinbursementValue,
  fixedRefundExcludeList: AddPlanAction.enum.setFixedRefundExcludeList,
};

export type TAddPlanAction = z.infer<typeof AddPlanAction>;

export type TContext = {
  state: TAddPlan;
  dispatch: (action: TAction) => void;
};

export {
  AddPlanAction,
  AddPlanSchema,
  AcquisitionExcludeParams,
  VaryingRefundExcludeParams,
  FixedRefundExcludeParams,
  includeParam,
};
// type Spread<Type> = { [Key in keyof Type]: Type[Key] }
