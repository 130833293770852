/* eslint-disable react/jsx-key */
import React, { useEffect, useState, useMemo } from "react";
import { useTable } from "react-table";
import { ToastContainer, toast } from "react-toastify";
import { useForm, FormProvider } from "react-hook-form";
import { api } from "../../utils/services/api";
import { LeftSidebar } from "../../components/LeftSidebar";
import { RegisterEnterpriseFormStepper } from "../../components/RegisterEnterpriseFormStepper";

import { useRegisterEnterprise } from "../../hooks/RegisterEnterpriseContext";

import MainContainer from "../../components/layout/MainContainer";
import LeftBarContainer from "../../components/layout/LeftBarContainer";
import Content from "../../components/layout/Content";
import { SelectAvailablePlans } from "../../components/SelectAvailablePlans";
import { TopbarEnterprise } from "../../components/TopbarEnterprise";

export const EnterpriseRegisterStep3 = () => {
  const [enterpriseRows, setEnterpriseRows] = useState<any[]>([]);
  const [plansRows, setPlansRows] = useState<any[]>([]);
  const [AllPlans, setAllPlans] = useState<any[]>([]);
  const createEnterpriseFormStepThree = useForm();
  const { state, dispatch } = useRegisterEnterprise();
  const [isDeleting, setIsDeleting] = useState(false);

  const fetchEnterpriseData = async () => {
    try {
      const enterpriseId = localStorage.getItem("choosedCompany");

      // Verifica se o enterpriseId foi obtido
      if (!enterpriseId) {
        console.error("Nenhum ID de empresa encontrado no localStorage");
        return; // Encerra a função se não houver enterpriseId
      }

      const getAllPlansResponse = await api.get(`/plan/`);
      const allPlans = getAllPlansResponse.data.plans; // Ajuste esta linha conforme a estrutura real
      setAllPlans(allPlans);

      const resEnterpriseResponse = await api.get(`/businessAcc/${enterpriseId}/plans`);
      const plansWithUserCount = resEnterpriseResponse.data.plansWithUserCount; // Ajuste conforme necessário
      setEnterpriseRows(plansWithUserCount);

      // Certifique-se de que ambos allPlans e plansWithUserCount são arrays
      if (Array.isArray(allPlans) && Array.isArray(plansWithUserCount)) {
        // Filtrar para incluir apenas os planos que estão presentes em ambos os arrays
        const matchingPlans = allPlans.filter((plan) =>
          plansWithUserCount.some((associatedPlan) => associatedPlan.id === plan.id),
        );

        setPlansRows(matchingPlans);
      } else {
        console.error("Dados recebidos não são arrays");
      }
    } catch (e: any) {
      console.error(e);
      setEnterpriseRows([]);
    }
  };

  const handleRemovePlan = async (businessId: any, planId: any) => {
    if (window.confirm("Deseja desvincular o plano?")) {
      setIsDeleting(true); // Begin the deletion process

      try {
        await api.delete(`/businessAcc/unlinkPlan/${businessId}/${planId}`);
        fetchEnterpriseData(); // Atualiza os dados após a remoção bem-sucedida
        // Você pode adicionar uma notificação de sucesso aqui
        toast.success("Remoção do plano bem-sucedida");
      } catch (error) {
        console.error(error);
      } finally {
        setIsDeleting(false); // Reset the state, whether successful or not
      }
    }
  };

  useEffect(() => {
    fetchEnterpriseData();
  }, [plansRows]);

  const columns: any = useMemo(
    () => [
      {
        Header: "# Plano",
        accessor: "plan_code",
        Cell: ({ value }: { value: string }) => value.substring(0, 4),
      },
      { Header: "Descrição Plano", accessor: "description_or_reference" },
      {
        Header: "Limite Global",
        accessor: "global_monthly_limit",
        Cell: ({ value }: { value: number }) => {
          const formattedValue = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(value);
          return formattedValue;
        },
      },
      {
        Header: "Limite Individual",
        accessor: "individual_monthly_limit",
        Cell: ({ value }: { value: number }) => {
          const formattedValue = new Intl.NumberFormat("pt-BR", {
            style: "currency",
            currency: "BRL",
          }).format(value);
          return formattedValue;
        },
      },
    ],
    [],
  );

  let someRows = plansRows;

  if (typeof enterpriseRows !== "undefined") {
    someRows = someRows?.concat(enterpriseRows);
  }

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data: someRows });

  const filteredRowsLinkedPlans = rows.filter((row, index, self) => {
    // Verifica se o plano atual está em plansRows
    const isPlanInPlansRows = plansRows.some((plan) => plan.plan_code === row.values.plan_code);

    // Encontra o índice do primeiro plano com o mesmo plan_code no array filtrado até agora
    const firstIndex = self.findIndex((r) => r.values.plan_code === row.values.plan_code);

    // Inclui o plano se estiver em plansRows e se for a primeira ocorrência desse plano no array filtrado
    return isPlanInPlansRows && firstIndex === index;
  });

  // Passo 1: Extrair os códigos dos planos vinculados
  const linkedPlanCodes = filteredRowsLinkedPlans.map((linkedPlan) => linkedPlan.values.plan_code);

  // Passo 2: Filtrar AllPlans para obter os planos não vinculados
  const unlinkedPlans = AllPlans.filter((plan) => !linkedPlanCodes.includes(plan.plan_code));

  return (
    <>
      <ToastContainer />
      <MainContainer className='bg-[#03001A]'>
        <LeftBarContainer className='bg-[#03001A]'>
          <LeftSidebar />
        </LeftBarContainer>

        <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, width: "480%" }}>
          <TopbarEnterprise hideAll />
          <Content className='bg-omni-gray rounded-[2rem] h-[100%] p-5 relative mb-10 mt-[-4.5rem]'>
            <div className='flex justify-between items-center m-5'>
              <h1 className='font-manrope font-bold font-regular text-5xl text-white justify-center mt-1'>
                Adicionar Planos
              </h1>
            </div>

            <div className='m-5'>
              <RegisterEnterpriseFormStepper currentStep={2} />
              <div className='border-t border-[#0517F5] -mt-4'></div>
              <FormProvider {...createEnterpriseFormStepThree}>
                <h2 className='text-xl text-opaque-white pt-6'>Defina quais planos estão vinculados à esta empresa </h2>
                <div className='flex flex-row gap-x-10 text-opaque-white pt-6 mb-3'>
                  <label className='flex flex-col'>
                    Código do Plano
                    <SelectAvailablePlans enterpriseRows={unlinkedPlans} setEnterpriseRows={setEnterpriseRows} />
                  </label>
                </div>
              </FormProvider>
              <div className='font-thin mt-8'>
                <section className='rounded-lg  '>
                  <table {...getTableProps()} className='w-full rounded-[1rem] bg-[#04011B]'>
                    <thead className='rounded-lg font-medium'>
                      <tr>
                        <th className='py-2 px-4 font-medium text-center text-white rounded-lg'>Plano</th>
                        <th className='py-2 px-4 font-medium text-center text-white rounded-lg'>Descrição do Plano</th>
                        <th className='py-2 px-4 font-medium text-center text-white rounded-lg'>Limite Global</th>
                        <th className='py-2 px-4 font-medium text-center text-white rounded-lg'>Limite Individual</th>
                        <th className='py-2 px-4 font-medium text-center text-white rounded-lg'>Ação</th>
                      </tr>
                    </thead>
                    <tbody {...getTableBodyProps()} className='rounded-lg'>
                      {filteredRowsLinkedPlans?.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row?.getRowProps()} className='text-center text-white'>
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()} className='py-2 px-4'>
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                            <td className='py-2 px-4'>
                              <button
                                onClick={() => handleRemovePlan(state.enterpriseId, row.original.id)}
                                className='bg-[#020B60] rounded-lg px-4 py-2 text-white font-medium'
                              >
                                Remover
                              </button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </section>
              </div>
            </div>
          </Content>
        </div>
      </MainContainer>
    </>
  );
};
