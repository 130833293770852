import React, { createContext, useState } from "react";

interface LoginContextState {
  linkedCompany: string;
  setLinkedCompany: React.Dispatch<React.SetStateAction<string>>;
}

const initialUserData: LoginContextState = {
  linkedCompany: "",
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLinkedCompany: () => {}, // Initial empty function
};

export const LoginContext = createContext<LoginContextState>(initialUserData);

export const LoginProvider = ({ children }: { children: JSX.Element }) => {
  const [linkedCompany, setLinkedCompany] = useState(initialUserData.linkedCompany);

  const value = { linkedCompany, setLinkedCompany };

  return <LoginContext.Provider value={value}>{children}</LoginContext.Provider>;
};
