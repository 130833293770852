import { z } from "zod";

const RegisterEnterpriseSchemaStepOne = z.object({
  id: z.string().optional(),
  enterpriseName: z.string().nonempty(),
  cnpj: z.string().nonempty(),
  representativesName: z.string().optional(),
  representativesCorporateEmail: z.string().email("E-mail inválido").optional(),
  enterpriseCep: z.string().nonempty(),
  enterpriseAddress: z.string().nonempty(),
  enterpriseNumber: z.string().nonempty(),
  enterpriseComplement: z.string().nonempty(),
  enterpriseCity: z.string().nonempty(),
  enterpriseState: z.string().nonempty(),
  accountStatus: z.string().nonempty(),
});

const RegisterEnterpriseSchemaStepTwo = z.object({
  fullName: z.string().nonempty(),
  phoneNumber: z.string().nonempty(),
  corporateEmail: z.string().email("E-mail inválido").nonempty(),
  cpf: z.string().nonempty().min(14).max(14, "CPF inválido"),
  gender: z.string().nonempty(),
  birthDate: z.string().nonempty(),
  role: z.string().nonempty(),
  accountType: z.string().nonempty(),
  crm: z
    .string()
    .optional()
    .refine((value) => !value || (value.length >= 1 && value.length <= 6), {
      message: "CRM deve ter entre 1 e 6 caracteres",
      path: ["crm"],
    }),
  uf: z
    .string()
    .optional()
    .refine((value) => !value || value.length === 2, {
      message: "UF deve ter 2 caracteres",
      path: ["uf"],
    }),
});

export type RegisterEnterpriseStepOneSchemaType = z.infer<typeof RegisterEnterpriseSchemaStepOne>;

export type RegisterEnterpriseStepTwoSchemaType = z.infer<typeof RegisterEnterpriseSchemaStepTwo>;

export { RegisterEnterpriseSchemaStepOne, RegisterEnterpriseSchemaStepTwo };
