import React, { useEffect, useLayoutEffect, useState } from "react";
import { api } from "../../utils/services/api";
import { BeatLoader } from "react-spinners";
import { supabaseConfig } from "../../config/supabaseConfig";

interface SelectCompanyFromHoldingProps {
   holdingSubsidiary: any;
   setHoldingSubsidiary: (company: string) => void;
   colaborator?: any;
}

export function SelectCompanyFromHolding({ holdingSubsidiary, setHoldingSubsidiary, colaborator }: SelectCompanyFromHoldingProps) {
   const [subsidiaries, setSubsidiaries] = useState([]);
   const [loading, setLoading] = useState(true);

   const canShow = () => {
      const userType = localStorage.getItem("userType");
      const linkedCompany = localStorage.getItem("linkedCompany");
      if (userType === "Admin" && linkedCompany === "0032aee1-af95-4bba-a39f-4292e139d458") {
         return;
      }
      return "hidden";
   };
   const getSubsidiaries = async () => {
      try {
         const editedUserId = localStorage.getItem("userEdition");
         const response = await api.get("/holding/sisters", {
            params: {
               userId: editedUserId,
            },
         });
         setSubsidiaries(response.data.message.value);
         if (!colaborator && response.data.message.value.length > 0) {
            setHoldingSubsidiary(response.data.message.value[0].id); // Instancia o primeiro item da lista
         }
      } catch (error) {
         console.log(error);
      } finally {
         setLoading(false);
      }
   };

   const populateCurrentSubsidiary = async () => {
      const linkedCompany = localStorage.getItem("linkedCompany");
      if (linkedCompany === "allHCompanies" || linkedCompany === "allCompanies") {
         if (colaborator) {
            try {
               const { data, error } = await supabaseConfig
                  .from("business_acc")
                  .select("*")
                  .eq("enterprise_name", colaborator.user.linked_company);
               console.log(data[0], "encontrou a empresa do virgumino");

               if (data && data.length > 0) {
                  setHoldingSubsidiary(data[0].id);
               }
            } catch (error) {
               console.log("Error fetching company:", error);
            }
         }
      } else if (linkedCompany) {
         setHoldingSubsidiary(linkedCompany);
      }
   };

   useLayoutEffect(() => {
      getSubsidiaries();
      populateCurrentSubsidiary();
   }, []);

   useEffect(() => {
      // Chamada de populateCurrentSubsidiary agora está dentro de um useEffect que monitora colaborator
      if (colaborator) {
         populateCurrentSubsidiary();
      }
   }, [colaborator]);

   const handleSelectChange = (event) => {
      setHoldingSubsidiary(event.target.value);
   };

   return (
      <div className={`${canShow()} flex flex-col w-full`}>
         <label htmlFor='currentCompany' className='text-white mt-4'>
            Empresa
         </label>
         {loading ? (
            <BeatLoader size={15} color={"#0517F5"} loading={loading} />
         ) : (
            <select
               name='currentCompany'
               id='currentCompany'
               value={holdingSubsidiary}
               defaultValue={holdingSubsidiary}
               onChange={handleSelectChange}
               className='custom-select w-full rounded-md text-white px-3 py-2 h-14 mt-1 bg-[#03001A]'
            >
               {subsidiaries.length > 0 &&
                  subsidiaries.map((subsidiary) => (
                     <option key={subsidiary.enterprise_name} value={subsidiary.id}>
                        {subsidiary.enterprise_name}
                     </option>
                  ))}
            </select>
         )}
      </div>
   );
}
