/* eslint-disable @typescript-eslint/no-explicit-any */
import { api } from "../utils/services/api";
import { toast } from "react-toastify";
import Select, { CSSObjectWithLabel } from "react-select";
import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useRegisterEnterprise } from "../hooks/RegisterEnterpriseContext";
import { Link } from "react-router-dom";

export const SelectAvailablePlans = ({
  enterpriseRows,
  setEnterpriseRows,
}: {
  enterpriseRows: any;
  setEnterpriseRows: any;
}) => {
  const { state, dispatch } = useRegisterEnterprise();
  const [assignedPlans, setAssignedPlans] = useState<string[]>([]);
  const linkedCompany = localStorage.getItem("linkedCompany");

  const { handleSubmit } = useFormContext();
  const handleRegisterEnterprise = (data: any) => {
    try {
      const obj = {
        enterprise_name: state.enterpriseName,
        cnpj: state.cnpj,
        enterprise_cep: state.enterpriseCep,
        address: state.enterpriseAddress,
        enterprise_number: state.enterpriseAddressNumber,
        enterprise_complement: state.enterpriseComplement,
        enterprise_city: state.enterpriseCity,
        enterprise_state: state.enterpriseState,
        account_status: state.enterpriseAccountStatus,
        representative_fullname: state.representativesFullName,
        representative_corporate_email: state.representativesCorporateEmail,
        representative_phone: state.representativesPhone,
        representative_cpf: state.representativesCpf,
        representative_gender: state.representativesGender,
        representative_birth_date: state.representativesBirthDate,
        representative_role: state.representativesRole,
        account_type: state.representativesAccountType,
        plans_pack: assignedPlans,
      };
      api.post("/businessAcc", obj);
      toast.success("Empresa criada com sucesso!");
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  const handleAssignPlan = async () => {
    const selectedPlansId = enterpriseRows
      .filter((plan: any) => assignedPlans.includes(plan.plan_code))
      .map((plan: any) => plan.id);

    const obj = {
      id: state.enterpriseId,
      plans: selectedPlansId,
    };

    try {
      const response = await api.post(`/businessAcc/linkPlan/${linkedCompany}`, obj);
      if (response.status === 200) {
        toast.success("Planos atribuídos com sucesso!");

        const selectedPlans = enterpriseRows.filter((plan: any) => assignedPlans.includes(plan.plan_code));

        const updatedRows = enterpriseRows.concat(selectedPlans);
        setEnterpriseRows(updatedRows);

        setAssignedPlans([]);
      }
    } catch (error: any) {
      toast.error(error.message);
    }
  };

  // event is not typed because react-select has its default and it's not compatible with the usual types, i.e React.ChangeEvent<HTMLSelect> etc
  type HandleSelectChange = typeof options;
  const handleSelectChange = (selectedOptions: HandleSelectChange) => {
    const selectedOptionValues = selectedOptions.map((option: any) => option.label);
    setAssignedPlans(selectedOptionValues);
  };

  const options = enterpriseRows.map((plan: any) => ({
    value: plan.id,
    label: plan.plan_code,
  }));

  return (
    <>
      <form className='flex flex-col mt-3 gap-3' onSubmit={handleSubmit(handleRegisterEnterprise)}>
        <Select
          options={options}
          isMulti
          value={options.filter((option: any) => assignedPlans.includes(option.label))}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: "#0517F5",
            },
          })}
          styles={{
            control: (baseStyles, state) =>
              ({
                ...baseStyles,
                borderColor: state.isFocused ? "#0517F5" : "#6B6F79",
                background: "#03001A",
                color: "white", // ajustado para uma cor válida
              }) as CSSObjectWithLabel,
            menu: (baseStyles) =>
              ({
                ...baseStyles,
                backgroundColor: "#03001A",
              }) as CSSObjectWithLabel,
            option: (baseStyles, state) =>
              ({
                ...baseStyles,
                backgroundColor: state.isFocused ? "#0517F5" : "white",
                borderRadius: "0.375rem",
                color: state.isSelected ? "white" : "black",
                "&:hover": {
                  backgroundColor: "#0517F5",
                  color: "white",
                },
              }) as CSSObjectWithLabel,
          }}
          className='rounded-md'
          onChange={handleSelectChange}
          placeholder='ex.: OM-A0001'
        />
        <div className='mt-4 inline-flex text-sm justify-around gap-x-5 min-x-xl'>
          <button
            className='bg-inherit text-white w-1/2 px-8 border-white border-[0.06rem] rounded-md py-2'
            type='button'
            onClick={handleAssignPlan}
          >
            Adicionar
          </button>

          <button
            className='bg-inherit flex-shrink-0 text-white w-1/2 px-8 border-white border-[0.06rem] rounded-md inline  py-2'
            type='button'
          >
            <Link to='/adicionar-plano/'>Criar Novo</Link>
          </button>
        </div>
        <button
          type='submit'
          //	disabled={isSubmitting}
          className='bg-omni-blue rounded-md mt-5 py-3 px-9 text-white w-3/5 text-center disabled:bg-zinc-50'
        >
          Finalizar
        </button>
      </form>
    </>
  );
};
