import { SvgIcon, SvgIconProps } from "@mui/material";

const BoardIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M11.8125 1.125H10.5907C10.359 0.47025 9.7335 0 9 0C8.2665 0 7.641 0.47025 7.40813 1.125H6.1875C5.877 1.125 5.625 1.377 5.625 1.6875V2.8125C5.625 3.123 5.877 3.375 6.1875 3.375H11.8125C12.123 3.375 12.375 3.123 12.375 2.8125V1.6875C12.375 1.377 12.123 1.125 11.8125 1.125Z'
          fill='white'
          fillOpacity='0.87'
        />
        <path
          d='M14.625 2.25H13.5V2.8125C13.5 3.74288 12.7429 4.5 11.8125 4.5H6.1875C5.25712 4.5 4.5 3.74288 4.5 2.8125V2.25H3.375C2.75513 2.25 2.25 2.75513 2.25 3.375V16.875C2.25 17.5061 2.74388 18 3.375 18H14.625C15.2561 18 15.75 17.5061 15.75 16.875V3.375C15.75 2.74388 15.2561 2.25 14.625 2.25ZM9.5625 5.68238C10.6627 5.90625 11.5313 6.77475 11.7551 7.875H9.5625V5.68238ZM8.4375 5.68238V8.4375C8.4375 8.748 8.6895 9 9 9H11.7551C11.4941 10.2825 10.359 11.25 9 11.25C7.44975 11.25 6.1875 9.98775 6.1875 8.4375C6.1875 7.07962 7.155 5.94338 8.4375 5.68238ZM9.5625 15.75H5.0625C4.752 15.75 4.5 15.498 4.5 15.1875C4.5 14.877 4.752 14.625 5.0625 14.625H9.5625C9.873 14.625 10.125 14.877 10.125 15.1875C10.125 15.498 9.873 15.75 9.5625 15.75ZM12.9375 13.5H5.0625C4.752 13.5 4.5 13.248 4.5 12.9375C4.5 12.627 4.752 12.375 5.0625 12.375H12.9375C13.248 12.375 13.5 12.627 13.5 12.9375C13.5 13.248 13.248 13.5 12.9375 13.5Z'
          fill='white'
          fillOpacity='0.87'
        />
      </svg>
    </SvgIcon>
  );
};

export default BoardIcon;
