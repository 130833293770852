export type TAccountStatus = "Ativa" | "Canceleda" | "Bloqueada";
export type TGender = "Male" | "Female" | "Other";
export type TRole = "Benefits Manager" | "Beneficiary" | "Superuser" | "Human Resources";
export type TState =
   | "SP"
   | "RJ"
   | "MG"
   | "RS"
   | "PR"
   | "SC"
   | "ES"
   | "BA"
   | "PE"
   | "CE"
   | "RN"
   | "PB"
   | "SE"
   | "AL"
   | "PI"
   | "MA"
   | "PA"
   | "AM"
   | "AC"
   | "RO"
   | "RR"
   | "AP"
   | "TO"
   | "DF"
   | "GO"
   | "MT"
   | "MS";

export interface RegisterEnterpriseState {
   enterpriseDetails: EnterpriseDetails | null;
}

export interface EnterpriseDetails {
   enterpriseId: string;
   account_status: string;
   enterpriseAddress: string;
   cnpj: string;
   createdAt: string;
   enterpriseCep: string;
   enterpriseCity: string;
   enterpriseComplement: string;
   enterpriseName: string;
   enterpriseAddressNumber: string;
   enterpriseState: string;
   monthly_payment: null | number;
}

export type TRegisterEnterprise = {
   enterpriseId: string;
   currentStep: number;
   enterpriseName: string;
   cnpj: string;
   enterpriseCep: string;
   enterpriseAddress: string;
   enterpriseAddressNumber: number;
   enterpriseComplement?: string;
   enterpriseCity: string;
   enterpriseState: TState;
   enterpriseAccountStatus: TAccountStatus;
   representativesFullName: string;
   representativesPhone: string;
   representativesCorporateEmail: string;
   representativesCpf: string;
   representativesGender: TGender;
   representativesBirthDate: string;
   representativesRole: TRole;
   representativesAccountType: string;
   crm: string;
   uf: string;
   inicioVigencia: string;
   fimVigencia: string;
};

export type TAction = {
   type: RegisterEnterpriseAction;
   payload: any;
};

export enum RegisterEnterpriseAction {
   setEnterpriseId,
   setCurrentStep,
   setEnterpriseName,
   setCnpj,
   setEnterpriseCep,
   setEnterpriseAddress,
   setEnterpriseAddressNumber,
   setEnterpriseComplement,
   setEnterpriseCity,
   setEnterpriseState,
   setEnterpriseAccountStatus,
   setRepresentativesFullName,
   setRepresentativesPhone,
   setRepresentativesCorporateEmail,
   setRepresentativesCpf,
   setRepresentativesGender,
   setRepresentativesBirthDate,
   setRepresentativesRole,
   setRepresentativesAccountType,
   setRepresentativeAdditionalInfo,
   setRepresentativesCRM,
   setRepresentativesUF,
   setInicioVigencia,
   setFimVigencia,
}

export type TContext = {
   state: TRegisterEnterprise;
   dispatch: (action: TAction) => void;
};
