import React, { useEffect, useState } from "react";
import { api } from "../../utils/services/api";

const CurrentTreatment = (currentTreatment: any) => {
  const [agenda, setAgenda] = useState([]);
  const [treatments, setTreatments] = useState([]);
  const [activeTreatments, setActiveTreatments] = useState([]);

  const userType = localStorage.getItem("userType");

  const cellStyle = {
    fontWeight: 400,
    color: "rgba(255, 255, 255, 0.6)",
    padding: "1.1rem",
  };

  useEffect(() => {
    const fetchTreatments = async () => {
      try {
        const userID = localStorage.getItem("userEdition");
        const response = await api.get(`/treatment/${userID}`);
        const currentDate = new Date().toISOString().split("T")[0]; // Get current date in YYYY-MM-DD format

        // Filter treatments to include only active ones
        const activeTreatments = response.data.filter((treatment: any) => {
          return treatment.agenda.some((agendaItem: any) => {
            const agendaDate = agendaItem.data.split("T")[0];
            return agendaDate >= currentDate;
          });
        });

        setActiveTreatments(activeTreatments);
      } catch (error) {
        console.error("Failed to fetch treatments:", error);
      }
    };

    fetchTreatments();
  }, []);

  const getStatus = (treatment: any) => {
    if (treatment.status === "Tomado") {
      return "Em Linha";
    } else {
      return "Atrasado";
    }
  };

  return (
    <div>
      <h1 className='font-manrope pb-[-5] pt-6 pr-4 text-4xl ml-3 pl-5' style={{ color: "rgba(255, 255, 255, 0.87)" }}>
        Tratamentos Atual
      </h1>

      <table
        className='w-5/6 m-5'
        style={{ background: "linear-gradient(0deg, #03001A, #03001A)", borderRadius: "20px" }}
      >
        <tbody>
          {activeTreatments.length > 0
            ? activeTreatments.map((treatment: any, index: number) => (
                <tr
                  style={{
                    padding: "1rem 0",
                    borderBottom: index !== activeTreatments.length - 1 ? "1px solid rgba(255, 255, 255, 0.3)" : "none",
                  }}
                  key={treatment.id}
                >
                  <td
                    className='text-base font-bold td-narrow'
                    style={{ fontWeight: 400, color: "rgba(255, 255, 255, 0.3)", padding: "1.0rem" }}
                  >
                    {userType === "Admin" ? treatment.medicamento || "" : "XXXXX"}
                  </td>
                  <td className='text-left'>
                    <span
                      className={`text-white text-sm rounded-full py-2 px-3 mr-2 ${
                        getStatus(agenda) === "Em Linha" ? "bg-green-500" : "bg-yellow-500"
                      }`}
                    >
                      {getStatus(agenda)}
                    </span>
                  </td>
                  <td
                    className='text-left'
                    style={{ ...cellStyle, fontWeight: 300, borderLeft: "1px solid rgba(255, 255, 255, 0.3)" }}
                  >
                    {treatment.un_dose ? treatment.un_dose.split(",")[0] : ""}
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </table>
    </div>
  );
};

export default CurrentTreatment;
