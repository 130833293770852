/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from "react";
import {
  useTable,
  Column,
  usePagination,
  TableInstance,
  Row,
  useSortBy,
  UseTableColumnProps,
  HeaderGroup,
} from "react-table";
import { useNavigate } from "react-router-dom";
import { useRegisterColaborator } from "../../hooks/ColaboratorContext";
import { RegisterColaboratorAction } from "../../types/CollaboratorDataTypes";
import { useUserContext } from "../../hooks/userContext";
import Pagination from "./Paginação";

interface ColumnWithSorting<D extends object> extends UseTableColumnProps<D> {
  getSortByToggleProps: (...args: any) => any;
  isSorted: boolean;
  isSortedDesc: boolean;
}

interface HeaderGroupWithSorting<D extends Record<string, unknown>> extends HeaderGroup<D> {
  getSortByToggleProps: (...args: any) => any;
  isSorted: boolean;
  isSortedDesc: boolean;
}

interface ApiData extends Record<string, unknown> {
  id: string;
  fullName: string;
  treatmentDays: number;
  condition: string;
  status: string;
  planType: string;
}

type ColumnType = Column;

interface CollaboratorTableProps {
  columns: ColumnType[];
  data: ApiData[];
  onPageChange: (pageIndex: number) => void;
  totalPages: number;
  currentPage: number;
}

interface TableInstanceWithPagination<T extends ApiData> extends Omit<TableInstance<T>, "state"> {
  page: Row<T>[];
  pageCount: number;
  canPreviousPage: boolean;
  canNextPage: boolean;
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
  nextPage: () => void;
  previousPage: () => void;
  state: any;
}

const CollaboratorTable: React.FC<CollaboratorTableProps> = ({
  columns,
  data,
  onPageChange,
  totalPages,
  currentPage,
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state: { pageIndex },
  } = useTable(
    {
      initialState: { sortBy: [{ id: "fullName", desc: false }] } as any,
      columns,
      data,
    },
    useSortBy,
    usePagination,
  ) as TableInstanceWithPagination<ApiData>;

  const navigate = useNavigate();
  const { state, dispatch } = useRegisterColaborator();
  const { setUserEdition } = useUserContext();
  const [sortedData, setSortedData] = useState<ApiData[]>([]);
  const [sortConfig, setSortConfig] = useState({ key: "", ascending: true });

  useEffect(() => {
    const sorted = sortData(data, sortConfig);
    setSortedData(sorted);
  }, [data, sortConfig]);

  const handlePageChange = (newPage: number) => {
    onPageChange(newPage); // Isso deve atualizar o estado `currentPage` no componente pai
  };

  const sortData = (data: ApiData[], config: any) => {
    const { key, ascending } = config;
    return [...data].sort((a: any, b: any) => {
      const valA = a[key];
      const valB = b[key];
      if (valA < valB) return ascending ? -1 : 1;
      if (valA > valB) return ascending ? 1 : -1;
      return 0;
    });
  };

  const navigateToProfile = (row: Row<ApiData>) => {
    dispatch({
      type: RegisterColaboratorAction.setColaboratorId,
      payload: row.values.id,
    });
    setUserEdition(row.values.id);
    localStorage.setItem("userEdition", row.values.id);
    navigate(`/perfil-colaborador`);
  };

  const formatPlanCode = (code: string) => {
    let planName = "";
    let credits = "";

    if (code.startsWith("R")) {
      planName = "Royal";
    } else if (code.startsWith("C")) {
      planName = "Celeste";
    } else if (code.startsWith("T")) {
      planName = "Turquesa";
    }

    credits = code.match(/\d+/)?.[0] || ""; // Extract numerical characters

    return `${planName} ${credits}`;
  };

  const renderCell = (cell, row) => {
    let cellValue = cell.value;
    let cellClasses = `px-4 mt-3 py-2`;
    let cellStyles = {};

    switch (cell.column.id) {
      case "id":
        cellValue = `#${cellValue.substring(0, 5)}`;
        cellClasses += " rounded-l-lg";
        break;
      case "fullName":
        cellClasses += " underline cursor-pointer";
        cellValue = <span onClick={() => navigateToProfile(row)}>{cellValue}</span>;
        break;
      case "status":
        cellStyles = { color: getStatusColor(cell.row.original.status) };
        break;
      case "planType":
        cellValue = formatPlanCode(cellValue);
        cellClasses += " rounded-r-lg";
        break;
      default:
      // No additional formatting
    }
    return (
      <td {...cell.getCellProps()} className={cellClasses} style={cellStyles}>
        {cellValue}
      </td>
    );
  };

  return (
    <>
      <table {...getTableProps()} className='w-full mt-5 text-white px-4 border-separate border-spacing-y-2'>
        <thead className='text-white mt-5'>
          {(headerGroups as HeaderGroupWithSorting<ApiData>[]).map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              {(headerGroup.headers as unknown as ColumnWithSorting<ApiData>[]).map((column, i) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={i}
                  className='text-white font-light opacity-60 pl-2 px-4 text-left'
                >
                  {column.render("Header")}
                  <span>{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}</span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className='bg-[#03001A] border-omni-gray hover:bg-gray-800 hover:bg-opacity-50'
                style={{ height: "3.75rem" }}
                key={`row-${rowIndex}`}
              >
                {row.cells.map((cell) => renderCell(cell, row))}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className='flex justify-end space-x-4 text-white mx-7 mt-3'>
        <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={onPageChange} />
      </div>
    </>
  );
};

const getStatusColor = (status: string) => {
  let color = "";

  switch (status) {
    case "Ativo":
      color = "#28D196";
      break;
    case "Bloqueada":
      color = "#F8AE53";
      break;
    case "Cancelada":
      color = "#FF5B26";
      break;
    default:
      break;
  }

  return color;
};

export default CollaboratorTable;
