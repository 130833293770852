/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from "react";
import { Row, TableInstance } from "react-table";
import { Link, useNavigate } from "react-router-dom";
import EyeIcon from "../../components/icons/EyeIcon";

export const TableLayout = ({ TableLayoutProps }: { TableLayoutProps: TableInstance }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [sortedData, setSortedData] = useState<any[]>([]);
  const [sortConfig, setSortConfig] = useState({ key: "dateTime", ascending: false });
  const [filterValue, setFilterValue] = useState<string | undefined>(undefined);

  useEffect(() => {
    let filteredRows = TableLayoutProps.rows;

    if (filterValue && filterValue !== "Todos") {
      filteredRows = TableLayoutProps.rows.filter((row) => (row.original as any).status === filterValue);
    }

    const sorted = sortData(filteredRows, sortConfig);
    setSortedData(sorted);

    if (filterValue) {
      setCurrentPage(0);
    }

    // Adjust the currentPage if it is out of bounds due to filtering
    if (filteredRows.length === 0) {
      setCurrentPage(0);
    } else if (Math.ceil(filteredRows.length / itemsPerPage) < currentPage) {
      setCurrentPage(Math.ceil(filteredRows.length / itemsPerPage));
    }
  }, [TableLayoutProps.rows, sortConfig, filterValue]);

  const gotoPage = (page: number) => {
    setCurrentPage(page);
  };

  const sortData = (data: Array<Row>, config: { ascending: boolean; key: string }) => {
    const { key, ascending } = config;

    const isDateTime = key === "dateTime";
    const isMonetary = key === "valor";

    const formatToISO = (dateStr: string) => {
      const [date, time] = dateStr.split(", ");
      const [day, month, year] = date.split("/");
      return `${year}-${month}-${day}T${time}:00`;
    };

    const formatToNumber = (monetaryStr: string) => {
      return parseFloat(monetaryStr.replace(/[^0-9.-]+/g, ""));
    };

    return [...data].sort((a: any, b: any) => {
      let valA = a.original[key];
      let valB = b.original[key];

      if (isDateTime) {
        valA = new Date(formatToISO(valA));
        valB = new Date(formatToISO(valB));
      } else if (isMonetary) {
        valA = formatToNumber(valA);
        valB = formatToNumber(valB);
      }

      if (valA < valB) return ascending ? -1 : 1;
      if (valA > valB) return ascending ? 1 : -1;
      return 0;
    });
  };

  const renderCell = (cell: any, row: any) => {
    let cellClasses = "px-4 py-3 text-white rounded text-left";
    let cellColor = "text-white";
    let truncatedValue = "";

    if (cell.value === "OK") {
      cellColor = "text-teal-400";
    } else if (cell.value === "Pendente") {
      cellColor = "text-omni-orange";
    } else if (cell.value === "Verificação Manual") {
      cellColor = "text-white";
    } else if (cell.value === "Faltam Anexos") {
      cellColor = "text-omni-light-orange";
    } else if (cell.value === "Ver Detalhes") {
      cellColor = "text-opaque-white underline";
    }

    switch (cell.column.id) {
      case "colaborador":
        cellClasses += " rounded-l-lg";
        break;
      case "action":
        cellClasses += " rounded-r-lg";
        return (
          <td {...cell.getCellProps()} className={cellClasses}>
            <div className='flex justify-start'>
              <Link to={`/aquisicoes/${row.original.id}`}>
                <EyeIcon />
              </Link>
            </div>
          </td>
        );
      case "status":
        cellClasses += "rounded-r-lg";
        truncatedValue = String(cell.value).length > 30 ? `${String(cell.value).substr(0, 20)}...` : cell.value;
        return (
          <td {...cell.getCellProps()} className={cellClasses}>
            <span className='text-sm'>
              <span className={cellColor}>{truncatedValue}</span>
            </span>
          </td>
        );
      case "local":
        cellClasses += "rounded-r-lg";
        truncatedValue = String(cell.value).length > 30 ? `${String(cell.value).substr(0, 15)}...` : cell.value;
        return (
          <td {...cell.getCellProps()} className={cellClasses}>
            <span className='text-sm'>
              <span className={cellColor}>{truncatedValue}</span>
            </span>
          </td>
        );
      default:
        break;
    }
    return (
      <td {...cell.getCellProps()} className={cellClasses}>
        <span className='text-sm'>
          <span className={cellColor}>{cell.value}</span>
        </span>
      </td>
    );
  };

  const navigate = useNavigate();

  const itemsPerPage = 10; // Número de itens por página
  const pageCount = Math.max(1, Math.ceil(sortedData.length / itemsPerPage));

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentRows = sortedData.slice(startIndex, endIndex);

  const maxPagesToShow = 3; // Número máximo de páginas a serem exibidas

  let startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 0);
  const endPage = Math.min(startPage + maxPagesToShow, pageCount) - 1;

  if (endPage === pageCount - 1) {
    startPage = Math.max(pageCount - maxPagesToShow, 0);
  }

  return (
    <div>
      <table
        {...TableLayoutProps.getTableProps()}
        className='w-full mt-5 px-4 rounded-md border-separate border-spacing-y-2'
      >
        <thead className='mt-5'>
          {TableLayoutProps.headerGroups.map((headerGroup: any, index) => (
            <tr key={index} {...headerGroup.getHeaderGroupProps()} className='px-4 py-2'>
              {headerGroup.headers.map((column: any) => (
                <th
                  {...column.getHeaderProps()}
                  onClick={() => {
                    if (column.id !== "status") {
                      setSortConfig({ key: column.id, ascending: !sortConfig.ascending });
                    }
                  }}
                  className={`text-white font-light opacity-60 pl-4 mb-6 cursor-pointer text-left ${
                    column.id === "id" ? "w-20" : ""
                  }`}
                >
                  {column.id !== "status" && (
                    <>
                      {column.render("Header")}
                      <span>{sortConfig.key === column.id ? (sortConfig.ascending ? " 🔽" : " 🔼") : ""}</span>
                    </>
                  )}
                  {column.id === "status" && (
                    <select
                      defaultValue={undefined}
                      className='bg-[#111627] rounded-md'
                      value={filterValue}
                      onChange={(e) => setFilterValue(e.target.value)}
                    >
                      <option disabled value={undefined}>
                        Status
                      </option>
                      <option value='Todos'>Todos</option>
                      <option value='OK: Compra Aprovada'>Compra Aprovada</option> {/* Adjusted this line */}
                      <option value='Pendente'>Pendente</option>
                      <option value='Inconsistencia Anexo: Medicamentos'>Inconsistência anexo</option>
                      <option value='Bloqueado: CRM inválido'>CRM Inválido</option>
                      <option value='Verificacao Manual'>Verificacao Manual</option>
                    </select>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...TableLayoutProps.getTableBodyProps()} className='pt-2 space-y-4'>
          {currentRows.map((row: any) => {
            // Include the type definition for 'row' if available
            TableLayoutProps.prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className='border-omni-gray border-8 h-10 chover:cursor-pointer hover:bg-gray-800 hover:bg-opacity-50 rounded-md bg-[#03001A]'
                style={{
                  height: "3.75rem",
                }}
              >
                {row.cells.map((cell) => renderCell(cell, row))}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className='flex justify-end space-x-4 text-white mx-7 mt-3'>
        <button
          onClick={() => setCurrentPage((prevPage) => (prevPage <= 1 ? 0 : prevPage - 1))}
          disabled={currentPage <= 0}
          className='rounded-lg px-4 py-2 font-inter font-medium text-base'
        >
          Anterior
        </button>

        {startPage > 0 && <span>...</span>}

        {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map((number) => (
          <button
            key={number}
            onClick={() => gotoPage(number)}
            disabled={currentPage === number}
            className={`${
              currentPage === number ? "bg-blue-500" : "bg-transparent"
            } rounded-lg px-4 py-2 font-inter font-medium text-base`}
          >
            {number + 1}
          </button>
        ))}

        {endPage < pageCount - 1 && <span>...</span>}

        <button
          onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
          disabled={currentPage === pageCount - 1}
          className='rounded-lg px-4 py-2 font-inter font-medium text-base'
        >
          Próximo
        </button>
        <div className='flex items-center'>
          Página
          <strong className='ml-2'>
            {currentPage + 1} de {pageCount}
          </strong>
        </div>
      </div>
    </div>
  );
};
