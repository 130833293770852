import React from "react";
import { useRegisterColaborator } from "../../hooks/ColaboratorContext";
import { RegisterColaboratorAction } from "../../types/CollaboratorDataTypes";

export function CustomSelectParentesco() {
  const { state, dispatch } = useRegisterColaborator();

  return (
    <select
      className='w-full rounded-md text-opaque-white px-3 py-2 h-14'
      style={{ backgroundColor: "#03001A" }}
      value={state.parentDependent}
      onChange={(e) => {
        dispatch({
          type: RegisterColaboratorAction.setParentDependent,
          payload: e.target.value,
        });
      }}
      defaultValue=''
    >
      <option value=''>Selecione</option>
      <option value='Esposa'>Esposa</option>
      <option value='Marido'>Marido</option>
      <option value='Filho(a)'>Filho(a)</option>
    </select>
  );
}
