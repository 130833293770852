import { create } from "zustand";
import { api } from "../utils/services/api";

interface User {
   name: string;
   cpf: string;
   cargoAtual: string;
   phone_number: string;
   email: string;
   date_of_birth: string;
   plano: string;
   id: string;
   user_type: string[]
}

interface UserStore {
   user: User;
   fetchUser: (id: string) => Promise<void>;
   updateUserField: (field: keyof User, value: string) => void;
}

const userEditStore = create<UserStore>((set) => ({
   user: {
      name: "",
      cpf: "",
      cargoAtual: "",
      phone_number: "",
      email: "",
      date_of_birth: "",
      plano: "",
      tipoUsuario: "",
      id: "",
      user_type: []
   },
   
   fetchUser: async (id) => {
      try {
         const response = await api.get(`/users/${id}`);
         set({ user: {
            name: response.data.userByPVault.name,
            cpf: response.data.userByPVault.cpf,
            cargoAtual: response.data.user.cargoAtual,
            phone_number: response.data.userByPVault.phone_number,
            email: response.data.user.email,
            date_of_birth: response.data.user.date_of_birth,
            plano: response.data.user.linked_plan,
            id: response.data.user.id,
            user_type: response.data.user.user_type,

        }});
      } catch (error) {
         console.error(error);
      }
   },
   updateUserField: (field, value) => {
      set((state) => ({
         user: { ...state.user, [field]: value },
      }));
   },
}));

export { userEditStore };
