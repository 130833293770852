/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

const CollaboratorProfileInfo = ({ data }: any) => {
   const capitalizeFirstLetter = (string: string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
   };

   const formatDate = (dateString: string) => {
      if (!dateString) return "";

      const [year, month, day] = dateString.split("-").map(Number);
      const correctedDate = new Date(Date.UTC(year, month - 1, day + 1));
      const formattedDate = format(correctedDate, "dd/MMM/yyyy", { locale: ptBR });
      const [dayPart, monthPart, yearPart] = formattedDate.split("/");

      const capitalizedMonth = capitalizeFirstLetter(monthPart);

      return `${dayPart}/${capitalizedMonth}/${yearPart}`;
   };

   const formatHeight = (height: string) => {
      const parsedHeight = parseFloat(height);
      if (!isNaN(parsedHeight)) {
         // Separa a parte inteira e a parte decimal
         const meters = Math.floor(parsedHeight);
         const centimeters = Math.round((parsedHeight - meters) * 100);

         // Formata a altura
         return `${meters},${centimeters}m`;
      }
      return height;
   };

   const formatPhoneNumber = (phoneNumber: string) => {
      if (!phoneNumber) return "";
      const cleanedNumber = phoneNumber.replace(/[^\d]/g, "");
      const ddd = cleanedNumber.slice(2, 4);
      const firstPart = cleanedNumber.slice(4, 9);
      const secondPart = cleanedNumber.slice(9);
      return `(${ddd}) ${firstPart}-${secondPart}`;
   };

   const formatPlanCode = (code: string) => {
      let planName = "";
      let credits = "";

      if (code.startsWith("R")) {
         planName = "Royal";
      } else if (code.startsWith("C")) {
         planName = "Celeste";
      } else if (code.startsWith("T")) {
         planName = "Turquesa";
      }

      credits = code.match(/\d+/)?.[0] || "";

      return `${planName} ${credits}`;
   };

   return (
      <div className='mx-5'>
         {!data || Object.keys(data).length === 0 ? (
            <div>Loading...</div>
         ) : (
            <table className='w-full mx-6 mt-5 table-spacing'>
               <tbody>
                  <tr>
                     <td
                        className='text-base font-bold'
                        style={{ fontWeight: 400, color: "rgba(255, 255, 255, 0.6)", paddingRight: "7rem" }}
                     >
                        Data de Nascimento
                     </td>
                     <td className='text-left' style={{ fontWeight: 500, color: "white", padding: "0.7rem 0" }}>
                        {data?.user?.date_of_birth ? formatDate(data?.user?.date_of_birth) : ""}
                     </td>
                  </tr>
                  <tr>
                     <td
                        className='text-base font-bold'
                        style={{ fontWeight: 400, color: "rgba(255, 255, 255, 0.6)", padding: "0.7rem 0" }}
                     >
                        CPF
                     </td>
                     <td className='text-base' style={{ fontWeight: 500, color: "white", padding: "0.7rem 0" }}>
                        {data?.userByPVault?.cpf ? data?.userByPVault?.cpf : ""}
                     </td>
                  </tr>
                  <tr>
                     <td
                        className='text-base font-bold'
                        style={{ fontWeight: 400, color: "rgba(255, 255, 255, 0.6)", padding: "0.7rem 0" }}
                     >
                        Gênero
                     </td>
                     <td className='text-base' style={{ fontWeight: 500, color: "white", padding: "0.7rem 0" }}>
                        {data?.user?.gender
                           ? data.user.gender === "Male" || data.user.gender === "Masculino"
                              ? "Masculino"
                              : data.user.gender === "Female" || data.user.gender === "Feminino"
                                ? "Feminino"
                                : data.user.gender === "Outros"
                                  ? "Outros"
                                  : data.user.gender === "Não informar"
                                    ? "Não informar"
                                    : ""
                           : ""}
                     </td>
                  </tr>
                  <tr>
                     <td
                        className='text-base font-bold'
                        style={{ fontWeight: 400, color: "rgba(255, 255, 255, 0.6)", padding: "0.7rem 0" }}
                     >
                        Altura
                     </td>
                     <td className='text-base' style={{ fontWeight: 500, color: "white", padding: "0.7rem 0" }}>
                        {data?.physicalData?.height ? formatHeight(data?.physicalData?.height) : ""}
                     </td>
                  </tr>
                  <tr>
                     <td
                        className='text-base font-bold'
                        style={{ fontWeight: 400, color: "rgba(255, 255, 255, 0.6)", padding: "0.7rem 0" }}
                     >
                        Peso
                     </td>
                     <td className='text-base' style={{ fontWeight: 500, color: "white", padding: "0.7rem 0" }}>
                        {data?.physicalData?.weight ? `${data?.physicalData?.weight}kg` : ""}
                     </td>
                  </tr>
                  <tr>
                     <td
                        className='text-base font-bold'
                        style={{ fontWeight: 400, color: "rgba(255, 255, 255, 0.6)", padding: "0.7rem 0" }}
                     >
                        Cargo
                     </td>
                     <td className='text-base' style={{ fontWeight: 500, color: "white", padding: "0.7rem 0" }}>
                        {data?.user?.cargoAtual ? data?.user?.cargoAtual : ""}
                     </td>
                  </tr>
                  <tr>
                     <td
                        className='text-base font-bold'
                        style={{ fontWeight: 400, color: "rgba(255, 255, 255, 0.6)", padding: "0.7rem 0" }}
                     >
                        E-mail
                     </td>
                     <td className='text-base' style={{ fontWeight: 500, color: "white", padding: "0.7rem 0" }}>
                        {data?.user?.email ? data?.user?.email : ""}
                     </td>
                  </tr>
                  <tr>
                     <td
                        className='text-base font-bold'
                        style={{ fontWeight: 400, color: "rgba(255, 255, 255, 0.6)", padding: "0.7rem 0" }}
                     >
                        Telefone Celular
                     </td>
                     <td className='text-base' style={{ fontWeight: 500, color: "white", padding: "0.7rem 0" }}>
                        {data?.user?.phone_number ? formatPhoneNumber(data?.user?.phone_number) : ""}
                     </td>
                  </tr>
                  <tr>
                     <td
                        className='text-base font-bold'
                        style={{ fontWeight: 400, color: "rgba(255, 255, 255, 0.6)", padding: "0.7rem 0" }}
                     >
                        IMC
                     </td>
                     <td className='text-base' style={{ fontWeight: 500, color: "white", padding: "0.7rem 0" }}>
                        {data?.physicalData?.imc ? Number(data?.physicalData?.imc).toFixed(2).replace(".", ",") : ""}
                     </td>
                  </tr>
                  <tr>
                     <td
                        className='text-base font-bold'
                        style={{ fontWeight: 400, color: "rgba(255, 255, 255, 0.6)", padding: "0.7rem 0" }}
                     >
                        Plano Associado
                     </td>
                     <td className='text-base' style={{ fontWeight: 500, color: "white", padding: "0.7rem 0" }}>
                        {data?.user?.linked_plan &&
                        data?.user?.plan_code !== null &&
                        data?.user?.plan_code !== undefined &&
                        data?.user?.plan_code !== "NULL"
                           ? formatPlanCode(data?.user?.plan_code)
                           : "Não vinculado"}
                     </td>
                  </tr>
                  {data?.user?.dependents?.map((dependent: any, index: any) => (
                     <tr key={dependent.id}>
                        <td
                           className='text-base font-bold'
                           style={{ fontWeight: 400, color: "rgba(255, 255, 255, 0.6)", padding: "0.7rem 0" }}
                        >
                           Dependente {index + 1}
                        </td>
                        <td
                           className='text-base'
                           style={{
                              fontWeight: 500,
                              color: "white",
                              padding: "0.7rem 0",
                              cursor: "pointer",
                              textDecoration: "underline",
                           }}
                           onClick={() => (window.location.href = `/perfil-colaborador/${dependent.id}`)}
                        >
                           {dependent.name}
                        </td>
                     </tr>
                  ))}
               </tbody>
            </table>
         )}
      </div>
   );
};

export default CollaboratorProfileInfo;
