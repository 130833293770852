import React, { useMemo } from "react";
import { TableLayout } from "./TableLayout";
import { useTable } from "react-table";
export const TableInstance = ({ tableData }: { tableData: any }) => {
  const [columns, data] = useMemo(() => {
    const columns = [
      {
        Header: "ID Plano",
        accessor: "id",
      },
      {
        Header: "Aquisição",
        accessor: "acquisition_allowed",
      },
      {
        Header: "Reembolso",
        accessor: "refund_allowed",
      },
      {
        Header: "Limite Global",
        accessor: "global_monthly_limit",
      },
      {
        Header: "Limite Individual",
        accessor: "individual_monthly_limit",
      },
      {
        Header: "Status",
        accessor: "accountStatus",
      },
    ];
    return [columns, tableData];
  }, [tableData]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const tableInstance = useTable({ columns, data: data });
  return <TableLayout TableLayoutProps={tableInstance} />;
};
