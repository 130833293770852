import React, { useState, useEffect } from "react";
import { FaDownload, FaSpinner, FaCheck, FaTimesCircle } from "react-icons/fa"; // Importando ícones adicionais

function DownloadButton({ isLoading, isSuccess, isError, onClick }) {
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      setShowConfetti(true);
      setTimeout(() => {
        setShowConfetti(false);
      }, 3000); // Tempo que a animação de confetes ficará visível em milissegundos (3 segundos)
    }
  }, [isSuccess]);

  let content;
  let backgroundColor = "rgba(255, 255, 255, 0.38)"; // Cor padrão

  if (isLoading) {
    content = <FaSpinner color='white' size='20px' className='animate-spin' />;
    backgroundColor = "rgba(255, 255, 255, 0.38)"; // Cor para estado de carregamento
  } else if (isSuccess) {
    content = <FaCheck color='white' size='20px' />;
    backgroundColor = "green"; // Cor para estado de sucesso
  } else if (isError) {
    content = <FaTimesCircle color='white' size='20px' />;
    backgroundColor = "red"; // Cor para estado de erro
  } else {
    content = <FaDownload color='white' size='20px' />;
  }

  return (
    <button
      title='Download dos colaboradores'
      onClick={onClick}
      style={{
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        width: "45px",
        height: "45px",
        borderRadius: "50%",
        backgroundColor,
        border: "none",
        cursor: "pointer",
        transition: "background-color 0.3s ease", // Adiciona uma transição suave na mudança de cor
      }}
    >
      {content}
      {showConfetti && (
        <div className='confetti-container'>
          {/* Aqui você pode adicionar o código ou componente para a animação de confetes */}
        </div>
      )}
    </button>
  );
}

export default DownloadButton;
