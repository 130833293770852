import React from "react";

const Loading = () => {
  return (
    <div className='flex justify-center items-center h-20'>
      <div className='flex items-center space-x-2'>
        <div className='w-6 h-6 bg-omni-blue rounded-full animate-bounce'></div>
        <div className='w-5 h-6 bg-omni-blue rounded-full animate-bounce'></div>
        <div className='w-3 h-6 bg-omni-blue rounded-full animate-bounce'></div>
      </div>
      <div className='ml-2 mb-2'>
        <h1 className='text-gray-300 animate-pulse text-lg'>Carregando...</h1>
      </div>
    </div>
  );
};

export default Loading;
