/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { supabaseConfig } from "../config/supabaseConfig";
import { api } from "../utils/services/api";
import { toast } from "react-toastify";

import defaultProfilePic from "../../public/default.jpg";

type Company = {
  id: string;
  enterprise_name: string;
};

function ProfilePicture() {
  const [showOptions, setShowOptions] = useState(false);
  const [profilePic, setProfilePic] = useState(defaultProfilePic);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [companies, setCompanies] = useState<Company[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState<string | null>(null); // New State Variable

  const colaboratorID = localStorage.getItem("userID");

  const userType = localStorage.getItem("userType");
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const optionsRef = useRef<HTMLDivElement>(null); // Specify the type for optionsRef

  const handleDivClick = () => {
    setShowOptions(!showOptions);
  };

  const handleCompanySelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCompany(e.target.value);
  };

  const importAndReload = () => {
    if (!selectedCompany && userType.toLowerCase() === "gestor-mor") {
      // Obter o ID armazenado no localStorage
      const linkedCompanyId = localStorage.getItem("linkedCompany");

      if (companies && companies.length > 0) {
        // Verificação adicionada aqui
        const linkedCompanyData = companies.find((company) => company.id === linkedCompanyId);
        if (linkedCompanyData) {
          localStorage.setItem("chosenCompany", linkedCompanyData.enterprise_name);
        }
      }
      window.location.reload();
    } else if (selectedCompany) {
      localStorage.setItem("linkedCompany", selectedCompany);

      // Obter o ID armazenado no localStorage
      const linkedCompanyId = localStorage.getItem("linkedCompany");

      if (companies && companies.length > 0) {
        // Verificação adicionada aqui
        const linkedCompanyData = companies.find((company) => company.id === linkedCompanyId);
        if (linkedCompanyData) {
          localStorage.setItem("chosenCompany", linkedCompanyData.enterprise_name);
        }
      }
      window.location.reload();
    }
  };

  useEffect(() => {
    const previousPath = localStorage.getItem("previousPath");
    getIds();
    // change for a switch case
    if (previousPath === "/" && (userType === "Admin" || userType === "Gestor-Mor")) {
      openModal();
    }
    localStorage.setItem("previousPath", location.pathname);
  }, [location]);

  const handleProfilePicChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("imagem", file);

    if (colaboratorID) {
      try {
        // Database Update
        const response = await api.patch(`/users/avatar/updateFile/${colaboratorID}`, formData);
        if (response.data) {
          const newImageURL = `${import.meta.env.VITE_SUPABASE_URL}/storage/v1/object/public/avatars/${
            response.data.user.avatar
          }`;

          setProfilePic(newImageURL);
          localStorage.setItem("profilePic", newImageURL);

          toast.success("Avatar updated successfully");
        }
      } catch (error) {
        toast.error("Error updating avatar");
      }
    }
  };

  const navigate = useNavigate();
  const logout = async () => {
    try {
      await supabaseConfig.auth.signOut();
      localStorage.clear();
      navigate("/");
    } catch (error) {
      console.error("An error occurred during logout:", error);
    }
  };

  const updateProfilePic = async () => {
    const fileInput = document.getElementById("profilePicInput") as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (optionsRef.current && !optionsRef.current.contains(event.target as Node)) {
      setShowOptions(false);
    }
  };

  const fetchData = async () => {
    try {
      const response = await api.get(`/users/${colaboratorID}`);
      if (response.data && response.data.user) {
        if (response.data.user.avatar) {
          const newImageURL = `${import.meta.env.VITE_SUPABASE_URL}/storage/v1/object/public/avatars/${
            response.data.user.avatar
          }`;
          setProfilePic(newImageURL); // Atualize o estado 'profilePic' aqui.
          localStorage.setItem("profilePic", newImageURL);
        }
      } else {
        toast.error("Erro ao buscar dados.");
      }
    } catch (error) {
      console.error(error);
    }
  };

  async function getIds() {
    const userType = localStorage.getItem("userType");
    const holdingID = localStorage.getItem("holdingID");

    // Função auxiliar para atualizar a empresa selecionada no localStorage
    const updateSelectedCompany = (companies: any) => {
      const linkedCompanyId = localStorage.getItem("linkedCompany");
      const linkedCompanyData = companies.find((company: any) => company.id === linkedCompanyId);

      if (linkedCompanyData) {
        localStorage.setItem("chosenCompany", linkedCompanyData.enterprise_name);
      } else {
        localStorage.removeItem("chosenCompany");
      }
    };

    try {
      setIsLoading(true);
      setError(null);

      let companies;
      if (userType.toLowerCase() === "admin") {
        const { data } = await api.get("/businessAcc/ids");
        companies = data.data;
        companies.sort((a: any, b: any) =>
          a.enterprise_name > b.enterprise_name ? 1 : b.enterprise_name > a.enterprise_name ? -1 : 0,
        );
      } else if (userType.toLowerCase() === "gestor-mor") {
        const { data } = await api.get(`/holding/${holdingID}/related`);
        companies = data.relatedCompanies;
        companies.sort((a: any, b: any) =>
          a.enterprise_name > b.enterprise_name ? 1 : b.enterprise_name > a.enterprise_name ? -1 : 0,
        );
      } else {
        // Caso userType não seja "Admin" ou "Gestor-Mor", não precisa de lista de empresas
        return;
      }

      setCompanies(companies);
      setIsLoading(false);
      setError(null);

      updateSelectedCompany(companies);
    } catch (error) {
      toast.error("Erro ao carregar lista de empresas.");
      setError("Erro ao carregar empresas.");
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getIds();
    fetchData();
    const storedProfilePic = localStorage.getItem("profilePic");
    if (storedProfilePic) {
      setProfilePic(storedProfilePic);
    }

    document.addEventListener("mousedown", handleClickOutside); //

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className='cursor-pointer z-10 '>
        {
          <div style={{ display: "flex" }} className='text-white relative'>
            <div onClick={handleDivClick} className='text-white flex items-center ml-[3rem]'>
              <img
                id='profile-pic'
                src={profilePic}
                alt=''
                className='rounded-full h-12 w-12 object-cover' // Removido 'fixed'
                style={{ marginRight: 8 }}
              />
              <p style={{ fontFamily: "Manrope" }}>
                {localStorage.getItem("chosenCompany")
                  ? String(localStorage.getItem("chosenCompany")).length > 38
                    ? String(localStorage.getItem("chosenCompany")).slice(0, 38 - 3) + "..."
                    : String(localStorage.getItem("chosenCompany"))
                  : ""}
              </p>
            </div>
            {showOptions && (
              <div
                ref={optionsRef}
                className='flex-column p-5 border fixed top-[6rem] right-[8rem] bg-omni-gray rounded-md text-opaque-white'
              >
                <div onClick={logout} className='hover:text-white'>
                  Logout
                </div>
                <div onClick={updateProfilePic} className='hover:text-white'>
                  Atualizar Foto de Perfil
                </div>
                {(localStorage.getItem("userType") === "Admin" ||
                  localStorage.getItem("userType") === "Gestor-Mor") && (
                  <div onClick={openModal} className='hover:text-white'>
                    Escolher Empresa
                  </div>
                )}

                <input
                  id='profilePicInput'
                  type='file'
                  accept='image/*'
                  onChange={handleProfilePicChange}
                  style={{ display: "none" }}
                />
              </div>
            )}
          </div>
        }
      </div>

      {isModalOpen && (
        <div className='fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-70 z-50'>
          <div className='bg-[#03001A] rounded-3xl border-solid border border-[#E0E7FE] w-[500px] p-4'>
            <div className='flex justify-between items-center p-4 bg-[#03001A]'>
              <h2 className='text-white'>Listagem de empresas ativas</h2>
              <button type='button' className='text-white' onClick={closeModal}>
                X
              </button>
            </div>

            <div className='p-4'>
              <h3 className='mb-2 text-white'>Escolha a empresa para visualização detalhada</h3>
              <div id='select-company-modal' className='relative'>
                {isLoading ? (
                  <p className='text-white'>Carregando empresas...</p>
                ) : error ? (
                  <p className='text-white'>{error}</p>
                ) : (
                  <select
                    id='company-selection'
                    value={selectedCompany ?? ""}
                    className='w-full bg-[#E0E7FE] rounded-md py-2 px-3 text-[#0517F5]'
                    onChange={handleCompanySelect}
                  >
                    <>
                      <option value={""} disabled>
                        Clique para selecionar
                      </option>
                      {userType === "Admin" ? (
                        <option value='allCompanies'>Todas as empresas</option>
                      ) : (
                        <option value='allHCompanies'>Todas as empresas da holding</option>
                      )}
                      {companies?.map((company, index) => (
                        <option key={index} value={company.id}>
                          {company.enterprise_name}
                        </option>
                      ))}
                    </>
                  </select>
                )}
                <button
                  id='import-company'
                  type='button'
                  className={`w-full mt-5 rounded-md text-white bg-[#0517F5] py-2 flex items-center justify-center`}
                  onClick={importAndReload}
                >
                  Importar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ProfilePicture;
