/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import moment from "moment";
import momentDurationFormatSetup from "moment-duration-format";

momentDurationFormatSetup(moment);

interface Treatment {
  id: string;
  medicamento: string;
  principio_ativo: string;
  laboratorio: string;
  tipo: string;
  doses_abertas: boolean;
  doses: string[];
  observacoes: string;
  un_dose: string;
  apresentacao: string;
  frequencia_dias: number;
  frequencia_horas: number;
  duracao_dias: number;
  qtd_total_consumida: number;
  qnt_total_medicamento: number;
  metodo_consumo: string;
  aviso_ativado: boolean;
  medicamento_tomado: boolean;
  log_conformidade: null;
  patologia_associado: string;
  agenda: {
    data: string;
    status: string;
  }[];
  created_at: string;
}

const CollaboratorTreatments = ({ data }: any) => {
  const [medications, setMedications] = useState([]);

  const userType = localStorage.getItem("userType"); // Busca o userType do localStorage

  useEffect(() => {
    // Verify if clinicalHistory exists, is an array, and has at least one element
    if (!Array.isArray(data.user.clinicalHistory) || data.user.clinicalHistory.length === 0) {
      console.error("clinicalHistory is either not an array or is empty.");
      setMedications([]);
      return; // Exit the useEffect if clinicalHistory is not present or not as expected
    }

    const treatments = data.user.clinicalHistory[0]?.tratamentos || [];
    const parsedMedications = treatments.map((treatment: any) => {
      const { medicamento, un_dose, agenda, laboratorio } = treatment;

      const finaliza_em = agenda
        .reduce((maxDate: any, item: any) => {
          const currentDate = moment(item.data);
          return currentDate.isAfter(maxDate) ? currentDate : maxDate;
        }, moment("2000-01-01"))
        .format("DD/MM/YYYY");

      const duracaoTratamento = treatment.duracao_dias;
      let duracaoFormatada = "";
      if (duracaoTratamento === 1) {
        duracaoFormatada = `${duracaoTratamento} dia`;
      } else if (duracaoTratamento > 1 && duracaoTratamento < 30) {
        duracaoFormatada = `${duracaoTratamento} dias`;
      } else if (duracaoTratamento >= 30 && duracaoTratamento < 365) {
        const duracaoMeses = Math.floor(duracaoTratamento / 30);
        duracaoFormatada = `${duracaoMeses} meses`;
      } else {
        const duracaoAnos = Math.floor(duracaoTratamento / 365);
        duracaoFormatada = `${duracaoAnos} anos`;
      }

      let status = "Finalizado";
      const now = moment().subtract(3, "hours");
      for (let i = 0; i < agenda.length; i++) {
        if (moment(agenda[i].data).isSameOrAfter(now)) {
          status = agenda[i].status === "Tomado" ? "Em Linha" : "Atrasado";
          break;
        }
      }

      return {
        id: treatment.id,
        nome: medicamento,
        dose: un_dose.split(",")[0] || "",
        duracao: duracaoFormatada,
        finaliza_em: finaliza_em,
        status: status,
        laboratorio: laboratorio,
      };
    });

    setMedications(parsedMedications);
  }, [data]);

  return (
    <div className='w-5/6 mx-auto mt-10'>
      {medications.map((medication: any) => (
        <div
          key={medication.id}
          className='custom-treatment mb-4'
          style={{
            background: "linear-gradient(0deg, #111627, #111627)",
            borderRadius: "20px",
            padding: "1rem",
            color: "white",
          }}
        >
          <div
            className='flex justify-between p-2 border-omni-white border-b border-1'
            style={{ alignContent: "center" }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p>{userType === "Admin" ? medication.nome : "XXXXX"}</p>
              <p>{userType === "Admin" ? medication.laboratorio : "XXXXX"}</p>
            </div>
            <span
              className={`text-white text-sm rounded-full py-1 px-2 h-9 flex items-center justify-center ${
                medication.status === "Em Linha"
                  ? "bg-green-500"
                  : medication.status === "Atrasado"
                    ? "bg-yellow-500"
                    : "bg-gray-500"
              }`}
              style={{
                backgroundColor:
                  medication.status === "Em Linha"
                    ? "#34D399"
                    : medication.status === "Atrasado"
                      ? "#F8AE53"
                      : "#718096",
              }}
            >
              {medication.status}
            </span>
          </div>
          <table className='w-full table-fixed'>
            <thead>
              <tr className='justify-between '>
                <th className='p-2 text-left'>Dose</th>
                <th className='p-2 text-center'>Duração</th>
                <th className='p-2 text-right'>Finaliza em</th>
              </tr>
            </thead>
            <tbody>
              <tr className='justify-between'>
                <td className='p-2 text-left'>{medication.dose}</td>
                <td className='p-2 text-center'>{medication.duracao}</td>
                <td className='p-2 text-right'>{medication.finaliza_em}</td>
              </tr>
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
};

export default CollaboratorTreatments;
