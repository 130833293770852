import axios from "axios";
import { FormattedAcquisition } from "../../types/CollaboratorDataTypes";
import { getAccessToken } from "../../config/supabaseConfig";

const baseURL = import.meta.env.VITE_API_URL;

const api = axios.create({
  baseURL,
});

const goURL = import.meta.env.VITE_API_URL_GO as string;

const apiGo = axios.create({ baseURL: goURL });

api.interceptors.request.use(
  async (config) => {
    const token = await getAccessToken();
    if (token) {
      config.headers.access_token = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

const fetchExternalAcquisitions = async (linkedCompany: string) => {
  try {
    const { data } = await api.get(`/buys/company/${linkedCompany}`, {
      validateStatus: (status) => status < 400,
    });
    const formatted = acquisitionFormatter(data.newDataFormatted);
    const dateSort = sortByDateTime(formatted);
    return dateSort.reverse();
  } catch (error) {
    console.error(error);
  }
};

const fetchExternalAcquisitionsByListCompanies = async () => {
  try {
    const holdingID = localStorage.getItem("holdingID");

    const { data: dataListCompany } = await api.get(`/holding/${holdingID}/related`);
    let companies = dataListCompany.relatedCompanies;

    // Preparando a lista de IDs das empresas para enviar
    const companyIDs = companies.map((company: any) => company.id);

    const { data } = await api.post(
      `/buys/companies`,
      {
        companyIDs: companyIDs, // Substitua ...arrayDeIDsDeEmpresas pelo array real dos IDs
      },
      { validateStatus: (status) => status < 400 },
    );

    const formatted = acquisitionFormatter(data.newDataFormatted);
    const dateSort = sortByDateTime(formatted);
    return dateSort.reverse();
  } catch (error) {
    console.error(error);
  }
};

export const fetchAllExternalAcquisitions = async () => {
  try {
    const { data } = await api.get(`/buys`, {
      validateStatus: (status) => status < 400,
    });
    const formatted = acquisitionFormatter(data.newDataFormatted);
    const dateSort = sortByDateTime(formatted);
    return dateSort.reverse();
  } catch (error) {
    console.error(error);
  }
};

export { api, apiGo, fetchExternalAcquisitions, fetchExternalAcquisitionsByListCompanies };

const acquisitionFormatter = (acquisitions: any): FormattedAcquisition[] => {
  const formattedAcquisition = acquisitions.map((item: any) => {
    const { date, status, establishmentName, buy_id, totalAMount, comprovante, colaborador } = item;
    const formattedTotalAmount = (parseFloat(totalAMount) || 0).toFixed(2);
    return {
      colaborador,
      valor: "R$ " + formattedTotalAmount,
      dateTime: formatDate(date),
      status,
      local: establishmentName,
      id: buy_id,
      comprovante,
    };
  });
  return formattedAcquisition.reverse();
};

export function formatDate(dateString: string) {
  const date = new Date(dateString);
  const formattedDate = new Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  }).format(date);
  return formattedDate;
}

function sortByDateTime(array: any): any[] {
  const compareDates = (dateStr1: string, dateStr2: string): number => {
    const [day1, month1, year1, time1] = dateStr1.split(/[/, ]+/);
    const [day2, month2, year2, time2] = dateStr2.split(/[/, ]+/);

    const date1 = new Date(`${year1}-${month1}-${day1}T${time1}`);
    const date2 = new Date(`${year2}-${month2}-${day2}T${time2}`);

    return date1.getTime() - date2.getTime();
  };

  return array.sort((a: any, b: any) => compareDates(a.dateTime, b.dateTime));
}
