/* eslint-disable react/jsx-key */
import React from "react";
import { TableInstance } from "react-table";

export const TableLayout = ({ TableLayoutProps }: { TableLayoutProps: TableInstance }) => {
  const renderCell = (cellValue: string | number, columnId: string) => {
    if (cellValue === "Sim" || cellValue === "Ativa") {
      return (
        <div className='flex justify-center items-center'>
          <span className='text-omni-yes'>{cellValue}</span>
        </div>
      );
    } else if (cellValue === "Não" || cellValue === "Bloqueada") {
      return (
        <div className='flex justify-center items-center'>
          <span className='text-omni-no'>{cellValue}</span>
        </div>
      );
    } else if (columnId === "global_monthly_limit" || columnId === "individual_monthly_limit") {
      return (
        <div className='flex justify-center items-center'>
          <span className='text-opaque-white'>{"R$ " + cellValue + ",00"}</span>
        </div>
      );
    } else {
      return (
        <div className='flex justify-center items-center'>
          <span className='text-opaque-white'>{cellValue}</span>
        </div>
      );
    }
  };

  return (
    <table {...TableLayoutProps.getTableProps()} className=' mt-5 w-full ml-1'>
      <thead className='mmt-5'>
        {TableLayoutProps.headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()} className='px-4 py-2'>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} className='text-white font-light py-2 px-4 opacity-60'>
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className='pt-2 rounded-md' {...TableLayoutProps.getTableBodyProps()}>
        {TableLayoutProps.rows.map((row) => {
          TableLayoutProps.prepareRow(row);
          return (
            <tr {...row.getRowProps()} className='border-omni-gray border-t border-8 rounded-md '>
              {row.cells.map((cell) => {
                return (
                  <td {...cell.getCellProps()} className='px-4 mt-3 py-3 text-opaque-white bg-dark-omni'>
                    <span className='mt-2 text-sm'>{renderCell(cell.value, cell.column.id)}</span>
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
