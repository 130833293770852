import React, { useState } from "react";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import EyeIcon from "../icons/EyeIcon";
import { useNavigate } from "react-router-dom";

function CollaboratorData({ data }: any) {
   const navigate = useNavigate();
   const formatDate = (dateString: string) => {
      const date = new Date(dateString);
      const formattedDate = format(date, "dd MMM, yyyy", { locale: ptBR });
      return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
   };
   const userType = localStorage.getItem("userType");

   const sortedData = [...data].sort((a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime());

   return (
      <>
         <div className='w-5/6 mx-auto mt-10'>
            {sortedData.map((aquisition: any, index: any) => (
               <div
                  key={aquisition.id}
                  style={{
                     borderRadius: "20px",
                     background: "linear-gradient(0deg, #111627, #111627)",
                     padding: "1rem",
                     color: "white",
                     marginBottom: "10px",
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "space-between",
                  }}
               >
                  <div style={{ textAlign: "left", flex: 1 }}>{"#" + aquisition.buy_id.slice(0, 5)}</div>
                  <div style={{ textAlign: "left", flex: 1 }}>{formatDate(aquisition.date)}</div>
                  <div style={{ textAlign: "center", flex: 2 }} className='ellipsis'>
                     {userType === "Admin"
                        ? aquisition.names?.length > 0
                           ? aquisition.names?.[0] + (aquisition.names?.indexOf(",") !== -1 ? "..." : "")
                           : "Medicamento não encontrado"
                        : "XXXXX"}
                  </div>
                  <div style={{ textAlign: "left", flex: 1 }}>{`R$ ${aquisition.total_amount.toFixed(2)}`}</div>
                  <div style={{ textAlign: "left", flex: 0.5 }}>
                     <button onClick={() => navigate(`/aquisicoes/${aquisition.buy_id}`)}>
                        <EyeIcon />
                     </button>
                  </div>
               </div>
            ))}
         </div>
         <style>
            {`
          .ellipsis {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        `}
         </style>
      </>
   );
}

export default CollaboratorData;
