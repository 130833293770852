export function convertToDate(dateStr: string) {
  const [day, month, year] = dateStr.split("/");
  return new Date(Number(year), Number(month) - 1, Number(day)); // months are 0-indexed in JavaScript
}

export function isDateBetween(inputDate: Date, startDate: Date, endDate: Date) {
  const input = new Date(inputDate);
  const start = new Date(startDate);
  const end = new Date(endDate);
  return input >= start && inputDate <= end;
}
