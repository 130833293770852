/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RegisterEnterpriseFormStepper } from "../../components/RegisterEnterpriseFormStepper";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { LeftSidebar } from "../../components/LeftSidebar";
import { useRegisterEnterprise } from "../../hooks/RegisterEnterpriseContext";
import { RegisterEnterpriseAction } from "../../types/RegisterEnterpriseTypes";
import { CustomSelectGender } from "../../components/CustomSelectGender";
import { CustomSelectAccountType } from "../../components/CustomSelectAccountType";
import { RegisterEnterpriseSchemaStepTwo, RegisterEnterpriseStepTwoSchemaType } from "../../utils/schemas/RegisterEnterpriseSchema";
import { TopbarEnterprise } from "../../components/TopbarEnterprise";
import MainContainer from "../../components/layout/MainContainer";
import LeftBarContainer from "../../components/layout/LeftBarContainer";
import Content from "../../components/layout/Content";
import { api } from "../../utils/services/api";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import { CustomSelectPlans } from "../../components/CollaboratorComponents/CustomSelectPlans";
import LoadingSpinnerIcon from "../../components/Loading/LoadingSpinnerIcon ";

type User = {
   user: {
      gender: string;
      date_of_birth: any;
      id: string;
      selfie: null;
      identity_card: null;
      token_ref: string;
      plan_code: string;
      id_user_pvault: string;
      id_user_pvault_token: string;
      avatar: null | string;
      email: string;
      phone_number: string;
      linked_company: string;
      cargoAtual: string;
      linked_plan: any[];
      user_type: string[];
      digital_wallet: null | string;
      bank_data: null | string;
      purchase_history: null | string;
      account_status: null | string;
      ismedic: boolean;
      additional_info: null | string;
      isPrimary: boolean;
      dependents: any[];
   };
   userByPVault?: {
      tokenRef: string;
      selfie: null;
      real_name: null;
      mother_name: null;
      medical_prescription: null;
      identity_card: null;
      high_risk: null;
      avatar: null | string;
      cpf: string;
      date_of_birth: string;
      email: string;
      gender: string;
      name: string;
      phone_number: string;
   };
};

type EnterpriseDataType = {
   name: string;
   phone_number: string;
   cpf: string;
   date_of_birth: string | null;
   email: string;
   gender: any; // Supondo que TGender já esteja definido em algum lugar
   linked_company: any; // De preferência substituir 'any' por um tipo mais específico
   linked_plan: string[];
   ismedic: boolean;
   additional_info: string | null;
   representative_user: boolean;
   user_type?: any[]; // Propriedade opcional
   accountType?: any[]; // Propriedade opcional
};

export const EnterpriseRegisterStep2 = () => {
   const { state, dispatch } = useRegisterEnterprise();
   const [_, setSaving] = useState(false);
   const [isNextStep, setNextStep] = useState(false);
   const [itemsTeste, setItemsTeste] = useState<User[]>([]);
   const [colaborator, setColaborator] = useState<User | null>(null);
   const [selectedRepresentative, setSelectedRepresentative] = useState<User | null>(null);
   const [isLoading, setLoading] = useState(false); // New loading state
   const [selectedAccountType, setSelectedAccountType] = useState("Gestor");
   const [gender, setGender] = useState("Masculino");

   const choosedCompanyID = localStorage.getItem("choosedCompany");

   const navigate = useNavigate();

   const fetchRepresentatives = async () => {
      try {
         const response = await api
            .get(`users/representative/user/${choosedCompanyID}`)
            .then((res) => res.data)
            .catch((error) => {
               console.error(error);
               return []; // Retorna um array vazio em caso de erro
            });

         // Filtra os representantes para incluir apenas aqueles que não foram excluídos
         const filteredRepresentatives = response.filter((user: any) => user.user.deleted_at === null);
         setItemsTeste(filteredRepresentatives);
      } catch (error: any) {
         throw new Error(error.message);
      }
   };

   const inititalState = () => {
      dispatch({
         type: RegisterEnterpriseAction.setRepresentativesFullName,
         payload: "",
      });
      dispatch({
         type: RegisterEnterpriseAction.setRepresentativesPhone,
         payload: "",
      });
      dispatch({
         type: RegisterEnterpriseAction.setRepresentativesCorporateEmail,
         payload: "",
      });
      dispatch({
         type: RegisterEnterpriseAction.setRepresentativesCpf,
         payload: "",
      });
      dispatch({
         type: RegisterEnterpriseAction.setRepresentativesGender,
         payload: "",
      });
      dispatch({
         type: RegisterEnterpriseAction.setRepresentativesBirthDate,
         payload: "",
      });
      dispatch({
         type: RegisterEnterpriseAction.setRepresentativesRole,
         payload: "",
      });
      dispatch({
         type: RegisterEnterpriseAction.setRepresentativesAccountType,
         payload: "",
      });
   };

   const populateFields = (data: any) => {
      dispatch({
         type: RegisterEnterpriseAction.setRepresentativesFullName,
         payload: data?.userByPVault?.name ? data.userByPVault.name : "",
      });
      dispatch({
         type: RegisterEnterpriseAction.setRepresentativesPhone,
         payload: data?.userByPVault?.phone_number ? data.userByPVault.phone_number : "",
      });
      dispatch({
         type: RegisterEnterpriseAction.setRepresentativesCorporateEmail,
         payload: data?.userByPVault?.email ? data.userByPVault.email : "",
      });
      dispatch({
         type: RegisterEnterpriseAction.setRepresentativesCpf,
         payload: data?.userByPVault?.cpf ? data.userByPVault.cpf : "",
      });
      dispatch({
         type: RegisterEnterpriseAction.setRepresentativesGender,
         payload: data?.user?.gender ? data.user.gender : "",
      });
      dispatch({
         type: RegisterEnterpriseAction.setRepresentativesBirthDate,
         payload: data?.user?.date_of_birth ? formatDate(data.user.date_of_birth) : "",
      });
      dispatch({
         type: RegisterEnterpriseAction.setRepresentativesRole,
         payload: data?.user?.cargoAtual ? data.user.cargoAtual : "",
      });
      dispatch({
         type: RegisterEnterpriseAction.setRepresentativesAccountType,
         payload: data?.user?.user_type[0] ? data.user.user_type[0] : "",
      });

      const additionalInfo = data.user.additional_info;

      if (additionalInfo) {
         const [crm, uf] = additionalInfo.split(" ");
         createEnterpriseFormStepTwo.setValue("crm", crm);
         createEnterpriseFormStepTwo.setValue("uf", uf.toUpperCase());
      }
   };

   useEffect(() => {
      inititalState();
      fetchRepresentatives();
   }, []); // Atualize a lista quando state.enterpriseName for alterado

   useEffect(() => {
      if (!choosedCompanyID) {
         navigate("/cadastro-empresa/perfil-individual");
      }
      inititalState();
      dispatch({
         type: RegisterEnterpriseAction.setCurrentStep,
         payload: 1,
      });
   }, []);

   const getAdditionalInfo = () => {
      const userCRM = localStorage.getItem("userCRM");
      const userUF = localStorage.getItem("userUF");

      if (userCRM && userUF) {
         // Validate CRM and UF
         const isValidCRM = /^(\d{2}\.\d{4})$/.test(userCRM);
         const isValidUF = /^[A-Z]{2}$/.test(userUF);

         if (isValidCRM && isValidUF) {
            return `${userCRM} ${userUF}`;
         }
      }
      return null;
   };

   function isValidCPF(cpf: any) {
      return typeof cpf === "string" && cpf.length === 14 && !cpf.includes("_");
   }

   function isValidName(name: any) {
      return typeof name === "string" && name.trim().length > 3 && !name.includes("_");
   }

   function isValidBirthDate(birthDate: any) {
      const pattern = /^\d{4}-\d{2}-\d{2}$/;
      return pattern.test(birthDate) && !birthDate.includes("_");
   }

   function convertBirthDate(birthDate: any) {
      // Divide a data em partes [DD, MM, YYYY]
      const parts = birthDate.split("/");

      // Verifica se a data está no formato correto
      if (parts.length === 3) {
         // Reorganiza as partes para o formato YYYY-MM-DD
         return `${parts[2]}-${parts[1]}-${parts[0]}`;
      } else {
         // Retorna null se o formato for inválido
         return null;
      }
   }

   const handleSave = async () => {
      let numero = state.representativesPhone;

      if (numero) {
         // Remover caracteres indesejados
         numero = numero.replace(/\(|\)|-/g, "");

         // Verificar se o número já começa com "+55", caso contrário, adicionar
         if (!numero.startsWith("+55")) {
            numero = "+55" + numero;
         }
      }
      const accountType = localStorage.getItem("accountType");

      let additional_info: string | null = null;

      if (accountType === "Medico") {
         additional_info = getAdditionalInfo();
         if (!additional_info) {
            toast.error("Digite CRM UF corretamente");
            return;
         }
      }

      const planCodeSelected = localStorage.getItem("planSelectedCode");

      if (!isValidName(state.representativesFullName)) {
         toast.error("Nome é obrigatório ou muito curto.");
         return;
      }

      if (!isValidCPF(state.representativesCpf)) {
         toast.error("CPF é obrigatório ou está no formato inválido.");
         return;
      }

      const convertedDate = convertBirthDate(state.representativesBirthDate);
      if (!isValidBirthDate(convertedDate)) {
         toast.error("Data de nascimento está no formato inválido.");
         return;
      }

      if (!planCodeSelected) {
         toast.error("Plano de Cobertura é obrigatório para efetuar o cadastro.");
         return;
      }

      const getCompany = await api
         .get(`businessAcc/${choosedCompanyID}`)
         .then((res) => res.data)
         .catch((error) => console.error(error));

      const enterpriseData: EnterpriseDataType = {
         name: state.representativesFullName,
         phone_number: numero,
         cpf: state.representativesCpf,
         date_of_birth: convertedDate,
         email: state.representativesCorporateEmail,
         gender: state.representativesGender,
         linked_company: getCompany.business[0].enterprise_name,
         linked_plan: planCodeSelected ? [planCodeSelected] : [],
         ismedic: accountType === "Medico" ? true : false,
         additional_info: additional_info,
         representative_user: true,
      };

      if (accountType === "Medico") {
         enterpriseData.ismedic = true;
         // Verificar se os campos CRM e UF estão preenchidos
         const userCRM = localStorage.getItem("userCRM");
         const userUF = localStorage.getItem("userUF");
         enterpriseData.additional_info = `${userCRM}/${userUF}`;
      }

      try {
         setLoading(true); // Start loading
         setSaving(true);

         if (!selectedRepresentative) {
            enterpriseData.user_type = [accountType];
            await api
               .post(`/users/create/user`, enterpriseData)
               .then((res) => {
                  toast.success("Representante cadastrado com sucesso!");
                  inititalState();
                  setSelectedAccountType("Gestor");
                  setGender("Masculino");
                  fetchRepresentatives();
                  populateFields(null);
               })
               .catch((err: any) => {
                  console.log(err);
                  toast.error(err.response.data.message);
               });
         } else {
            enterpriseData.accountType = [accountType];
            enterpriseData.linked_company = choosedCompanyID;
            console.log("vai atualizar", enterpriseData);
            await api
               .patch(`/users/${selectedRepresentative.user.id}`, enterpriseData)
               .then((res) => {
                  toast.success("Representante atualizado com sucesso!");
                  inititalState();
                  setSelectedAccountType("Gestor");
                  setGender("Masculino");
                  fetchRepresentatives();
                  setSelectedRepresentative(null);
                  populateFields(null);
               })
               .catch((err) => {
                  console.error("Erro na atualização do Representante", err);
                  // toast.error("Erro na atualização do Representante");
               });
         }
      } catch (error: any) {
         throw new Error(error.message);
      } finally {
         setSaving(false);
         setNextStep(false);
         setLoading(false); // Stop loading
      }
   };

   function formatDate(dateStr: string) {
      // Certifique-se de que a string de data não seja nula ou indefinida.
      if (!dateStr) {
         return "";
      }

      const parts = dateStr.split("-"); // Divide a string de data em [ano, mês, dia]

      // Verifica se todas as partes necessárias (ano, mês, dia) estão presentes.
      if (parts.length === 3) {
         const [year, month, day] = parts;
         return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
      } else {
         // Retorna um valor padrão ou lança um erro se a data estiver em um formato inesperado.
         console.error("Formato de data inválido:", dateStr);
         return "";
      }
   }

   //
   const createEnterpriseFormStepTwo = useForm<RegisterEnterpriseStepTwoSchemaType>({
      resolver: zodResolver(RegisterEnterpriseSchemaStepTwo),
   });

   const {
      handleSubmit,
      formState: { isSubmitting },
      control,
      register,
   } = createEnterpriseFormStepTwo;

   const handleEdit = (itemId: string) => {
      const selectedItem = itemsTeste.find((item: User) => item.user.id === itemId);
      if (selectedItem) {
         inititalState();
         setSelectedAccountType(selectedItem.user.user_type[0]);
         setSelectedRepresentative(selectedItem);
         setColaborator(selectedItem);
         setGender(selectedItem.user.gender);
         populateFields(selectedItem);

         // Atualiza o estado com a data de nascimento do usuário selecionado
         const formattedDateOfBirth = formatDate(selectedItem.user.date_of_birth);
         dispatch({
            type: RegisterEnterpriseAction.setRepresentativesBirthDate, // Certifique-se de que está usando o campo correto aqui
            payload: formattedDateOfBirth,
         });

         if (selectedItem.user.user_type[0] === "Medico") {
            const additionalInfo = selectedItem.user.additional_info;
            if (additionalInfo) {
               const [crm, uf] = additionalInfo.split(/[ /-]/);
               localStorage.setItem("userCRM", crm);
               localStorage.setItem("userUF", uf);
               createEnterpriseFormStepTwo.setValue("crm", crm);
               createEnterpriseFormStepTwo.setValue("uf", uf.toUpperCase());
            }
         }
      }
   };

   const handleDelete = async (itemId: string) => {
      const confirmDelete = window.confirm("Você realmente deseja deletar esse usuário?");
      if (!confirmDelete) {
         // eslint-disable-next-line no-mixed-spaces-and-tabs
         return;
      }
      try {
         await api
            .delete(`/users/${itemId}`)
            .then(() => toast.success("Representante deletado com sucesso!"))
            .catch(() => toast.error("Não foi possível deletar o Representante"));
         setItemsTeste((prevItems: any) =>
            prevItems.filter((item: User) => {
               return item.user.id !== itemId;
            }),
         );
         inititalState();
         fetchRepresentatives();
      } catch (error: any) {
         inititalState();
         fetchRepresentatives();
         throw new Error(error.message);
      }
   };

   return (
      <>
         <MainContainer className='bg-[#03001A]'>
            <LeftBarContainer className='bg-[#03001A]'>
               <LeftSidebar />
            </LeftBarContainer>

            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, width: "480%" }}>
               <TopbarEnterprise hide='invisible' />

               <Content className='bg-omni-gray rounded-[2rem] h-[100%] p-5 relative mb-10 mt-[-4.5rem]'>
                  <div className='flex justify-between items-center m-5'>
                     <h1 className='font-manrope font-bold font-regular text-5xl text-white justify-center mt-1'>
                        Adicionar Representante
                     </h1>
                  </div>

                  <div className='m-5'>
                     <RegisterEnterpriseFormStepper currentStep={1} />

                     <div className='border-t border-[#0517F5] -mt-4'></div>

                     <FormProvider {...createEnterpriseFormStepTwo}>
                        <form className='flex flex-col text-opaque-white gap-2 mt-3' onSubmit={handleSubmit(handleSave)}>
                           <div className='flex flex-row gap-x-10 pt-3 mb-3 pr-5'>
                              <div className='w-1/3'>
                                 <label className='block mb-1'>Nome Completo *</label>
                                 <input
                                    className='w-full bg-input-register rounded-md text-opaque-white px-3 py-2'
                                    placeholder='ex: João Silva'
                                    type='text'
                                    value={state.representativesFullName}
                                    onChange={(e) => {
                                       dispatch({
                                          type: RegisterEnterpriseAction.setRepresentativesFullName,
                                          payload: e.target.value,
                                       });
                                    }}
                                 />
                              </div>

                              <div className='w-1/3'>
                                 <label className='text-white mb-1'>Telefone Celular</label>
                                 <InputMask
                                    mask='(99)99999-9999'
                                    maskChar=''
                                    className='bg-input-register rounded-md pl-3 text-opaque-white h-10 w-full'
                                    placeholder='ex: (11)9949-9303'
                                    type='text'
                                    value={state.representativesPhone}
                                    onChange={(e) => {
                                       dispatch({
                                          type: RegisterEnterpriseAction.setRepresentativesPhone,
                                          payload: e.target.value,
                                       });
                                    }}
                                 />
                              </div>

                              <div className='w-1/3'>
                                 <label className='block mb-1'>E-mail Corporativo</label>
                                 <input
                                    className='bg-input-register rounded-md pl-3 text-opaque-white h-10 w-full'
                                    placeholder='ex: joaosilva@empresa.com'
                                    type='text'
                                    value={state.representativesCorporateEmail}
                                    onChange={(e) => {
                                       dispatch({
                                          type: RegisterEnterpriseAction.setRepresentativesCorporateEmail,
                                          payload: e.target.value,
                                       });
                                    }}
                                 />
                              </div>
                           </div>

                           <div className='flex flex-row gap-x-10 pt-3 mb-3 pr-5'>
                              <div className='w-1/3'>
                                 <label className='block mb-1'>CPF *</label>
                                 <InputMask
                                    mask='999.999.999-99'
                                    maskChar=''
                                    className='bg-input-register rounded-md pl-3 text-opaque-white h-10 w-full'
                                    placeholder='ex: 123.456.789-00'
                                    type='text'
                                    value={state.representativesCpf}
                                    onChange={(e) => {
                                       dispatch({
                                          type: RegisterEnterpriseAction.setRepresentativesCpf,
                                          payload: e.target.value,
                                       });
                                    }}
                                 />
                              </div>

                              <div className='w-1/3'>
                                 <label className='block mb-1'>Gênero</label>
                                 <CustomSelectGender genderUser={gender} />
                              </div>

                              <div className='w-1/3'>
                                 <label className='block mb-1'>Data de Nascimento *</label>
                                 <InputMask
                                    mask='99/99/9999'
                                    value={state.representativesBirthDate}
                                    alwaysShowMask={true}
                                    onChange={(e) => {
                                       dispatch({
                                          type: RegisterEnterpriseAction.setRepresentativesBirthDate,
                                          payload: e.target.value,
                                       });
                                    }}
                                    id='birthDate'
                                    className='w-full bg-input-register rounded-md text-opaque-white px-3 py-2'
                                    style={{ backgroundColor: "#03001A" }}
                                    type='text'
                                    placeholder='ex: DD/MM/YYYY'
                                 />
                              </div>
                           </div>

                           <div className='flex flex-row gap-x-10 pt-3 mb-3 pr-5'>
                              <div className='w-1/3'>
                                 <label className='block mb-1'>Cargo Atual</label>
                                 <input
                                    className='bg-input-register rounded-md pl-3 text-opaque-white h-10 w-full'
                                    placeholder='ex: Gerente Administrativo'
                                    type='text'
                                    onChange={(e) => {
                                       dispatch({
                                          type: RegisterEnterpriseAction.setRepresentativesRole,
                                          payload: e.target.value,
                                       });
                                    }}
                                 />
                              </div>

                              <div className='w-1/3'>
                                 <label className='block mb-1'>Tipo de Conta *</label>
                                 <CustomSelectAccountType
                                    allowedTypes={["Gestor", "Medico"]}
                                    accountTypeUser={selectedAccountType}
                                    additionalInfoUser={colaborator?.user.additional_info}
                                 />
                              </div>

                              <div className='w-1/3'>
                                 <label className='block mb-1'>Plano de Cobertura *</label>
                                 <CustomSelectPlans initialPlanId={colaborator?.user.linked_plan[0]} />
                              </div>
                           </div>

                           <div className='flex flex-row gap-3'>
                              <button
                                 type='submit'
                                 disabled={isLoading}
                                 className='flex justify-center items-center bg-transparent border text-white w-1/4 rounded-md mt-5 py-3 text-center'
                                 onClick={() => {
                                    handleSubmit(handleSave);
                                    handleSave();
                                 }}
                              >
                                 {isLoading ? <LoadingSpinnerIcon colorClass='text-white-500' /> : "Salvar"}
                              </button>
                              <button
                                 type='submit'
                                 disabled={isSubmitting || isNextStep}
                                 className='bg-omni-blue w-1/4 rounded-md mt-5 py-3 text-opaque-white text-center disabled:bg-zinc-50'
                                 onClick={() => {
                                    navigate("/cadastro-empresa/planos-ativos");
                                 }}
                              >
                                 Próximo
                              </button>
                           </div>
                        </form>
                     </FormProvider>

                     <div className='font-thin mt-10 px-9'>
                        <section className='rounded-lg'>
                           <table className='w-full rounded-[1.5rem] bg-[#04011B] px-4'>
                              <thead className='rounded-lg font-medium'>
                                 <tr>
                                    <th className='py-2 px-4 font-medium text-center text-white rounded-lg'>Gestor</th>
                                    <th className='py-2 px-4 font-medium text-center text-white rounded-lg'>Nome do Gestor</th>
                                    <th className='py-2 px-4 font-medium text-center text-white rounded-lg'>E-mail Corporativo</th>
                                    <th className='py-2 px-4 font-medium text-center text-white rounded-lg'>Tipo de Conta</th>
                                    <th className='py-2 px-4 font-medium text-center text-white rounded-lg'>Ação</th>
                                 </tr>
                              </thead>
                              <tbody className='rounded-b-lg text-white'>
                                 {itemsTeste.length > 0
                                    ? itemsTeste.map((user: any, index) => (
                                         <tr key={user.user.id} className={index === itemsTeste.length - 1 ? "no-border-bottom" : ""}>
                                            <td className='px-3 text-center border-y text-left py-4 rounded-lg'>{index + 1}</td>
                                            <td className='px-3 text-center border-y text-left py-4 rounded-lg border-x'>
                                               {user.userByPVault.name}
                                            </td>
                                            <td className='px-3 text-center border-y text-left py-4 rounded-lg border-x'>
                                               {user.userByPVault.email}
                                            </td>
                                            <td className='px-3 text-center border-y text-left py-4 rounded-lg border-x'>
                                               {user.user.user_type[0]}
                                            </td>
                                            <td className='px-3 text-center border-y text-left py-4 rounded-lg'>
                                               <button onClick={() => handleEdit(user.user.id)} className='mr-4'>
                                                  <img src='/editIcon.svg' className='h-87 w-8 text-blue-500' alt='Ícone de edição' />
                                               </button>
                                               <button onClick={() => handleDelete(user.user.id)}>
                                                  <img src='/deleteIcon.svg' className='h-87 w-8 text-red-500' alt='Ícone de exclusão' />
                                               </button>
                                            </td>
                                         </tr>
                                      ))
                                    : null}
                              </tbody>
                           </table>
                        </section>
                     </div>
                  </div>
               </Content>
            </div>
         </MainContainer>

         <style>
            {`
          .no-border-bottom {
            border-bottom: none !important;
          }
        `}
         </style>
      </>
   );
};
