import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import InputMask from "react-input-mask";
import { api } from "../../utils/services/api";
import LoadingSpinnerIcon from "../Loading/LoadingSpinnerIcon ";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Base64ContentViewer from "./PrescriptionRender";


export type GoBuy = {
   buyId: string;
   userId: string;
   medications: string[];
   quantity: string[];
   individualPrice: string[];
   endToEndId: string;
   totalAmount: string;
   attachmentsId: string;
   paymentMean: string;
   date: string;
   medName: string[];
   address: string;
   status: string;
   establishmentName: string;
   comprovante: string;
   prescription: string;
};

type BuyImageDetailsProps = {
   closeModal: () => void;
   comprovante: string;
};

const BuyImageDetails: React.FC<BuyImageDetailsProps> = ({ closeModal }) => {
   const [imageURL, setImageURL] = useState<string[] | null>(null);
   const [userDetails, setUserDetails] = useState<any | null>(null);
   const [buyDetails, setBuyDetails] = useState<GoBuy | null>(null);
   const [dateFormatted, setDateFormatted] = useState<any | null>(null);
   const [medicationDetails, setMedicationDetails] = useState<any[] | []>([]);
   const [showComprovante, setShowComprovante] = useState<boolean>(false);
   const [modalOpen, _] = useState<boolean>(false); // Estado do modal
   const [isLoading, setIsLoading] = useState(true); // Add a loading state
   const [isPdf, setIsPdf] = useState(false);
   const [prescription, setPrescriptions] = useState<any | null>(null);

   
   const { id } = useParams();
   
   const processFile = (fileData, fileType) => {
      const binaryData = atob(fileData);
      const byteArray = new Uint8Array(binaryData.length);
      for (let i = 0; i < binaryData.length; i++) {
         byteArray[i] = binaryData.charCodeAt(i);
      }

      const blob = new Blob([byteArray], { type: fileType });
      const fileURL = URL.createObjectURL(blob);
      return fileURL; // Retorna a URL do arquivo processado
   };

   const readBase64FromBuy = async (buyID: string) => {
      try {
         const response = await axios.get(`${import.meta.env.VITE_API_URL_GO}/buy-details/${buyID}`, {
            responseType: "json",
         });

         const responseUserData = await api.get(`/users/${response.data.userId}`);

         setUserDetails(responseUserData.data);
         setBuyDetails(response.data);

         const prescriptionData = response.data.prescriptions;
         setPrescriptions(prescriptionData);

         setIsPdf(response.data.prescricao_digital);

            const imageUrls = prescriptionData.map((fileData) => processFile(fileData, isPdf? "application/pdf" : "image/png"));
            setImageURL(imageUrls); // Armazena um array de URLs para imagens

         if (response.data) {
            const formattedDate = new Date(response.data.date).toLocaleDateString("pt-BR");
            setDateFormatted(formattedDate);
         }

         // Fazer uma requisição para cada item na array medications
         const medicationsPromises = response.data.medications.map(async (medicationId) => {
            try {
               const responseMedication = await api.get(`/medication/${medicationId}`);
               // Faça algo com a resposta do medicamento, por exemplo, armazene-a em uma array
               return responseMedication.data;
            } catch (err) {
               console.log(err);
               // Trate erros aqui, se necessário
               return null;
            }
         });

         // Aguarde todas as requisições de medicamentos serem concluídas
         const medicationsData = await Promise.all(medicationsPromises);

         setMedicationDetails(medicationsData);
         setIsLoading(false);

         // medicationsData agora contém os dados de todos os medicamentos
      } catch (err) {
         console.log(err);
      }
   };

   useEffect(() => {
      readBase64FromBuy(id as string);
   }, []);

   return (
      <>
         {modalOpen && <div className='overlay'></div>}
         <div
            className={`fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex w-auto max-h-[85%] z-50 flex-shrink-0 ${
               modalOpen ? "blur-background" : ""
            }`}
            style={{
               border: "0.5px solid #E0E7FE",
               borderRadius: "1.875rem",
               backgroundColor: "#03001A",
               boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
               color: "white",
            }}
         >
            <div
               style={{
                  display: "flex",
                  flexDirection: "column", 
                  alignItems: "center", 
                  justifyContent: "center", 
                  width: "667px",
                  height: "auto",
                  padding: "35px",
                  background: "#03001A",
                  borderRadius: "1.875rem",
                  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
               }}
            >
               {isLoading ? (
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                     <LoadingSpinnerIcon colorClass='text-white-500' size='h-6 w-6' />
                  </div>
               ) : showComprovante ? (
                  <p className='max-w-full h-[80%] flex items-center justify-center overflow-hidden whitespace-pre-line font-mono text-center scrollable-text'>
                     {buyDetails.comprovante}
                  </p>
               ) : prescription && <Base64ContentViewer base64Array={prescription} />}
            </div>
            <div
               className='scrollable-container flex-col w-[70%] flex-shrink overflow-y-auto max-h-screen p-4 custom-scrollbar'
               id='manual'
            >
               {" "}
               <div className='font-Manrope font-medium leading-5 mt-6 mb-5' style={{ fontSize: "1.375rem" }}>
                  <div className='flex items-center justify-between'>
                     <div>Conferência Manual</div>
                     <button
                        className='hover:border-[#E0E7FE] rounded-md'
                        onClick={closeModal}
                        style={{
                           background: "transparent",
                           fontSize: "1.5rem",
                           fontWeight: "bold",
                           color: "#333",
                        }}
                     >
                        <svg
                           width='20'
                           height='20'
                           viewBox='0 0 20 20'
                           fill='none'
                           xmlns='http://www.w3.org/2000/svg'
                           className='rounded-md m-1'
                        >
                           <path d='M0 2.22222L2.22222 0L20 17.7778L17.7778 20L0 2.22222Z' fill='#C4C4C4' />
                           <path d='M2.22222 20L7.94727e-07 17.7778L17.7778 1.76373e-08L20 2.22222L2.22222 20Z' fill='#C4C4C4' />
                        </svg>
                     </button>
                  </div>
               </div>
               <div
                  style={{
                     width: "18.75rem",
                     height: "2.8125rem",
                     flexShrink: 0,
                     borderRadius: "0.625rem",
                     background: "#111627",
                     display: "flex",
                  }}
                  onClick={() => {
                     if (buyDetails.comprovante) {
                        setShowComprovante(!showComprovante);
                     }
                  }}
               >
                  <div
                     className='hover:cursor-pointer'
                     style={{
                        width: "50%", // Ajuste para ocupar 50% do container
                        height: "2.8125rem",
                        flexShrink: 0,
                        borderRadius: "0.625rem",
                        background: !showComprovante && "#0517F5",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        transition: "background-color 0.2s",
                     }}
                  >
                     Prescrição Médica
                  </div>
                  <div
                     className='text-white-400/[0.6] hover:cursor-pointer'
                     style={{
                        width: "50%", // Ajuste para ocupar 50% do container
                        height: "2.8125rem",
                        flexShrink: 0,
                        borderRadius: "0.625rem",
                        display: "flex",
                        background: showComprovante && buyDetails.comprovante && "#0517F5",
                        justifyContent: "center",
                        alignItems: "center",
                        transition: "background-color 0.2s",
                     }}
                  >
                     Cupom Fiscal
                  </div>
               </div>
               <div className='mt-5 mb-[-0.6rem]'>Data da prescrição</div>
               <label htmlFor='prescriptionDate' className=''>
                  <input
                     type='text'
                     placeholder='Defina a data'
                     disabled
                     value={dateFormatted || null}
                     className='my-4 pl-3 w-[82%] h-[3.125rem] flex-shrink-0 rounded-[0.625rem] border border-[0.5px] border-[#E0E7FE] bg-gradient-to-b from-[rgba(255,255,255,0.90)] to-[rgba(255,255,255,0.90)] text-black text-left max-w-screen '
                  />
               </label>
               <label htmlFor='cpf' className='text-white'>
                  <div className='mb-[0.3rem]'>Defina Beneficiário ou Dependente</div>
                  <InputMask
                     disabled
                     value={userDetails?.userByPVault?.cpf || ""}
                     className='w-[82%] h-[3.125rem] flex-shrink-0 rounded-[0.625rem] border border-[0.5px] border-[#E0E7FE] bg-gradient-to-b from-[rgba(255,255,255,0.90)] to-[rgba(255,255,255,0.90)] text-black text-left pl-3'
                     id='cpf'
                     mask='999.999.999-99'
                     type='text'
                     placeholder='ex: 000.000.000-00'
                  />
               </label>
               {medicationDetails.length > 0 ? ( // Conditionally render if medicationDetails has data
                  <div className='w-[82%] rounded-3xl border border-gray-300 border-opacity-38 mt-8'>
                     <table className='w-[100%] rounded-3xl bg-omni-gray'>
                        <thead className='font-medium h-14 border-gray-300'>
                           <tr>
                              <th className='py-2 px-4 font-medium text-left text-white-500 opacity-60'>Qtd</th>
                              <th className='py-2 px-4 font-medium text-left text-white-500 opacity-60'>Medicamentos</th>
                           </tr>
                        </thead>
                        <tbody className='rounded-b-lg text-white'>
                           {buyDetails.quantity.map((quantity, index) => (
                              <tr key={index} className='border-b border-t border-gray-300 border-opacity-40'>
                                 <td className='py-2 px-4 text-left '>{quantity}</td>
                                 <td className='py-2 px-4 text-left border-l border-gray-300 border-opacity-40 '>
                                    {medicationDetails[index].NOME}
                                 </td>
                              </tr>
                           ))}
                        </tbody>
                        <tfoot className=''>
                           <tr>
                              <td className='border-t border-r border-gray-300 border-opacity-40' />
                              <td className='border-t border-gray-300 border-opacity-40 h-[3rem]'>
                                 <button
                                    style={{
                                       width: "12.5rem",
                                       flexShrink: "0",
                                       borderRadius: "0.625rem",
                                       backgroundColor: "rgba(255, 255, 255, 0.38)",
                                       fontSize: "14px",
                                       padding: "4px 8px",
                                       border: "none",
                                       cursor: "pointer",
                                       transition: "background-color 0.3s",
                                    }}
                                    className='itens-center ml-4'
                                    onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "rgba(255, 255, 255, 0.5)")}
                                    onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "rgba(255, 255, 255, 0.38)")}
                                 >
                                    + Adicionar Medicamento
                                 </button>
                              </td>
                           </tr>
                        </tfoot>
                     </table>
                  </div>
               ) : (
                  // Skeleton loader when medicationDetails.length is 0
                  <div className='w-[82%] rounded-3xl border border-gray-300 border-opacity-38 mt-8'>
                     <div className='w-[100%] rounded-3xl bg-omni-gray'>
                        <thead className='font-medium h-14'>
                           <tr>
                              <th className='py-2 px-4 font-medium text-left text-white-500 opacity-60'>Qtd</th>
                              <th className='py-2 px-4 font-medium text-left text-white-500 opacity-60'>Medicamentos</th>
                           </tr>
                        </thead>
                        <div className='font-medium h-14'>
                           <div className='flex'>
                              <div className='w-1/4 py-2 px-4'>
                                 <div className='border border-gray-300 border-opacity-40 rounded-md h-[2rem] animate-pulse bg-gradient-to-r from-gray-200 to-gray-300' />
                              </div>
                              <div className='w-3/4 py-2 px-4'>
                                 <div className='border border-gray-300 border-opacity-40 rounded-md h-[2rem] animate-pulse bg-gradient-to-r from-gray-200 to-gray-300' />
                              </div>
                           </div>
                        </div>
                        <div className='rounded-b-lg text-white'>
                           <div className='flex'>
                              <div className='w-1/4 py-2 px-4'>
                                 <div className='border border-gray-300 border-opacity-40 rounded-md h-[2rem] animate-pulse bg-gradient-to-r from-gray-200 to-gray-300' />
                              </div>
                              <div className='w-3/4 py-2 px-4'>
                                 <div className='border border-gray-300 border-opacity-40 rounded-md h-[2rem] animate-pulse bg-gradient-to-r from-gray-200 to-gray-300' />
                              </div>
                           </div>
                           {/* You can add more skeleton rows as needed */}
                        </div>
                        <div className=''>
                           <div className='flex'>
                              <div className='w-1/4 border-t border-r border-gray-300 border-opacity-40' />
                              <div className='w-3/4 border-t border-gray-300 border-opacity-40 h-[3rem] pt-2'>
                                 <button
                                    style={{
                                       width: "12.5rem",
                                       flexShrink: "0",
                                       borderRadius: "0.625rem",
                                       backgroundColor: "rgba(255, 255, 255, 0.38)",
                                       fontSize: "14px",
                                       padding: "4px 8px",
                                       border: "none",
                                       cursor: "pointer",
                                       transition: "background-color 0.3s",
                                    }}
                                    className='itens-center ml-4 hover:bg-opacity-60'
                                    onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "rgba(255, 255, 255, 0.5)")}
                                    onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "rgba(255, 255, 255, 0.38)")}
                                 >
                                    + Adicionar Medicamento
                                 </button>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               )}
               <label htmlFor='doctorName' className=''>
                  <div className='mt-4 '>Defina o médico Prescritor</div>
                  <input
                     id='doctorName'
                     type='text'
                     placeholder='Digite o nome e o CRM do médico'
                     disabled
                     className='mt-3 mb-4 w-[82%] h-[3.125rem] flex-shrink-0 rounded-[0.625rem] border border-[0.5px] border-[#E0E7FE] bg-gradient-to-b from-[rgba(255,255,255,0.90)] to-[rgba(255,255,255,0.90)] text-black pl-3'
                  />
               </label>
               <button
                  className='mb-4 w-[82%]'
                  style={{
                     height: "3.125rem",
                     flexShrink: "0",
                     background: "#0517F5",
                     borderRadius: "0.625rem",
                     fontFamily: "Manrope",
                     fontWeight: "500",
                     fontSize: "1rem",
                     lineHeight: "normal",
                  }}
               >
                  SALVAR
               </button>
               <button
                  className='mb-4 w-[82%]'
                  style={{
                     height: "3.125rem",
                     flexShrink: "0",
                     borderRadius: "0.625rem",
                     fontFamily: "Manrope",
                     fontWeight: "500",
                     fontSize: "1rem",
                     lineHeight: "normal",
                     background: "#03001A",
                     border: "0.5px solid #0517F5",
                  }}
               >
                  NÃO FOI POSSIVEL IDENTIFICAR
               </button>
            </div>
         </div>

         <style>
            {`
          .scrollable-text {
            max-height: 80%; /* ajuste conforme necessário */
            overflow-y: auto; /* habilita a rolagem vertical */
            text-align: center;
            font-family: monospace;
            padding: 10px;
            word-break: break-word; /* quebra palavras longas para evitar a rolagem horizontal */
          }

          .image-zoom {
            transition: transform 0.25s ease;
          }

          .image-zoom:hover {
            transform: scale(1.5);
          }

        .overlay {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          backdrop-filter: ${modalOpen ? "blur(8px)" : "none"}; /* Aplica o desfoque apenas quando o modal estiver aberto */
          z-index: 999; /* Certifique-se de que esteja acima do modal */
          pointer-events: ${modalOpen ? "none" : "auto"}; /* Impede interações quando o modal estiver aberto */
        }

        .custom-scrollbar::-webkit-scrollbar {
          width: 10px;
        }
      
        /* Estilos para o conteúdo desfocado fora do modal */
        .blur-background {
          backdrop-filter: blur(8px); /* Aplica o desfoque fora do modal */
        }
      `}
         </style>
      </>
   );
};

export default BuyImageDetails;
