import React, { createContext, useContext, useReducer } from "react";
import dayjs from "dayjs";
import { TAction, TContext, TRegisterEnterprise, RegisterEnterpriseAction } from "../types/RegisterEnterpriseTypes";

const initialData: TRegisterEnterprise = {
   enterpriseId: "",
   currentStep: 0,
   enterpriseName: "My Enterprise",
   cnpj: "123456789",
   enterpriseCep: "12345-678",
   enterpriseAddress: "123 Main St",
   enterpriseAddressNumber: 10,
   enterpriseComplement: "Apt 5",
   enterpriseCity: "Sao Paulo",
   enterpriseState: "SP",
   enterpriseAccountStatus: "Ativa",
   representativesFullName: "",
   representativesPhone: "",
   representativesCorporateEmail: "",
   representativesCpf: "",
   representativesGender: "Male",
   representativesBirthDate: dayjs().format("DD-MM-YYYY"),
   representativesRole: "Beneficiary",
   representativesAccountType: "",
   crm: "",
   uf: "",
   inicioVigencia: dayjs().format("DD-MM-YYYY"),
   fimVigencia: dayjs().format("DD-MM-YYYY"),
};

export const RegisterContext = createContext<TContext | undefined>(undefined);

const registerEnterpriseReducer = (state: TRegisterEnterprise, action: TAction) => {
   switch (action.type) {
      case RegisterEnterpriseAction.setEnterpriseId:
         return { ...state, enterpriseId: action.payload };
      case RegisterEnterpriseAction.setCurrentStep:
         return { ...state, currentStep: action.payload };
      case RegisterEnterpriseAction.setEnterpriseName:
         return { ...state, enterpriseName: action.payload };
      case RegisterEnterpriseAction.setCnpj:
         return { ...state, cnpj: action.payload };
      case RegisterEnterpriseAction.setEnterpriseCep:
         return { ...state, enterpriseCep: action.payload };
      case RegisterEnterpriseAction.setEnterpriseAddress:
         return { ...state, enterpriseAddress: action.payload };
      case RegisterEnterpriseAction.setEnterpriseAddressNumber:
         return { ...state, enterpriseAddressNumber: action.payload };
      case RegisterEnterpriseAction.setEnterpriseComplement:
         return { ...state, enterpriseComplement: action.payload };
      case RegisterEnterpriseAction.setEnterpriseCity:
         return { ...state, enterpriseCity: action.payload };
      case RegisterEnterpriseAction.setEnterpriseState:
         return { ...state, enterpriseState: action.payload };
      case RegisterEnterpriseAction.setRepresentativesFullName:
         return { ...state, representativesFullName: action.payload };
      case RegisterEnterpriseAction.setRepresentativesCorporateEmail:
         return { ...state, representativesCorporateEmail: action.payload };
      case RegisterEnterpriseAction.setRepresentativesPhone:
         return { ...state, representativesPhone: action.payload };
      case RegisterEnterpriseAction.setRepresentativesCpf:
         return { ...state, representativesCpf: action.payload };
      case RegisterEnterpriseAction.setRepresentativesBirthDate:
         return { ...state, representativesBirthDate: action.payload };
      case RegisterEnterpriseAction.setRepresentativesGender:
         return { ...state, representativesGender: action.payload };
      case RegisterEnterpriseAction.setRepresentativesRole:
         return { ...state, representativesRole: action.payload };
      case RegisterEnterpriseAction.setRepresentativesAccountType:
         return { ...state, representativesAccountType: action.payload };
      case RegisterEnterpriseAction.setEnterpriseAccountStatus:
         return { ...state, enterpriseAccountStatus: action.payload };
      case RegisterEnterpriseAction.setRepresentativesCRM:
         return { ...state, crm: action.payload };
      case RegisterEnterpriseAction.setRepresentativesUF:
         return { ...state, uf: action.payload };
      case RegisterEnterpriseAction.setInicioVigencia:
         return { ...state, inicioVigencia: action.payload };
      case RegisterEnterpriseAction.setFimVigencia:
         return { ...state, fimVigencia: action.payload };
      default:
         return state;
   }
};

export const RegisterProvider = ({ children }: { children: JSX.Element }) => {
   const [state, dispatch] = useReducer(registerEnterpriseReducer, initialData);
   const value = { state, dispatch };
   return (
      // eslint-disable-next-line react/react-in-jsx-scope
      <RegisterContext.Provider value={value}>{children}</RegisterContext.Provider>
   );
};

export const useRegisterEnterprise = () => {
   const context = useContext(RegisterContext);

   if (context === undefined) {
      throw new Error("useRegisterEnterprise must be used inside Register Enterprise Provider");
   }
   return context;
};
