import { SvgIcon, SvgIconProps } from "@mui/material";

const CoverageIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M3.84962 5.81004e-07H12.8016C13.2891 -1.27002e-05 13.7099 -2.41597e-05 14.0564 0.0274764C14.4221 0.0565047 14.7859 0.120595 15.1359 0.293855C15.6581 0.552334 16.0826 0.964776 16.3487 1.47207C16.527 1.81211 16.593 2.16549 16.6229 2.52078C16.6512 2.8574 16.6512 3.26608 16.6512 3.73981V6.05805C15.6709 5.98187 14.6637 6.30789 13.9136 7.03611L7.7963 12.9717C7.42621 13.3308 7.09963 13.6476 6.82871 14.017C6.59036 14.342 6.38987 14.6917 6.23095 15.0597C6.01615 15.5571 5.88915 16.1171 5.81064 16.4634C5.7534 16.7158 5.68419 16.9664 5.64704 17.2226C5.62524 17.373 5.5942 17.6469 5.65269 17.9736H3.8496C3.36196 17.9736 2.94128 17.9737 2.59479 17.9462C2.22907 17.9171 1.86531 17.853 1.51529 17.6798C0.993101 17.4213 0.56855 17.0089 0.302483 16.5016C0.124135 16.1615 0.0581636 15.8081 0.0282831 15.4528C-2.48897e-05 15.1163 -1.3085e-05 14.7076 5.98894e-07 14.2339V3.73976C-1.3085e-05 3.26608 -2.48897e-05 2.85737 0.0282831 2.52078C0.0581636 2.16549 0.124135 1.81211 0.302483 1.47207C0.56855 0.964776 0.993101 0.552334 1.51529 0.293855C1.86531 0.120595 2.22907 0.0565047 2.59479 0.0274764C2.94126 -2.41597e-05 3.36203 -1.27002e-05 3.84962 5.81004e-07ZM3.70026 3.59473C3.18936 3.59473 2.7752 3.99708 2.7752 4.49341C2.7752 4.98974 3.18936 5.39209 3.70026 5.39209H10.1757C10.6866 5.39209 11.1008 4.98974 11.1008 4.49341C11.1008 3.99708 10.6866 3.59473 10.1757 3.59473H3.70026ZM3.70026 7.18945C3.18936 7.18945 2.7752 7.59181 2.7752 8.08813C2.7752 8.58446 3.18936 8.98681 3.70026 8.98681H8.32559C8.83648 8.98681 9.25065 8.58446 9.25065 8.08813C9.25065 7.59181 8.83648 7.18945 8.32559 7.18945H3.70026ZM3.70026 10.7842C3.18936 10.7842 2.7752 11.1865 2.7752 11.6829C2.7752 12.1792 3.18936 12.5815 3.70026 12.5815H5.55039C6.06129 12.5815 6.47546 12.1792 6.47546 11.6829C6.47546 11.1865 6.06129 10.7842 5.55039 10.7842H3.70026Z'
          fill='white'
          fillOpacity='0.87'
        />
        <path
          d='M16.6512 13.9309V14.2338C16.6512 14.7076 16.6512 15.1162 16.6229 15.4528C16.593 15.8081 16.527 16.1615 16.3487 16.5016C16.0826 17.0089 15.6581 17.4213 15.1359 17.6798C14.7859 17.853 14.4221 17.9171 14.0564 17.9462C13.7099 17.9737 13.2892 17.9736 12.8016 17.9736H12.4832L16.6512 13.9309Z'
          fill='white'
          fillOpacity='0.87'
        />
        <path
          d='M17.5232 8.30781C16.8874 7.69019 15.8567 7.69019 15.2209 8.30781L9.1793 14.17C8.73604 14.6006 8.51441 14.8159 8.33541 15.06C8.1765 15.2766 8.04285 15.5098 7.9369 15.7551C7.81755 16.0314 7.74582 16.3279 7.60237 16.9207L7.571 17.0503C7.48349 17.4119 7.43974 17.5927 7.49115 17.7222C7.53606 17.8353 7.62613 17.926 7.7409 17.9736C7.87223 18.0282 8.05979 17.9922 8.43486 17.9201L8.52446 17.9029C9.18068 17.777 9.50887 17.7139 9.81485 17.5979C10.0865 17.4949 10.3446 17.3611 10.5838 17.1992C10.8534 17.0168 11.0899 16.787 11.563 16.3275L17.5232 10.5444C18.1589 9.9268 18.1589 8.92543 17.5232 8.30781Z'
          fill='white'
          fillOpacity='0.87'
        />
      </svg>
    </SvgIcon>
  );
};

export default CoverageIcon;
