import React from "react";
import { useState } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { useRegisterColaborator } from "../../hooks/ColaboratorContext";

export const RegisterColaboratorFormStepper = () => {
  const [step, setSelectedStep] = useState(0);
  const location = window.location.pathname;
  const steps = location.includes("novo-perfil-individual")? ["Dados Pessoais", "Dependentes"] : ["Dados Pessoais"];
  const colaboratorID = localStorage.getItem("userEdition");

  const navigate = useNavigate();
  const { state } = useRegisterColaborator();

  const initialUppercase = (str: string) => {
    return str
      .trim()
      .split(" ")
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleNavLinkClick = (index: number) => {
    if (index === 0 && !colaboratorID) {
      navigate("/novo-perfil-individual");
    } else if (index === 0 && colaboratorID) {
      navigate("/atualizar-perfil-individual");
    } else if (index === 1 && state.colaboratorId) {
      navigate("/listaDependentes");
    }
    setSelectedStep(index);
  };

  const isButtonDisabled = (index: number) => {
    return index !== 0 && !state.colaboratorId;
  };

  return (
    <>
      <div className='flex flex-row py-4 mb-4 mt-[-1rem]'>
        {steps.map((step, index) => (
          <ul className='flex justify-start pl-1' key={index}>
            <NavLink
              to={
                index === 0 && !colaboratorID
                  ? "/novo-perfil-individual"
                  : index === 0 && colaboratorID
                    ? "/atualizar-perfil-individual"
                    : "/listaDependentes"
              }
              onClick={() => handleNavLinkClick(index)}
              className={`rounded-md pr-8 text-white text-sm font-normal px-4 mt-3 ${
                isButtonDisabled(index) ? "opacity-50 cursor-not-allowed" : ""
              } ${index === Number(step) ? "text-teal-400" : ""}`}
              style={index === 0 ? {} : !state.colaboratorId ? { pointerEvents: "none", opacity: 0.5 } : {}}
            >
              {initialUppercase(step)}
            </NavLink>
          </ul>
        ))}
      </div>

      <Outlet />
    </>
  );
};
