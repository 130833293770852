import React from "react";

import { LeftSidebar } from "../../components/LeftSidebar";
import MainContainer from "../../components/layout/MainContainer";
import LeftBarContainer from "../../components/layout/LeftBarContainer";
import Content from "../../components/layout/Content";
import { TopbarEnterprise } from "../../components/TopbarEnterprise";

export const PlanDetails = () => {
  return (
    <MainContainer className='bg-[#03001A]'>
      <LeftBarContainer className='bg-[#03001A]'>
        <LeftSidebar />
      </LeftBarContainer>

      <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, width: "480%" }}>
        <TopbarEnterprise />

        <Content className='bg-omni-gray rounded-[2rem] h-[100%] p-5 relative mb-10'>
          <div className='flex justify-between items-center m-5 mt-4'>
            <h1 className='font-manrope font-bold font-regular text-5xl text-white justify-center mt-1'>
              Plan Details
            </h1>
          </div>
        </Content>
      </div>
    </MainContainer>
  );
};
