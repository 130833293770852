import React, { useEffect } from "react";

interface PaginationProps {
  totalPages: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ currentPage, totalPages, onPageChange }) => {
  const maxPagesToShow = 3;
  let startPage = Math.max(1, currentPage - Math.floor(maxPagesToShow / 2));
  const endPage = Math.min(startPage + maxPagesToShow - 1, totalPages);

  if (endPage - startPage + 1 < maxPagesToShow) {
    startPage = Math.max(1, endPage - maxPagesToShow + 1);
  }

  const pageNumbers = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);

  return (
    <div className='flex justify-end space-x-4 text-white mx-7 mt-3'>
      <button
        onClick={() => onPageChange(Math.max(1, currentPage - 1))}
        disabled={currentPage === 1}
        className='rounded-lg px-4 py-2 font-inter font-medium text-base'
      >
        Anterior
      </button>

      {pageNumbers.map((number) => (
        <button
          key={number}
          onClick={() => onPageChange(number)}
          className={`rounded-lg px-4 py-2 font-inter font-medium text-base ${
            number === currentPage ? "bg-blue-500 text-white" : "bg-transparent text-white"
          }`}
        >
          {number}
        </button>
      ))}

      <button
        onClick={() => onPageChange(Math.min(currentPage + 1, totalPages))}
        disabled={currentPage === totalPages}
        className='rounded-lg px-4 py-2 font-inter font-medium text-base'
      >
        Próximo
      </button>

      <div className='flex items-center'>
        Página{" "}
        <strong className='ml-2'>
          {currentPage} de {totalPages}
        </strong>
      </div>
    </div>
  );
};

export default Pagination;
