/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useState, ReactNode, useContext, useReducer, FC } from "react";
import { RegisterEnterpriseState, EnterpriseDetails } from "../types/RegisterEnterpriseTypes";
import { registerEnterpriseReducer } from "../reducer/registerEnterpriseReducer ";
import { EnterpriseListing } from "../pages/EnterpriseRegister/EnterpriseListing";

interface CompanyContextState {
  updateCompanyData: (id: string) => void;
  updatePlanData: (id: string) => void;
  collaboratorData: (id: string) => void;
  linkedCompany: string;
  linkedPlan: string;
  collaboratorId: string;
}

const defaultCompanyState: CompanyContextState = {
  updateCompanyData: () => {},
  updatePlanData: () => {},
  collaboratorData: () => {},
  linkedCompany: "",
  linkedPlan: "",
  collaboratorId: "",
};

const CompanyContext = createContext<CompanyContextState>(defaultCompanyState);

interface CompanyProviderProps {
  children: ReactNode;
}

const CompanyProvider: React.FC<CompanyProviderProps> = ({ children }) => {
  const [linkedCompany, setLinkedCompany] = useState("");
  const [linkedPlan, setLinkedPlan] = useState("");
  const [collaboratorId, setCollaboratorId] = useState("");

  const updateCompanyData = (id: string) => {
    setLinkedCompany(id);
  };

  const updatePlanData = (id: string) => {
    setLinkedPlan(id);
  };

  const collaboratorData = (id: string) => {
    setCollaboratorId(id);
  };

  const companyData: CompanyContextState = {
    updateCompanyData,
    updatePlanData,
    collaboratorData,
    linkedCompany,
    linkedPlan,
    collaboratorId,
  };

  return <CompanyContext.Provider value={companyData}>{children}</CompanyContext.Provider>;
};

// Define the structure of the initial state
const initialState: RegisterEnterpriseState = {
  enterpriseDetails: null,
};

const RegisterEnterpriseContext = createContext<{
  state: RegisterEnterpriseState | null;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

export const RegisterEnterpriseProvider: FC = ({ children }: any) => {
  const [state, dispatch] = useReducer(registerEnterpriseReducer, initialState);

  return (
    <RegisterEnterpriseContext.Provider value={{ state: state as RegisterEnterpriseState | null, dispatch }}>
      <EnterpriseListing />
    </RegisterEnterpriseContext.Provider>
  );
};

export const useRegisterEnterprise = () => useContext(RegisterEnterpriseContext);

export { CompanyContext, CompanyProvider };
