import React from "react";
import { ReactNode } from "react";

type Props = {
  className?: string;
  children: ReactNode;
};

const Content = ({ children, className = "" }: Props) => {
  return <div className={`col-span-5 ${className}`}>{children}</div>;
};

export default Content;
