import React, { useContext, useEffect, useState } from "react";
import { LeftSidebar } from "../../components/LeftSidebar";
import { OmniLogo } from "../../components/Logo";
import { FilterPlans } from "../../components/modais/FilterPlans";
import { TableInstance } from "./TableInstance";

import MainContainer from "../../components/layout/MainContainer";
import LeftBarContainer from "../../components/layout/LeftBarContainer";
import Content from "../../components/layout/Content";
import FilterIcon from "../../components/icons/FilterIcon";
import { api } from "../../utils/services/api";
import { TopbarEnterprise } from "../../components/TopbarEnterprise";

export const AvailablePlans = () => {
  const [filterPlan, setFilterPlan] = useState(false);
  const [tableData, setTableData] = useState<any[]>([]);

  const getEnterpriseData = async () => {
    try {
      const { data: plans } = await api.get(`/plan/`);

      if (plans && plans.plans) {
        plans.plans.forEach((plan: any) => {
          plan.id = `#${plan.id.substring(0, 5)}`;
          plan.acquisition_allowed = plan.acquisition_allowed ? "Sim" : "Não";
          plan.refund_allowed = plan.fixated_reibursement_allowed || plan.varying_reimbursement_allowed ? "Sim" : "Não";
        });
        setTableData(plans.plans);
      } else {
        setTableData([]); // Definir uma array vazia quando não há resultados da API
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getEnterpriseData();
  }, []);

  return (
    <>
      <MainContainer className='bg-[#03001A]'>
        <LeftBarContainer className='bg-[#03001A]'>
          <LeftSidebar />
        </LeftBarContainer>
        <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, width: "480%" }}>
          <TopbarEnterprise hide='invisible' />

          <Content className='bg-omni-gray rounded-[2rem] h-[100%] p-5 relative mb-10'>
            <div className='flex justify-between items-center m-5 mt-4'>
              <h1 className='font-manrope font-bold font-regular text-5xl text-white justify-center mt-1'>
                Planos Disponíveis
              </h1>
            </div>

            <TableInstance tableData={tableData} />
          </Content>
        </div>
      </MainContainer>
    </>
  );
};
