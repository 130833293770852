import { z } from "zod";

const RegisterColaboratorSchemaStepOne = z.object({
  id: z.string().optional(),
  avatar: z.string().optional(),
  email: z.string().email("E-mail inválido").optional(),
  name: z.string().nonempty(),
  cpf: z.string().nonempty().min(14).max(14, "CPF inválido"),
  date_of_birth: z.string().optional(),
  phone_number: z.string().nonempty().optional(),
  gender: z.string().nonempty(),
  linked_company: z.string().nonempty(),
  linked_plan: z.string().nonempty(),
  user_type: z.string().nonempty(),
});

const RegisterColaboratorSchemaStepTwo = z.object({
  idDependent: z.string().optional(),
  fullNameDependent: z.string().nonempty(),
  phoneNumberDependent: z.string().nonempty(),
  cpfDependent: z.string().nonempty().min(14).max(14, "CPF inválido"),
  parentDependent: z.string().nonempty(),
  accountTypeDependent: z.string().nonempty(),
});

export type RegisterColaboratorStepOneSchemaType = z.infer<typeof RegisterColaboratorSchemaStepOne>;

export type RegisterColaboratorStepTwoSchemaType = z.infer<typeof RegisterColaboratorSchemaStepTwo>;

export { RegisterColaboratorSchemaStepOne, RegisterColaboratorSchemaStepTwo };
