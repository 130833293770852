import { createReducer, PayloadAction } from "@reduxjs/toolkit";
import { RegisterEnterpriseState, EnterpriseDetails } from "../types/RegisterEnterpriseTypes";

const initialState: RegisterEnterpriseState = {
  enterpriseDetails: null,
};

// Define the action type
interface SaveEnterpriseDetailsAction extends PayloadAction<EnterpriseDetails> {
  type: "SAVE_ENTERPRISE_DETAILS";
}

// Define the action creator function
export const saveEnterpriseDetails = (payload: EnterpriseDetails): SaveEnterpriseDetailsAction => ({
  type: "SAVE_ENTERPRISE_DETAILS",
  payload,
});

export const registerEnterpriseReducer = createReducer(initialState, (builder) => {
  builder.addCase("SAVE_ENTERPRISE_DETAILS", (state, action: SaveEnterpriseDetailsAction) => {
    state.enterpriseDetails = action.payload;
  });

  // Add other cases and actions here

  // If needed, you can also add handlers for async actions using `.addCase().fulfilled`:
  // builder.addCase(fetchEnterpriseDetails.fulfilled, (state, action) => {
  //   state.enterpriseDetails = action.payload;
  // });
});

export default registerEnterpriseReducer;
