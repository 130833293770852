import React, { createContext, useContext, useReducer } from "react";
import {
  TColaboratorAction,
  TColaboratorContext,
  TRegisterColaborator,
  RegisterColaboratorAction,
} from "../types/CollaboratorDataTypes";

const initialData: TRegisterColaborator = {
  colaboratorId: "",
  currentStep: 0,
  avatar: "",
  name: "",
  phoneNumber: "",
  birthDate: "",
  cpf: "",
  height: "",
  weight: "",
  jobTitle: "",
  email: "",
  bmi: "",
  associatedPlan: "",
  accountType: "",
  vinculado: "",
  treatment: [],
  idDependent: "",
  nameDependent: "",
  cpfDependent: "",
  accountTypeDependent: ["Dependente"],
  parentDependent: "",
  phoneNumberDependent: "",
  additionalInfo: "",
  userCategory: "",
  holderCPF: "",
};

const ColaboratorContext = createContext<TColaboratorContext | undefined>(undefined);

const registerColaboratorReducer = (state: TRegisterColaborator, action: TColaboratorAction) => {
  switch (action.type) {
    case RegisterColaboratorAction.setColaboratorId:
      return { ...state, colaboratorId: action.payload };
    case RegisterColaboratorAction.setCurrentStep:
      return { ...state, currentStep: action.payload };
    case RegisterColaboratorAction.setAvatar:
      return { ...state, avatar: action.payload };
    case RegisterColaboratorAction.setName:
      return { ...state, name: action.payload };
    case RegisterColaboratorAction.setCpf:
      return { ...state, cpf: action.payload };
    case RegisterColaboratorAction.setBirthDate:
      return { ...state, birthDate: action.payload };
    case RegisterColaboratorAction.setHeight:
      return { ...state, height: action.payload };
    case RegisterColaboratorAction.setWeight:
      return { ...state, width: action.payload };
    case RegisterColaboratorAction.setJobTitle:
      return { ...state, jobTitle: action.payload };
    case RegisterColaboratorAction.setEmail:
      return { ...state, email: action.payload };
    case RegisterColaboratorAction.setPhoneNumber:
      return { ...state, phoneNumber: action.payload };
    case RegisterColaboratorAction.setBmi:
      return { ...state, bmi: action.payload };
    case RegisterColaboratorAction.setAssociatedPlan:
      return { ...state, associatedPlan: action.payload };
    case RegisterColaboratorAction.setDependent:
      return { ...state, dependent: action.payload };
    case RegisterColaboratorAction.setIdDependent:
      return { ...state, idDependent: action.payload };
    case RegisterColaboratorAction.setNameDependent:
      return { ...state, nameDependent: action.payload };
    case RegisterColaboratorAction.setPhoneNumberDependent:
      return { ...state, phoneNumberDependent: action.payload };
    case RegisterColaboratorAction.setCpfDependent:
      return { ...state, cpfDependent: action.payload };
    case RegisterColaboratorAction.setParentDependent:
      return { ...state, parentDependent: action.payload };
    case RegisterColaboratorAction.setAccountTypeDependent:
      return { ...state, accountTypeDependent: action.payload };
    case RegisterColaboratorAction.setAdditionalInfo:
      return { ...state, additionalInfo: action.payload };
    case RegisterColaboratorAction.setAccountType:
      return { ...state, accountType: action.payload };
    case RegisterColaboratorAction.setUserCategory:
      return { ...state, userCategory: action.payload };
    case RegisterColaboratorAction.setHolderCPF:
      return { ...state, holderCPF: action.payload };
    default:
      return state;
  }
};

export const ColaboratorProvider = ({ children }: { children: JSX.Element }) => {
  const [state, dispatch] = useReducer(registerColaboratorReducer, initialData);
  const value = { state, dispatch };

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <ColaboratorContext.Provider value={value}>{children}</ColaboratorContext.Provider>
  );
};

export const useRegisterColaborator = () => {
  const context = useContext(ColaboratorContext);

  if (context === undefined) {
    throw new Error("useRegisterColaborator must be used inside ColaboratorProvider");
  }
  return context;
};
