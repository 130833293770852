import React from "react";
import { NavLink } from "react-router-dom";

interface ProfileColaboratorStepperProps {
  selectedStep: number;
  setSelectedStep: (step: number) => void;
}

export const ProfileColaboratorStepper = ({ selectedStep, setSelectedStep }: ProfileColaboratorStepperProps) => {
  const steps = ["Informações Pessoais", "Aquisições", "Tratamentos"];

  const handleNavLinkClick = (index: number) => {
    setSelectedStep(index);
  };

  return (
    <div className='flex flex-row justify-between'>
      {steps.map((step: any, index) => (
        <div key={index} style={{ flex: 1, textAlign: "center" }}>
          <NavLink
            to='#'
            onClick={() => handleNavLinkClick(index)}
            className={`rounded-md pr-8 text-sm font-normal px-4 mt-3 ${
              index === selectedStep ? "text-white" : "text-opaque-white"
            }`}
            style={{
              color: index === selectedStep ? "text-white" : "text-opaque-white",
            }}
          >
            {step}
          </NavLink>
          {index === selectedStep && (
            <div
              className='mt-3'
              style={{
                borderBottom: index === selectedStep ? "3px solid #0517F5" : "3px solid #0517F5",
                width: "100%",
              }}
            />
          )}
        </div>
      ))}
    </div>
  );
};
