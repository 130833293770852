import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { LeftSidebar } from "../../components/LeftSidebar";
import "react-datepicker/dist/react-datepicker.css";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { RegisterColaboratorSchemaStepOne, RegisterColaboratorStepTwoSchemaType } from "../../utils/schemas/RegisterColaboratorSchema";
import { TopbarEnterprise } from "../../components/TopbarEnterprise";
import MainContainer from "../../components/layout/MainContainer";
import LeftBarContainer from "../../components/layout/LeftBarContainer";
import Content from "../../components/layout/Content";
import InputMask from "react-input-mask";
import { RegisterColaboratorFormStepper } from "../../components/CollaboratorComponents/RegisterColaboratorFormStepper";
import { CustomSelectPlans } from "../../components/CollaboratorComponents/CustomSelectPlans";
import { api } from "../../utils/services/api";
import { CustomSelectAccountType } from "../../components/CustomSelectAccountType";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptBRLocale from "date-fns/locale/pt-BR";
import { SelectCompanyFromHolding } from "../../components/CollaboratorComponents/SelectCompanyFromHolding";
import CustomTextField from "../../components/layout/CustomTextField";
import { userEditStore } from "../../Zustand/UserEditionStore";

export const CollaboratorEdition = () => {
   const fileInputRef = useRef<HTMLInputElement>(null);
   const location = useLocation();
   const [selectedImage, setSelectedImage] = useState<string | null>(null);
   const [avatar, setAvatar] = useState<FormData | null>(null);
   const [isLoading, setIsLoading] = useState(false);
   const [holdingSubsidiary, setHoldingSubsidiary] = useState<any>();
   const [subsidiaries, setSubsidiaries] = useState([]);

   const { user, fetchUser, updateUserField } = userEditStore((state) => ({
      user: state.user,
      fetchUser: state.fetchUser,
      updateUserField: state.updateUserField,
   }));
   const colaboratorID = localStorage.getItem("userEdition");
   
   const navigate = useNavigate();

   const [accountType, setAccountType] = useState<string>("Beneficiário");
   const [additionalInfo, setAdditionalInfo] = useState<string>("");

   const getSubsidiaries = async () => {
      const editedUserId = localStorage.getItem("userEdition");
      const response = await api.get("/holding/sisters", {
         params: {
            userId: editedUserId,
         },
      });
      setSubsidiaries(response.data.message.value);
   };

   const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const file = e.target.files?.[0];
      if (file) {
         const reader = new FileReader();
         reader.onload = async (event) => {
            const result = event.target?.result as string | null;
            setSelectedImage(result);

            const formData = new FormData();
            formData.append("imagem", file);

            setAvatar(formData);

            if (colaboratorID) {
               try {
                  await api.patch(`/users/avatar/updateFile/${colaboratorID}`, formData);
                  console.log("Imagem enviada com sucesso!");
               } catch (error) {
                  console.log("Ocorreu um erro ao enviar a imagem:", error);
               }
            }
         };

         reader.readAsDataURL(file);
      }
   };

   const handleClick = (event: React.MouseEvent) => {
      event.stopPropagation();
      fileInputRef.current?.click();
   };


   useEffect(() => {
    fetchUser(colaboratorID);
      getSubsidiaries();
   }, [location]);

   const createColaboratorForm = useForm<RegisterColaboratorStepTwoSchemaType>({
      resolver: zodResolver(RegisterColaboratorSchemaStepOne),
   });


   const onSubmit = async (data: any) => {
      try {
         setIsLoading(true);
         console.log(user)
        const response = await api.patch(`/users/representante/${colaboratorID}`, user);
         if(response.status === 200){
            toast.success("Colaborador editado com sucesso!");
            setIsLoading(false);
            navigate("/perfil-colaborador");
         }
      } catch (error: unknown) {
         setIsLoading(false);
      }
   };

   const {
      handleSubmit,
      formState: { isSubmitting },
   } = createColaboratorForm;

   function formatDate(dateString) {
      const date = new Date(dateString);
      let day = date.getDate().toString();
      let month = (date.getMonth() + 1).toString(); // JavaScript months are 0-based
      const year = date.getFullYear().toString();
  
      // Ensure day and month are always two digits
      day = day.padStart(2, '0');
      month = month.padStart(2, '0');
  
      // Return formatted date
      return `${month}-${day}-${year}`;
  }

   return (
      <>
         <MainContainer className='bg-[#03001A]'>
            <LeftBarContainer className='bg-[#03001A]'>
               <LeftSidebar />
            </LeftBarContainer>

            <div style={{ display: "flex", flexDirection: "column", width: "480%" }}>
               <TopbarEnterprise hideAll />
               <Content className='bg-omni-gray rounded-[2rem] h-[100%] p-5 relative mb-10 mt-[-4.5rem]'>
                  <div className='flex justify-between items-center m-5'>
                     <h1 className='font-manrope font-bold font-regular text-5xl text-white justify-center mt-1'>
                        {"Editar usuário"}
                     </h1>
                  </div>

                  <RegisterColaboratorFormStepper />

                  <div className='border-t border-[#0517F5] -mt-5 ml-4 mr-4' />
                  <FormProvider {...createColaboratorForm}>
                     <form className='grid grid-cols-3 gap-4 p-5' onSubmit={handleSubmit(onSubmit)}>
                        <div className='flex flex-col'>
                           <label htmlFor='profilePicture' className='text-white mt-3 mb-2'>
                              Foto do Perfil
                           </label>
                           {!selectedImage && (
                              <div
                                 className='custom-file-button flex flex-col items-center justify-center p-20'
                                 onClick={(e) => handleClick(e)}
                                 style={{
                                    background: "linear-gradient(0deg, #03001A, #03001A)",
                                    borderRadius: "10px",
                                    backgroundColor: "#03001A",
                                    maxWidth: "90%", // Defina o valor desejado para a largura máxima responsiva
                                    height: "300px",
                                 }}
                              >
                                 <svg width='60' height='50' viewBox='0 0 60 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                       d='M47.5742 16.5514C47.4489 7.40366 39.9685 0 30.7914 0C26.7092 0 22.7763 1.485 19.7175 4.18148C16.9875 6.58792 15.12 9.80377 14.3804 13.3345C14.3264 13.3339 14.2728 13.3334 14.2194 13.3334C6.37897 13.3334 0 19.7123 0 27.5528C0 35.3934 6.37897 41.7722 14.2195 41.7722H24.1503C24.8568 41.7722 25.4299 41.1994 25.4299 40.4927C25.4299 39.7859 24.8568 39.2131 24.1503 39.2131H14.2195C7.78991 39.2131 2.55914 33.9823 2.55914 27.5528C2.55914 21.1234 7.78991 15.8926 14.2195 15.8926C14.562 15.8926 14.9242 15.911 15.3267 15.9488C16.009 16.0127 16.6215 15.5274 16.7144 14.8477C17.1752 11.4704 18.8427 8.36412 21.4098 6.10112C24.0009 3.81691 27.3326 2.55902 30.7913 2.55902C38.6352 2.55902 45.0166 8.94045 45.0166 16.7843C45.0166 17.0667 44.9956 17.3578 44.9734 17.666L44.9639 17.797C44.9373 18.1734 45.0782 18.5424 45.3491 18.8053C45.6197 19.0681 45.9923 19.198 46.3683 19.1604C46.7086 19.126 47.052 19.1088 47.3888 19.1088C52.9314 19.1088 57.4409 23.6182 57.4409 29.1609C57.4409 34.7035 52.9315 39.2128 47.3888 39.2128H36.9461C36.2396 39.2128 35.6665 39.7857 35.6665 40.4924C35.6665 41.1992 36.2396 41.772 36.9461 41.772H47.3888C54.3426 41.772 60 36.1148 60 29.1609C59.9999 22.2694 54.4429 16.6509 47.5742 16.5514Z'
                                       fill='white'
                                       fillOpacity='0.6'
                                    />
                                    <path
                                       d='M30.7913 6.01392C25.4408 6.01392 20.8607 10.0125 20.1374 15.3147C20.0419 16.0149 20.5322 16.66 21.2323 16.7555C21.291 16.7635 21.3492 16.7675 21.4068 16.7675C22.0366 16.7675 22.5855 16.3023 22.6728 15.6608C23.224 11.6202 26.7142 8.57305 30.7912 8.57305C31.498 8.57305 32.0708 8.00024 32.0708 7.29348C32.0709 6.58684 31.498 6.01392 30.7913 6.01392Z'
                                       fill='white'
                                       fillOpacity='0.6'
                                    />
                                    <path
                                       d='M36.6108 31.1929L31.9563 27.0351C31.1539 26.3179 29.9434 26.318 29.1407 27.0349L24.4862 31.193C23.9591 31.6637 23.9137 32.4727 24.3844 32.9998C24.8551 33.5266 25.664 33.5725 26.1912 33.1016L29.269 30.3522V46.094C29.269 46.8008 29.8418 47.3736 30.5486 47.3736C31.2552 47.3736 31.8281 46.8008 31.8281 46.094V30.3519L34.906 33.1015C35.15 33.3196 35.4546 33.4268 35.758 33.4268C36.1098 33.4268 36.4599 33.2828 36.7128 32.9996C37.1833 32.4725 37.1378 31.6637 36.6108 31.1929Z'
                                       fill='white'
                                       fillOpacity='0.6'
                                    />
                                 </svg>

                                 <button
                                    className='text-white mt-2 bg-blue-500 px-6 py-3 rounded-lg'
                                    type='button'
                                    style={{
                                       backgroundColor: "#0517F5",
                                       borderRadius: "9999px",
                                    }}
                                 >
                                    Anexar imagem
                                 </button>
                                 <div
                                    className='text-white mt-2 flex flex-col items-center justify-center'
                                    style={{
                                       width: "250px",
                                    }}
                                 >
                                    Tamanho Máximo: 800x800
                                 </div>
                              </div>
                           )}
                           {selectedImage && (
                              <div
                                 className='flex flex-col items-center justify-center'
                                 style={{
                                    background: "linear-gradient(0deg, #03001A, #03001A)",
                                    borderRadius: "10px",
                                    backgroundColor: "#03001A",
                                    maxWidth: "90%", // Defina o valor desejado para a largura máxima responsiva
                                    height: "300px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                 }}
                              >
                                 <img
                                    src={selectedImage}
                                    alt='Selected'
                                    className='cursor-pointer w-100 h-72 object-cover'
                                    onClick={handleClick}
                                 />
                              </div>
                           )}
                           <input type='file' id='profilePicture' className='hidden' ref={fileInputRef} onChange={handleImageChange} />

                           <button
                              type='button'
                              className={`bg-[#0517F5] rounded-md mt-8 py-3 text-white text-center ${isLoading ? "loading-button" : ""}`}
                              style={{ maxWidth: "90%" }}
                              onClick={() => {
                                //  handleSubmit(onSubmit);
                                 onSubmit(user);
                              }}
                           >
                              {isLoading ? "Carregando..." : "Salvar"}
                           </button>
                        </div>

                        <div className='flex flex-col'>
                           <label htmlFor='name' className='text-white mt-4'>
                              Nome Completo *
                           </label>
                           <input
                              id='name'
                              className='rounded-md pl-3 text-white h-14 mt-2'
                              style={{ backgroundColor: "#03001A" }}
                              type='text'
                              placeholder='ex: Charlene Reed'
                              value={user.name}
                              onChange={(e) => {
                                updateUserField("name", e.target.value)
                            }}
                           />
                           <label htmlFor='cpf' className='text-white mt-4'>
                              CPF *
                           </label>
                           <InputMask
                              className='rounded-md pl-3 text-white h-14 mt-2'
                              style={{ backgroundColor: "#03001A" }}
                              id='cpf'
                              mask='999.999.999-99'
                              type='text'
                              placeholder='ex: 000.000.000-00'
                              value={user.cpf}
                              onChange={(e) => updateUserField("cpf", e.target.value)}
                           />

                           <label htmlFor='currentRole' className='text-white mt-4'>
                              Cargo Atual
                           </label>
                           <input
                              id='currentRole'
                              className='rounded-md pl-3 text-white h-14 mt-2'
                              style={{ backgroundColor: "#03001A" }}
                              type='text'
                              placeholder='ex: CEO'
                              value={user.cargoAtual}
                              onChange={(e) => updateUserField("cargoAtual", e.target.value)}
                           />

                           <label htmlFor='currentRole' className='text-white mt-4'>
                              Data de Nascimento
                           </label>
                           <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBRLocale}>
                              <MuiDatePicker
                                 value={user.date_of_birth}
                                 onChange={
                                    (date) => {
                                       console.log('formated date', formatDate(date))
                                       updateUserField("date_of_birth", (formatDate(date)));
                                       console.log(user.date_of_birth)
                                    }
                                 }
                                 maxDate={new Date()}
                                 renderInput={(params) => (
                                    <CustomTextField
                                       {...params}
                                       id='birthDate'
                                       inputProps={{
                                          ...params.inputProps,
                                          placeholder: "dd/mm/aaaa",
                                       }}
                                    />
                                 )}
                              />
                           </LocalizationProvider>
                        </div>
                        <div className='flex flex-col'>
                           <label htmlFor='phoneNumber' className='text-white mt-4'>
                              Telefone Celular
                           </label>
                           <InputMask
                              id='phoneNumber'
                              mask='(99) 9999-9999[9]' // A parte entre colchetes é opcional, permitindo 8 ou 9 dígitos.
                              className='rounded-md pl-3 text-white h-14 mt-2'
                              style={{ backgroundColor: "#03001A" }}
                              type='text'
                              placeholder='ex: (11) 98888-7777 ou (11) 8888-7777'
                              value={user.phone_number}
                              onChange={(e) => updateUserField("phone_number", e.target.value)}
                           />

                           <label htmlFor='corporateEmail' className='text-white mt-4'>
                              E-mail Corporativo
                           </label>
                           <input
                              id='corporateEmail'
                              className='rounded-md pl-3 text-white h-14 mt-2'
                              style={{ backgroundColor: "#03001A" }}
                              type='text'
                              placeholder='ex: creed@empresa.com'
                              value={user.email}
                              onChange={(e) => updateUserField("email", e.target.value)}
                           />
                           <label htmlFor='coveragePlan' className='text-white mt-5'>
                              Plano de Cobertura *
                           </label>
                           <CustomSelectPlans initialPlanId={user.plano} isEditing={colaboratorID ? true : false} />
                           <label htmlFor='coveragePlan' className='text-white mt-5'>
                              Tipo do Usuário *
                           </label>
                           <CustomSelectAccountType
                              accountTypeUser={accountType}
                              additionalInfoUser={additionalInfo}
                              setAccountType={setAccountType}
                              setAdditionalInfo={setAdditionalInfo}
                           />
                           {subsidiaries.length > 0 && (
                              <SelectCompanyFromHolding
                                 colaborator={user}
                                 holdingSubsidiary={holdingSubsidiary}
                                 setHoldingSubsidiary={setHoldingSubsidiary}
                              />
                           )}
                        </div>
                     </form>
                  </FormProvider>
               </Content>
            </div>
         </MainContainer>

         <style>
            {`
           .loading-button::after {
             content: "";
             width: 20px;
             height: 20px;
             border: 4px solid rgba(255, 255, 255, 0.3);
             border-radius: 50%;
             border-top: 4px solid white;
             animation: spin 1s linear infinite;
             display: inline-block;
             margin-left: 10px;
             vertical-align: middle;  /* Aligns the spinner with the text */
           }
 
           .myCustomDatePicker .MuiInputBase-root {
             background-color: #03001A !important; // Using !important to increase specificity
             // ... other styles
           }          
           
           @keyframes spin {
             0% { transform: rotate(0deg); }
             100% { transform: rotate(360deg); }
           }
 
           .date-picker-custom {
             background-color: #03001A;
             /* Add any other styles you need here */
           }
         `}
         </style>
      </>
   );
};
