import React from "react";
// import { CgFileDocument } from "react-icons/cg";

export const QuantityCoverageTable = ({ plans }: any) => {
  const totalMonthlyLimit = plans?.reduce((sum: number, row: any) => {
    return sum + row.monthly_fee * row.userCount;
  }, 0);

  const formatPlanCode = (code: string) => {
    let planName = "";
    let credits = "";

    if (code.startsWith("R")) {
      planName = "Royal";
    } else if (code.startsWith("C")) {
      planName = "Celeste";
    } else if (code.startsWith("T")) {
      planName = "Turquesa";
    }

    credits = code.match(/\d+/)?.[0] || ""; // Extract numerical characters

    return `${planName} ${credits}`;
  };

  const formattedTotal = totalMonthlyLimit?.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });

  return (
    <div className='w-full' style={{ width: "110%" }}>
      <h2 className='pb-5 pt-3 pl-3 text-white' style={{ fontFamily: "Manrope", fontSize: "1rem", fontWeight: 500 }}>
        Composição da Mensalidade
      </h2>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <table
          style={{
            width: "100%",
            background: "linear-gradient(0deg, #03001A, #03001A)",
            borderRadius: "20px",
            borderCollapse: "collapse",
            marginRight: "0.5rem",
          }}
        >
          <thead>
            <tr style={{ color: "#fff" }}>
              <th
                style={{
                  padding: "1rem",
                  width: "10%",
                  borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
                  textAlign: "left",
                }}
              >
                Qtd
              </th>
              <th
                style={{
                  padding: "1rem",
                  width: "70%",
                  borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
                  textAlign: "left",
                }}
              >
                Cobertura
              </th>
              <th
                style={{
                  padding: "1rem",
                  width: "70%",
                  borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
                  textAlign: "left",
                }}
              >
                Individual
              </th>
              <th style={{ padding: "1rem", width: "20%", borderBottom: "1px solid rgba(255, 255, 255, 0.3)" }}>
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            {plans?.length > 0 &&
              plans.map((row: any, index: any) => (
                <tr
                  key={index}
                  style={{
                    color: "#fff",
                    borderBottom: index !== plans?.length - 1 ? "1px solid rgba(255, 255, 255, 0.3)" : "none",
                  }}
                >
                  <td style={{ padding: "1.0rem", borderRight: "1px solid rgba(255, 255, 255, 0.3)" }}>
                    {row.userCount}
                  </td>
                  <td style={{ borderRight: "1px solid rgba(255, 255, 255, 0.3)", padding: "1rem" }}>
                    {formatPlanCode(row.plan_code)}
                  </td>
                  <td style={{ padding: "1rem", borderRight: "1px solid rgba(255, 255, 255, 0.3)" }}>
                    {row.monthly_fee.toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </td>
                  <td style={{ padding: "1rem" }}>
                    {(row.monthly_fee * row.userCount).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </td>
                </tr>
              ))}
            <tr style={{ color: "#FFF", borderTop: "1px solid rgba(255, 255, 255, 0.3)" }}>
              <td
                colSpan={2}
                style={{
                  background: "#0517F5",
                  borderRadius: "0 0 0 20px",
                  textAlign: "left",
                  fontWeight: "bold",
                  padding: "1rem",
                }}
              >
                Valor Mensalidade
              </td>
              <td style={{ background: "#0517F5", padding: "1rem" }}></td>
              <td style={{ background: "#0517F5", borderRadius: "0 0 20px 0", padding: "1rem", fontWeight: "bold" }}>
                {formattedTotal}
              </td>
            </tr>
          </tbody>
        </table>
        {/* <div
          className=''
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            marginLeft: "20px",
            paddingRight: "2rem",
          }}
        >
          <button
            className='flex items-center justify-center text-center rounded-md space-x-4'
            style={{
              width: "9.375rem",
              height: "3.36rem",
              // backgroundColor: "rgba(255, 255, 255, 0.38)",
              color: "#fff",
            }}
          >
            <CgFileDocument />
            <span  style={{ marginLeft: "0.3rem" }}>Emitir Boleto</span>
          </button>
        </div> */}
      </div>
    </div>
  );
};
