import { createClient } from "@supabase/supabase-js";

const supabase_url = import.meta.env.VITE_SUPABASE_URL;
const anon_key = import.meta.env.VITE_SUPABASE_ANON_KEY;

const supabaseConfig = createClient(supabase_url, anon_key, {
  auth: {
    persistSession: true,
    detectSessionInUrl: true,
  },
});

const getAccessToken = async () => {
  try {
    const { data: session, error } = await supabaseConfig.auth.getSession();

    if (session.session?.access_token) {
      return session?.session?.access_token;
    }
    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const isSessionActive = async () => {
  try {
    const { data: session, error: sessionError } = await supabaseConfig.auth.getSession();

    if (sessionError) {
      throw new Error("An error occurred while checking if the session is active");
    }

    return session ? true : false;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export { supabaseConfig, getAccessToken, isSessionActive };
