import React from "react";
import { useEffect, useState } from "react";
import { NavLink, Outlet, useNavigate } from "react-router-dom";

export const RegisterEnterpriseFormStepper = ({ currentStep }: { currentStep: number }) => {
  const [enterpriseId, setEnterpriseId] = useState("");

  let to = "";
  const [step, setSelectedStep] = useState(0);
  const steps = ["dados cadastrais", "representantes", "planos ativos"];

  const navigate = useNavigate();
  useEffect(() => {
    const storedEnterpriseId = localStorage.getItem("choosedCompany");

    if (!storedEnterpriseId) {
      console.warn("Nenhum ID de empresa encontrado");
      setEnterpriseId("");
      // Outras ações podem ser tomadas aqui, como redirecionamento
    } else {
      setEnterpriseId(storedEnterpriseId); // Atualiza o estado com o ID armazenado
    }
  }, []);

  const initialUppercase = (str: string) => {
    return str
      .trim()
      .split(" ")
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join(" ");
  };

  const removeSpecialCharacters = (name: string) => {
    const trimmedName = name.toLowerCase();
    const link = trimmedName
      .replace(/\s/g, "-")
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    return link;
  };

  const handleNavLinkClick = (index: number) => {
    if (index === 0) {
      if (enterpriseId === "") {
        to = `/cadastro-empresa/${removeSpecialCharacters(steps[index])}`;
      } else {
        to = "/cadastro-empresa/perfil-individual";
      }
    } else if (index === 1) {
      if (enterpriseId === "") {
        to = "/cadastro-empresa/dados-cadastrais";
      } else {
        to = `/cadastro-empresa/${removeSpecialCharacters(steps[index])}`;
      }
    } else if (index === 2) {
      to = `/cadastro-empresa/${removeSpecialCharacters(steps[index])}`;
    }

    navigate(to);
    setSelectedStep(index);
  };

  return (
    <>
      <div className='flex flex-row py-4'>
        {steps.map((step, index) => (
          <ul className='flex justify-start pl-1' key={index}>
            <NavLink
              to={to}
              onClick={() => handleNavLinkClick(index)}
              className={`rounded-md pr-8 text-lg mt-3 mb-3 text-opaque-white font-normal`}
            >
              {initialUppercase(step)}
            </NavLink>
          </ul>
        ))}
      </div>

      <Outlet />
    </>
  );
};
