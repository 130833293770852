import React from "react";
import Logo from "../assets/omni-logo-RGB.svg";
import { Link } from "react-router-dom";

export default function GenericErrorEl() {
  return (
    <>
      <section className='h-screen w-full bg-[#03001A] overflow-y-hidden'>
        <header className='top-0 sticky'>
          <section className='grid grid-cols-10'>
            <div className='flex self-start col-span-2'>
              <Link to='/' className='ml-14 w-3/5 h-3/5 float-left'>
                <img src={Logo} alt='Logo Omni' />
              </Link>
            </div>

            <section className='col-span-4 self-center'></section>
            <section className='col-span-4 self-center text-white  font-bold'>
              <div className='grid grid-cols-4'>
                <span className='col-span-1 leading-10 hover:text-opaque-white cursor-pointer'>
                  <a href='https://www.omnisaude.app/about'>Sobre a omni</a>
                </span>
                <span className='col-span-1 leading-10 hover:text-opaque-white cursor-pointer'>
                  {" "}
                  <a href='https://www.omnisaude.app/blog'>Nosso blog </a>
                </span>
                <span className='col-span-1 leading-10  cursor-pointer rounded-full bg-[#0517F5] hover:bg-white hover:text-[#03001A] text-center ease-in-out duration-500'>
                  {" "}
                  <Link to='/login'>Logar </Link>
                </span>
              </div>
            </section>
          </section>
        </header>

        <div className='flex min-h-full flex-col gap-y-4 items-center justify-center text-5xl text-opaque-white'>
          <span className='flex flex-row'> Oops! Aconteceu algo de errado. Que tal navegar para outra seção?</span>
          <br />
          <Link to='/dashboard' className='hover:text-white underline'>
            Voltar à página do dashboard geral
          </Link>
        </div>
      </section>
    </>
  );
}
