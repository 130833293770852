import { supabaseConfig } from "../config/supabaseConfig";

export async function fetchUserDataForTestEnvironment(): Promise<string | null> {
  try {
    const { data: user, error: userError } = await supabaseConfig
      .from("user")
      .select("email")
      .eq("phone_number", import.meta.env.VITE_TEST_PHONE_NUMBER);

    if (userError) {
      throw new Error("Error fetching user email");
    }

    return user[0]?.email;
  } catch (error) {
    return null;
  }
}
