/* eslint-disable react/react-in-jsx-scope */
import { supabaseConfig } from "../config/supabaseConfig";
import { NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { api } from "../utils/services/api";
import { fetchUserDataForTestEnvironment } from "../utils/fetchUserDataForTestEnvironment";
import { userAuthStore } from "../Zustand/UserAuthStore";


const OAuthCallback = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const fetchLoggedUser = userAuthStore((state) => state.fetchAuthenticatedUser);

  useEffect(() => {
    const initializeSession = async () => {
      const hashFragment = location.hash;
      const hashParams = new URLSearchParams(hashFragment.slice(1));
      const accessToken = hashParams.get("access_token");
      const refreshToken = hashParams.get("refresh_token");

      let userEmail: string;

      if (accessToken && refreshToken) {
        const { error, data } = await supabaseConfig.auth.setSession({
          access_token: accessToken,
          refresh_token: refreshToken,
        });

        if (error) {
          console.error("Error setting session:", error);
          navigate("/");
        }
        userEmail = data.user.email;
      } else {
        console.error('OAuth callback URL does not contain an "access_token" parameter.');
        navigate("/");
      }

      if (import.meta.env.VITE_TEST_ENVIRONMENT && !userEmail) {
        userEmail = await fetchUserDataForTestEnvironment();
        if (!userEmail) {
          console.error("Error retrieving user data.");
          navigate("/");
        }
        await fetchUserData(userEmail, navigate);
      }

      if (userEmail) {
        fetchLoggedUser(userEmail);
        navigate("/dashboard");
        await fetchUserData(userEmail, navigate);
      }
    };
    initializeSession();
  }, [navigate]);

  return <div className="bg-dark-omni text-white">

  </div>;
};

const fetchUserData = async (userEmail: string, navigate: NavigateFunction) => {
  try {
    const response = await api.get(`/login/email/${userEmail}`);

    if (response.status === 200) {
      localStorage.setItem("userEmail", response.data.email);
      localStorage.setItem("userID", response.data.user);
      localStorage.setItem("linkedCompany", response.data.linkedCompany);
      localStorage.setItem("userType", response.data.userType[0]);
      localStorage.setItem("previousPath", "/");
      localStorage.setItem("magicLink", JSON.stringify(true));
      localStorage.setItem("holdingCnpj", response.data.holdingCnpj);
      localStorage.setItem("holdingID", response.data.linkedCompany);
      // navigate("/dashboard");
    } else {
      console.error("Error retrieving user data.");
      navigate("/");
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
    navigate("/");
  }
};

export { OAuthCallback };
