import { SvgIcon, SvgIconProps } from "@mui/material";

const HomeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M17.5142 7.82913C17.5138 7.82872 17.5134 7.82831 17.513 7.8279L10.1704 0.485596C9.85742 0.172485 9.44132 0 8.9987 0C8.55609 0 8.13999 0.172348 7.82688 0.485458L0.488147 7.82405C0.485675 7.82652 0.483203 7.82913 0.480731 7.8316C-0.161969 8.47801 -0.16087 9.52679 0.48389 10.1716C0.778461 10.4663 1.16751 10.637 1.58348 10.6548C1.60038 10.6565 1.6174 10.6573 1.63457 10.6573H1.92722V16.0608C1.92722 17.13 2.7972 18 3.86672 18H6.73937C7.0305 18 7.26671 17.7639 7.26671 17.4727V13.2363C7.26671 12.7484 7.66359 12.3515 8.15152 12.3515H9.84589C10.3338 12.3515 10.7307 12.7484 10.7307 13.2363V17.4727C10.7307 17.7639 10.9668 18 11.258 18H14.1307C15.2002 18 16.0702 17.13 16.0702 16.0608V10.6573H16.3416C16.784 10.6573 17.2001 10.4849 17.5134 10.1718C18.1588 9.52597 18.1591 8.4754 17.5142 7.82913Z'
          fill='white'
          fillOpacity='0.87'
        />
      </svg>
    </SvgIcon>
  );
};

export default HomeIcon;
