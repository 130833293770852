/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useRef, useState } from "react";
import { ToastContainer } from "react-toastify";
import { LeftSidebar as LeftSidebarContent } from "../components/LeftSidebar";
import MainContainer from "../components/layout/MainContainer";
import LeftBarContainer from "../components/layout/LeftBarContainer";
import Content from "../components/layout/Content";
import { api } from "../utils/services/api";
import PatientIcon from "../assets/PatientIcon.svg";
import EnfermeiroIcon from "../assets/EnfermeiroIcon.svg";
import AquisitionIcon from "../assets/AquisitionIcon.svg";
import MedicationIcon from "../assets/MedicationIcon.svg";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { LineChart, Line, XAxis, YAxis, Legend as Legenda, ResponsiveContainer, Tooltip as TooltipR, BarChart, Bar, Cell } from "recharts";
import { TopbarEnterprise } from "../components/TopbarEnterprise";
import { userAuthStore } from "../Zustand/UserAuthStore";

ChartJS.register(ArcElement, Tooltip, Legend);

export type HoldingComp = {
   b_acc_cnpj: string;
   holding_cnpj: string;
   id: string;
   enterprise_name: string;
   representative_name: string;
   representative_corporate_email: string;
   cnpj: string;
   enterprise_cep: string;
   address: string;
   enterprise_number: string;
   enterprise_complement: string;
   enterprise_city: string;
   enterprise_state: string;
   account_status: string;
   monthly_payment?: string;
   createdAt?: Date;
   is_subsidiary: boolean;
};

interface IDataDashboard {
   [key: string]: {
      month_year: string;
      treatment_started: string;
      treatment_finished: string;
      total: string;
      sum_total_amount: string;
      sum_medications_length: string;
      top5Pathologies: { [key: string]: number };
      colaboradores: string; // Atualizado para ser uma string
   };
}

type IDataDashboardd = {
   month_year: string;
   treatment_started: string;
   treatment_finished: string;
   total: string;
   sum_total_amount: string;
   sum_medications_length: string;
   top5Pathologies: { [key: string]: number };
   colaboradores: string;
   [key: string]: string | number | { [key: string]: number };
};

const useWindowSize = () => {
   const [windowSize, setWindowSize] = useState({
      width: window.innerWidth,
      height: window.innerHeight,
   });

   useEffect(() => {
      const handleResize = () => {
         setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
         });
      };

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
   }, []);

   return windowSize;
};

// popup todas empresas admin popup empresas holding gestor mor
export const Dashboard = () => {
   const loggedUser = userAuthStore((state) => state.authenticatedUser);
   const [modalShow, setModalShow] = useState(false);


   const [dataDashboard, setDataDashboard] = useState<IDataDashboard>({});
   const [totalCollaborators, setTotalCollaborators] = useState(0);
   const [filteredData, setFilteredData] = useState<IDataDashboard>({});
   const [selectedRange, setSelectedRange] = useState("6");
   const [cardData, setCardData] = useState([
      { title: "Colaboradores", value: "0", icon: PatientIcon },
      { title: "Tratamentos", value: "0", icon: EnfermeiroIcon },
      { title: "Aquisições", value: "R$0,00", icon: AquisitionIcon },
      { title: "Medicamentos", value: "0", icon: MedicationIcon },
   ]);
   const [barChartData, setBarChartData] = useState<{ name: string; data: number }[] | null>(null);
   const [shouldProcess, setShouldProcess] = useState(false);
   const [sumData, setSumData] = useState<IDataDashboardd>();
   const [dataLine, setdataLine] = useState([
      { name: "Jan", acquisitions: 0, collaborators: 0 },
      { name: "Fev", acquisitions: 0, collaborators: 0 },
      { name: "Mar", acquisitions: 0, collaborators: 0 },
      { name: "Abr", acquisitions: 0, collaborators: 0 },
      { name: "Mai", acquisitions: 0, collaborators: 0 },
      { name: "Jun", acquisitions: 0, collaborators: 0 },
      { name: "Jul", acquisitions: 0, collaborators: 0 },
      { name: "Ago", acquisitions: 0, collaborators: 0 },
      { name: "Set", acquisitions: 0, collaborators: 0 },
      { name: "Out", acquisitions: 0, collaborators: 0 },
      { name: "Nov", acquisitions: 0, collaborators: 0 },
      { name: "Dez", acquisitions: 0, collaborators: 0 },
   ]);

   const [monthlyAvgData, setMonthlyAvgData] = useState([
      { name: "Jan", "ticket médio": 0.0, "ticket médio global": 0.0 },
      { name: "Fev", "ticket médio": 0.0, "ticket médio global": 0.0 },
      { name: "Mar", "ticket médio": 0.0, "ticket médio global": 0.0 },
      { name: "Abr", "ticket médio": 0.0, "ticket médio global": 0.0 },
      { name: "Mai", "ticket médio": 0.0, "ticket médio global": 0.0 },
      { name: "Jun", "ticket médio": 0.0, "ticket médio global": 0.0 },
      { name: "Jul", "ticket médio": 0.0, "ticket médio global": 0.0 },
      { name: "Ago", "ticket médio": 0.0, "ticket médio global": 0.0 },
      { name: "Set", "ticket médio": 0.0, "ticket médio global": 0.0 },
      { name: "Out", "ticket médio": 0.0, "ticket médio global": 0.0 },
      { name: "Nov", "ticket médio": 0.0, "ticket médio global": 0.0 },
      { name: "Dez", "ticket médio": 0.0, "ticket médio global": 0.0 },
   ]);

   const [commonHeight, setCommonHeight] = useState(0);
   const MAX_LIMIT = 100;
   const clampedData = barChartData
      ? barChartData.map((entry) => {
           return {
              ...entry,
              data: Math.min(entry.data, MAX_LIMIT),
           };
        })
      : null;

   const maxDataPoint = clampedData ? Math.max(...clampedData.map((entry) => entry.data)) : 0;
   const yAxisDomain = [0, maxDataPoint + 10]; // Adds 10 for a little padding

   const { width } = useWindowSize();

   const chartWidth = Math.min((width * 2) / 3 - 450, 800);
   const firstDivRef = useRef<HTMLDivElement | null>(null);

   useEffect(() => {
      if (firstDivRef.current) {
         setCommonHeight(firstDivRef.current.offsetHeight);
      }
   }, [firstDivRef.current]);

   async function getUserData() {
      const linkedCompany = localStorage.getItem("linkedCompany");
      const holdingID = localStorage.getItem("holdingID");
      const choosedCompany = localStorage.getItem("choosedCompany");
      const userType = localStorage.getItem("userType")?.toLowerCase();

      const isCompanyAllCompanies = linkedCompany?.toLowerCase() === "allcompanies";
      const isCompanyAllHoldingCompanies = linkedCompany?.toLowerCase() === "allhcompanies";

      try {
         let endpointDashboard = "/dashboard/";
         let endpointBusiness = "/businessAcc/";

         // Verifica se o userType é 'admin'
         if (userType === "admin") {
            // Caso o usuário não esteja visualizando todas as empresas, adiciona a empresa específica ao endpoint
            if (!isCompanyAllCompanies && linkedCompany) {
               endpointDashboard += linkedCompany;
               endpointBusiness += `${linkedCompany}/users`;
            } else {
               endpointBusiness += `${choosedCompany}/users?allCompanies=true`;
            }
            // Não é necessário um caminho adicional se isCompanyAllCompanies for verdadeiro, portanto, não há 'else'
         } else if (userType === "gestor-mor") {
            // Para 'gestor-mor', verifica se a visualização é para todas as holding companies
            if (isCompanyAllHoldingCompanies) {
               // Adiciona holdingID ao endpoint com o parâmetro de query para 'gestor_mor'
               endpointDashboard += `${holdingID}?gestor_mor=true`;
               endpointBusiness += `${holdingID}/users?allCompanies=true`;
            } else if (linkedCompany) {
               // Caso contrário, se não é para todas as holding companies, mas uma empresa específica está ligada, adiciona essa empresa ao endpoint
               endpointDashboard += linkedCompany;
               endpointBusiness += `${linkedCompany}/users`;
            }
         }

         const responseDash = await api.get(endpointDashboard);
         const responseBusiness = await api.get(endpointBusiness);

         setTotalCollaborators(responseBusiness.data.usersData.length);

         if (responseDash.data.yearlyInfo) {
            setDataDashboard(responseDash.data.yearlyInfo);
         }
      } catch (error) {
         console.error(`Failed to fetch dashboard data: ${error}`);
      }
   }

   useEffect(() => {
      getUserData();
   }, []);

   const filterDataByMonths = (months: string, dashboardData: IDataDashboard) => {
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;

      const twoDigitYearCutoff = 2000;

      let startMonth = currentMonth - parseInt(months);
      let startYear = currentYear;

      while (startMonth < 1) {
         startMonth += 12;
         startYear -= 1;
      }

      const startDate = new Date(startYear, startMonth - 1);
      const endDate = currentDate;

      const filteredData: IDataDashboard = {};

      for (const key in dashboardData) {
         const dateParts = key.split("/");
         if (dateParts.length === 2) {
            const [month, year] = dateParts.map(Number);

            const adjustedYear = year < twoDigitYearCutoff ? year + 2000 : year;

            const dateFromData = new Date(adjustedYear, month - 1);

            if (dateFromData >= startDate && dateFromData <= endDate) {
               filteredData[key] = dashboardData[key];
            }
         }
      }

      const dataEntries = Object.entries(filteredData);

      if (dataEntries.length > 0 && dataEntries.length < 10) {
         dataEntries.shift();
      }

      const updatedFilteredData = Object.fromEntries(dataEntries);

      setFilteredData(updatedFilteredData);
      setShouldProcess(true);
      const { unifiedData, monthlyAvgData } = convertToDataLine(updatedFilteredData);
      setdataLine(unifiedData);
      setMonthlyAvgData(monthlyAvgData);
   };

   const convertToDataLine = (updatedFilteredData: Record<string, any>) => {
      const monthMap: { [key: string]: string } = {
         "01": "Jan",
         "02": "Fev",
         "03": "Mar",
         "04": "Abr",
         "05": "Mai",
         "06": "Jun",
         "07": "Jul",
         "08": "Ago",
         "09": "Set",
         "10": "Out",
         "11": "Nov",
         "12": "Dez",
      };

      const unifiedData: any[] = [];
      const monthlyAvgData: any[] = [];
      let runningSumOfCollaborators = 0;
      const summedCollaborators: { [key: string]: number } = {};

      for (const key in updatedFilteredData) {
         const data = updatedFilteredData[key];
         runningSumOfCollaborators += data.colaboradores;
         summedCollaborators[key] = runningSumOfCollaborators;
      }

      for (const key in updatedFilteredData) {
         const data = updatedFilteredData[key];
         const isAllZero =
            data.avg_per_month === "0" &&
            data.colaboradores === 0 &&
            data.sum_total_amount === "0" &&
            data.total === "0" &&
            Object.keys(data.top5Pathologies).length === 0;

         if (!isAllZero) {
            const [month] = key.split("/");
            const monthName = monthMap[month];

            unifiedData.push({
               name: monthName ? `${monthName}` : key,
               collaborators: summedCollaborators[key],
               acquisitions: parseInt(data.sum_total_amount, 10),
            });
            monthlyAvgData.push({
               name: monthName ? `${monthName}` : key,
               "ticket médio": parseFloat(data.avg_all_buys).toFixed(2),
               "ticket médio global": parseFloat(data.avg_per_month).toFixed(2),
            });
         }
      }

      return { unifiedData, monthlyAvgData };
   };

   useEffect(() => {
      if (!shouldProcess) {
         return;
      }

      let totalColaboradores = 0;

      const top5PathologiesCount: { [key: string]: number } = {};
      const accumulator: { [key: string]: number } = {
         month_year: 0,
         treatment_started: 0,
         treatment_finished: 0,
         total: 0,
         sum_total_amount: 0,
         sum_medications_length: 0,
      };

      const sliceCount = parseInt(selectedRange, 10);
      const slicedData = Object.keys(filteredData).slice(0, sliceCount);

      slicedData.forEach((key) => {
         const data: IDataDashboardd = filteredData[key];
         const monthPathologies = data.top5Pathologies || {};
         for (const [field, value] of Object.entries(data)) {
            if (field === "colaboradores") {
               totalColaboradores += Number(value);
            } else if (field !== "month_year" && field !== "top5Pathologies") {
               accumulator[field] += Number(value);
            }
         }
         for (const pathology in monthPathologies) {
            if (Object.prototype.hasOwnProperty.call(monthPathologies, pathology)) {
               if (top5PathologiesCount[pathology]) {
                  top5PathologiesCount[pathology] += monthPathologies[pathology];
               } else {
                  top5PathologiesCount[pathology] = monthPathologies[pathology];
               }
            }
         }
      });
      const sortedPathologies = Object.entries(top5PathologiesCount)
         .sort(([, countA], [, countB]) => countB - countA)
         .slice(0, 5);

      const top5SortedPathologies: { [key: string]: number } = Object.fromEntries(sortedPathologies);

      const newSumData: IDataDashboardd = {
         month_year: "",
         treatment_started: accumulator.treatment_started.toString(),
         treatment_finished: accumulator.treatment_finished.toString(),
         total: accumulator.total.toString(),
         sum_total_amount: accumulator.sum_total_amount.toString(),
         sum_medications_length: accumulator.sum_medications_length.toString(),
         top5Pathologies: top5SortedPathologies,
         colaboradores: totalColaboradores.toString(),
      };

      setSumData(newSumData);
      setShouldProcess(false);

      const newCardData = [
         { title: "Colaboradores", value: totalCollaborators.toString(), icon: PatientIcon },
         { title: "Tratamentos", value: newSumData.treatment_started.toString(), icon: EnfermeiroIcon },
         {
            title: "Aquisições",
            value: `R$${new Intl.NumberFormat("pt-BR").format(parseFloat(newSumData.sum_total_amount))}`,
            icon: AquisitionIcon,
         },
         { title: "Medicamentos", value: newSumData.sum_medications_length.toString(), icon: MedicationIcon },
      ];
      setCardData(newCardData);
   }, [filteredData, shouldProcess]);

   useEffect(() => {
      if (sumData && sumData.top5Pathologies) {
         const labels = Object.keys(sumData.top5Pathologies);
         const data = Object.values(sumData.top5Pathologies);

         const transformedData = labels.map((label, index) => {
            return { name: label, data: data[index] };
         });

         setBarChartData(transformedData);
      }
   }, [sumData]);

   useEffect(() => {
      if (Object.keys(dataDashboard).length > 0) {
         filterDataByMonths(selectedRange, dataDashboard);
      }
   }, [selectedRange, dataDashboard]);

   const renderLegend = (props: any) => {
      const { payload } = props;
      return (
         <ul
            style={{
               listStyle: "none",
               display: "flex",
               justifyContent: "center",
               color: "#ffffff",
               flexWrap: "wrap",
               paddingBottom: 10,
               marginTop: 20,
            }}
         >
            {payload.map((entry: any, index: any) => (
               <li key={`item-${index}`} style={{ margin: "0 10px", display: "flex", alignItems: "center" }}>
                  <svg width='10' height='10' style={{ marginRight: "5px" }}>
                     <circle cx='5' cy='5' r='5' fill={entry.color} />
                  </svg>
                  {entry.value}
               </li>
            ))}
         </ul>
      );
   };

   const CustomTooltip = ({ active, payload }: any) => {
      if (active && payload && payload.length) {
         const valueCollaborators = payload.find((p: any) => p.dataKey === "collaborators" && p.stroke === "#00BFAF")?.value || 0;
         const valueAcquisitions = payload.find((p: any) => p.dataKey === "acquisitions" && p.stroke === "#0517F5")?.value || 0;

         const formattedAcquisitions = valueAcquisitions.toLocaleString("pt-BR", { style: "currency", currency: "BRL" });

         return (
            <div
               className='custom-tooltip'
               style={{ backgroundColor: "#03001A", padding: "10px", borderRadius: "5px", border: "1px solid white" }}
            >
               <p className='label text-white'>{`Aquisições: ${formattedAcquisitions} `}</p>
               <p className='label text-white'>{`Colaboradores: ${valueCollaborators} ativos`}</p>
            </div>
         );
      }
      return null;
   };

   const CustomLegend = (props: any) => {
      const { payload } = props;
      return (
         <div style={{ textAlign: "center", color: "#FFFFFF" }}>
            {payload.map((entry: any, index: any) => (
               <span key={`item-${index}`} style={{ marginRight: "40px" }}>
                  <span
                     style={{
                        display: "inline-block",
                        backgroundColor: entry.color,
                        width: "12px",
                        height: "12px",
                        borderRadius: "50%",
                        marginRight: "8px",
                     }}
                  ></span>
                  {entry.value}
               </span>
            ))}
         </div>
      );
   };

   const CustomBar = (props: any) => {
      const { x, y, width, height, fill } = props;
      let radius = 10;

      if (height < 20) {
         radius = height / 2;
      }

      if (height < radius * 2) {
         return (
            <g>
               <rect x={x} y={y + height - radius} width={width} height={height - radius} fill={fill} />
               <rect x={x} y={y + height - 2 * radius} rx={radius} ry={radius} width={width} height={2 * radius} fill={fill} />
            </g>
         );
      }

      return (
         <g>
            <rect x={x} y={y + radius} width={width} height={height - radius} fill={fill} />
            <rect x={x} y={y} rx={radius} ry={radius} width={width} height={2 * radius} fill={fill} />
         </g>
      );
   };

   const pvColor = "#0517F5";
   const uvColor = "#00BFAF";
   const COLORS = ["#0018FF", "#52BE35", "#10C6EE", "#0AE39E", "#01008A"];

   const CustomLabel = (props: any) => {
      const { x, y, width, value } = props;
      const xPos = x + width / 2;

      return (
         <text x={xPos} y={y - 5} dy={0} fill='#ffffff' fontSize={16} textAnchor='middle' fontWeight='bold'>
            {value}
         </text>
      );
   };

   return (
      <>
         <ToastContainer />
         <MainContainer className='bg-[#03001A] dashboard-continer'>
            <LeftBarContainer className='bg-[#03001A]'>
               <LeftSidebarContent />
            </LeftBarContainer>

            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, width: "480%", position: "relative" }}>
               <TopbarEnterprise />
               <div className='flex items-center m-5 mt-[-4.5rem]'>
                  <h1 className='font-manrope font-bold font-regular text-5xl text-white justify-center mr-12'>Dashboard de Métricas</h1>
                  <select
                     id='time-period-filter'
                     value={selectedRange}
                     className='rounded-md px-2'
                     style={{
                        border: "1px solid #fff",
                        backgroundColor: "#111627",
                        color: "white",
                        fontFamily: "Inter",
                        fontWeight: 400,
                        fontSize: "16px",
                        lineHeight: "19.36px",
                        padding: "0.5rem",
                     }}
                     onChange={(e) => setSelectedRange(e.target.value)}
                  >
                     <option value={12}>Últimos 12 meses</option>
                     <option value={6}>Últimos 6 meses</option>
                     <option value={3}>Últimos 3 meses</option>
                  </select>
               </div>

               <Content className='bg-omni-gray rounded-[2rem] h-[100%] p-5 relative mb-10'>
                  {modalShow ? (
                     <div>
                        <img
                           src='/images/construction.png'
                           alt='Construction'
                           style={{
                              position: "absolute",
                              top: "10%",
                              left: "0%",
                              zIndex: 10,
                           }}
                        />
                        <div
                           style={{
                              marginTop: 10,
                           }}
                        >
                           <img
                              src='./src/assets/DashboardMock.jpg'
                              alt=''
                              style={{
                                 marginTop: 10,
                                 position: "revert",
                                 top: "20rem",
                                 left: 10,
                                 width: "1920px",
                                 height: "auto",
                                 objectFit: "cover",
                              }}
                           />
                        </div>
                     </div>
                  ) : (
                     <>
                        <div className='grid grid-cols-4 gap-4 p-5 mt-[-1.3rem]'>
                           {cardData.map((card, index) => (
                              <div
                                 key={index}
                                 className='rounded-3xl'
                                 style={{
                                    width: "auto",
                                    height: "6.56rem",
                                    backgroundColor: "#03001A",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-start", // Mudar para 'flex-start'
                                    padding: "0.625rem",
                                 }}
                              >
                                 <div
                                    style={{
                                       width: "3.44rem",
                                       height: "3.44rem",
                                       borderRadius: "10px",
                                       backgroundColor: "#111627",
                                       display: "flex",
                                       alignItems: "center",
                                       justifyContent: "center",
                                       margin: "0 0.625rem 0 0.5rem", // Ajuste a margem conforme necessário
                                    }}
                                 >
                                    <img src={card.icon} alt={card.title} style={{ width: "1.498rem", height: "1.498rem" }} />
                                 </div>
                                 <div style={{ marginLeft: "0.625rem" }}>
                                    <p
                                       style={{
                                          width: "7rem",
                                          height: "1.365rem",
                                          fontFamily: "Manrope",
                                          fontWeight: 500,
                                          fontSize: "1rem",
                                          lineHeight: "1.365rem",
                                          color: "#fff",
                                       }}
                                    >
                                       {card.title}
                                    </p>
                                    <p
                                       style={{
                                          width: "2.75rem",
                                          height: "2.25rem",
                                          fontFamily: "Manrope",
                                          fontWeight: 800,
                                          fontSize: card.value.length > 10 ? "1.15rem" : "1.35rem",
                                          lineHeight: "2.22rem",
                                          color: "#fff",
                                       }}
                                    >
                                       {card.value}
                                    </p>
                                 </div>
                              </div>
                           ))}
                        </div>

                        <div className='flex bg-omni-gray rounded-xl items-stretch mt-[-1rem]'>
                           <div ref={firstDivRef} className='w-3/4 p-5 ' style={{ width: "75%", height: "400px" }}>
                              <h2 className='font-manrope font-regular text-4xl text-white justify-center align-middle ml-5 mb-3'>
                                 Colaboradores Ativos & Aquisições
                              </h2>
                              <div
                                 className='rounded-3xl'
                                 style={{
                                    width: "100%",
                                    backgroundColor: "#03001A",
                                    padding: "2rem",
                                    paddingLeft: "1.5rem",
                                 }}
                              >
                                 <ResponsiveContainer width={"100%"} height={commonHeight}>
                                    <LineChart
                                       style={{ width: "100%" }}
                                       width={chartWidth}
                                       height={commonHeight}
                                       data={dataLine}
                                       margin={{ top: 5, bottom: 5, left: 10, right: 10 }}
                                    >
                                       <XAxis
                                          dataKey='name'
                                          stroke='#ffffff' // Color of the line
                                          strokeWidth={3} // Width of the line
                                       />
                                       <YAxis domain={[0, "dataMax + 10"]} type='number' hide />
                                       <TooltipR content={<CustomTooltip />} />
                                       <Legenda verticalAlign='bottom' content={renderLegend} />
                                       <Line
                                          type='monotone'
                                          dataKey='collaborators'
                                          stroke='#00BFAF'
                                          name='Colaboradores Ativos'
                                          activeDot={{ r: 8 }}
                                          strokeWidth={4}
                                       />
                                       <Line
                                          type='monotone'
                                          dataKey='acquisitions'
                                          stroke='#0517F5'
                                          name='Aquisições'
                                          activeDot={{ r: 8 }}
                                          strokeWidth={4}
                                       />
                                    </LineChart>
                                 </ResponsiveContainer>
                              </div>
                           </div>

                           {/* Espaçamento entre as divs */}
                           <div className='w-3'></div>

                           {/* Div para o gráfico de pizza (1/3 do tamanho total) */}
                           <div className='w-1/3 pr-0 py-5 mr-5 bg-omni-gray adjust-for-small-screen'>
                              <h2 className='font-manrope font-regular text-4xl text-white text-center mb-3 bg-omni-gray'>Tratamentos</h2>
                              <div
                                 className='rounded-3xl'
                                 style={{
                                    backgroundColor: "#03001A",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginLeft: 10,
                                    padding: 35,
                                    minHeight: commonHeight, // Set minHeight to match commonHeight
                                 }}
                              >
                                 <div
                                    style={{
                                       flexGrow: 1,
                                       display: "flex",
                                       justifyContent: "center",
                                       alignItems: "center",
                                       width: "100%",
                                    }}
                                 >
                                    {barChartData && (
                                       <ResponsiveContainer width='100%' height={400}>
                                          <BarChart data={barChartData}>
                                             <XAxis dataKey='name' stroke='#ffffff' hide />
                                             <YAxis hide stroke='#ffffff' domain={yAxisDomain} />
                                             <TooltipR />
                                             <Legenda
                                                content={(props) => {
                                                   // Mapeia os nomes dos itens da legenda
                                                   const legendData = barChartData.map((entry, index) => ({
                                                      value: entry.name,
                                                      type: "square",
                                                      color: COLORS[index % COLORS.length],
                                                   }));

                                                   return (
                                                      <ul style={{ listStyle: "none", paddingLeft: 0, marginTop: "1rem" }}>
                                                         {legendData.map((entry, index) => (
                                                            <li
                                                               key={`item-${index}`}
                                                               style={{ display: "flex", alignItems: "center", marginBottom: "4px" }}
                                                            >
                                                               <span
                                                                  style={{
                                                                     width: "12px",
                                                                     height: "12px",
                                                                     borderRadius: "50%",
                                                                     backgroundColor: entry.color,
                                                                     marginRight: "8px",
                                                                  }}
                                                               ></span>
                                                               <span style={{ color: "white" }}>{entry.value}</span>
                                                            </li>
                                                         ))}
                                                      </ul>
                                                   );
                                                }}
                                             />
                                             <Bar dataKey='data' fill='#8884d8' label={CustomLabel}>
                                                {barChartData.map((entry, index) => (
                                                   <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                ))}
                                             </Bar>
                                          </BarChart>
                                       </ResponsiveContainer>
                                    )}
                                 </div>
                              </div>
                           </div>
                        </div>

                        <div className='flex bg-omni-gray'>
                           <div className='w-full pr-7 pl-2'>
                              <h2 className='font-manrope font-regular text-4xl text-white justify-center align-middle ml-10 mb-3 pl-3'>
                                 Utilização Mensal
                              </h2>
                              <div
                                 className='rounded-3xl'
                                 style={{
                                    backgroundColor: "#03001A",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    marginLeft: 10,
                                    padding: 35,
                                    width: "100%",
                                    height: "80%",
                                 }}
                              >
                                 <div
                                    style={{
                                       flexGrow: 1,
                                       display: "flex",
                                       justifyContent: "center",
                                       alignItems: "center",
                                       width: "100%",
                                    }}
                                 >
                                    <ResponsiveContainer width={"100%"} height={"100%"}>
                                       <BarChart data={monthlyAvgData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                                          <XAxis
                                             dataKey='name'
                                             stroke='rgba(255, 255, 255)'
                                             strokeWidth={3} // Width of the line
                                             tick={{ fill: "rgba(255, 255, 255, 0.6)" }}
                                          />
                                          <YAxis
                                             hide
                                             tickFormatter={(tick) => tick.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}
                                             domain={[0, "dataMax + 100"]}
                                             stroke='rgba(255, 255, 255, 0.6)'
                                             tick={{ fill: "rgba(255, 255, 255, 0.6)" }}
                                          />
                                          <TooltipR
                                             formatter={(value: any) => [
                                                `${value.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })}`,
                                                "R$",
                                             ]}
                                          />
                                          <Legenda
                                             content={<CustomLegend />}
                                             layout='horizontal'
                                             verticalAlign='bottom'
                                             align='center'
                                             wrapperStyle={{
                                                color: "#FFFFFF",
                                                paddingLeft: "10px",
                                                paddingRight: "10px",
                                                lineHeight: "24px",
                                             }}
                                          />
                                          <Bar dataKey='ticket médio' fill={uvColor} shape={<CustomBar />} barSize={25} />
                                          <Bar dataKey='ticket médio global' fill={pvColor} shape={<CustomBar />} barSize={25} />
                                       </BarChart>
                                    </ResponsiveContainer>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </>
                  )}
               </Content>
            </div>
         </MainContainer>
      </>
   );
};

<style>
   {`
  .bg-omni-gray {
      background-color: #111627;
  }

  .rounded-xl {
      max-height: 250px;
      overflow: hidden;
  }

  @media (max-width: 2100px) {
      .adjust-for-small-screen {
          transform: translateY(50%); /* Ajuste conforme necessário */
      }
  }
  
  .pie-chart-wrapper {
      position: relative;
  }
  
  .pie-chart {
      position: absolute;
      top: -40px; /* Adjust as needed */
  }
  
  .text-opaque-white {
      color: rgba(255, 255, 255, 0.6);
  }
  
  .text-opaque-white:hover {
      color: rgba(255, 255, 255, 0.8);
  }
  
  .text-opaque-white.selected {
      color: #fff;
  }
  
  .font-regular {
      font-weight: 400;
  }
  
  .select-style {
      font-family: 'Inter';
      font-weight: 400;
      font-size: 16px;
      line-height: 19.36px;
      background-color: #111627;
  }
 `}
</style>;
