import React, { useState, Dispatch, SetStateAction } from "react";
import DatePicker from "react-datepicker";
import { ptBR } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";

interface DateFilterProps {
  setstartEndInterval: any;
}

const DateFilter: React.FC<DateFilterProps> = ({ setstartEndInterval }) => {
  const [startDate, setStartDate] = useState<Date | null>(new Date("08/01/2023"));
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  const onDateChange = (date: Date | null, type: "start" | "end") => {
    if (type === "start") {
      setStartDate(date);
      if (date === null) {
        setstartEndInterval((prev: any) => ({ ...prev, start: null }));
        return;
      }
    } else {
      setEndDate(date);
      if (date === null) {
        setstartEndInterval((prev: any) => ({ ...prev, end: null }));
        return;
      }
    }

    const updateInterval = (prevStartDate: Date | null, prevEndDate: Date | null) => {
      if (prevStartDate && prevEndDate) {
        setstartEndInterval({ start: prevStartDate, end: prevEndDate });
      }
    };

    if (type === "start" && date) {
      updateInterval(date, endDate);
    } else if (type === "end" && date) {
      updateInterval(startDate, date);
    }
  };

  return (
    <div className=' z-10 flex   text-white mt-2  font-manrope'>
      <div>
        <DatePicker
          className='rounded-lg w-[8.5rem] bg-[#03001A] h-11 text-center '
          dateFormat='dd/MM/yyyy'
          selected={startDate}
          locale={ptBR}
          onChange={(date) => onDateChange(date, "start")}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          isClearable
        />
      </div>
      <div className='ml-1'>
        <DatePicker
          className='rounded-lg w-[8.5rem] bg-[#03001A] h-11 text-center'
          locale={ptBR}
          selected={endDate}
          dateFormat='dd/MM/yyyy'
          onChange={(date) => onDateChange(date, "end")}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          isClearable
        />
      </div>
    </div>
  );
};

export default DateFilter;
