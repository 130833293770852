import React, { useState, FormEvent, useEffect } from "react";
import { useFormContext } from "react-hook-form";

export const CustomSelectAccount = ({ data, RegisterEnterpriseAction, dispatch }: any) => {
   const [selectedValue, setSelectedValue] = useState("");

   const getOptionStyle = (value: string) => {
      switch (value) {
         case "Ativa":
            return "green";
         case "Cancelado":
            return "#f5dcbd";
         case "Bloqueada":
            return "red";
         default:
            return "inherit";
      }
   };

   useEffect(() => {
      setSelectedValue(data.enterpriseAccountStatus);
   }, []);

   const handleSelectChange = (event: any) => {
      const { value } = event.target;
      setSelectedValue(value);
      dispatch({
         type: RegisterEnterpriseAction.setEnterpriseAccountStatus,
         payload: value,
      });
   };

   const selectStyle = {
      color: selectedValue ? getOptionStyle(selectedValue) : "inherit",
   };

   return (
      <>
         <select
            value={data.enterpriseAccountStatus}
            // className='bg-input-register active:text-white text-opaque-white border-none rounded-md mt-3 px-3 h-10'
            className='rounded-md pl-3 text-white h-14 mt-2 bg-input-register'
            onChange={handleSelectChange}
            style={selectStyle}
         >
            <option value='Ativa' style={{ color: getOptionStyle("Ativa") }}>
               Ativa
            </option>
            <option value='Cancelado' style={{ color: getOptionStyle("Cancelado") }}>
               Cancelada{" "}
            </option>
            <option value='Bloqueada' style={{ color: getOptionStyle("Bloqueada") }} title='Veja como desbloquear'>
               Bloqueada{" "}
            </option>
         </select>
      </>
   );
};
