import React from "react";
import { RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import MobileBanner from "./components/MobileBanner";
import { RegisterProvider } from "./hooks/RegisterEnterpriseContext";
import { AddPlanProvider } from "./hooks/AddPlansContext";
import { FilteredMedicationsProvider } from "./hooks/MedicinesContext";
import { CompanyProvider } from "./hooks/CompanyContext";
import { router } from "./router";
import { ColaboratorProvider } from "./hooks/ColaboratorContext";
import { LoginProvider } from "./hooks/LoginContext";
import { UserProvider } from "./hooks/userContext";
import { Analytics } from "@vercel/analytics/react";
import { inject } from "@vercel/analytics";
import { ToastContainer } from "react-toastify";
import { AuthProvider } from "./components/AuthContext";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

function isMobileDevice() {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
}

inject();
function App() {
  return (
    <>
      <AuthProvider>
        <ToastContainer />
        {isMobileDevice() ? <MobileBanner /> : null}
        <div className='h-full'>
          <UserProvider>
            <LoginProvider>
              <CompanyProvider>
                <ColaboratorProvider>
                  <QueryClientProvider client={queryClient}>
                    <AddPlanProvider>
                      <RegisterProvider>
                        <FilteredMedicationsProvider>
                          <RouterProvider router={router} />
                        </FilteredMedicationsProvider>
                      </RegisterProvider>
                    </AddPlanProvider>
                  </QueryClientProvider>
                </ColaboratorProvider>
              </CompanyProvider>
            </LoginProvider>
          </UserProvider>
        </div>
        <Analytics />
      </AuthProvider>
    </>
  );
}

export default App;
