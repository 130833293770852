import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const PencilIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M17.5045 4.99587L15.8838 6.61658C15.7185 6.78181 15.4513 6.78181 15.2861 6.61658L11.3838 2.71423C11.2185 2.549 11.2185 2.28181 11.3838 2.11658L13.0045 0.495874C13.6619 -0.161548 14.7306 -0.161548 15.3916 0.495874L17.5045 2.60876C18.1654 3.26619 18.1654 4.33494 17.5045 4.99587ZM9.99158 3.50877L0.759549 12.7408L0.0142365 17.0123C-0.0877166 17.5888 0.415018 18.0881 0.99158 17.9896L5.26307 17.2408L14.4951 8.00877C14.6603 7.84353 14.6603 7.57634 14.4951 7.41111L10.5928 3.50877C10.424 3.34353 10.1568 3.34353 9.99158 3.50877V3.50877ZM4.36307 11.9498C4.16971 11.7564 4.16971 11.447 4.36307 11.2537L9.77713 5.83962C9.97049 5.64627 10.2799 5.64627 10.4732 5.83962C10.6666 6.03298 10.6666 6.34236 10.4732 6.53572L5.05916 11.9498C4.8658 12.1431 4.55642 12.1431 4.36307 11.9498V11.9498ZM3.09392 14.9064H4.78142V16.1826L2.51385 16.5799L1.42049 15.4865L1.81775 13.2189H3.09392V14.9064Z'
          fill='white'
          fillOpacity='1'
        />
      </svg>
    </SvgIcon>
  );
};

export default PencilIcon;
