import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { LeftSidebar } from "../../components/LeftSidebar";
import Content from "../../components/layout/Content";
import LeftBarContainer from "../../components/layout/LeftBarContainer";
import MainContainer from "../../components/layout/MainContainer";
import { api } from "../../utils/services/api";
import EyeIcon from "../../components/icons/EyeIcon";
import BuyImageDetails from "../../components/AcquisitionsComponents/BuyImageDetails";
import { CustomSelectRxStatus } from "../../components/CustomSelectRxStatus";
import { TopbarEnterprise } from "../../components/TopbarEnterprise";

interface AcquisitionsDetailsProps {
  establishmentName: string;
  userId: string;
  acquistionType: string;
  establishmentAddress: string;
  dateAndTime: string;
  products: Product[];
  status: string;
  totalAmount: string | number;
  comprovante: string;
}

interface Product {
  NOME: string;
  description: string | undefined;
  quantity: number;
  price: number;
  apresentacao: string;
  dose: string;
  qtd_apresentacao: number | string;
  tipo: string;
}

interface Purchase {
  buy_id: string;
  user_id: string;
  individual_price: number[];
  medications: string[];
  quantity: number[];
  total_amount: number;
  date: string;
  establishment_address: string;
  establishment_name: string;
  status: string;
  names: string[];
  products: Product[];
  acquisition_type: string;
  comprovante: string;
}

const BlueBar: React.FC<{ strings: string[]; onTabChange: (index: number) => void }> = ({ strings, onTabChange }) => {
  const [selectedStringIndex, setSelectedStringIndex] = useState<number>(0);
  const textStyles = {
    color: "rgba(255, 255, 255, 0.87)",
    fontFamily: "Manrope",
    fontSize: "1.0625rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    marginRight: "1.5rem",
  };
  const underlineStyle: React.CSSProperties = {
    width: "100%",
    height: "0.2rem",
    flexShrink: 0,
    display: "flex",
    flexDirection: "row",
  };
  const getPortionStyle = (index: number) => {
    if (index === 0 && selectedStringIndex === 0) return { flexBasis: "15%", background: "#0517F5" };
    if (index === 1 && selectedStringIndex === 1) return { flexBasis: "10%", background: "#0517F5" };
    if (index === 0) return { flexBasis: "15%", background: "#020B60" };
    if (index === 1) return { flexBasis: "10%", background: "#020B60" };
    return { flexBasis: "75%", background: "#020B60" };
  };
  const handleStringClick = (index: number) => {
    setSelectedStringIndex(index);
    onTabChange(index);
  };
  return (
    <div>
      <div style={{ display: "flex", flexDirection: "row", marginBottom: "0.5rem" }}>
        {strings.map((str, index) => (
          <div
            key={index}
            style={{
              ...textStyles,
              flexBasis: index === 0 ? "15%" : index === 1 ? "10%" : "75%",
              cursor: "pointer",
              whiteSpace: index === 0 ? "nowrap" : "normal",
            }}
            onClick={() => handleStringClick(index)}
          >
            {str}
          </div>
        ))}
      </div>
      <div style={underlineStyle}>
        <div style={getPortionStyle(0)} />
        <div style={getPortionStyle(1)} />
        <div style={getPortionStyle(2)} />
      </div>
    </div>
  );
};

const AcquisitionsDetails = () => {
  function prepareAcquisition(purchase: Purchase): AcquisitionsDetailsProps {
    const {
      date,
      total_amount,
      products, // the products come with its properties from the node api, that makes a JOIN by iterating over the ids (in the medications[] array in the db, and then joining it with the medications table (based in the ID_PRODUTO column))
      establishment_address,
      establishment_name,
      status,
      acquisition_type,
      user_id,
      comprovante,
    } = purchase;
    return {
      dateAndTime: date,
      products,
      establishmentAddress: establishment_address,
      establishmentName: establishment_name,
      status,
      acquistionType: acquisition_type || "Compra",
      totalAmount: total_amount,
      userId: user_id,
      comprovante,
    };
  }

  const [acquisitionsDetails, setAcquisitionsDetails] = useState<AcquisitionsDetailsProps>(
    {} as AcquisitionsDetailsProps,
  );
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userName, setUserName] = useState<string>("carregando...");

  const userType = localStorage.getItem("userType");

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleTabChange = (index: number) => {
    setSelectedTabIndex(index);
  };
  const fetchAcquisitionsDetails = async () => {
    try {
      const { data } = await api.get(`/buys/${id}`);
      const prepared = prepareAcquisition(data);
      setAcquisitionsDetails(prepared);
    } catch (error) {
      console.error(error);
    }
  };

  const getUserName = async (userId: string) => {
    try {
      const { data } = await api.get(`/users/name/${userId}`);
      setUserName(data.userName);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAcquisitionsDetails();
    if (acquisitionsDetails.userId) {
      getUserName(acquisitionsDetails.userId);
    }
  }, [acquisitionsDetails.userId]);

  const allMedications = acquisitionsDetails.products?.map((product) => product.NOME).join(", ");
  // alterar status da compra diretamente do sistema admin

  return (
    <MainContainer className='bg-[#03001A]'>
      <LeftBarContainer className='bg-[#03001A]'>
        <LeftSidebar />
      </LeftBarContainer>

      <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, width: "480%" }}>
        <TopbarEnterprise hideAll />

        <Content className='bg-omni-gray rounded-[2rem] h-[100%] p-5 relative mb-10 mt-[-4.5rem]'>
          <div className='flex justify-between items-center m-5'>
            <h1 className='font-manrope font-bold font-regular text-5xl text-white justify-center mt-1 mb-5'>
              Detalhes da Aquisição
            </h1>
          </div>

          <div className='px-4'>
            <BlueBar strings={["Informações Gerais", "Anexos"]} onTabChange={handleTabChange} />
            <div className={`${selectedTabIndex === 0 ? "" : "hidden"}`}>
              <div className={`flex flex-row gap-x-10 w-95 `}>
                <div className='flex flex-col w-plw '>
                  <label htmlFor='colaboratorName' className='text-white mt-4'>
                    Colaborador
                  </label>
                  <input
                    type='text'
                    className='bg-[#03001A] rounded-md pl-3 text-opaque-white h-10 w-full mt-1'
                    disabled
                    value={userName}
                  />
                  <label htmlFor='establishmentName' className='text-white mt-4 '>
                    Estabelecimento de Compra
                  </label>
                  <input
                    type='text'
                    className='bg-[#03001A] rounded-md pl-3 text-opaque-white h-10 w-full mt-1'
                    disabled
                    value={acquisitionsDetails.establishmentName}
                  />
                </div>
                <div className='flex flex-col w-plw '>
                  <label htmlFor='acquistionType' className='text-white mt-4 '>
                    Tipo de Aquisição
                  </label>
                  <input
                    type='text'
                    className='bg-[#03001A] rounded-md pl-3 text-opaque-white h-10 w-full mt-1'
                    disabled
                    value={acquisitionsDetails.acquistionType}
                  />
                  <label htmlFor='establishmentAddress' className='text-white mt-4 '>
                    Endereço da Farmácia
                  </label>
                  <div
                    className='bg-[#03001A] rounded-md pl-3 text-opaque-white min-h-[2.5rem] w-full mt-2'
                    style={{
                      lineHeight: "2.5rem",
                    }}
                  >
                    {acquisitionsDetails.establishmentAddress}
                  </div>
                </div>
                <div className='flex flex-col w-plw '>
                  <label htmlFor='date' className='text-white mt-4 '>
                    Data e hora da aquisição
                  </label>
                  <input
                    type='text'
                    className='bg-[#03001A] rounded-md pl-3 text-opaque-white h-10 w-full mt-1'
                    disabled
                    value={acquisitionsDetails.dateAndTime}
                  />
                  <label htmlFor='establishmentName' className='text-white mt-4'>
                    Status
                  </label>
                  {userType === "Admin" ? (
                    <CustomSelectRxStatus buyID={id!} initStatus={acquisitionsDetails.status} />
                  ) : (
                    <input
                      type='text'
                      className='bg-dark-omni rounded-md pl-3 text-opaque-white h-10 w-full mt-1'
                      disabled
                      value={acquisitionsDetails.status}
                    />
                  )}
                </div>
              </div>
              <hr className='border-t border-gray-200 my-4 opacity-40 mt-20 ' />
              <div className='font-thin mt-10 px-9'>
                <section>
                  <table className='w-full rounded-3xl bg-[#04011B] px-4 border-b border-gray-300 border-opacity-40 '>
                    <thead className=' font-medium h-14'>
                      <tr className='border-b'>
                        <th className='py-2 px-4 font-medium text-center text-white opacity-60'>Qtd</th>
                        <th className='py-2 px-4 font-medium text-center text-white opacity-60'>Medicamentos</th>
                        <th className='py-2 px-4 font-medium text-center text-white opacity-60'>Forma Farmacêutica</th>
                        <th className='py-2 px-4 font-medium text-center text-white opacity-60'>Valor</th>
                      </tr>
                    </thead>

                    <tbody className='rounded-b-lg text-white opacity-80'>
                      {acquisitionsDetails.products?.map((product, index) => {
                        function productDescription(product: Product) {
                          return `Tipo: ${product.tipo} | Apres.: ${product.apresentacao} | Qtd. Apres.: ${product.qtd_apresentacao} | Dose: ${product.dose}`;
                        }
                        return (
                          <tr key={index} className='border-b border-gray-300 border-opacity-40'>
                            <td className='py-2 px-4 text-center border-r'>{product.quantity}</td>
                            <td className='py-2 px-4 text-center border-r'>
                              {userType === "Admin" ? product.NOME : "XXXXX"}
                            </td>
                            <td className='py-2 px-4 text-center border-r'>
                              {userType === "Admin" ? productDescription(product) : "XXXXX"}
                            </td>
                            <td className='py-2 px-4 text-center'>R$ {product.price}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot className='bg-white bg-opacity-40 flex-shrink-0 h-14 text-white'>
                      <tr className=''>
                        <td colSpan={3} className='text-right rounded-bl-lg'>
                          <strong className='text-white mr-2'>Valor da compra</strong>
                        </td>
                        <td colSpan={1} className='rounded-br-lg'>
                          <strong className='flex justify-center'>R$ {acquisitionsDetails.totalAmount}</strong>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </section>
              </div>
            </div>
            <div className={`${selectedTabIndex === 1 ? "" : "hidden"}`}>
              <div className='w-full rounded-3xl border border-gray-300 border-opacity-40 mt-12'>
                <table className='w-[100%] rounded-3xl bg-[#04011B] px-4 '>
                  <thead className=' font-medium h-14'>
                    <tr>
                      <th className='py-2 px-4 font-medium text-center text-white opacity-60'>Tipo</th>
                      <th className='py-2 px-4 font-medium text-center text-white opacity-60'>Medicamentos</th>
                      <th className='py-2 px-4 font-medium text-center text-white opacity-60'>Data da Emissão</th>
                      <th className='py-2 px-4 font-medium text-center text-white opacity-60'>Emissor</th>
                      <th className='py-2 px-4 font-medium text-center text-white opacity-60'>Status</th>
                      <th className='py-2 px-4 font-medium text-center text-white opacity-60'>Ação</th>
                    </tr>
                  </thead>

                  <tbody className=' text-white'>
                    <tr className='border-b border-t border-gray-300 border-opacity-40'>
                      <td className='py-2 px-4 text-center '>Cupom Fiscal</td>
                      <td className='py-2 px-4 text-center border-l border-gray-300 border-opacity-40 underline'>
                        {allMedications}
                      </td>
                      <td className='py-2 px-4 text-center border-l border-gray-300 border-opacity-40'>
                        {acquisitionsDetails.dateAndTime}
                      </td>
                      <td className='py-2 px-4 text-center border-l border-gray-300 border-opacity-40'>
                        {acquisitionsDetails.establishmentName}
                      </td>
                      <td className='py-2 px-4 text-center border-l border-gray-300 border-opacity-40'>
                        {acquisitionsDetails.status}
                      </td>
                      <td className='py-2 px-4 text-center flex justify-center border-l border-gray-300 border-opacity-40'>
                        <button onClick={openModal}>
                          <EyeIcon />
                        </button>
                      </td>
                    </tr>
                    <tr className=' border-gray-300 border-opacity-40'>
                      <td className='py-2 px-4 text-center '>Prescrição</td>
                      <td className='py-2 px-4 text-center border-l border-gray-300 border-opacity-40 underline'>
                        {allMedications}
                      </td>
                      <td className='py-2 px-4 text-center border-l border-gray-300 border-opacity-40'>
                        {acquisitionsDetails.dateAndTime}
                      </td>
                      <td className='py-2 px-4 text-center border-l border-gray-300 border-opacity-40'>
                        Nome do Médico +crm
                      </td>
                      <td className='py-2 px-4 text-center border-l border-gray-300 border-opacity-40'>OK</td>
                      <td className='py-2 px-4 text-center flex justify-center border-l border-gray-300 border-opacity-40'>
                        <button onClick={openModal}>
                          <EyeIcon />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          {isModalOpen && <BuyImageDetails closeModal={closeModal} comprovante={acquisitionsDetails.comprovante} />}
        </Content>
      </div>
    </MainContainer>
  );
};

export default AcquisitionsDetails;
