import React from "react";
import { ReactNode } from "react";

type Props = {
  className?: string;
  children: ReactNode;
};

const LeftBarContainer = ({ children, className = "" }: Props) => {
  return (
    <div id='left-menu' className={`col-span-1 h-screen ${className}`}>
      {children}
    </div>
  );
};

export default LeftBarContainer;
