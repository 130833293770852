import React, { useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";

const MobileBanner = () => {
  const [open, isOpen] = useState(true);
  return (
    <>
      {open && (
        <div className='fixed bottom-0 text-white bg-dark-omni w-full'>
          <AiOutlineCloseCircle
            className='flex flex-row float-right justify-center text-red-200 mt-5 mr-3'
            onClick={() => isOpen(false)}
          />
          <section className='flex flex-col h-16 justify-center pl-3'>
            <span className=''>A sua experiência é melhor no App! </span>

            <span className=''> Baixe-o em: </span>
          </section>
        </div>
      )}
    </>
  );
};

export default MobileBanner;
