import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Menu } from "@headlessui/react";
import { useLayoutEffect, useState } from "react";
import { getUrlSubroute } from "../utils/getUrlSubroute";

export type SubMenuItem = {
  title: string;
  link: string;
  subroute: string;
  onClick?: () => void;
};

export type SubMenuData = {
  title: string;
  icon: React.ReactNode;
  icon2?: React.ReactNode;
  icon2Open?: React.ReactNode;
  items: SubMenuItem[];
  link?: string;
  isActive?: boolean;
  onClick?: () => void;
};

export const SubMenu = ({
  subMenu,
  id,
  isOpen,
  handleClick,
}: {
  subMenu: SubMenuData;
  id: number;
  isOpen: any;
  // selectedId: number | null;
  handleClick: (id: number) => void;
}) => {
  const [currentSubroute, setCurrentSubroute] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(isOpen);
  const [isMenuSelected, setIsMenuSelected] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setIsMenuOpen(isOpen);
  }, [isOpen]);

  const handleItemClick = (item: SubMenuItem) => {
    if (item.onClick) {
      item.onClick();
    } else if (item.link) {
      navigate(item.link);
    }

    // Verificar se estamos clicando no item "Adicionar Novo"
    if (item.title === "Adicionar Novo") {
      localStorage.removeItem("userEdition");
    }
  };

  useEffect(() => {
    if (location.pathname === subMenu.link) {
      setIsMenuSelected(true);
    }
  }, [isMenuOpen]);

  useLayoutEffect(() => {
    const newUrl = location.pathname;

    const newSubroute = getUrlSubroute(newUrl);

    if (newSubroute !== currentSubroute) {
      setCurrentSubroute(newSubroute);

      const isCurrentlySelectedMenu = subMenu.items.find((item) => {
        return item.subroute == newSubroute;
      });

      setIsMenuSelected(!!isCurrentlySelectedMenu);
      if (isCurrentlySelectedMenu) {
        setIsMenuOpen(true);
      }
      if (subMenu.items.length === 0) {
        setIsMenuSelected(false);
      }
    }
  }, [location]);

  const handleClickChild = () => {
    handleClick(id);
    if (subMenu.link) {
      navigate(subMenu.link);
    }
    if (subMenu.items.length === 0) {
      setIsMenuOpen(false);
    } else {
      setIsMenuOpen(!isMenuOpen);
    }
  };

  const getRoute = () => {
    const title = subMenu.title;
    const normTitle = title
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase();
    const url = useLocation().pathname.toLocaleLowerCase();
    if (url.includes(normTitle)) return "bg-[#0515F3] ";
    if (title === "Quadro de Saúde" && url === "/quadro-geral") return "bg-[#0515F3] ";
  };

  return (
    <Menu>
      <>
        <Menu.Button
          className={`rounded-md pr-8 text-opaque-white text-[17px] font-semibold py-2 pl-3 w-[12.625rem] cursor-pointer 
            hover:bg-omni-blue  text-white-87
            ${getRoute()}
            ${isMenuSelected && "bg-[#0515F3]"}
          `}
          onClick={(event) => {
            event.preventDefault();
            handleClickChild();
          }}
        >
          <div className='flex items-center gap-x-2'>
            {subMenu.icon}
            {subMenu.title}
            {isMenuOpen ? subMenu.icon2Open : subMenu.icon2}
          </div>
        </Menu.Button>

        {isMenuOpen && (
          <Menu.Items className='flex flex-col' static>
            {subMenu.items.map((item, index) => {
              return (
                <Menu.Item
                  as='a'
                  key={index}
                  onClick={() => handleItemClick(item)}
                  className={`ml-5 text-sm mt-3 ${
                    currentSubroute === item.subroute ? "text-white" : "text-opaque-white"
                  }`}
                >
                  {item.title}
                </Menu.Item>
              );
            })}
          </Menu.Items>
        )}
      </>
    </Menu>
  );
};
