/* eslint-disable prefer-const */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { LeftSidebar } from "../../components/LeftSidebar";
import MainContainer from "../../components/layout/MainContainer";
import LeftBarContainer from "../../components/layout/LeftBarContainer";
import Content from "../../components/layout/Content";
import { useNavigate } from "react-router-dom";
import CurrentTreatment from "../../components/CollaboratorComponents/CurrentTreatment";
import { TopbarEnterprise } from "../../components/TopbarEnterprise";
import DeleteIcon from "../../components/icons/DeleteIcon";
import PencilIcon from "../../components/icons/PencilIcon";
import { ProfileColaboratorStepper } from "../../components/CollaboratorComponents/ProfileColaboratorStepper";
import CollaboratorProfileInfo from "../../components/CollaboratorComponents/CollaboratorProfileInfo";
import CollaboratorData from "../../components/CollaboratorComponents/CollaboratorData";
import CollaboratorTreatments from "../../components/CollaboratorComponents/CollaboratorTreatments";
import Loading from "../../components/Loading";
import moment from "moment";
import { toast } from "react-toastify";
import LoadingSpinnerIcon from "../../components/Loading/LoadingSpinnerIcon ";
import { api, apiGo } from "../../utils/services/api";
import { CardDwUser } from "../../components/CardDw";

interface Colaborator {
   user: {
      id: string;
      primary_user: string;
      id_user_pvault: string;
      id_user_pvault_token: string;
      avatar: string | null;
      email: string;
      phone_number: string;
      linked_company: string;
      cargoAtual: string;
      linked_plan: string[];
      user_type: string[];
      digital_wallet: string | null;
      bank_data: string | null;
      purchase_history: string | null;
      account_status: string;
      ismedic: boolean;
      additional_info: string | null;
      isPrimary: boolean;
      magicToken: string | null;
      confirmed: boolean;
      dependents: string[];
      parentesco: string | null;
      medicalprescriptions: string[];
      date_of_birth: string;
      gender: string;
   };
   userByPVault: {
      avatar: string | null;
      cpf: string;
      email: string;
      id: string;
      medical_prescription: string | null;
      name: string;
      phone_number: string;
   };
   physicalData: any; // Update with the actual type if available
   clinicalHistory: any[]; // Update with the actual type if available
   hasAllRegistrationData: boolean;
}

type GoCheckBal = {
   balance: string;
   days: number;
   limitUser: string;
   presentationMax: number;
};

type GoReq = {
   user_id: string;
};

function ColaboratorProfilePage() {
   const [loading, setLoading] = useState(false);
   const navigate = useNavigate();

   const [colaborator, setColaborator] = useState<Colaborator>({} as Colaborator);
   const [selectedStep, setSelectedStep] = useState(0);
   const [balance, setBalance] = useState<GoCheckBal>();
   const [treatments, setTreatments] = useState([]);
   const [avatarColaborator, setAvatarColaborator] = useState("");
   const [acquisitionByUser, setAcquisitionByUser] = useState([]);
   const [isDeleting, setIsDeleting] = useState(false);

   const defaultProfilePic = "/default.jpg";

   const { id } = useParams(); // Chamado no escopo do componente

   useEffect(() => {
      const fetchData = async () => {
         setLoading(true);
         const userID = id || localStorage.getItem("userEdition");

         let collab = await api.get(`/users/${userID}`);

         if (collab.data && collab.data.clinicalHistory && collab.data.clinicalHistory.length > 0) {
            const filtered = collab.data.clinicalHistory[0]?.tratamentos
               ? collab.data.clinicalHistory[0].tratamentos.filter((treatment: any) => {
                    const agenda = treatment.agenda;
                    const now = moment().subtract(3, "hours");
                    for (let i = 0; i < agenda.length; i++) {
                       if (moment(agenda[i].data).isSameOrAfter(now)) {
                          return true;
                       }
                    }
                    return false;
                 })
               : [];
            setTreatments(filtered);
         } else {
            setTreatments([]);
         }

         const fetchedAvatar = collab.data?.user?.avatar
            ? `${import.meta.env.VITE_SUPABASE_URL}/storage/v1/object/public/avatars/${collab.data.user.avatar}`
            : defaultProfilePic;
         setAvatarColaborator(fetchedAvatar);

         const responseAcquisition = await api.get(`/buys/user/${userID}`);
         setAcquisitionByUser(responseAcquisition.data);

         let dependentIds = collab.data.user.dependents || [];
         let validDependents: Array<{ id: string; name: string }> = [];
         let requestsToUpdateDependents: string[] = [];

         // Check if there are any dependents and the length is not 0
         if (dependentIds.length > 0) {
            for (const dependentId of dependentIds) {
               try {
                  const response = await api.get(`/users/${dependentId}`);
                  if (response.data) {
                     // Add valid dependent data
                     validDependents.push({
                        id: dependentId,
                        name: response.data.userByPVault.name,
                     });
                  } else {
                     // If not valid, prepare to update the user record to remove the invalid dependent
                     requestsToUpdateDependents.push(dependentId);
                  }
               } catch (error) {
                  console.error(`Failed to fetch dependent with ID ${dependentId}:`, error);
                  requestsToUpdateDependents.push(dependentId);
               }
            }

            if (requestsToUpdateDependents.length > 0) {
               // Filter out the invalid dependent IDs
               const updatedDependentIds = dependentIds.filter((dependentId: any) => !requestsToUpdateDependents.includes(dependentId));

               // Update the primary user's dependents list to remove invalid dependents
               await api
                  .patch(`/users/${userID}`, { dependents: updatedDependentIds })
                  .then((response) => console.log("Primary user updated with the new dependents list"))
                  .catch((error) => console.error("Error updating primary user dependents", error));
            }

            // Set the filtered and validated dependents
            collab.data.user.dependents = validDependents;
         }
         let bGo: GoReq = {
            user_id: userID,
         };
         let res = await apiGo
            .post("/check-balance", bGo)
            .then((res) => (res = res.data))
            .catch((e: any) => console.error("it errored when making the request to go", e));
         // VITE_API_URL_GO
         setColaborator(collab.data);
         setBalance(res);
         setLoading(false);
      };
      fetchData();
   }, []);

   const handleDelete = async (id: string) => {
      if (window.confirm("Deseja excluir o colaborador?")) {
         setIsDeleting(true); // Begin the deletion process
         toast.info("Deletando o usuário...");

         try {
            await api.delete(`users/${id}`);
            toast.success(`Colaborador deletado com sucesso!`);
            localStorage.removeItem("userEdition");

            setTimeout(() => {
               navigate("/listaColaboradores");
            }, 1500);
         } catch (error) {
            toast.error("Erro ao deletar o colaborador");
            console.error("Error deleting user:", error);
         } finally {
            setIsDeleting(false); // Reset the state, whether successful or not
         }
      }
   };

   const renderStepComponent = () => {
      switch (selectedStep) {
         case 0:
            return <CollaboratorProfileInfo data={colaborator} />;
         case 1:
            return <CollaboratorData data={acquisitionByUser} />;
         case 2:
            return <CollaboratorTreatments data={colaborator} />;
         default:
            return null;
      }
   };

   return (
      <>
         <MainContainer className='bg-[#03001A]'>
            <LeftBarContainer className='bg-[#03001A]'>
               <LeftSidebar />
            </LeftBarContainer>
            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, width: "480%" }}>
               <TopbarEnterprise hideAll hide='invisible' />

               <Content className='bg-omni-gray rounded-[2rem] h-[100%] p-5 relative mb-10 mt-[-4.5rem]'>
                  <div style={{ display: "flex" }} className='m-5'>
                     <div className={treatments.length === 0 ? "w-full" : "w-2/3"}>
                        <div className='flex justify-between items-center mt-1'>
                           <h1 className='font-manrope font-bold font-regular text-5xl text-white justify-center mb-6'>
                              Dados do Colaborador
                           </h1>
                        </div>

                        <div
                           className='pb-10'
                           style={{
                              background: "linear-gradient(0deg, #03001A, #03001A)",
                              borderRadius: "20px",
                           }}
                        >
                           <div className='flex flex-column m-5'>
                              <div className='flex flex-column items-center justify-center mt-5'>
                                 <img
                                    src={avatarColaborator}
                                    onError={(e) => {
                                       const imgElement = e.target as HTMLImageElement;
                                       imgElement.onerror = null; // To prevent infinite loop in case defaultAvatar also fails to load
                                       imgElement.src = defaultProfilePic;
                                    }}
                                    alt='Selected'
                                    className='mt-2 cursor-pointer rounded-full object-cover mx-5'
                                    style={{ height: "160px", width: "160px" }}
                                 />
                                 <div className='text-center mt-5'>
                                    <h3 className='tracking-widest text-white' style={{ fontFamily: "Manrope", fontSize: "2.5rem" }}>
                                       {colaborator?.userByPVault?.name || ""}
                                    </h3>
                                    <div className='flex flex-row items-center my-2'>
                                       {colaborator?.user && (
                                          <>
                                             <p className='font-inter text-base text-white opacity-87 mr-2'>
                                                #ID{colaborator?.user?.id?.substring(0, 5) || ""}
                                             </p>
                                             <span
                                                className={`text-white text-sm rounded-full py-1 px-2 ${
                                                   colaborator.user.account_status === "Ativo"
                                                      ? "bg-green-500"
                                                      : colaborator.user.account_status === "Aguardando"
                                                        ? "bg-yellow-500"
                                                        : colaborator.user.account_status === "Atrasado"
                                                          ? "bg-red-500"
                                                          : ""
                                                }`}
                                             >
                                                {colaborator.user.account_status || ""}
                                             </span>
                                          </>
                                       )}
                                    </div>
                                    <span className='flex flex-col text-left'>
                                       {balance && localStorage.getItem("userType").toLowerCase() === "admin" && (
                                          <CardDwUser
                                             balance={balance.balance}
                                             presentationMax={balance.presentationMax}
                                             limitUser={balance.limitUser}
                                             days={balance.days}
                                          />
                                       )}

                                       {!balance && localStorage.getItem("userType").toLowerCase() === "admin" && (
                                          <span className='text-omni-light-gray'> Usuário sem carteira vinculada </span>
                                       )}
                                    </span>
                                 </div>
                              </div>
                              <div className='flex flex-grow justify-end mt-4 mx-3'>
                                 <button
                                    className='mr-2 flex justify-end items-start m-3'
                                    onClick={() => {
                                       console.log(colaborator);
                                       // Verifica se o tipo do usuário é 'dependente'
                                       if (colaborator.user.user_type[0].toLowerCase() === "dependente") {
                                          // Se for dependente, configura o localStorage com o primary_user
                                          localStorage.setItem("userEdition", colaborator.user.primary_user);
                                          // Redireciona para a página de lista de dependentes
                                          navigate(`/listaDependentes`);
                                       } else {
                                          // Se não for dependente, segue o fluxo original
                                          navigate(`/atualizar-perfil-individual`, {
                                             state: { collaborator: colaborator },
                                          });
                                          localStorage.setItem("userEdition", colaborator.user.id);
                                       }
                                    }}
                                 >
                                    <PencilIcon />
                                 </button>
                                 <button
                                    className='flex justify-end items-start m-3'
                                    disabled={isDeleting} // Optionally disable the button during deletion
                                    onClick={() => {
                                       handleDelete(colaborator?.user?.id);
                                    }}
                                 >
                                    {isDeleting ? <LoadingSpinnerIcon /> : <DeleteIcon />}
                                 </button>
                              </div>
                           </div>

                           <ProfileColaboratorStepper selectedStep={selectedStep} setSelectedStep={setSelectedStep} />
                           {loading ? <Loading /> : <>{renderStepComponent()}</>}
                        </div>
                     </div>

                     {Array.isArray(treatments) && treatments.length > 0 ? (
                        <div className='w-1/3 ml-3'>
                           <CurrentTreatment data={treatments} />
                        </div>
                     ) : null}
                     {/* <ClinicalData data={colaborator} /> */}
                  </div>
               </Content>
            </div>
         </MainContainer>
      </>
   );
}

export default ColaboratorProfilePage;
