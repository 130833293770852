import React, { useEffect, useState } from "react";
import { useRegisterEnterprise } from "../hooks/RegisterEnterpriseContext";
import { RegisterEnterpriseAction } from "../types/RegisterEnterpriseTypes";

type Gender = "Masculino" | "Feminino" | "Outros";

type CustomSelectGenderProps = {
   genderUser?: string;
};

export function CustomSelectGender({ genderUser }: CustomSelectGenderProps) {
   const { state, dispatch } = useRegisterEnterprise();

   const initialGender: Gender = genderUser === "Feminino" || genderUser === "Outros" ? genderUser : "Masculino";
   const [gender, setGender] = useState<Gender>(initialGender);

   useEffect(() => {
      if (genderUser && (genderUser === "Feminino" || genderUser === "Outros" || genderUser === "Masculino")) {
         setGender(genderUser);
         dispatch({
            type: RegisterEnterpriseAction.setRepresentativesGender,
            payload: genderUser,
         });
      }
   }, [genderUser, dispatch]);

   const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedValue = event.target.value as Gender;
      setGender(selectedValue);
      dispatch({
         type: RegisterEnterpriseAction.setRepresentativesGender,
         payload: selectedValue,
      });
   };

   return (
      <div className='relative'>
         <select
            className='w-full bg-input-register rounded-md text-opaque-white px-3 py-2 appearance-none'
            onChange={handleSelectChange}
            value={gender}
         >
            <option value='Masculino'>Masculino</option>
            <option value='Feminino'>Feminino</option>
            {/* // TODO api nao aceita o valor 'Outros' */}
            {/* <option value='Outros'>Prefiro não informar</option> */}
         </select>
         <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-500'>
            <svg className='w-4 h-4' fill='none' stroke='currentColor' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
               <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M19 9l-7 7-7-7' />
            </svg>
         </div>
      </div>
   );
}
