import React, { createContext, Dispatch, SetStateAction, useContext, useState } from "react";
import { z } from "zod";
import { TUser } from "../types/CollaboratorDataTypes";

// adicionar ao context do user as permissoes
// mover aquilo que está atrelado ao local storage ao context: manter alguns dados tanto no state quanto no localStorage, principalmente os dados estáticos
// mover as requisições externas como da lista de aquisições e colaboradores ao react query
interface UserContextType {
  userEdition: string | null;
  user: TUser | null;
  setUser: Dispatch<SetStateAction<TUser | null>>;
  setUserEdition: (id: string | null) => void;
  userRoles?: UserRoles;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUserContext deve ser utilizado dentro de um UserProvider");
  }
  return context;
};

export const UserProvider = ({ children }: { children: JSX.Element }) => {
  const [userEdition, setUserEdition] = useState<string | null>(localStorage.getItem("userEdition"));
  const [user, setUser] = useState<TUser | null>(null);

  return <UserContext.Provider value={{ user, userEdition, setUserEdition, setUser }}>{children}</UserContext.Provider>;
};

// const uPermissions = z.enum(["read", "write", "delete"]);

const userRoles = z.enum(["Admin", "Gestor", "Médico", "Gestor-Mor"]);
type UserRoles = z.infer<typeof userRoles>;
