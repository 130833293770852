import React, { useState, useEffect, useRef } from "react";
import { apiGo } from "../utils/services/api";
import { GoReturn } from "../types/ApiReturn";
import { toast } from "react-toastify";
import axios from "axios";
import { api } from "../utils/services/api";

interface Product {
  NOME: string;
  description: string | undefined;
  quantity: number;
  price: number;
  apresentacao: string;
  dose: string;
  qtd_apresentacao: number | string;
  tipo: string;
}

interface Purchase {
  buy_id: string;
  user_id: string;
  individual_price: number[];
  medications: string[];
  quantity: number[];
  total_amount: number;
  date: string;
  establishment_address: string;
  establishment_name: string;
  status: string;
  names: string[];
  products: Product[];
  acquisition_type: string;
  comprovante: string;
}

interface CustomSelectRxStatusProps {
  buyID: string;
  initStatus: string;
}
export const CustomSelectRxStatus = (CustomSelectRxStatus: CustomSelectRxStatusProps) => {
  const [selectedValue, setSelectedValue] = useState("");
  const [buyStatus, setBuyStatus] = useState<string>("");
  const selectRef = useRef<any>();
  useEffect(() => {
    fetchAcquisitionsDetails();
    // some way to fetch the previous status of the buy prior to the change
    // setSelectedValue(enterpriseAccountStatus);
  }, []);
  // alterar status da compra diretamente do sistema admin
  const getOptionStyle = (value: string) => {
    switch (value) {
      case "OK: Compra Aprovada":
        return "green";
      case "Inconsistencia Anexo: Usuario ou Medicamentos":
        return "#f5dcbd";
      case "Pendente":
        return "red";
      default:
        return "inherit";
    }
  };

  const handleSelectChange = async (event: any) => {
    const { value } = event.target;
    let msg = "";
    let errored = false;
    try {
      // the id should be appended after the slash in order for the go service to use it as req param
      const reqBody = {
        status: value,
      };
      const res = await apiGo.put(`/buys/change-status/${CustomSelectRxStatus.buyID}`, reqBody);
      const data: GoReturn = res.data;
      msg = data.message;
      errored = false;
    } catch (error) {
      msg = "Erro ao alterar status da compra";
      errored = true;
    } finally {
      setSelectedValue(CustomSelectRxStatus.initStatus as unknown as string);
      if (errored) {
        toast.error(msg);
      } else {
        toast.info(msg);
      }
    }
  };

  const fetchAcquisitionsDetails = async () => {
    try {
      const { data }: { data: Purchase } = await api.get(`/buys/${CustomSelectRxStatus.buyID}`);
      setBuyStatus(data.status);
    } catch (error) {
      console.error("Error fetching acquisition details", error);
    }
  };

  const selectStyle = {
    color: selectedValue ? getOptionStyle(selectedValue) : "opaque-white",
  };

  return (
    <>
      <select
        value={CustomSelectRxStatus.initStatus} // Controlled component
        className='bg-[#03001A] text-white border-none rounded-md mt-3 px-3 h-10'
        onChange={handleSelectChange}
        style={selectStyle}
      >
        <option value='' disabled className='text-opaque-white'>
          {CustomSelectRxStatus.initStatus || "Selecione um status..."}
        </option>
        <option value='OK: Compra Aprovada' style={{ color: getOptionStyle("OK: Compra Aprovada") }}>
          OK: Compra Aprovada
        </option>
        <option
          value='Inconsistencia Anexo: Usuario ou Medicamentos'
          style={{ color: getOptionStyle("Inconsistencia Anexo: Usuario ou Medicamentos") }}
        >
          Inconsistencia Anexo: Usuario ou Medicamentos
        </option>
        <option value='Pendente' style={{ color: getOptionStyle("Pendente") }} title='Veja como desbloquear'>
          Pendente
        </option>
      </select>
    </>
  );
};
