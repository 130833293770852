import React from "react";

const VERSION = "1.5.0";
const SERVICE_NAME = "Portal OMNI";
export const VersionComponent = () => {
    return (
    <div className='fixed left-0 bottom-0 pl-16 pb-8 text-opaque-white text-sm '>
        <p className='mt-4 text-opaque-white text-sm'></p>
        <p>Versão {VERSION}</p>
        <p>{SERVICE_NAME}</p>
    </div>
    );
};
