import React from "react";
import MainContainer from "../../components/layout/MainContainer";
import LeftBarContainer from "../../components/layout/LeftBarContainer";
import { LeftSidebar } from "../../components/LeftSidebar";
import { TopbarEnterprise } from "../../components/TopbarEnterprise";
import { CreatePlanComponent } from "../../components/Plan/CreatePlanComponent";

export interface NewPlan {
  companyId: string,
  costPerLife: number,
  individualLimit: number,
  companyGlobalLimit: number,
  coverage: "celeste" | "turquesa" | "royal",
}

export default function CreatePlan(): JSX.Element {


  return (
      <>
        <MainContainer className='bg-[#03001A]'>
          <LeftBarContainer className='bg-[#03001A]'>
            <LeftSidebar />
          </LeftBarContainer>

          <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, width: "480%" }}>
            <TopbarEnterprise />
            <CreatePlanComponent />
          </div>
        </MainContainer>
      </>
  );
}
