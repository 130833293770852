import React, { useEffect, useState } from "react";
import { LeftSidebar } from "../components/LeftSidebar";
import { TopbarEnterprise } from "../components/TopbarEnterprise";
import MainContainer from "../components/layout/MainContainer";
import LeftBarContainer from "../components/layout/LeftBarContainer";
import Content from "../components/layout/Content";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Label,
  Legend,
  BarChart,
  Bar,
  LabelList,
} from "recharts";
import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { api } from "../utils/services/api";

import EnfermeiroIcon from "../assets/EnfermeiroIcon.svg";

interface Medication {
  [index: number]: [string, number];
}
interface TreatmentsReport {
  totalForMonth: number;
  treatmentsPerDay: { [key: string]: number };
}

interface GeneralHealth {
  totalAmountForMonth: number;
  totalPerDay: { [key: string]: number };
  top5Medications: Medication[];
  top5Pathologies: Array<[string, number]>;
  treatmentsReport: TreatmentsReport;
}

export const HealthPanel = () => {
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();

  const linkedCompany = localStorage.getItem("linkedCompany");
  const holdingID = localStorage.getItem("holdingID");

  const userType = localStorage.getItem("userType");

  const [generalHealth, setGeneralHealth] = useState<GeneralHealth | null>(null);
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [graphData, setGraphData] = useState<any>([]);
  const [acquisitionData, setAcquisitionData] = useState<any>([]);
  const [treatmentData, setTreatmentData] = useState<any>([]);
  const [formattedFirstDay, setFormattedFirstDay] = useState<string>("");
  const [formattedLastDay, setFormattedLastDay] = useState<string>("");

  useEffect(() => {
    const firstDay = new Date(currentYear, selectedMonth - 1, 1);
    const lastDay = new Date(currentYear, selectedMonth, 0);

    setFormattedFirstDay(format(firstDay, "dd MMMM", { locale: ptBR }));
    setFormattedLastDay(format(lastDay, "dd MMMM", { locale: ptBR }));
  }, [selectedMonth, currentYear]);

  useEffect(() => {
    const fetchReport = async () => {
      try {
        const isGestorMor = userType.toLowerCase() === "gestor-mor";

        // Construir a URL baseada no userType
        const url =
          isGestorMor && linkedCompany === "allHCompanies"
            ? `buys/monthly-report/${holdingID}/${selectedMonth}?gestor_mor=true`
            : `buys/monthly-report/${linkedCompany}/${selectedMonth}`;

        const response = await api.get(url);
        if (response.data) {
          setGeneralHealth(response.data);

          const treatmentsPerDay = response.data.treatmentsReport.treatmentsPerDay;
          // Convert treatmentsPerDay to suitable format for graphing
          const treatmentData = Object.keys(treatmentsPerDay).map((day) => ({
            date: format(new Date(`${selectedMonth}-${day}`), "MMM-dd"),
            treatments: treatmentsPerDay[day],
          }));

          setGraphData(treatmentData);

          const acquisitionMedsData = response.data.top5Medications.map(([name, pv]: [string, number]) => ({
            name,
            pv,
          }));
          setAcquisitionData(acquisitionMedsData);

          // Directly use response.data for immediate use
          const treatmentMedsData = response.data.top5Pathologies?.map(([name, pv]: [string, number]) => ({
            name,
            pv,
          }));
          setTreatmentData(treatmentMedsData);
        } else {
          console.log("API did not return expected data");
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchReport();
  }, [selectedMonth]);

  const handleMonthChange = (event: any) => {
    const newMonth = parseInt(event.target.value, 10);
    setSelectedMonth(newMonth);
  };

  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  });

  const transformDataForGraph = (data: any, selectedMonth: any) => {
    if (!data || !data.totalPerDay) {
      return [];
    }

    const { totalPerDay } = data;
    const year = new Date().getFullYear();
    // eslint-disable-next-line prefer-const
    let transformedData: { date: string; acquisitions: any }[] = [];

    Object.keys(totalPerDay).forEach((day) => {
      const date = new Date(year, selectedMonth - 1, +day);
      const formattedDate = format(date, "MMM-dd");
      const acquisitions = totalPerDay[day];

      transformedData.push({
        date: formattedDate,
        acquisitions: acquisitions, // If you need this in R$, you may want to format it accordingly here
      });
    });

    return transformedData;
  };

  const transformedAcquisitionData = transformDataForGraph(generalHealth, selectedMonth);

  const combinedData = graphData.map((graphItem: any) => {
    const matchingAcquisition = transformedAcquisitionData.find(
      (acquisitionItem) => acquisitionItem.date === graphItem.date,
    );
    return {
      date: graphItem.date,
      treatments: graphItem.treatments,
      acquisitions: matchingAcquisition ? matchingAcquisition.acquisitions : 0,
    };
  });

  const CustomLegend = ({ payload }: any) => {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "0.5rem" }}>
        {payload.map((entry: any, index: any) => (
          <div key={`item-${index}`} style={{ color: "#FFF", fontSize: "13px", marginLeft: "20px" }}>
            <span style={{ marginRight: "4px", display: "inline-flex", alignItems: "center" }}>
              <svg width='13' height='13'>
                <circle cx='6.5' cy='6.5' r='6.5' fill={entry.color} />
              </svg>
            </span>
            {entry.value}
          </div>
        ))}
      </div>
    );
  };

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const dateStr = payload[0].payload.date; // Sep-28
      const currentYear = new Date().getFullYear(); // e.g., 2023
      const fullDateStr = `${dateStr}-${currentYear}`; // Sep-28-2023
      const parsedDate = new Date(Date.parse(fullDateStr));
      const formattedDate = format(parsedDate, "MMM-dd", { locale: ptBR });

      return (
        <div className='custom-tooltip'>
          <p className='label text-white'>{`Data: ${capitalizeFirstLetter(formattedDate)}`}</p>
          <p className='label text-white'>{`Aquisições: ${formatter.format(payload[0].payload.acquisitions)}`}</p>
          <p className='label text-white'>{`Tratamentos: ${payload[0].payload.treatments}`}</p>
        </div>
      );
    }
    return null;
  };

  const customHeight = acquisitionData.length * 60;

  return (
    <>
      <MainContainer className='bg-[#03001A]'>
        <LeftBarContainer className='bg-[#03001A]'>
          <LeftSidebar />
        </LeftBarContainer>
        <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, width: "480%", position: "relative" }}>
          <TopbarEnterprise />

          <Content className='bg-omni-gray rounded-[2rem] h-[100%] p-5 relative mb-10 mt-[-4.5rem]'>
            <div className='flex justify-between items-center m-5'>
              <h1 className='font-manrope font-bold font-regular text-5xl text-white justify-center mt-1 mb-5'>
                Quadro Geral de Saúde
              </h1>
            </div>
            {linkedCompany === "allHCompanies" || linkedCompany === "allCompanies" ? (
              <p className='pl-6 text-white'>
                Selecione uma das empresas para ver as informações de Quadro Geral de Saúde.
              </p>
            ) : (
              <>
                <div className='flex justify-between items-center'>
                  <p className='font-manrope pl-6 pr-4 text-white'>
                    Quadro Geral de Saúde Colaboradores <br />
                    <span>
                      De {formattedFirstDay} a {formattedLastDay} de {currentYear}
                    </span>
                  </p>

                  <select
                    onChange={handleMonthChange}
                    value={selectedMonth}
                    className='rounded-lg'
                    style={{
                      border: "1px solid #fff",
                      backgroundColor: "#111627",
                      color: "white",
                      fontFamily: "Inter",
                      marginRight: "2rem",
                      textAlign: "center",
                      fontWeight: 500,
                      fontSize: "16px",
                      lineHeight: "19.36px",
                      padding: "0.5rem",
                    }}
                  >
                    <option value={1}>Janeiro</option>
                    <option value={2}>Fevereiro</option>
                    <option value={3}>Março</option>
                    <option value={4}>Abril</option>
                    <option value={5}>Maio</option>
                    <option value={6}>Junho</option>
                    <option value={7}>Julho</option>
                    <option value={8}>Agosto</option>
                    <option value={9}>Setembro</option>
                    <option value={10}>Outubro</option>
                    <option value={11}>Novembro</option>
                    <option value={12}>Dezembro</option>
                  </select>
                </div>

                <div style={{ display: "flex", margin: "1.8rem" }}>
                  {/* Primeira Div - 1/4 de largura */}
                  <div style={{ flex: "1" }}>
                    <div
                      className='border-[0.019rem] border-white/40	rounded-3xl'
                      style={{
                        flex: "1",
                        overflow: "hidden",
                        height: "11.25rem", // 175px em rem (175/16)
                        marginBottom: "1rem",
                        backgroundColor: "#03001A",
                      }}
                    >
                      <ResponsiveContainer width='100%'>
                        <AreaChart data={combinedData || []} margin={{ top: 20, right: 0, left: 0, bottom: -60 }}>
                          {/* Definindo o gradiente */}
                          <defs>
                            <linearGradient id='colorGradient' x1='0' y1='0' x2='0' y2='1'>
                              <stop offset='0%' stopColor='#0517F5' stopOpacity={1} />
                              <stop offset='100%' stopColor='#0517F5' stopOpacity={0} />
                            </linearGradient>
                          </defs>

                          {/* Removendo as linhas X e Y */}
                          <XAxis dataKey='date' axisLine={false} tickLine={false} />
                          <YAxis hide={true} />

                          <Tooltip formatter={(value: number) => [formatter.format(value), "Aquisições"]} />
                          <ReferenceLine y={5000} label='Max' stroke='red' />

                          {/* Utilizando o gradiente no preenchimento */}
                          <Area
                            type='monotone'
                            dataKey='acquisitions'
                            stroke='#35436A'
                            fill='url(#colorGradient)'
                            fillOpacity={0.5}
                            strokeWidth={2}
                          />

                          {/* Adicionando título e subtítulo */}
                          <text
                            style={{ fontFamily: "Manrope", fontSize: "1rem", fontWeight: 500 }}
                            x={30}
                            y={30}
                            fontSize={16}
                            fontWeight='bold'
                            fill='#ffffff'
                          >
                            Aquisições
                          </text>
                          <text
                            style={{ fontFamily: "Manrope", fontSize: "1.5rem", fontWeight: 700 }}
                            x={30}
                            y={60}
                            fontSize={14}
                            fill='#ffffff'
                          >
                            {formatter.format(generalHealth?.totalAmountForMonth ?? 0)}
                          </text>
                        </AreaChart>
                      </ResponsiveContainer>
                    </div>

                    <div
                      className='border-[0.019rem] border-white/40	rounded-3xl'
                      style={{
                        height: "7.25rem", // 175px em rem
                        backgroundColor: "#03001A",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start", // Mudar para 'flex-start'
                        padding: "0.625rem",
                      }}
                    >
                      <div
                        style={{
                          width: "3.44rem",
                          height: "3.44rem",
                          backgroundColor: "#111627",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: "0 0 0 0.5rem", // Ajuste a margem conforme necessário
                        }}
                      >
                        <img
                          src={EnfermeiroIcon}
                          alt={"Enfermeiro"}
                          style={{ width: "1.498rem", height: "1.498rem" }}
                        />
                      </div>
                      <div style={{ marginLeft: "2rem" }}>
                        <p
                          style={{
                            width: "auto",
                            fontFamily: "Manrope",
                            fontWeight: 500,
                            fontSize: "1rem",
                            lineHeight: "1.365rem",
                            color: "#fff",
                          }}
                        >
                          Tratamentos Ativos
                        </p>
                        <p
                          style={{
                            width: "2.75rem",
                            height: "2.25rem",
                            fontFamily: "Manrope",
                            fontWeight: 800,
                            fontSize: "1.625rem",
                            lineHeight: "2.22rem",
                            color: "#fff",
                          }}
                        >
                          {generalHealth?.treatmentsReport.totalForMonth ?? 0}
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Segunda Div - 3/4 de largura */}
                  <div
                    className='border-[0.019rem] border-white/40	rounded-3xl'
                    style={{
                      flex: "3",
                      height: "23.5rem",
                      marginLeft: "1rem",
                      backgroundColor: "#03001A",
                    }}
                  >
                    <ResponsiveContainer width='98%' height={380}>
                      <AreaChart data={combinedData} margin={{ top: 60, right: 0, left: 40, bottom: 10 }}>
                        <defs>
                          <linearGradient id='colorAcquisition' x1='0' y1='0' x2='0' y2='1'>
                            <stop offset='5%' stopColor='#0517F5' stopOpacity={0.8} />
                            <stop offset='95%' stopColor='#0517F5' stopOpacity={0} />
                          </linearGradient>
                          <linearGradient id='colorTreatment' x1='0' y1='0' x2='0' y2='1'>
                            <stop offset='5%' stopColor='#00BFAF' stopOpacity={0.8} />
                            <stop offset='95%' stopColor='#00BFAF' stopOpacity={0} />
                          </linearGradient>
                        </defs>
                        <XAxis dataKey='date' />
                        <YAxis axisLine={false} tickLine={false} hide={true} />
                        <CartesianGrid strokeDasharray='0 0' vertical={false} horizontal={false} />
                        <Tooltip content={<CustomTooltip />} />
                        <Legend
                          align='center'
                          verticalAlign='bottom'
                          height={40}
                          wrapperStyle={{ color: "#FFF", fontSize: "13px" }}
                          iconSize={20}
                          iconType='circle'
                          content={<CustomLegend />}
                        />
                        <Area
                          type='monotone'
                          dataKey='acquisitions'
                          name='Aquisições'
                          stroke='#0517F5'
                          color='#FFF'
                          fillOpacity={1}
                          fill='url(#colorAcquisition)'
                          strokeWidth={2}
                        />
                        <Area
                          type='monotone'
                          dataKey='treatments'
                          name='Tratamentos'
                          stroke='#C1E9DB'
                          fillOpacity={1}
                          fill='url(#colorTreatment)'
                          strokeWidth={2}
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: "2", margin: "0.5rem" }}>
                    <p
                      className='pl-6 pr-4 text-white'
                      style={{ fontFamily: "Manrope", fontSize: "1.4rem", fontWeight: 600 }}
                    >
                      Medicamentos mais comuns
                    </p>
                    <div
                      className='border-[0.019rem] border-white/40	rounded-3xl'
                      style={{
                        height: "24rem",
                        backgroundColor: "#03001A",
                        margin: "1rem",
                      }}
                    >
                      <ResponsiveContainer width='95%' height={Math.max(customHeight, 380)}>
                        <BarChart
                          data={acquisitionData}
                          layout='vertical'
                          margin={{ top: 30, right: 30, left: 20, bottom: 0 }}
                        >
                          <YAxis dataKey='name' type='category' axisLine={false} tickLine={false} hide={true} />
                          <XAxis
                            type='number'
                            domain={[0, "dataMax + 10"]}
                            axisLine={false}
                            tickLine={false}
                            hide={true}
                          >
                            <Label value='Percentage' position='insideBottom' />
                          </XAxis>
                          <Bar dataKey='pv' fill='#0517F5' barSize={20} radius={35}>
                            <LabelList dataKey='pv' position='right' />
                            <LabelList
                              dataKey='name'
                              position='top'
                              content={({ x, y, value }: any) => {
                                return (
                                  <text x={x} y={y - 10} textAnchor='start' fill='white'>
                                    {value}
                                  </text>
                                );
                              }}
                            />
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </div>

                  <div style={{ flex: "2", margin: "0.5rem" }}>
                    <p
                      className='pl-6 pr-4 text-white'
                      style={{ fontFamily: "Manrope", fontSize: "1.4rem", fontWeight: 600 }}
                    >
                      Patologias mais recorrentes
                    </p>
                    <div
                      className='border-[0.019rem] border-white/40	rounded-3xl'
                      style={{
                        height: "24rem",
                        backgroundColor: "#03001A",
                        margin: "1rem",
                      }}
                    >
                      <ResponsiveContainer width='95%' height={380}>
                        <BarChart
                          width={730}
                          height={350}
                          data={treatmentData}
                          layout='vertical'
                          margin={{ top: 30, right: 30, left: 20, bottom: 0 }}
                        >
                          <YAxis dataKey='name' type='category' axisLine={false} tickLine={false} hide={true} />
                          <XAxis
                            type='number'
                            domain={[0, "dataMax + 10"]}
                            axisLine={false}
                            tickLine={false}
                            hide={true}
                          />
                          <Bar dataKey='pv' fill='#00BFAF' barSize={20} radius={35}>
                            <LabelList dataKey='pv' position='right' />
                            <LabelList
                              dataKey='name'
                              position='top'
                              content={({ x, y, value }: any) => {
                                return (
                                  <text x={x} y={y - 10} textAnchor='start' fill='white'>
                                    {value}
                                  </text>
                                );
                              }}
                            />
                          </Bar>
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Content>
        </div>
      </MainContainer>
    </>
  );
};
