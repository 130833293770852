import { z } from "zod";

export interface RegisterColaboratorState {
  colaboratorDetails: PersonalInfo | null;
}

export interface PersonalInfo {
  avatar: string;
  name: string;
  phoneNumber: string;
  birthDate: string;
  cpf: string;
  height: string;
  weight: string;
  jobTitle: string;
  email: string;
  bmi: string;
  associatedPlan: string;
  dependent: string;
  vinculado: string;
}

export type TRegisterColaborator = {
  colaboratorId: string;
  currentStep: number;
  avatar: string;
  name: string;
  phoneNumber: string;
  birthDate: string;
  cpf: string;
  height: string;
  weight: string;
  jobTitle: string;
  email: string;
  bmi: string;
  associatedPlan: string;
  accountType: string;
  additionalInfo: string;
  // dependent: DependentInfo[];
  vinculado: string;
  treatment: Treatment[];
  idDependent: string;
  nameDependent: string;
  phoneNumberDependent: string;
  cpfDependent: string;
  parentDependent: string;
  accountTypeDependent: string[];
  userCategory: string;
  holderCPF: string;
};

export type TColaboratorAction = {
  type: RegisterColaboratorAction;
  payload: any;
};

export enum RegisterColaboratorAction {
  setColaboratorId,
  setCurrentStep,
  setAvatar,
  setName,
  setPhoneNumber,
  setBirthDate,
  setCpf,
  setHeight,
  setWeight,
  setJobTitle,
  setEmail,
  setBmi,
  setAssociatedPlan,
  setDependent,
  setVinculado,
  setIdDependent,
  setNameDependent,
  setPhoneNumberDependent,
  setCpfDependent,
  setParentDependent,
  setAdditionalInfo,
  setAccountType,
  setAccountTypeDependent,
  setUserCategory,
  setHolderCPF
}

export type TColaboratorContext = {
  state: TRegisterColaborator;
  dispatch: (action: TColaboratorAction) => void;
};

export interface Acquisition {
  date: string;
  medications: string;
  sum: string;
}

export interface FormattedAcquisition extends Acquisition {
  colaborador: string;
  value: string;
  date: string;
  status: string;
  stablishmentName: string;
  buy_id: string;
  comprovante: string;
}

export interface Treatment {
  id: string;
  dataFinal: string;
  status: string;
  nomeRemedio: string;
  dosagem: string;
  duracao: string;
}

export interface CollaboratorDataInterface {
  data: {
    personalInfo: PersonalInfo;
    acquisitions: Acquisition[];
    treatments: Treatment[];
  };
}

export interface CollaboratorProfileInfoProps {
  data: {
    user: {
      id: string;
      id_user_pvault: string;
      id_user_pvault_token: string;
      avatar: string | null;
      email: string | null;
      phone_number: string;
      linked_company: string | null;
      cargoAtual: string;
      linked_plan: string | null;
      user_type: string[];
      digital_wallet: string | null;
      bank_data: string | null;
      purchase_history: string | null;
      account_status: string | null;
      ismedic: boolean;
      additional_info: string | null;
      isPrimary: boolean;
      magicToken: string | null;
      confirmed: boolean;
      dependents: string[];
      parentesco: string | null;
      medicalprescriptions: string[];
    };
    userByPVault: {
      cpf: string | null;
      date_of_birth: string;
      email: string | null;
      gender: string;
      name: string;
      phone_number: string;
    };
    physicalData: {
      id: string;
      height: string;
      weight: string;
      imc: string;
      blood_type: string;
      allergies: string[];
      intolerances: string[];
      drug_restriction: string[];
    };
    clinicalHistory: {
      id: string;
      historicoDoencas: string[];
      doencasCronicas: string;
      tratamentos: {
        id: string;
        medicamento: string;
        principio_ativo: string;
        laboratorio: string;
        tipo: string;
        doses_abertas: boolean;
        doses: any[];
        observacoes: string;
        un_dose: string;
        apresentacao: string;
        frequencia_dias: number;
        frequencia_horas: number;
        duracao_dias: number;
        qtd_total_consumida: number;
        qnt_total_medicamento: number;
        metodo_consumo: string;
        aviso_ativado: boolean;
        log_conformidade: string | null;
        patologia_associado: string;
        created_at: string;
      }[];
    }[];
    hasAllRegistrationData: boolean;
  };
}

export type TUser = {
  user_node: {
    user: {
      id: string;
      id_user_pvault: string;
      id_user_pvault_token: string;
      avatar: string | null;
      email: string | null;
      phone_number: string;
      linked_company: string | null;
      cargoAtual: string;
      linked_plan: string | null;
      user_type: string[];
      digital_wallet: string | null;
      bank_data: string | null;
      purchase_history: string | null;
      account_status: string | null;
      ismedic: boolean;
      additional_info: string | null;
      isPrimary: boolean;
      magicToken: string | null;
      confirmed: boolean;
      dependents: string[];
      parentesco: string | null;
      medicalprescriptions: string[];
    };
    userByPVault: {
      cpf: string | null;
      date_of_birth: string;
      email: string | null;
      gender: string;
      name: string;
      phone_number: string;
    };
    physicalData: {
      id: string;
      height: string;
      weight: string;
      imc: string;
      blood_type: string;
      allergies: string[];
      intolerances: string[];
      drug_restriction: string[];
    };
    clinicalHistory: {
      id: string;
      historicoDoencas: string[];
      doencasCronicas: string;
      tratamentos: {
        id: string;
        medicamento: string;
        principio_ativo: string;
        laboratorio: string;
        tipo: string;
        doses_abertas: boolean;
        doses: any[];
        observacoes: string;
        un_dose: string;
        apresentacao: string;
        frequencia_dias: number;
        frequencia_horas: number;
        duracao_dias: number;
        qtd_total_consumida: number;
        qnt_total_medicamento: number;
        metodo_consumo: string;
        aviso_ativado: boolean;
        log_conformidade: string | null;
        patologia_associado: string;
        created_at: string;
      }[];
    }[];
    hasAllRegistrationData: boolean;
  };
  user_state_fe: {
    previousPath?: string;
    pageAccessedDate?: Date;
    pageAccessed?: number;
    userEmail?: string;
    linkedCompany?: string;
    userType?: UserRoles; // whether or not it will be used at all
    magicLink?: string;
    apitoken?: string | gismal;
    holdingCnpj?: string | null;
    companySelected?: string;
    isRepresentative?: string;
  };
};

const userRoles = z.enum(["Admin", "Gestor", "Médico", "Gestor-Mor"]);
type UserRoles = z.infer<typeof userRoles>;
type gismal = { [key: string]: string };
