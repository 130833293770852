import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const DeleteIcon = (props: SvgIconProps, id: string) => {
  return (
    <>
      <SvgIcon {...props}>
        <svg
          width='18'
          height='20'
          viewBox='0 0 18 20'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          className='hover:cursor-pointer'
        >
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M8.16137 -0.000348123C7.47789 -0.00136867 6.87802 -0.00226441 6.3435 0.207958C5.87612 0.391779 5.46236 0.689997 5.14017 1.07527C4.77171 1.51588 4.58286 2.08525 4.3677 2.73397L4.27924 2.9998H1C0.447715 2.9998 0 3.44751 0 3.9998C0 4.55208 0.447715 4.9998 1 4.9998H2.06445L2.80553 16.1161C2.83849 16.6107 2.86702 17.0389 2.91878 17.3915C2.9736 17.7648 3.0642 18.1333 3.26443 18.4848C3.56454 19.0116 4.0172 19.4351 4.56278 19.6995C4.9268 19.8759 5.30052 19.9418 5.67668 19.9716C6.03186 19.9998 6.46109 19.9998 6.95674 19.9998H11.0432C11.5389 19.9998 11.9681 19.9998 12.3233 19.9716C12.6995 19.9418 13.0732 19.8759 13.4372 19.6995C13.9828 19.4351 14.4355 19.0116 14.7356 18.4848C14.9358 18.1333 15.0264 17.7648 15.0812 17.3915C15.133 17.0389 15.1615 16.6107 15.1945 16.1161L15.9356 4.9998H17C17.5523 4.9998 18 4.55208 18 3.9998C18 3.44751 17.5523 2.9998 17 2.9998H13.7208L13.6323 2.73396C13.4171 2.08525 13.2283 1.51587 12.8598 1.07527C12.5376 0.689997 12.1239 0.391778 11.6565 0.207958C11.122 -0.00226441 10.5221 -0.00136867 9.83864 -0.000348123H8.16137ZM6.39062 2.9998H11.6094C11.4558 2.5606 11.3961 2.44258 11.3256 2.35829C11.2182 2.22986 11.0803 2.13046 10.9245 2.06918C10.7908 2.0166 10.6083 1.9998 9.69357 1.9998H8.30643C7.39173 1.9998 7.20921 2.0166 7.07551 2.06918C6.91972 2.13046 6.7818 2.22986 6.6744 2.35829C6.60391 2.44258 6.54424 2.5606 6.39062 2.9998ZM8 8C8 7.44772 7.55228 7 7 7C6.44772 7 6 7.44772 6 8V15C6 15.5523 6.44772 16 7 16C7.55228 16 8 15.5523 8 15V8ZM12 8C12 7.44772 11.5523 7 11 7C10.4477 7 10 7.44772 10 8V15C10 15.5523 10.4477 16 11 16C11.5523 16 12 15.5523 12 15V8Z'
            fill='#FF5B26'
          />
        </svg>
      </SvgIcon>
    </>
  );
};

export default DeleteIcon;
