import React from "react";

const EyeIcon = () => {
  return (
    <div className='w-10 h-10 flex items-center justify-center relative'>
      <svg className='absolute w-full h-full' viewBox='0 0 30 30' fill='none'>
        <circle cx='15' cy='15' r='15' fill='#0517F5' />
      </svg>
      <svg className='absolute w-6 h-4' viewBox='0 0 18 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M17.84 4.43716C17.84 4.43716 14.0965 0 9 0C3.90388 0 0.159959 4.43716 0.159959 4.43716C-0.0533196 4.68992 -0.0533196 5.10379 0.159959 5.35681C0.159959 5.3564 3.90388 9.79362 9 9.79362C14.0961 9.79362 17.8397 5.35646 17.8397 5.35646C18.0534 5.10369 18.0534 4.68997 17.84 4.43723L17.84 4.43716ZM9 8.66621C6.91832 8.66621 5.23055 6.97872 5.23055 4.89676C5.23055 2.8148 6.91846 1.1273 9 1.1273C11.0815 1.1273 12.7695 2.81522 12.7695 4.89676C12.7695 6.9783 11.082 8.66621 9 8.66621Z'
          fill='white'
          fillOpacity='0.87'
        />
      </svg>
      <svg className='absolute w-2 h-2' viewBox='0 0 6 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M4.7779 2.10788C4.33509 2.10788 3.97574 1.7488 3.97574 1.30572C3.97574 1.18999 4.00047 1.07987 4.0446 0.980678C3.73432 0.81112 3.3784 0.714661 3.00009 0.714661C1.79517 0.714661 0.818268 1.69156 0.818268 2.89648C0.818268 4.1014 1.79517 5.07858 3.00009 5.07858C4.205 5.07858 5.18191 4.10168 5.18191 2.89676C5.18191 2.60451 5.12412 2.32566 5.01973 2.07071C4.94349 2.09462 4.86206 2.10788 4.7779 2.10788Z'
          fill='white'
          fillOpacity='0.87'
        />
      </svg>
    </div>
  );
};

export default EyeIcon;
