import React, { createContext, useContext, useReducer } from "react";
import { TAction, TMedications, TContext } from "../types/MedicationsTypes";

const initialState: any = {};

export const FilteredMedicationsContext = createContext<TContext | undefined>(undefined);

const FilteredMedicationsReducer = (state: TMedications, action: TAction) => {
  switch (action.type) {
    case "setAcquisitionMedications":
      return { ...state, payload: action.payload };
    case "setVaryingRefundMedications":
      return { ...state, payload: action.payload };
    case "setFixedRefundMedications":
      return { ...state, payload: action.payload };
    default:
      return state;
  }
};

export const FilteredMedicationsProvider = ({ children }: { children: JSX.Element }) => {
  const [state, dispatch] = useReducer(FilteredMedicationsReducer, initialState);
  const value = { state, dispatch };
  return <FilteredMedicationsContext.Provider value={value}>{children}</FilteredMedicationsContext.Provider>;
};

export const useFilteredMedications = () => {
  const context = useContext(FilteredMedicationsContext);
  if (context === undefined) {
    throw new Error("useFilteredMedications must be used inside FilteredMedicationsProvider");
  }
  return context;
};
