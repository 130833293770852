/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable prefer-const */
import React, { ChangeEvent, useState, useEffect } from "react";
import InputMask from "react-input-mask";
import { RegisterColaboratorAction } from "../types/CollaboratorDataTypes";
import { useRegisterColaborator } from "../hooks/ColaboratorContext";

type AccountType = "Beneficiário" | "Gestor" | "Medico" | "Homologador" | "Dependente";

type CustomSelectAccountTypeProps = {
   accountTypeUser?: string;
   additionalInfoUser?: string | null;
   allowedTypes?: AccountType[];
   setAccountType?: (accountType: AccountType) => void;
   setAdditionalInfo?: (info: string) => void;
};

const userType = localStorage.getItem("userType");

export function CustomSelectAccountType({
   accountTypeUser,
   additionalInfoUser,
   allowedTypes = userType === "Admin" ? ["Beneficiário", "Gestor", "Medico", "Homologador", "Dependente"] : ["Beneficiário", "Gestor", "Medico", "Dependente"],
}: CustomSelectAccountTypeProps) {
   // Inicialize o estado com "Beneficiário" se accountTypeUser não estiver presente ou não for permitido
   const initialAccountType = allowedTypes.includes(accountTypeUser as AccountType) ? (accountTypeUser as AccountType) : allowedTypes[0];

   const [accountTypes, setAccountTypes] = useState<AccountType>(initialAccountType);

   const [crmValue, setCRMValue] = useState("");
   const [ufValue, setUfValue] = useState("");

   const { dispatch, state } = useRegisterColaborator();

   useEffect(() => {
      if (accountTypes === "Medico" && additionalInfoUser) {
         const { crm, uf } = splitAdditionalInfo(additionalInfoUser);
         validateAndSetCRMAndUF(crm, uf);
      }
   }, [accountTypeUser, additionalInfoUser, allowedTypes]);

   useEffect(() => {
      setAccountTypes(accountTypeUser as AccountType);
   }, [accountTypeUser]);

   const validateAndSetCRMAndUF = (crm: any, uf: any) => {
      const isValidCRM = /^(\d{2}\.\d{4})$/.test(crm);
      const isValidUF = /^[A-Z]{2}$/.test(uf);

      if (isValidCRM && isValidUF) {
         setCRMValue(crm);
         setUfValue(uf);
      } else {
         console.error("Erro de validação do CRM ou UF");
      }
   };

   const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
      const selectedValue = event.target.value as AccountType; // Assegura que o valor é um AccountType válido.
      if (allowedTypes.includes(selectedValue)) {
         setAccountTypes(selectedValue);
         dispatch({
            type: RegisterColaboratorAction.setAccountType,
            payload: selectedValue,
         });
         if (selectedValue !== "Medico") {
            setCRMValue("");
            setUfValue("");
         }
      }
   };

   const handleCRMInput = (event: ChangeEvent<HTMLInputElement>) => {
      let value = event.target.value;
      if (value.length > 7) {
         value = value.slice(0, -1);
      }
      setCRMValue(value);
   };
   //
   const handleUfInput = (event: ChangeEvent<HTMLInputElement>) => {
      const input = event.target.value.toUpperCase();
      const formattedValue = input.replace(/[^A-Z]/g, "");
      event.target.value = formattedValue;
      setUfValue(formattedValue);
   };

   const splitAdditionalInfo = (additionalInfo: string) => {
      // Usa uma expressão regular para dividir a string por espaço ou barra
      const [crm, uf] = additionalInfo.split(/[ /-]/);
      return { crm, uf };
   };

   return (
      <>
         <div className='relative'>
            <select
               className='custom-select w-full rounded-md bg-[#03001A] text-white px-3 py-2 h-14 mt-1'
               value={accountTypes}
               defaultValue={state.accountType}
               onChange={handleSelectChange}
            >
               {allowedTypes.includes("Beneficiário") && <option value='Beneficiário'>Beneficiário</option>}
               {allowedTypes.includes("Gestor") && <option value='Gestor'>Gestor de Benefícios</option>}
               {allowedTypes.includes("Medico") && <option value='Medico'>Médico</option>}
               {allowedTypes.includes("Homologador") && <option value='Homologador'>Homologador</option>}
               {allowedTypes.includes("Dependente") && <option value='Dependente'>Dependente</option>}
            </select>
            <div className='pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-white'>
               <svg className='w-4 h-4' fill='none' stroke='currentColor' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
                  <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M19 9l-7 7-7-7' />
               </svg>
            </div>
         </div>

         {accountTypes === "Medico" && (
            <div className='flex pt-5'>
               <div className='w-1/2 pr-2.5'>
                  <label className='text-white mt-5 mr-1 pl-1'>CRM</label>
                  <InputMask
                     value={crmValue}
                     className='rounded-md text-white h-14 mt-2 pl-2 bg-[#03001A] w-[90%]'
                     mask='99.9999'
                     maskChar=''
                     placeholder='ex: 12.3454'
                     type='text'
                     onInput={handleCRMInput}
                  />
               </div>
               <div className='w-1/2'>
                  <label className='text-white mt-5 ml-3'>UF</label>
                  <input
                     name='uf'
                     className='rounded-md text-white h-14 mt-2 ml-3 pl-2 bg-[#03001A] w-[90%]'
                     placeholder='ex: SP'
                     type='text'
                     maxLength={2}
                     value={ufValue}
                     onInput={handleUfInput}
                  />
               </div>
            </div>
         )}

         <style>
            {`
          .custom-select {
            -webkit-appearance: none;  /* Remover a seta padrão no Chrome, Safari */
            -moz-appearance: none;     /* Remover a seta padrão no Firefox */
            appearance: none;          /* Remover a seta padrão em navegadores modernos */
          }

          .custom-select::-ms-expand {
            display: none;
          }
          `}
         </style>
      </>
   );
}
