/* eslint-disable react/react-in-jsx-scope */
import { useState, useEffect } from "react";
import { LeftSidebar } from "../../components/LeftSidebar";
import "react-toastify/dist/ReactToastify.css";
import { api } from "../../utils/services/api";
import { TableInstance } from "./TableInstance";
import MainContainer from "../../components/layout/MainContainer";
import LeftBarContainer from "../../components/layout/LeftBarContainer";
import Content from "../../components/layout/Content";
import Loading from "../../components/Loading";
import { TopbarEnterprise } from "../../components/TopbarEnterprise";

export const EnterpriseListing = () => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");

  const getEnterpriseData = async () => {
    try {
      const res = await api.get("/businessAcc");
      setTableData(res.data.businesses);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Remove o 'choosedCompany' do localStorage ao entrar na página de listagem
    localStorage.removeItem("choosedCompany");

    // Função para buscar dados da empresa
    getEnterpriseData();
  }, []);

  const filteredData = tableData.filter((item) =>
    item.enterprise_name.toLowerCase().includes(searchValue.toLowerCase()),
  );

  return (
    <>
      <MainContainer className='bg-[#03001A]'>
        <LeftBarContainer className='bg-[#03001A]'>
          <LeftSidebar />
        </LeftBarContainer>

        <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, width: "480%" }}>
          <TopbarEnterprise onSearchChange={(e) => setSearchValue(e.target.value)} placeholder='Buscar Empresa' />

          <Content className='bg-omni-gray rounded-[2rem] h-[100%] p-5 relative mb-10'>
            <div className='flex justify-between items-center m-5 mb-10 mt-6'>
              <h1 className='font-manrope font-bold font-regular text-5xl text-white justify-center'>
                Empresas Habilitadas
              </h1>
            </div>

            {isLoading ? <Loading /> : <TableInstance tableData={filteredData} />}
          </Content>
        </div>
      </MainContainer>
    </>
  );
};
