/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

export function CustomSelectState({ data, RegisterEnterpriseAction, dispatch }: any) {
   const handleSelectChange = (event: any) => {
      const { value } = event.target;
      dispatch({
         type: RegisterEnterpriseAction.setEnterpriseState,
         payload: value,
      });
   };

   return (
      <>
         <select
            value={data.enterpriseState}
            // className='bg-input-register text-opaque-white border-none rounded-md mt-3 pl-3 h-10'
            className='rounded-md pl-3 text-white h-14 mt-2 bg-input-register'
            onChange={handleSelectChange}
         >
            <option value=''>Selecionar</option>
            <option value='AP'> Amapá </option>
            <option value='AM'>Amazonas </option>
            <option value='AC'>Acre </option>
            <option value='SP'>São Paulo</option>
            <option value='RJ'>Rio de Janeiro</option>
            <option value='MG'>Minas Gerais</option>
            <option value='ES'>Espírito Santo</option>
            <option value='RS'>Rio Grande do Sul</option>
            <option value='SC'>Santa Catarina</option>
            <option value='PR'>Paraná</option>
            <option value='BA'>Bahia</option>
            <option value='SE'>Sergipe</option>
            <option value='PE'>Pernambuco</option>
            <option value='CE'>Ceará</option>
            <option value='RN'>Rio Grande do Norte</option>
            <option value='PB'>Paraíba</option>
            <option value='PI'>Piauí</option>
            <option value='MA'>Maranhão</option>
            <option value='PA'>Pará</option>
            <option value='TO'>Tocantins</option>
            <option value='RO'>Rondônia</option>
            <option value='RR'>Roraima</option>
            <option value='DF'>Distrito Federal</option>
            <option value='GO'>Goiás</option>
            <option value='MT'>Mato Grosso</option>
            <option value='MS'>Mato Grosso do Sul</option>
         </select>
      </>
   );
}
