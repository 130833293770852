import React, { useState, useEffect, useRef } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

interface Base64ContentViewerProps {
  base64Array: string[];
}

interface UrlData {
  url: string;
  contentType: string;
}

const Base64ContentViewer: React.FC<Base64ContentViewerProps> = ({ base64Array }) => {
  const [urls, setUrls] = useState<UrlData[]>([]);

  useEffect(() => {
    // Function to convert base64 string to a Blob
    const base64ToBlob = (base64: string, contentType: string): Blob => {
      const byteCharacters = atob(base64);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: contentType });
    };

    // Function to determine the content type
    const getContentType = (base64: string): string => {
      // Simple check to differentiate between PDF and image (you can expand this)
      if (base64.startsWith('JVBERi0')) {
        return 'application/pdf';
      } else if (base64.startsWith('/9j/')) {
        return 'image/jpeg';
      } else if (base64.startsWith('iVBORw0KGgo')) {
        return 'image/png';
      }
      return 'application/octet-stream';
    };

    const generateUrls = (): UrlData[] => {
      return base64Array.map(base64 => {
        const contentType = getContentType(base64);
        const blob = base64ToBlob(base64, contentType);
        return { url: URL.createObjectURL(blob), contentType };
      });
    };

    const urls = generateUrls();
    setUrls(urls);

    // Clean up Blob URLs when the component unmounts
    return () => {
      urls.forEach(({ url }) => URL.revokeObjectURL(url));
    };
  }, [base64Array]);

  return (
    <Carousel showThumbs={false} dynamicHeight={true} infiniteLoop={true}>
      {urls.map(({ url, contentType }, index) => (
        contentType === 'application/pdf' ? (
          <iframe
            key={index}
            src={url}
            width="100%"
            height="600px"
            title={`PDF Viewer ${index}`}
            style={{ border: 'none', marginBottom: '20px' }}
            
            />
        ) : (
          <div key={index}>
            <img
              src={url}
              alt={`Image ${index}`}
              style={{ width: '100%', height: 'auto', marginBottom: '20px' }}
            />
          </div>
        )
      ))}
    </Carousel>
  );
};

export default Base64ContentViewer;
