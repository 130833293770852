import React, { useState, useEffect } from "react";
import { LeftSidebar } from "../../components/LeftSidebar";
import MainContainer from "../../components/layout/MainContainer";
import LeftBarContainer from "../../components/layout/LeftBarContainer";
import Content from "../../components/layout/Content";
import { TableInstance } from "./TableInstance";
import {
  fetchAllExternalAcquisitions,
  fetchExternalAcquisitions,
  fetchExternalAcquisitionsByListCompanies,
} from "../../utils/services/api";
import { TopbarEnterprise } from "../../components/TopbarEnterprise";
import Loading from "../../components/Loading";
import DateFilter from "../../components/DateFilter";
import FilterIcon from "../../components/icons/FilterIcon";
import DownloadButton from "../../components/icons/DownloadIcon";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { convertToDate, isDateBetween } from "../../utils/dateIntervalChecker";

export const Acquisitions = () => {
  const [decodedInfo, setDecodedInfo] = useState<any[] | null>(null);
  const [searchValue, setSearchValue] = useState("");
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [startEndInterval, setstartEndInterval] = useState({ start: null, end: null });
  const [downloadState, setDownloadState] = useState("padrao"); // 'padrao', 'carregando', 'sucesso'
  const [isLoadingToDownload, setIsLoadingToDownload] = useState(false);

  const fetchAcquisitions = async () => {
    const linkedCompany = localStorage.getItem("linkedCompany");
    setIsLoadingToDownload(true);
    try {
      let acquisitions: any[] = [];

      if (linkedCompany === "allCompanies") {
        acquisitions = await fetchAllExternalAcquisitions();
      } else if (linkedCompany === "allHCompanies") {
        acquisitions = await fetchExternalAcquisitionsByListCompanies();
      } else {
        acquisitions = await fetchExternalAcquisitions(linkedCompany as string);
      }

      setDecodedInfo(acquisitions);

      if (acquisitions.length > 0) {
        console.log("Dados carregados com sucesso.");
      } else {
        console.log("Não há dados para download.");
        setDownloadState("padrao");
      }
    } catch (error) {
      console.error("Erro ao buscar aquisições:", error);
      setDownloadState("erro");
    } finally {
      setIsLoadingToDownload(false);
    }
  };

  const handleDownloadClick = async () => {
    setDownloadState("carregando");

    setTimeout(async () => {
      if (!decodedInfo || decodedInfo.length === 0) {
        try {
          await fetchAcquisitions();
        } catch (error) {
          console.error("Erro ao buscar aquisições:", error);
          setDownloadState("erro");
        }
      } else {
        generateAndDownloadAcquisitionsXLSX(decodedInfo);
        setDownloadState("sucesso");
      }
      setTimeout(() => setDownloadState("padrao"), 1500);
    }, 1500);
  };

  const generateAndDownloadAcquisitionsXLSX = (data: any[]) => {
    const ws = XLSX.utils.json_to_sheet(
      data.map(({ colaborador, dateTime, local, status, valor }) => ({
        Colaborador: colaborador || "Não Informado",
        "Data e Hora": dateTime || "Não Informado",
        Local: local || "Não Informado",
        Status: status || "Não Informado",
        Valor: valor || "Não Informado",
      })),
      { header: ["Colaborador", "Data e Hora", "Local", "Status", "Valor"] },
    );

    const wscols = [{ wch: 30 }, { wch: 20 }, { wch: 40 }, { wch: 25 }, { wch: 15 }];
    ws["!cols"] = wscols;

    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Aquisições");
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
    saveAs(blob, "ListaDeAquisicoes.xlsx");
  };

  function s2ab(s: string) {
    const buffer = new ArrayBuffer(s.length);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buffer;
  }

  const clickFilter = () => setShowCalendar(!showCalendar);

  const normalizeString = (str: string) => {
    return str
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^\w\s]/g, "")
      .toLowerCase();
  };

  const filteredData = decodedInfo?.filter((item) => {
    const isIncluded = normalizeString(item.colaborador).includes(normalizeString(searchValue));
    const horaCompra = item.dateTime.split(",")[0];
    const acquisitionDate = convertToDate(horaCompra);
    const withinDateRange =
      startEndInterval.start && startEndInterval.end
        ? isDateBetween(acquisitionDate, startEndInterval.start, startEndInterval.end)
        : true;
    return isIncluded && withinDateRange;
  });

  useEffect(() => {
    fetchAcquisitions();
  }, []);

  return (
    <>
      <MainContainer className='bg-[#03001A]'>
        <LeftBarContainer className='bg-[#03001A]'>
          <LeftSidebar />
        </LeftBarContainer>
        <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, width: "480%", position: "relative" }}>
          <TopbarEnterprise
            onSearchChange={(e) => setSearchValue(e.target.value)}
            placeholder='Buscar compras'
            hide='invisible'
          />
          <Content className='bg-omni-gray rounded-[2rem] h-[100%] p-5 relative mb-10'>
            <div className='flex justify-between items-center m-5 mt-1'>
              <h1 className='font-manrope font-bold font-regular text-5xl text-white justify-center mt-1'>
                Lista de Aquisições
              </h1>
              <div className='flex'>
                <div className={`${showCalendar ? "" : "hidden"} mr-2`}>
                  <DateFilter setstartEndInterval={setstartEndInterval} />
                </div>
                <div className='mt-2 mr-4 cursor-pointer'>
                  <DownloadButton
                    isLoading={isLoadingToDownload}
                    isSuccess={downloadState === "sucesso"}
                    isError={downloadState === "erro"}
                    onClick={handleDownloadClick}
                  />
                </div>
                <div className='mt-2 mr-2 cursor-pointer' onClick={clickFilter}>
                  <FilterIcon />
                </div>
              </div>
            </div>
            {decodedInfo ? <TableInstance tableData={filteredData} /> : <Loading />}
          </Content>
        </div>
      </MainContainer>
    </>
  );
};
