import React, { useEffect, useState, useCallback } from "react";
import { OmniLogo } from "./Logo";

import HomeIcon from "./icons/HomeIcon";
import AddPersonIcon from "./icons/AddPersonIcon";
import AcquisitionsIcon from "./icons/AcquisitionsIcon";
import BoardIcon from "./icons/BoardIcon";
import CoverageIcon from "./icons/CoverageIcon";
import CompaniesIcon from "./icons/CompaniesIcon";
import HeartIcon from "./icons/HeartIcon";
import { SubMenu, SubMenuData } from "./SubMenu";
import RightArrow from "./icons/RightArrow";
import DownArrow from "./icons/DownArrow";
import { useLocation, useNavigate } from "react-router-dom";

const companiesSubMenu = {
  title: "Empresas",
  icon: <CompaniesIcon style={{ width: "18px", height: "18px" }} />,
  icon2: <RightArrow />,
  icon2Open: <DownArrow />,
  items: [
    {
      title: "Lista",
      link: "/empresas",
      subroute: "/empresas",
    },
    {
      title: "Criar Novo",
      link: "/cadastro-empresa/dados-cadastrais",
      subroute: "/cadastro-empresa",
    },
  ],
} as SubMenuData;

const plansSubMenu = {
  title: "Planos",
  icon: <HeartIcon style={{ width: "18px", height: "18px" }} />,
  icon2: <RightArrow />,
  icon2Open: <DownArrow />,
  items: [
    {
      title: "Lista",
      link: "/planos-disponiveis",
      subroute: "/planos-disponiveis",
    },
    {
      title: "Detalhes",
      link: "/detalhes-plano",
      subroute: "/detalhes-plano",
    },
    {
      title: "Criar Novo",
      link: "/adicionar-plano/",
      subroute: "/adicionar-plano",
    },
    {
      title: "Simulação",
      link: "/planos/simulacao",
      subroute: "/planos",
    },
  ],
} as SubMenuData;

const acquisitionsSubMenu = {
  title: "Aquisições",
  icon: <AcquisitionsIcon style={{ width: "18px", height: "18px" }} />,
  items: [],
  link: "/aquisicoes",
} as SubMenuData;

const boardSubMenu = {
  title: "Quadro de Saúde",
  icon: <BoardIcon style={{ width: "18px", height: "18px" }} />,
  items: [],
  link: "/quadro-geral",
} as SubMenuData;

const dashboardSubMenu = {
  title: "Dashboard",
  icon: <HomeIcon style={{ width: "18px", height: "18px" }} />,
  items: [],
  link: "/dashboard",
  titleClassName: window.innerWidth <= 1300 ? "hide-on-small-screen" : "",
} as SubMenuData;

const coverageSubMenu = {
  title: "Cobertura",
  icon: <CoverageIcon style={{ width: "18px", height: "18px" }} />,
  items: [],
  link: "/cobertura",
} as SubMenuData;

export const LeftSidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [openedMenuId, setOpenedMenuId] = useState<number | null>(null);
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [forceOpen, setForceOpen] = useState<boolean>(false);

  const isOpen = ["/perfil-colaborador", "/novo-perfil-individual", "/listaColaboradores"].includes(location.pathname);
  const pathNames = ["/perfil-colaborador", "/novo-perfil-individual", "/listaColaboradores"];

  const userType = localStorage.getItem("userType"); // Retrieve userType from localStorage

  const handleClickChild = (id: number, link?: string) => {
    setSelectedId(id);

    if (forceOpen && id === 2) {
      setForceOpen(false);
      return;
    }

    // Navega para o link se ele for fornecido
    if (link) {
      navigate(link);
    }

    setOpenedMenuId((prevState) => (prevState === id ? null : id));
  };

  function useForceUpdate() {
    const [, setTick] = useState(0);
    const update = useCallback(() => {
      setTick((tick) => tick + 1);
    }, []);

    return update;
  }

  const IconContainer = ({ children }: any) => <div className='icon-container'>{children}</div>;

  const handleAddNewClick = () => {
    localStorage.removeItem("userEdition");
    navigate("/novo-perfil-individual");
  };

  const [collaboratorsSubMenu, setCollaboratorsSubMenu] = useState<SubMenuData>({
    title: "Colaboradores",
    icon: (
      <IconContainer>
        <AddPersonIcon />
      </IconContainer>
    ),
    icon2: (
      <IconContainer>
        <RightArrow />
      </IconContainer>
    ),
    icon2Open: (
      <IconContainer>
        <DownArrow />
      </IconContainer>
    ),
    items: [
      {
        title: "Lista",
        link: "/listaColaboradores",
        subroute: "/listaColaboradores",
      },
      {
        title: "Adicionar Novo",
        link: "/novo-perfil-individual",
        subroute: "/novo-perfil-individual",
        onClick: handleAddNewClick, // Adicione esta função aqui
      },
    ],
  });

  useEffect(() => {
    const updatedUserEdition = localStorage.getItem("userEdition");
    if (updatedUserEdition) {
      setCollaboratorsSubMenu((prevSubMenu) => ({
        ...prevSubMenu,
        items: [
          ...prevSubMenu.items,
          {
            title: "Perfil Individual",
            link: "/perfil-colaborador",
            subroute: "/perfil-colaborador",
          },
        ],
      }));
    } else {
      localStorage.removeItem("userEdition");
    }
  }, [localStorage.getItem("userEdition")]);

  const forceUpdate = useForceUpdate(); // Função personalizada para forçar atualização

  useEffect(() => {
    forceUpdate();
  }, [location.pathname]);

  useEffect(() => {
    if (pathNames.includes(location.pathname)) {
      setOpenedMenuId(2);
      setForceOpen(true);
    } else if (!pathNames.includes(location.pathname) && openedMenuId === 2) {
      setOpenedMenuId(null);
      setForceOpen(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    const pathNames = ["/perfil-colaborador", "/novo-perfil-individual", "/listaColaboradores", "perfil-colaborador"];

    if (pathNames.includes(location.pathname)) {
      setOpenedMenuId(2);
      setForceOpen(true);
    } else {
      setForceOpen(false);
    }
  }, [location.pathname]);

  return (
    <>
      <aside className='px-5 py-8 bg-[#03001A]'>
        <OmniLogo />

        <ul id='dashboard-item' className='flex justify-start'>
          <li className='rounded-md text-white text-md font-normal py-2 cursor-pointer bg-[#03001A]'>
            <div className={window.innerWidth <= 1300 ? "show-only-icon" : ""}>
              <SubMenu
                subMenu={dashboardSubMenu}
                id={1}
                // selectedId={selectedId}
                isOpen={openedMenuId === 1}
                handleClick={handleClickChild}
              />
            </div>
          </li>
        </ul>

        <ul id='collaborators-item' className='flex justify-start '>
          <li
            key={isOpen ? "open" : "close"} // Added key to force re-render
            className='rounded-md text-white text-md font-normal py-2 cursor-pointer'
            style={{ whiteSpace: "nowrap", width: "12rem", backgroundColor: "#03001A" }}
          >
            <SubMenu
              subMenu={collaboratorsSubMenu}
              id={2}
              isOpen={openedMenuId === 2}
              handleClick={() => handleClickChild(2, collaboratorsSubMenu.link)}
            />
          </li>
        </ul>

        <ul id='acquistions-item' className='flex justify-start bg-[#03001A]'>
          <li
            className='rounded-md text-white text-md font-normal py-2 cursor-pointer'
            style={{ whiteSpace: "nowrap", width: "12rem", backgroundColor: "#03001A" }}
          >
            <SubMenu
              subMenu={acquisitionsSubMenu}
              id={3}
              // selectedId={selectedId}
              isOpen={openedMenuId === 3}
              handleClick={handleClickChild}
            />
          </li>
        </ul>

        <ul id='health-board-item' className='flex justify-start'>
          <li
            className='rounded-md text-white text-md font-normal py-2 cursor-pointer bg-[#03001A]'
            style={{ whiteSpace: "nowrap", width: "12rem", backgroundColor: "#03001A" }}
          >
            <SubMenu
              subMenu={boardSubMenu}
              id={4}
              // selectedId={selectedId}
              isOpen={openedMenuId === 4}
              handleClick={handleClickChild}
            />
          </li>
        </ul>

        <ul id='coverage-item' className='flex justify-start'>
          <li
            className='rounded-md text-white  font-normal py-2 cursor-pointer bg-[#03001A]'
            style={{ whiteSpace: "nowrap", width: "12rem", backgroundColor: "#03001A" }}
          >
            <SubMenu
              subMenu={coverageSubMenu}
              id={5}
              // selectedId={selectedId}
              isOpen={openedMenuId === 5}
              handleClick={handleClickChild}
            />
          </li>
        </ul>

        {userType === "Admin" && (
          <ul id='companies-item' className='flex justify-start'>
            <li
              className='rounded-md text-white text-md font-normal py-2 cursor-pointer bg-[#03001A]'
              style={{ whiteSpace: "nowrap", width: "12rem", backgroundColor: "#03001A" }}
            >
              <SubMenu
                subMenu={companiesSubMenu}
                id={6}
                // selectedId={selectedId}
                isOpen={openedMenuId === 6}
                handleClick={handleClickChild}
              />
            </li>
          </ul>
        )}
        {userType === "Admin" && (
          <ul id='plans-item' className='flex justify-start'>
            <li
              className='rounded-md text-white text-md font-normal py-2 cursor-pointer bg-[#03001A]'
              style={{ whiteSpace: "nowrap", width: "12rem", backgroundColor: "#03001A" }}
            >
              <SubMenu
                subMenu={plansSubMenu}
                id={7}
                // selectedId={selectedId}
                isOpen={openedMenuId === 7}
                handleClick={handleClickChild}
              />
            </li>
          </ul>
        )}
      </aside>
    </>
  );
};
