/* eslint-disable react/react-in-jsx-scope */
import { useState, useEffect, useLayoutEffect } from "react";
import { LeftSidebar } from "../../components/LeftSidebar";
import MainContainer from "../../components/layout/MainContainer";
import LeftBarContainer from "../../components/layout/LeftBarContainer";
import Content from "../../components/layout/Content";
import { RegisterColaboratorFormStepper } from "../../components/CollaboratorComponents/RegisterColaboratorFormStepper";
import { FormProvider, useForm } from "react-hook-form";
import { useRegisterColaborator } from "../../hooks/ColaboratorContext";
import { RegisterColaboratorAction } from "../../types/CollaboratorDataTypes";
import { RegisterColaboratorSchemaStepTwo, RegisterColaboratorStepTwoSchemaType } from "../../utils/schemas/RegisterColaboratorSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import { api } from "../../utils/services/api";
import { CustomSelectParentesco } from "../../components/CollaboratorComponents/CustomSelectParentesco";
import { TopbarEnterprise } from "../../components/TopbarEnterprise";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import ptBRLocale from "date-fns/locale/pt-BR";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

type User = {
   user: {
      id: string;
      id_user_pvault: string;
      id_user_pvault_token: string;
      avatar: null | string;
      email: string;
      phone_number: string;
      linked_company: string;
      cargoAtual: string;
      linked_plan: any[];
      user_type: string[];
      digital_wallet: null | string;
      bank_data: null | string;
      purchase_history: null | string;
      account_status: null | string;
      ismedic: boolean;
      additional_info: null | string;
      isPrimary: boolean;
      dependents: string[];
      parentesco: string;
      plan_code: string;
      gender: string;
      date_of_birth: string;
   };
   userByPVault?: {
      avatar: null | string;
      cpf: string;
      email: string;
      name: string;
      phone_number: string;
   };
};

const CustomTextField = styled(TextField)({
   backgroundColor: "#03001A", // Cor de fundo
   borderRadius: "0.375rem", // borda arredondada (rounded-md)
   color: "white", // cor do texto (text-white)

   height: "3.5rem", // altura (h-14)
   width: "100%", // largura total (w-full)
   marginTop: "0.5rem", // margem superior (mt-2)
   // Estilos para o input
   "& .MuiInputBase-input": {
      color: "white", // Isso garante que o texto digitado seja branco
   },

   // Estilos para o placeholder
   "& .MuiInputBase-input::placeholder": {
      color: "white", // Isso garante que o placeholder seja branco
      opacity: 1, // Opcional: ajusta a opacidade do placeholder se necessário
   },

   // Estilos para o ícone do calendário
   "& .MuiSvgIcon-root": {
      color: "white", // Isso garante que o ícone do calendário seja branco
   },
});

function CollaboratorDependent() {
   const { state, dispatch } = useRegisterColaborator();

   const colaboratorID = localStorage.getItem("userEdition");
   const [userPrimary, setUserPrimary] = useState<User | null>(null);
   const [isLoading, setIsLoading] = useState(false);
   const [userDependents, setUserDependents] = useState<User[] | []>([]);
   const [editDependent, setEditDependent] = useState(false);
   const [choosedUserDependent, setChoosedUserDependent] = useState<string | null>(null);
   const [birthDate, setBirthDate] = useState(new Date());

   useEffect(() => {
      const fetchData = async () => {
         try {
            const responseData = await api.get(`/users/${colaboratorID}`).then((res) => res.data);
            setUserPrimary(responseData);
            const response = await api.get(`/users/usersDepen/list/${colaboratorID}`).then((res) => res.data);
            setUserDependents(response.usersData);
         } catch (error) {
            console.error(error);
         }
      };

      fetchData();
   }, []);

   useLayoutEffect(() => {
      dispatch({
         type: RegisterColaboratorAction.setCurrentStep,
         payload: 0,
      });
   }, []);

   const createColaboratorForm = useForm<RegisterColaboratorStepTwoSchemaType>({
      resolver: zodResolver(RegisterColaboratorSchemaStepTwo),
   });

   function isValidCPF(cpf: string) {
      return typeof cpf === "string" && cpf.length === 14 && !cpf.includes("_");
   }

   function convertDigitIn(date: Date): string {
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Janeiro é 0!
      const year = date.getFullYear();
      return `${year}-${month}-${day}`;
   }

   function isValidBirthDate(birthDate: string) {
      const pattern = /^\d{4}-\d{2}-\d{2}$/; // Alteração para validar o formato yyyy-mm-dd
      return pattern.test(birthDate) && !birthDate.includes("_");
   }

   function isValidPhoneNumber(phoneNumber) {
      if (!phoneNumber.trim()) return true; // Retorna true se o campo estiver vazio, considerando-o opcional

      const digits = phoneNumber.replace(/\D/g, ""); // Remove tudo que não é dígito
      return digits.length === 10 || digits.length === 11; // Aceita 10 ou 11 dígitos
   }

   const onSubmit = async () => {
      try {
         const formattedPhoneNumber = state.phoneNumberDependent ? state.phoneNumberDependent.replace(/\D/g, "") : null;

         const birthDataFormated = convertDigitIn(birthDate);

         const dependentData = {
            name: state.nameDependent,
            cpf: userPrimary?.userByPVault?.cpf,
            cpfTitular: state.cpfDependent,
            phone_number: formattedPhoneNumber ? `+55${formattedPhoneNumber}` : null,
            parentesco: state.parentDependent,
            user_type: ["Dependente"],
            gender: "Masculino",
            linked_plan: [userPrimary.user.plan_code],
            linked_company: userPrimary.user.linked_company,
            date_of_birth: birthDataFormated,
         };

         if (!isValidCPF(dependentData.cpf)) {
            toast.error("CPF é obrigatório ou está no formato inválido.");
            setIsLoading(false);
            return;
         }

         // if (!isValidPhoneNumber(state.phoneNumber)) {
         //    console.log(`??`);
         //    toast.error("Número de telefone inválido.");
         //    setIsLoading(false);
         //    return;
         // }

         if (!dependentData.parentesco) {
            toast.error("Parentesco é obrigatório.");
            setIsLoading(false);
            return;
         }

         if (birthDataFormated && !isValidBirthDate(birthDataFormated)) {
            toast.error("Data de nascimento está no formato inválido.");
            setIsLoading(false);
            return;
         }

         // Verificar se está editando um dependente existente
         if (choosedUserDependent) {
            const updatedDependentData = {
               name: state.nameDependent,
               cpf: state.cpfDependent,
               phone_number: formattedPhoneNumber ? `+55${formattedPhoneNumber}` : null,
               parentesco: state.parentDependent,
               user_type: ["Dependente"],
               gender: "Masculino",
               linked_plan: [userPrimary.user.plan_code],
               linked_company: userPrimary.user.linked_company,
               date_of_birth: birthDataFormated,
            };
            setIsLoading(true);
            try {
               await api.patch(`/users/${choosedUserDependent}`, updatedDependentData, {
                  headers: {
                     "Content-Type": "application/json",
                  },
               });
               toast.success("Dependente atualizado com sucesso!");
               setIsLoading(false);
               setEditDependent(false);
               createColaboratorForm.reset();
               setBirthDate(new Date());
            } catch (error) {
               toast.error("Erro ao atualizar dependente;");
               setIsLoading(false);
            } finally {
               const responseListDepen = await api.get(`/users/usersDepen/list/${colaboratorID}`).then((res) => res.data);
               setUserDependents(responseListDepen.usersData);
            }
         } else {
            setIsLoading(true);
            try {
               await api.post(`/users/create/user`, JSON.stringify(dependentData), {
                  headers: {
                     "Content-Type": "application/json",
                  },
               });
               toast.success("Dependente cadastrado com sucesso!");
               setIsLoading(false);
            } catch (error) {
               console.log("erro no cadastro de dependente", error.response.data.message);
               toast.error(error.response.data.message);
               setIsLoading(false);
            } finally {
               const responseListDepen = await api.get(`/users/usersDepen/list/${colaboratorID}`).then((res) => res.data);
               setUserDependents(responseListDepen.usersData);
            }
         }

         // Resetar os campos após submissão
         dispatch({
            type: RegisterColaboratorAction.setIdDependent,
            payload: "",
         });

         dispatch({
            type: RegisterColaboratorAction.setNameDependent,
            payload: "",
         });

         dispatch({
            type: RegisterColaboratorAction.setPhoneNumberDependent,
            payload: "",
         });

         dispatch({
            type: RegisterColaboratorAction.setCpfDependent,
            payload: "",
         });

         dispatch({
            type: RegisterColaboratorAction.setParentDependent,
            payload: "",
         });

         setChoosedUserDependent(null);
      } catch (error: any) {
         setIsLoading(false);
         toast.error("Falha na criação/atualização de um novo Dependente!");
      }
   };

   const handleEdit = (itemId: string) => {
      try {
         const selectedItem = userDependents.find((item: User) => item.user.id === itemId);
         if (selectedItem) {
            setEditDependent(true);
            setChoosedUserDependent(itemId);
            // Reset the form
            createColaboratorForm.reset();
            // Manually set the values of the form inputs
            dispatch({
               type: RegisterColaboratorAction.setNameDependent,
               payload: selectedItem.userByPVault.name ?? "",
            });

            const phoneNumber = selectedItem.userByPVault.phone_number && selectedItem.userByPVault.phone_number.replace("+55", "");
            if (phoneNumber) {
               dispatch({
                  type: RegisterColaboratorAction.setPhoneNumberDependent,
                  payload: String(phoneNumber),
               });
            }

            dispatch({
               type: RegisterColaboratorAction.setCpfDependent,
               payload: String(selectedItem.userByPVault.cpf) ?? "",
            });

            dispatch({
               type: RegisterColaboratorAction.setParentDependent,
               payload: String(selectedItem.user.parentesco) ?? "",
            });

            dispatch({
               type: RegisterColaboratorAction.setAccountTypeDependent,
               payload: selectedItem.user.user_type[0],
            });

            // Convertendo a data de nascimento para um objeto Date
            const birthDateStr = selectedItem.user.date_of_birth;
            if (birthDateStr) {
               const [year, month, day] = birthDateStr.split("-").map((str) => parseInt(str));
               const birthDateObj = new Date(year, month - 1, day); // Os meses no JavaScript começam do 0
               setBirthDate(birthDateObj);
            }
         }
      } catch (error: any) {
         throw new Error(error.message);
      }
   };

   const handleDateChange = (date: Date | null) => {
      setBirthDate(date);
      const formattedDate = date
         ? `${date.getDate().toString().padStart(2, "0")}/${(date.getMonth() + 1).toString().padStart(2, "0")}/${date.getFullYear()}`
         : "";
      dispatch({
         type: RegisterColaboratorAction.setBirthDate,
         payload: formattedDate,
      });
   };

   const handleDelete = async (itemId: string) => {
      const confirmDelete = window.confirm("Você realmente deseja deletar esse usuário?");
      if (!confirmDelete) {
         return;
      }

      setIsLoading(true); // Ative o indicador de carregamento

      try {
         // Tenta realizar a operação de deleção
         const response = await api.delete(`/users/${itemId}`);
         if (response.status === 204) {
            // Verifica se o status é 204
            toast.success("Dependente deletado com sucesso!");
            setUserDependents((prevItems) => prevItems.filter((item: any) => item.user.id !== itemId));
            setEditDependent(false);
            createColaboratorForm.reset();
            setBirthDate(new Date());
         } else {
            // Caso o status não seja 204, exibe um erro
            toast.error("Erro na deleção do usuário");
         }
      } catch (error) {
         // Captura e trata qualquer erro que ocorra durante a requisição
         console.error("Erro na deleção do dependente: ", error);
         toast.error("Erro na deleção do usuário");
      } finally {
         // Recarrega a lista de dependentes para refletir a deleção
         const updatedList = await api.get(`/users/usersDepen/list/${colaboratorID}`).then((res) => res.data);
         setUserDependents(updatedList.usersData);
         setIsLoading(false); // Desativa o indicador de carregamento
      }
   };

   function formatPhoneNumber(phoneNumber: string) {
      try {
         if (!phoneNumber) {
            return null;
         }
         const cleaned = phoneNumber.replace(/\D/g, ""); // Remove caracteres não numéricos
         const match = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/); // Captura os grupos de dígitos

         if (match) {
            return `(${match[1]})${match[2]}-${match[3]}`;
         }

         // Retorna o número sem formatação caso não corresponda ao padrão esperado
         return phoneNumber;
      } catch (error) {
         console.error("Erro em formatPhoneNumber", error);
         return null;
      }
   }

   const {
      handleSubmit,
      formState: { isSubmitting },
   } = createColaboratorForm;

   return (
      <>
         <MainContainer className='bg-[#03001A]'>
            <LeftBarContainer className='bg-[#03001A]'>
               <LeftSidebar />
            </LeftBarContainer>
            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, width: "480%" }}>
               <TopbarEnterprise hideAll />

               <Content className='bg-omni-gray rounded-[2rem] h-[100%] p-5 relative mb-10 mt-[-4.5rem]'>
                  <div className='flex justify-between items-center m-5'>
                     <h1 className='font-manrope font-bold font-regular text-5xl text-white justify-center mt-1'>
                        {!editDependent ? "Adicionar Dependente" : "Editar Dependente"}
                     </h1>
                  </div>
                  <RegisterColaboratorFormStepper />

                  <div className='border-t border-[#0517F5] -mt-5 ml-4 mr-4' />
                  <FormProvider {...createColaboratorForm}>
                     <form className='flex flex-col text-white gap-2 pl-4 mt-3' onSubmit={handleSubmit(onSubmit)}>
                        <div className='flex flex-row gap-x-10 pt-3 mb-3 pr-5'>
                           <div className='w-1/3'>
                              <label className='block mb-1'>Nome Completo *</label>
                              <input
                                 className='w-full bg-dark-omni rounded-md text-white px-3 py-2 h-14'
                                 style={{ backgroundColor: "#03001A" }}
                                 placeholder='ex: João Silva'
                                 type='text'
                                 value={state.nameDependent}
                                 onChange={(e) => {
                                    dispatch({
                                       type: RegisterColaboratorAction.setNameDependent,
                                       payload: e.target.value,
                                    });
                                 }}
                              />
                           </div>

                           <div className='w-1/3'>
                              <label className='block mb-1'>Telefone Celular</label>
                              <InputMask
                                 mask='(99) 9999-9999[9]' // A parte entre colchetes é opcional, permitindo 8 ou 9 dígitos.
                                 maskChar=''
                                 className='rounded-md pl-3 text-white h-14 w-full'
                                 style={{ backgroundColor: "#03001A" }}
                                 placeholder='(11) 98888-7777 ou (11) 8888-7777'
                                 type='text'
                                 value={state.phoneNumberDependent}
                                 onChange={(e) => {
                                    dispatch({
                                       type: RegisterColaboratorAction.setPhoneNumberDependent,
                                       payload: e.target.value,
                                    });
                                 }}
                              />
                           </div>
                        </div>

                        <div className='flex flex-row gap-x-10 pt-3 mb-3 pr-5'>
                           <div className='w-1/3'>
                              <label className='block mb-1'>CPF *</label>
                              <InputMask
                                 mask='999.999.999-99'
                                 maskChar=''
                                 className='bg-dark-omni rounded-md pl-3 text-white h-14 w-full'
                                 style={{ backgroundColor: "#03001A" }}
                                 placeholder='ex: 123.456.789-00'
                                 type='text'
                                 value={state.cpfDependent}
                                 onChange={(e) => {
                                    dispatch({
                                       type: RegisterColaboratorAction.setCpfDependent,
                                       payload: e.target.value,
                                    });
                                 }}
                              />
                           </div>

                           <div className='w-1/3'>
                              <label className='block mb-1'>Parentesco *</label>
                              <CustomSelectParentesco />
                           </div>
                        </div>

                        <div className='flex flex-row gap-x-10 pt-3 mb-3 pr-5'>
                           <div className='w-1/3'>
                              <label className='block mb-1'>Data de Nascimento *</label>
                              <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBRLocale}>
                                 <MuiDatePicker
                                    value={birthDate}
                                    onChange={handleDateChange}
                                    maxDate={new Date()}
                                    renderInput={(params) => (
                                       <CustomTextField
                                          {...params}
                                          id='birthDate'
                                          inputProps={{
                                             ...params.inputProps,
                                             placeholder: birthDate ? undefined : "dd/mm/aaaa",
                                          }}
                                       />
                                    )}
                                 />
                              </LocalizationProvider>
                           </div>
                        </div>

                        <div className='flex flex-row gap-3'>
                           <button
                              type='submit'
                              disabled={isSubmitting}
                              className={`bg-transparent border border-white text-white w-1/4 rounded-md mt-5 py-3 text-center disabled:bg-zinc-50 ${
                                 isLoading ? "loading-button" : ""
                              }`}
                              onClick={() => {
                                 handleSubmit(onSubmit);
                                 onSubmit();
                              }}
                           >
                              {isSubmitting ? "Salvando..." : "Salvar"}
                           </button>
                           <button
                              type='button'
                              disabled={isSubmitting}
                              className={`bg-[#0517F5] w-1/4 rounded-md mt-5 py-3 text-white text-center disabled:bg-zinc-50 ${
                                 isLoading ? "loading-button" : ""
                              }`}
                              onClick={() => {
                                 handleSubmit(onSubmit);
                                 onSubmit();
                              }}
                           >
                              {isSubmitting ? "Finalizando..." : "Finalizar"}
                           </button>
                        </div>
                     </form>
                  </FormProvider>

                  <div className='font-thin mt-10 px-9'>
                     <section className='rounded-lg'>
                        <table className='w-full rounded-[1.5rem] bg-[#04011B] px-4'>
                           <thead className='rounded-lg font-medium'>
                              <tr>
                                 <th className='py-2 px-4 font-medium text-center text-white rounded-lg'>#Qtd</th>
                                 <th className='py-2 px-4 font-medium text-center text-white rounded-lg'>Nome do Dependente</th>
                                 <th className='py-2 px-4 font-medium text-center text-white rounded-lg'>CPF</th>
                                 <th className='py-2 px-4 font-medium text-center text-white rounded-lg'>Telefone Celular</th>
                                 <th className='py-2 px-4 font-medium text-center text-white rounded-lg'>Parentesco</th>
                                 <th className='py-2 px-4 font-medium text-center text-white rounded-lg'>Ação</th>
                              </tr>
                           </thead>
                           <tbody className='rounded-b-lg text-white'>
                              {userDependents.length > 0
                                 ? userDependents.map((user: any, index) => (
                                      <tr key={index} style={{ borderBottom: index === userDependents.length - 1 ? "none" : "" }}>
                                         <td className='px-3 border-y text-left py-4 rounded-lg'>{index + 1}</td>
                                         <td className='px-3 border-y text-left py-4 rounded-lg border-x'>
                                            {user.userByPVault?.name ?? ""}
                                         </td>
                                         <td className='px-3 border-y text-left py-4 rounded-lg border-x'>{user.userByPVault?.cpf}</td>
                                         <td className='px-3 border-y text-left py-4 rounded-lg border-x'>
                                            {formatPhoneNumber(user.userByPVault?.phone_number) ?? ""}
                                         </td>
                                         <td className='px-3 border-y text-left py-4 rounded-lg border-x'>{user.user.parentesco}</td>
                                         <td className='px-3 border-y text-left py-4 rounded-lg'>
                                            <button onClick={() => handleEdit(user.user.id)} className='mr-4'>
                                               <img src='/editIcon.svg' className='h-87 w-8 text-blue-500' alt='Ícone de edição' />
                                            </button>
                                            <button onClick={() => handleDelete(user.user.id)}>
                                               <img src='/deleteIcon.svg' className='h-87 w-8 text-red-500' alt='Ícone de exclusão' />
                                            </button>
                                         </td>
                                      </tr>
                                   ))
                                 : null}
                           </tbody>
                        </table>
                     </section>
                  </div>
               </Content>
            </div>
         </MainContainer>

         <style>
            {`
               .loading-button::after {
                  content: "";
                  width: 20px;
                  height: 20px;
                  border: 4px solid rgba(255, 255, 255, 0.3);
                  border-radius: 50%;
                  border-top: 4px solid white;
                  animation: spin 1s linear infinite;
                  display: inline-block;
                  margin-left: 10px;
                  vertical-align: middle;  /* Aligns the spinner with the text */
               }
               
               @keyframes spin {
                  0% { transform: rotate(0deg); }
                  100% { transform: rotate(360deg); }
               }

               tr:last-child td {
                  border-bottom: none;
               }
            `}
         </style>
      </>
   );
}

export { CollaboratorDependent };
