import React from "react";

type Props = {
  className?: string;
};

const DownArrow = (props: Props) => {
  return (
    <svg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M13 1L7 7L1 0.999999' stroke='white' strokeWidth='2' />
    </svg>
  );
};

export default DownArrow;
