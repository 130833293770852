/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from "react";
import { Row, TableInstance } from "react-table";
import { useNavigate } from "react-router-dom";

export const TableLayout = ({ TableLayoutProps }: { TableLayoutProps: TableInstance }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const navigate = useNavigate();
  const [sortedData, setSortedData] = useState<any[]>([]);
  const [sortConfig, setSortConfig] = useState({ key: "enterprise_name", ascending: true });

  interface Original {
    [key: string]: string | number | Date; // Add other types as needed
  }

  interface Row {
    original: Original;
  }

  useEffect(() => {
    const sorted = sortData(TableLayoutProps.rows, sortConfig);
    setSortedData(sorted);
  }, [TableLayoutProps.rows, sortConfig]);

  const sortData = (data: Array<Row>, config: { ascending: boolean; key: string }) => {
    const { key, ascending } = config;

    const isDateTime = key === "dateTime";
    const isMonetary = key === "valor";
    const isAlphabetical = typeof data[0]?.original[key] === "string";

    const formatToISO = (dateStr: string) => {
      const [date, time] = dateStr.split(", ");
      const [day, month, year] = date.split("/");
      return `${year}-${month}-${day}T${time}:00`;
    };

    const formatToNumber = (monetaryStr: string) => {
      return parseFloat(monetaryStr.replace(/[^0-9.-]+/g, ""));
    };

    return [...data].sort((a: any, b: any) => {
      let valA = a.original[key];
      let valB = b.original[key];

      if (isDateTime) {
        valA = new Date(formatToISO(valA));
        valB = new Date(formatToISO(valB));
      } else if (isMonetary) {
        valA = formatToNumber(valA);
        valB = formatToNumber(valB);
      } else if (isAlphabetical) {
        return ascending ? valA.localeCompare(valB) : valB.localeCompare(valA);
      }

      if (valA < valB) return ascending ? -1 : 1;
      if (valA > valB) return ascending ? 1 : -1;
      return 0;
    });
  };

  const renderCell = (cell: any, row: any) => {
    let cellValue = cell.value;
    let cellClasses = "px-4 py-3 bg-[#03001A]";
    let cellColor = "";

    switch (cell.column.id) {
      case "id":
        cellValue = `#${cellValue.substring(0, 5)}`;
        cellClasses += " rounded-l-lg";
        break;
      case "account_status":
        cellClasses += " rounded-r-lg";
        break;
      default:
        break;
    }

    if (cellValue === "Ativa") {
      cellColor = "text-teal-400";
    } else if (cellValue === "Bloqueada") {
      cellColor = "text-omni-orange";
    } else {
      cellColor = "text-opaque-white";
    }

    return (
      <td {...cell.getCellProps()} className={cellClasses}>
        <span className='text-sm'>
          <span className={cellColor}>{cellValue}</span>
        </span>
      </td>
    );
  };

  const itemsPerPage = 9; // Número de itens por página
  const pageCount = Math.ceil(TableLayoutProps.rows.length / itemsPerPage);

  const visiblePageNumbers = Array.from({ length: pageCount }, (_, i) => i);

  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const currentRows = sortedData.slice(startIndex, endIndex);

  return (
    <div>
      <table
        {...TableLayoutProps.getTableProps()}
        className='w-full mt-5 rounded-md px-4 border-separate border-spacing-y-2'
      >
        <thead className='text-white mt-5'>
          {TableLayoutProps.headerGroups.map((headerGroup: any, index) => (
            <tr key={index} {...headerGroup.getHeaderGroupProps()} className='px-4 py-2'>
              {headerGroup.headers.map((column: any) => (
                <th
                  {...column.getHeaderProps()}
                  onClick={() => setSortConfig({ key: column.id, ascending: !sortConfig.ascending })}
                  className={`text-white font-light opacity-60 pl-4 mb-6 cursor-pointer text-left ${
                    column.id === "id" ? "w-20" : ""
                  }`}
                >
                  {column.render("Header")}
                  <span>{sortConfig.key === column.id ? (sortConfig.ascending ? " 🔽" : " 🔼") : ""}</span>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...TableLayoutProps.getTableBodyProps()}>
          {currentRows.map((row) => {
            TableLayoutProps.prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className='border-omni-gray border-t border-8 rounded-md h-12 cursor-pointer hover:bg-gray-800'
                style={{ height: "4.2rem" }}
                onClick={() => {
                  localStorage.setItem("choosedCompany", row.original.id); // Armazena o ID no localStorage
                  navigate("/cadastro-empresa/perfil-individual", { state: { enterprise: row.original } });
                }}
              >
                {row.cells.map((cell) => renderCell(cell, row))}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className='flex justify-end space-x-4 text-white mx-7 mt-3'>
        <button
          onClick={() => setCurrentPage((prevPage) => prevPage - 1)}
          disabled={currentPage === 0}
          className='rounded-lg px-4 py-2 font-inter font-medium text-base'
        >
          Anterior
        </button>
        {visiblePageNumbers.map((number) => (
          <button
            key={number}
            onClick={() => setCurrentPage(number)}
            disabled={currentPage === number}
            className={`${
              currentPage === number ? "bg-blue-500" : "bg-transparent"
            } rounded-lg px-4 py-2 font-inter font-medium text-base`}
          >
            {number + 1}
          </button>
        ))}
        <button
          onClick={() => setCurrentPage((prevPage) => prevPage + 1)}
          disabled={currentPage === pageCount - 1}
          className='rounded-lg px-4 py-2 font-inter font-medium text-base'
        >
          Próximo
        </button>
        <div className='flex items-center'>
          Página
          <strong className='ml-2'>
            {currentPage + 1} de {pageCount}
          </strong>
        </div>
      </div>
    </div>
  );
};
