import React, { useMemo } from "react";
import { useTable, useSortBy } from "react-table"; // Import useSortBy
import { TableLayout } from "./TableLayout";

export const TableInstance = ({ tableData }: any) => {
  const [columns, data] = useMemo(() => {
    const columns = [
      {
        Header: "Colaborador",
        accessor: "colaborador",
      },
      {
        Header: "Valor",
        accessor: "valor",
      },
      {
        Header: "Data e Hora",
        accessor: "dateTime",
      },
      {
        Header: "Farmácia",
        accessor: "local",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Ação",
        accessor: "action",
      },
    ];
    return [columns, tableData];
  }, [tableData]);

  const tableInstance = useTable(
    { columns, data: data },
    useSortBy, // Add this line for sorting functionality
  );

  return <TableLayout TableLayoutProps={tableInstance} />;
};
