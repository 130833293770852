import React from "react";
import { SvgIcon, SvgIconProps } from "@mui/material";

const AcquisitionsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M2.06185 1.41675C4.27042 -0.676673 7.08872 -0.223546 8.99956 1.59384C10.9058 -0.223872 13.6855 -0.650438 15.9179 1.40799C18.3658 3.66503 18.6602 7.51746 16.7748 10.2088C16.0631 11.2249 14.6784 12.7458 13.3535 14.1331C12.0114 15.5385 10.6725 16.8663 10.0123 17.515L9.99925 17.5278C9.9378 17.5882 9.85791 17.6668 9.78082 17.7312C9.68753 17.8091 9.55067 17.9084 9.36691 17.9692C9.12467 18.0493 8.86451 18.0493 8.62226 17.9692C8.43851 17.9084 8.30164 17.8091 8.20836 17.7312C8.13126 17.6668 8.05137 17.5882 7.98992 17.5278L7.9769 17.515C7.31671 16.8663 5.97782 15.5385 4.63563 14.1331C3.31073 12.7458 1.92611 11.2249 1.21432 10.2088C-0.678965 7.50606 -0.329706 3.68363 2.06185 1.41675Z'
          fill='white'
          fillOpacity='0.87'
        />
      </svg>
    </SvgIcon>
  );
};

export default AcquisitionsIcon;
