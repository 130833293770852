import React, { useEffect, useState } from "react";
import { ReactNode } from "react";
import Chatwoot from "../modais/ChatBot";
import { useLocation } from "react-router-dom";
import { VersionComponent } from "../VersionComponent";

type Props = {
  className?: string;
  children: ReactNode;
};

const MainContainer = ({ children, className = "" }: Props) => {
  const location = useLocation();
  const [showChatwoot, setShowChatwoot] = useState(true);

  useEffect(() => {
    setShowChatwoot(location.pathname !== "/");
  }, [location.pathname]);

  return (
    <section className={`grid grid-cols-6 grid-flow-col ${className} `}>
      {/* <ProfilePicture /> */}
      {children}
      {showChatwoot && <Chatwoot />}
      <VersionComponent />
    </section>
  );
};

export default MainContainer;