/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useLayoutEffect, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { LeftSidebar } from "../../components/LeftSidebar";
import { useRegisterEnterprise } from "../../hooks/RegisterEnterpriseContext";
import { RegisterEnterpriseAction } from "../../types/RegisterEnterpriseTypes";
import { CustomSelectAccount } from "../../components/CustomSelectAccount";
import { RegisterEnterpriseSchemaStepOne, RegisterEnterpriseStepOneSchemaType } from "../../utils/schemas/RegisterEnterpriseSchema";
import { RegisterEnterpriseFormStepper } from "../../components/RegisterEnterpriseFormStepper";
import { TopbarEnterprise } from "../../components/TopbarEnterprise";
import MainContainer from "../../components/layout/MainContainer";
import LeftBarContainer from "../../components/layout/LeftBarContainer";
import Content from "../../components/layout/Content";
import InputMask from "react-input-mask";
import { CustomSelectState } from "../../components/CustomSelectState";
import axios from "axios";
import { api } from "../../utils/services/api";
import DeleteIcon from "../../components/icons/DeleteIcon";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import CustomTextField from "../../components/layout/CustomTextField";

export const EnterpriseRegister = () => {
   const location = useLocation();

   const { enterprise } = location.state ? location.state : { enterprise: null };

   const { state, dispatch } = useRegisterEnterprise();
   const navigate = useNavigate();
   const [inicioVigencia, setInicioVigencia] = useState<Date | null>(null);
   const [fimVigencia, setFimVigencia] = useState<Date | null>(null);

   useEffect(() => {
      if (location.pathname === "/cadastro-empresa/dados-cadastrais") {
         dispatch({
            type: RegisterEnterpriseAction.setEnterpriseId,
            payload: "",
         });
         dispatch({
            type: RegisterEnterpriseAction.setEnterpriseName,
            payload: "",
         });
         dispatch({
            type: RegisterEnterpriseAction.setCnpj,
            payload: "",
         });
         dispatch({
            type: RegisterEnterpriseAction.setEnterpriseCep,
            payload: "",
         });
         dispatch({
            type: RegisterEnterpriseAction.setEnterpriseAddress,
            payload: "",
         });
         dispatch({
            type: RegisterEnterpriseAction.setEnterpriseAddressNumber,
            payload: "",
         });
         dispatch({
            type: RegisterEnterpriseAction.setEnterpriseComplement,
            payload: "",
         });
         dispatch({
            type: RegisterEnterpriseAction.setEnterpriseCity,
            payload: "",
         });
         dispatch({
            type: RegisterEnterpriseAction.setEnterpriseState,
            payload: "",
         });
         dispatch({
            type: RegisterEnterpriseAction.setEnterpriseAccountStatus,
            payload: "Ativa",
         });
         dispatch({
            type: RegisterEnterpriseAction.setRepresentativesFullName,
            payload: "",
         });
         dispatch({
            type: RegisterEnterpriseAction.setRepresentativesCorporateEmail,
            payload: "",
         });
         dispatch({
            type: RegisterEnterpriseAction.setEnterpriseCep,
            payload: "",
         });
      }
   }, [location]);

   useLayoutEffect(() => {
      dispatch({
         type: RegisterEnterpriseAction.setCurrentStep,
         payload: 0,
      });
   }, []);

   useEffect(() => {
      if (enterprise) {
         try {
            dispatch({
               type: RegisterEnterpriseAction.setEnterpriseId,
               payload: enterprise.id || state.enterpriseId,
            });
            dispatch({
               type: RegisterEnterpriseAction.setEnterpriseName,
               payload: enterprise.enterprise_name || state.enterpriseName,
            });
            dispatch({
               type: RegisterEnterpriseAction.setCnpj,
               payload: enterprise.cnpj || state.cnpj,
            });
            dispatch({
               type: RegisterEnterpriseAction.setEnterpriseCep,
               payload: enterprise.enterprise_cep || state.enterpriseCep,
            });
            dispatch({
               type: RegisterEnterpriseAction.setEnterpriseAddress,
               payload: enterprise.address || state.enterpriseAddress,
            });
            dispatch({
               type: RegisterEnterpriseAction.setEnterpriseAddressNumber,
               payload: enterprise.enterprise_number || state.enterpriseAddressNumber,
            });
            dispatch({
               type: RegisterEnterpriseAction.setEnterpriseComplement,
               payload: enterprise.enterprise_complement || state.enterpriseComplement,
            });
            dispatch({
               type: RegisterEnterpriseAction.setEnterpriseCity,
               payload: enterprise.enterprise_city || state.enterpriseCity,
            });
            dispatch({
               type: RegisterEnterpriseAction.setEnterpriseState,
               payload: enterprise.enterprise_state || state.enterpriseState,
            });
            dispatch({
               type: RegisterEnterpriseAction.setEnterpriseAccountStatus,
               payload: enterprise.account_status || state.enterpriseAccountStatus,
            });
            dispatch({
               type: RegisterEnterpriseAction.setRepresentativesFullName,
               payload: enterprise.representatives_name || "",
            });
            dispatch({
               type: RegisterEnterpriseAction.setRepresentativesCorporateEmail,
               payload: enterprise.representative_corporate_email || "",
            });
            dispatch({
               type: RegisterEnterpriseAction.setEnterpriseCep,
               payload: enterprise.enterprise_cep || state.enterpriseCep,
            });
            dispatch({
               type: RegisterEnterpriseAction.setInicioVigencia,
               payload: enterprise.inicio_vigencia,
            });
            dispatch({
               type: RegisterEnterpriseAction.setFimVigencia,
               payload: enterprise.fim_vigencia,
            });

            enterprise.inicio_vigencia && setInicioVigencia(new Date(`${enterprise.inicio_vigencia}T03:00:00Z`));

            enterprise.fim_vigencia && setFimVigencia(new Date(`${enterprise.fim_vigencia}T03:00:00Z`));
         } catch (error: any) {
            toast.error(error.res?.data?.message || "Ocorreu um erro inesperado.");
         }
      }
   }, [enterprise]);

   const handleNextStep = async (data: any) => {
      try {
         const enterpriseData = {
            enterprise_name: data.enterpriseName,
            cnpj: data.cnpj,
            representative_name: data.representativesFullName,
            representative_corporate_email: data.representativesCorporateEmail,
            enterprise_cep: data.enterpriseCep,
            address: data.enterpriseAddress,
            enterprise_number: data.enterpriseAddressNumber,
            enterprise_complement: data.enterpriseComplement,
            enterprise_city: data.enterpriseCity,
            enterprise_state: data.enterpriseState,
            account_status: data.enterpriseAccountStatus,
            inicio_vigencia: data.inicioVigencia,
            fim_vigencia: fimVigencia,
         };

         if (!data?.enterpriseId) {
            const responseUser = await api.post(`/businessAcc/`, enterpriseData);
            toast.success("Nova empresa criada com sucesso!");

            dispatch({
               type: RegisterEnterpriseAction.setEnterpriseId,
               payload: responseUser.data.business,
            });

            localStorage.setItem("choosedCompany", responseUser.data.business.id);

            navigate("/cadastro-empresa/representantes");
         } else {
            await api.patch(`/businessAcc/${data.enterpriseId}`, enterpriseData);

            toast.success("Empresa atualizada com sucesso!");

            navigate("/cadastro-empresa/representantes");
         }
      } catch (error: any) {
         toast.error("Erro ao salvar os dados da Empresa.");
         console.log(error);
      }
   };

   const createEnterpriseForm = useForm<RegisterEnterpriseStepOneSchemaType>({
      resolver: zodResolver(RegisterEnterpriseSchemaStepOne),
   });

   const handleStartDateChange = (date: Date | null) => {
      setInicioVigencia(date);
      const formattedDate = date
         ? `${date.getDate().toString().padStart(2, "0")}/${(date.getMonth() + 1).toString().padStart(2, "0")}/${date.getFullYear()}`
         : "";
      dispatch({
         type: RegisterEnterpriseAction.setInicioVigencia,
         payload: formattedDate,
      });
   };
   const handleEndDateChange = (date: Date | null) => {
      setFimVigencia(date);
      const formattedDate = date
         ? `${date.getDate().toString().padStart(2, "0")}/${(date.getMonth() + 1).toString().padStart(2, "0")}/${date.getFullYear()}`
         : "";
      dispatch({
         type: RegisterEnterpriseAction.setFimVigencia,
         payload: formattedDate,
      });
   };

   const {
      handleSubmit,
      formState: { isSubmitting },
   } = createEnterpriseForm;

   const checkCep = async (cep: string) => {
      const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
      dispatch({
         type: RegisterEnterpriseAction.setEnterpriseAddress,
         payload: data.logradouro,
      });
      dispatch({
         type: RegisterEnterpriseAction.setEnterpriseCity,
         payload: data.localidade,
      });
      dispatch({
         type: RegisterEnterpriseAction.setEnterpriseState,
         payload: data.uf,
      });
   };

   const handleDeleteEnterprise = async () => {
      try {
         await api.patch(`/businessAcc/soft-delete/${state.enterpriseId}`);
         navigate("/empresas");
      } catch (error) {
         console.error("Error deleting company:", error);
      }
   };

   const handleDelete = async () => {
      if (window.confirm("Deseja excluir a empresa?")) {
         toast.promise(
            handleDeleteEnterprise,
            {
               pending: "Deletando a empresa...",
               success: "Empresa deletada com sucesso!",
               error: "Erro ao deletar a empresa.",
            },
            { hideProgressBar: true },
         );
      }
   };

   return (
      <>
         <MainContainer className='bg-[#03001A]'>
            <LeftBarContainer className='bg-[#03001A]'>
               <LeftSidebar />
            </LeftBarContainer>
            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, width: "480%" }}>
               <TopbarEnterprise hideAll />

               <Content className='bg-omni-gray rounded-[2rem] h-[100%] p-5 relative mb-10 mt-[-4.5rem]'>
                  <div className='flex justify-between items-center m-5 mt-4'>
                     <h1 className='font-manrope font-bold font-regular text-5xl text-white justify-center mt-1'>
                        {location.pathname === "/cadastro-empresa/perfil-individual" && enterprise?.id
                           ? enterprise.enterprise_name
                           : "Adicionar Empresa"}
                     </h1>
                     <div onClick={handleDelete}>
                        <DeleteIcon />
                     </div>
                  </div>

                  <div className='m-5'>
                     <RegisterEnterpriseFormStepper currentStep={0} />
                     <div className='border-t border-[#0517F5] -mt-4'></div>
                     <FormProvider {...createEnterpriseForm}>
                        <form className='flex flex-col text-opaque-white gap-3 flex-grow mt-4' onSubmit={handleSubmit(handleNextStep)}>
                           <div className='flex flex-row gap-x-10 pt-3 mb-3'>
                              <div className='w-1/3'>
                                 <label className='flex flex-col gap-y-2 mb-1'>
                                    Nome da Empresa
                                    <input
                                       value={state.enterpriseName}
                                       className='rounded-md pl-3 text-white h-14 mt-2 bg-input-register'
                                       placeholder='ex: Omni Saúde LTDA'
                                       type='text'
                                       onChange={(e) => {
                                          dispatch({
                                             type: RegisterEnterpriseAction.setEnterpriseName,
                                             payload: e.target.value,
                                          });
                                       }}
                                    />
                                 </label>
                              </div>

                              <div className='w-1/3'>
                                 <label className='flex flex-col gap-y-2 mb-1'>
                                    CNPJ
                                    <InputMask
                                       value={state.cnpj}
                                       mask='99.999.999/9999-99'
                                       maskChar=''
                                       className='rounded-md pl-3 text-white h-14 mt-2 bg-input-register'
                                       placeholder='ex: 00.000.000/0000-00'
                                       type='text'
                                       onChange={(e) => {
                                          dispatch({
                                             type: RegisterEnterpriseAction.setCnpj,
                                             payload: e.target.value,
                                          });
                                       }}
                                    />
                                 </label>
                              </div>
                           </div>

                           <div className='flex flex-row gap-x-10 mb-3'>
                              <div className='w-1/3'>
                                 <label className='flex flex-col gap-y-2 mb-1'>
                                    CEP
                                    <InputMask
                                       value={state.enterpriseCep || ""}
                                       mask='99999-999'
                                       maskChar=''
                                       className='rounded-md pl-3 text-white h-14 mt-2 bg-input-register'
                                       placeholder='ex: 00000-000'
                                       type='text'
                                       onBlur={(e) => {
                                          checkCep(e.target.value);
                                       }}
                                       onChange={(e) => {
                                          dispatch({
                                             type: RegisterEnterpriseAction.setEnterpriseCep,
                                             payload: e.target.value,
                                          });
                                       }}
                                    />
                                 </label>
                              </div>
                              <div className='w-1/3'>
                                 <label className='flex flex-col gap-y-2 mb-1'>
                                    Logradouro
                                    <input
                                       value={state.enterpriseAddress}
                                       className='rounded-md pl-3 text-white h-14 mt-2 bg-input-register'
                                       placeholder='ex: Rua dos Bobos'
                                       type='text'
                                       onChange={(e) => {
                                          dispatch({
                                             type: RegisterEnterpriseAction.setEnterpriseAddress,
                                             payload: e.target.value,
                                          });
                                       }}
                                    />
                                 </label>
                              </div>
                              <div className='w-1/6'>
                                 <label className='flex flex-col gap-y-2 mb-1 '>
                                    Número
                                    <input
                                       value={Number(state.enterpriseAddressNumber)}
                                       className='rounded-md pl-3 text-white h-14 mt-2 bg-input-register'
                                       placeholder='ex: 0'
                                       type='text'
                                       onChange={(e) => {
                                          dispatch({
                                             type: RegisterEnterpriseAction.setEnterpriseAddressNumber,
                                             payload: e.target.value,
                                          });
                                       }}
                                    />
                                 </label>
                              </div>
                           </div>

                           <div className='flex flex-row gap-x-10 mb-3'>
                              <div className='w-1/3'>
                                 <label className='flex flex-col gap-y-2 mb-1'>
                                    Complemento
                                    <input
                                       value={state.enterpriseComplement || ""}
                                       className='rounded-md pl-3 text-white h-14 mt-2 bg-input-register'
                                       placeholder='Ap 11, Torre 1'
                                       type='text'
                                       onChange={(e) => {
                                          dispatch({
                                             type: RegisterEnterpriseAction.setEnterpriseComplement,
                                             payload: e.target.value,
                                          });
                                       }}
                                    />
                                 </label>
                              </div>
                              <div className='w-1/3'>
                                 <label className='flex flex-col gap-y-2 mb-1'>
                                    Cidade
                                    <input
                                       value={state.enterpriseCity || ""}
                                       className='rounded-md pl-3 text-white h-14 mt-2 bg-input-register'
                                       placeholder='ex: São Paulo'
                                       type='city'
                                       onChange={(e) => {
                                          dispatch({
                                             type: RegisterEnterpriseAction.setEnterpriseCity,
                                             payload: e.target.value,
                                          });
                                       }}
                                    />
                                 </label>
                              </div>
                              <div className='w-1/6'>
                                 <div className='flex flex-col text-opaque-white '>
                                    <label className='mb-2'>Estado</label>
                                    <CustomSelectState
                                       data={state}
                                       RegisterEnterpriseAction={RegisterEnterpriseAction}
                                       dispatch={dispatch}
                                    />
                                 </div>
                              </div>
                           </div>
                           <div className='flex flex-row gap-x-10 pt-3 mb-3'>
                              <div className='w-1/3'>
                                 <label className='flex flex-col gap-y-2 mb-1'>
                                    Status da conta
                                    <CustomSelectAccount
                                       data={state}
                                       RegisterEnterpriseAction={RegisterEnterpriseAction}
                                       dispatch={dispatch}
                                    />
                                 </label>
                              </div>
                              <div className='h-2'>
                                 <label className='flex flex-col gap-y-2 mb-4'>Inicio do contrato</label>
                                 <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <MuiDatePicker
                                       inputFormat='dd/MM/yyyy'
                                       value={inicioVigencia}
                                       onChange={(e) => {
                                          handleStartDateChange(e);
                                       }}
                                       renderInput={(params) => (
                                          <CustomTextField
                                             {...params}
                                             id='startDate'
                                             inputProps={{
                                                ...params.inputProps,
                                             }}
                                          />
                                       )}
                                    />
                                 </LocalizationProvider>
                              </div>
                              <div>
                                 <label className='flex flex-col gap-y-2 mb-4'>Fim do contrato</label>
                                 <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <MuiDatePicker
                                       inputFormat='dd/MM/yyyy'
                                       value={fimVigencia}
                                       onChange={(e) => {
                                          handleEndDateChange(e);
                                       }}
                                       renderInput={(params) => (
                                          <CustomTextField
                                             {...params}
                                             id='endDate'
                                             inputProps={{
                                                ...params.inputProps,
                                             }}
                                          />
                                       )}
                                    />
                                 </LocalizationProvider>
                              </div>
                           </div>
                           <button
                              type='button'
                              disabled={isSubmitting}
                              className='bg-omni-blue w-1/4 rounded-md mt-5 py-3 text-opaque-white text-center disabled:bg-zinc-50'
                              onClick={() => {
                                 handleSubmit(handleNextStep);
                                 handleNextStep(state);
                              }}
                           >
                              Próximo
                           </button>
                        </form>
                     </FormProvider>
                  </div>
               </Content>
            </div>
         </MainContainer>
      </>
   );
};
