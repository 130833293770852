declare global {
  interface Window {
    chatwootSDK: any;
  }
}

import { useEffect } from "react";

const Chatwoot = () => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://chat.opencloudx.io/packs/js/sdk.js";
    script.async = true;

    script.onload = () => {
      if (window.chatwootSDK) {
        window.chatwootSDK.run({
          websiteToken: "sXLNKdAV477KxuEFGyNcBMiD",
          baseUrl: "https://chat.opencloudx.io",
        });
      }
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null; // This component doesn't render anything
};

export default Chatwoot;
