import React from "react";

export const CoveragePlansTable = ({ plans }: any) => {
  const formatPlanCode = (code: string) => {
    let planName = "";
    let credits = "";

    if (code.startsWith("R")) {
      planName = "Royal";
    } else if (code.startsWith("C")) {
      planName = "Celeste";
    } else if (code.startsWith("T")) {
      planName = "Turquesa";
    }

    credits = code.match(/\d+/)?.[0] || ""; // Extract numerical characters

    return `${planName} ${credits}`;
  };

  return (
    <div className='w-full ml-5' style={{ marginTop: "1rem", paddingLeft: "1.2rem", width: "100%" }}>
      {/* Aumento na largura */}
      <h2 className='pb-5 text-white pl-3' style={{ fontFamily: "Manrope", fontSize: "1rem", fontWeight: 500 }}>
        Limites de Gastos Mensais
      </h2>
      <table
        style={{
          width: "90%",
          background: "linear-gradient(0deg, #03001A, #03001A)",
          borderRadius: "20px",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr style={{ color: "#fff" }}>
            <th
              style={{
                width: "80%",
                borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
                borderRight: "1px solid rgba(255, 255, 255, 0.3)",
                textAlign: "left",
                padding: "1rem",
              }}
            >
              Planos de Cobertura
            </th>
            <th style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.3)", padding: "1rem" }}>Limite</th>
          </tr>
        </thead>
        <tbody>
          {plans?.length > 0 &&
            plans.map((row: any, index: any) => (
              <tr
                key={index}
                style={{
                  color: "#fff",
                  borderBottom: index !== plans?.length - 1 ? "1px solid rgba(255, 255, 255, 0.3)" : "none",
                }}
              >
                <td style={{ padding: "1rem", borderRight: "1px solid rgba(255, 255, 255, 0.3)" }}>
                  {formatPlanCode(row.plan_code)}
                </td>
                <td style={{ padding: "1rem" }}>
                  {parseFloat(row.individual_monthly_limit)?.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
