/* eslint-disable react/react-in-jsx-scope */
import { useState, FormEvent, useEffect, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsGoogle } from "react-icons/bs";
import Logo from "../assets/omni-logo.svg";
import RightImage from "../assets/Ilustrações-omni login-right.svg";
import { supabaseConfig } from "../config/supabaseConfig";
import "./Login.styles.css";
import { api } from "../utils/services/api";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../components/AuthContext";
import { VersionComponent } from "../components/VersionComponent";

export function useFirstRender() {
  const isFirstRender = useRef(true);

  useEffect(() => {
    isFirstRender.current = false;
  }, []);

  return isFirstRender.current;
}

const Login = () => {
  const navigate = useNavigate();
  const { errorMessage } = useAuth();

  const isFirstRender = useFirstRender();
  const [toastShown, setToastShown] = useState(false);

  // const supabaseCode = extractDatabase(import.meta.env.VITE_SUPABASE_URL);

  useEffect(() => {
    const currentPath = window.location.pathname;

    // Skip the first render and if on the login page
    if (isFirstRender || currentPath === "/") return;

    // Your existing logic here
    if (errorMessage && !toastShown) {
      toast.error(errorMessage);
      setToastShown(true);
    }
  }, [errorMessage, isFirstRender]);

  useEffect(() => {
    if (isFirstRender || !errorMessage) {
      setToastShown(false);
    }
  }, [isFirstRender, errorMessage]);

  const [email, setEmail] = useState("");

  const parseMessage = (msg: string) => {
    const regex = /['"]+/g;
    msg = msg.replace(regex, "");
    return msg;
  };

  const handleSubmit = async (e: FormEvent<HTMLElement>) => {
    e.preventDefault();
    const { data, error } = await supabaseConfig.auth.signInWithOtp({
      email: email,
      options: {
        shouldCreateUser: false,
        emailRedirectTo: window.location.origin + "/oauth/callback",
      },
    });

    if (error) {
      toast.error(parseMessage("Erro ao fazer login. Por favor, tente novamente em alguns instantes."));
    } else {
      toast.success("Um magic link chegou no seu email!");
    }

    if (import.meta.env.TEST_ENVIRONMENT) {
      const response = await api.get(`/login/${email}`, {
        headers: { supa: localStorage.getItem("sb-jeixovvfrpfzvpjobvbb-auth-token") },
      });
      localStorage.setItem("previousPath", "/");
      localStorage.setItem("userEmail", email);
      localStorage.setItem("userID", response.data.user);
      localStorage.setItem("linkedCompany", response.data.linkedCompany);
      localStorage.setItem("userType", response.data.userType[0]);
      localStorage.setItem("magicLink", JSON.stringify(true));
      localStorage.setItem("apitoken", response.data.apitoken ?? "");
      localStorage.setItem("holdingCnpj", response.data.holdingCnpj);
      localStorage.setItem("holdingID", response.data.linkedCompany);
    }
  };

  const handleGoogleOAuth = async () => {
    try {
      await supabaseConfig.auth.signInWithOAuth({
        provider: "google",
        options: {
          redirectTo: window.location.origin + "/oauth/callback",
        },
      });
    } catch (error) {
      console.error("Error during sign in:", error);
    }
  };

  useEffect(() => {
    const thresholdInSeconds = 10;
    const currentTime = new Date().getTime();
    const pageAccessedTime = localStorage.getItem("pageAccessed");
    localStorage.removeItem("userEdition");

    if (!pageAccessedTime) {
      localStorage.setItem("pageAccessed", currentTime.toString());
      window.location.reload();
      return;
    }

    if (currentTime - parseInt(pageAccessedTime) < thresholdInSeconds * 1000) {
      const userID = localStorage.getItem("userID");
      if (userID) {
        api.get(`/users/${userID}`).then((response: any) => {
          if (localStorage.getItem("magicLink")) {
            localStorage.removeItem("magicLink");
            navigate("/dashboard");
          }
        });
      } else {
        localStorage.removeItem("userID");
        localStorage.removeItem("magicLink");
      }

      return;
    }

    // Reset the timestamp if outside the threshold
    localStorage.setItem("pageAccessed", currentTime.toString());
    window.location.reload();
  }, []);

  return (
    <>
      <ToastContainer />
      <div className='bg-dark-omni flex h-screen'>
        <div className='left-column bg-[#03001A] h-full flex flex-col justify-center'>
          <div className='image-container flex justify-center'>
            <div className='w-[80%] flex'>
              <img src={Logo} alt='Omni Logo' className='mb-4 opacity-87 w-[80%]' />
            </div>
            <form className='flex flex-col gap-4 w-[80%]' onSubmit={handleSubmit}>
              <label className='text-opaque-white text-sm'>E-mail</label>
              <input
                type='text'
                name='email'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className='text-white text-opacity-60 px-4 py-1 rounded-md h-10 bg-[#111627] outline-1 w-full'
                placeholder='Digite seu e-mail'
              />

              <button className='bg-[#0517F5] text-white rounded-md h-10 w-full' type='submit'>
                Entrar
              </button>
              <button
                id='google-login'
                className='align-middle text-dark-omni justify-center bg-opaque-white rounded-md h-10 mt-3 justify-center w-full'
                type='button'
                onClick={handleGoogleOAuth}
              >
                <div className='flex flex-row justify-center gap-3'>
                  <BsGoogle className='flex mt-1' />
                  <span className='inline-flex'> Entrar com Google </span>
                </div>
              </button>
              <p className='mt-4 text-opaque-white text-sm'>
                Ao clicar no botão entrar, você está concordando os nossos{" "}
                <a
                  href='https://www.omnisaude.app/termos'
                  className='font-bold underline'
                  target='_blank'
                  rel='noopener noreferrer'
                >Termos de uso{" "}
                </a>
                e{" "}
                <a
                  href='https://www.omnisaude.app/politica-de-privacidade'
                  className='font-bold underline'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Política de privacidade
                </a>
              </p>
              <p className='mt-4 text-opaque-white text-sm'>
                Não possui conta?{" "}
                <a
                  href='https://e4cwjwxhv3e.typeform.com/to/AJe1WWpt'
                  className='font-bold underline'
                  target='_blank'
                  rel='noopener noreferrer'
                >
                  Peça para sua Empresa
                </a>
              </p>
            </form>
          </div>
        </div>
        <div className='right-column bg-[#F6F8FE]'>
          <div className='h-full flex justify-center items-center'>
            <img src={RightImage} alt='Illustration' className='responsive-image' />
          </div>
        </div>
      </div>
    <VersionComponent />

    </>
  );
};

export { Login };
