import React, { useEffect, useState } from "react";
import { useRegisterColaborator } from "../../hooks/ColaboratorContext";
import { RegisterColaboratorAction } from "../../types/CollaboratorDataTypes";
import { api } from "../../utils/services/api";
import { useLocation } from "react-router-dom"; // Passo 1

interface CustomSelectPlansProps {
  initialPlanId?: string;
  isEditing?: boolean;
}

export function CustomSelectPlans({ initialPlanId, isEditing }: CustomSelectPlansProps) {
  const { state, dispatch } = useRegisterColaborator();
  const [plans, setPlans] = useState<any[]>([]);
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);

  useEffect(() => {
    const getPlans = async () => {
      try {
        const isRepresentativesRoute = location.pathname === "/cadastro-empresa/representantes";
        const companyID = isRepresentativesRoute
          ? localStorage.getItem("choosedCompany") // Passo 3
          : localStorage.getItem("linkedCompany");
        const { data } = await api.get(`/businessAcc/${companyID}/plans`);
        setPlans(data.plansWithUserCount || []);

        // Define o plano inicial baseado no initialPlanId ou no primeiro plano da lista
        let initialPlan = data.plansWithUserCount.find((plan: any) => plan.id === initialPlanId);
        if (!initialPlan && data.plansWithUserCount.length > 0) {
          // Caso não haja um initialPlanId, use o primeiro plano da lista
          initialPlan = data.plansWithUserCount[0];
        }

        if (initialPlan) {
          setSelectedPlan(initialPlan.id);
          localStorage.setItem("planSelectedId", initialPlan.id);
          localStorage.setItem("planSelectedCode", initialPlan.plan_code);

          dispatch({
            type: RegisterColaboratorAction.setAssociatedPlan,
            payload: initialPlan.plan_code,
          });
        }
      } catch (error) {
        console.error("Error fetching plans:", error);
      }
    };

    getPlans();
  }, [initialPlanId, dispatch]);

  const handlePlanChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedPlanId = e.target.value;
    setSelectedPlan(selectedPlanId);

    const selectedPlanDetail = plans.find((plan: any) => plan.id === selectedPlanId);
    if (selectedPlanDetail) {
      localStorage.setItem("planSelectedId", selectedPlanId);
      localStorage.setItem("planSelectedCode", selectedPlanDetail.plan_code);

      dispatch({
        type: RegisterColaboratorAction.setAssociatedPlan,
        payload: selectedPlanDetail.plan_code,
      });
    }
  };

  const formatPlanCode = (code: string) => {
    let planName = "";
    const credits = code.match(/\d+/)?.[0] || ""; // Extract numerical characters

    if (code.startsWith("R")) {
      planName = "Royal";
    } else if (code.startsWith("C")) {
      planName = "Celeste";
    } else if (code.startsWith("T")) {
      planName = "Turquesa";
    }

    return `${planName} ${credits}`;
  };

  return (
    <select
      className='w-full rounded-md text-opaque-white px-3 py-2 h-14 mt-1'
      style={{ backgroundColor: "#03001A" }}
      value={selectedPlan || state.associatedPlan}
      onChange={handlePlanChange}
      disabled={isEditing}
    >
      <option value='' disabled>
        Selecione
      </option>
      {plans.map((plan: any) => (
        <option key={plan.id} value={plan.id}>
          {plan.description_or_reference}
        </option>
      ))}
    </select>
  );
}
