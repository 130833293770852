import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

const CustomTextField = styled(TextField)({
   backgroundColor: "#03001A", // Cor de fundo
   borderRadius: "0.375rem", // borda arredondada (rounded-md)
   color: "white", // cor do texto (text-white)

   height: "3.5rem", // altura (h-14)
   marginTop: "0.5rem", // margem superior (mt-2)
   // Estilos para o input
   "& .MuiInputBase-input": {
      color: "white", // Isso garante que o texto digitado seja branco
   },

   // Estilos para o placeholder
   "& .MuiInputBase-input::placeholder": {
      color: "white", // Isso garante que o placeholder seja branco
      opacity: 1, // Opcional: ajusta a opacidade do placeholder se necessário
   },

   // Estilos para o ícone do calendário
   "& .MuiSvgIcon-root": {
      color: "white", // Isso garante que o ícone do calendário seja branco
   },
});

export default CustomTextField;
