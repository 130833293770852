import Logo from "../assets/omni-logo-RGB-dash.svg";
import React from "react";
import { Link } from "react-router-dom";

export const OmniLogo = () => {
  return (
    <>
      <div className='bg-[#03001A] logo-container'>
        <Link to='/dashboard' className='bg-[#03001A]'>
          <img src={Logo} alt='Omni Logo' className='omni-logo' />
        </Link>
      </div>

      <style>
        {`
          .logo-container {
            display: flex;
            align-items: center;
          }
          .omni-logo {
            max-width: 20vw; /* max width as a percentage of viewport width */
            min-width: 200px; /* minimum width to prevent it from becoming too small */
            height: auto; /* maintain aspect ratio */
            filter: opacity(80%);
          }
        `}
      </style>
    </>
  );
};
