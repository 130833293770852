import React, { createContext, useContext, useReducer } from "react";
import { TAction, TContext, TAddPlan, AddPlanAction } from "../types/AddPlansTypes";

const initialData: TAddPlan = {
  currentStep: 0,
  activePlansCode: "",
  descriptionOrReference: "",
  globalLimitPerEnterprise: "",
  individualLimit: "",
  boxesNumber: "",
  accountStatus: "Ativa",
  acquisitionAllowed: false,
  authorizedList: [],
  acquisitionExcludeList: {
    ean: "",
    associated_pathologies: "",
    anvisa_code: "",
    abc_farma_code: "",
    ans_code_tt: "",
    product_type: undefined,
    stripe: "",
    lab_name: "",
    pack_quantity: "",
    active_ingredient: "",
  },
  varyingReinbursementAllowed: false,
  varyingReinbursementAuthorizedList: [],
  varyingReinbursementPercentage: 0,
  varyingRefundExcludeList: {},
  fixatedReinbursementAllowed: false,
  fixatedReinbursementAuthorizedList: [],
  fixatedReinbursementValue: 0,
  fixedRefundExcludeList: {},
};

export const AddPlanContext = createContext<TContext | undefined>(undefined);

const addPlanReducer = (state: TAddPlan | any, action: TAction) => {
  switch (action.type) {
    case AddPlanAction.enum.setCurrentStep:
      return { ...state, currentStep: action.payload };
    case AddPlanAction.enum.setActivePlansCode:
      return { ...state, activePlansCode: action.payload };
    case AddPlanAction.enum.setDescriptionOrReference:
      return { ...state, descriptionOrReference: action.payload };
    case AddPlanAction.enum.setGlobalLimitPerEnterprise:
      return { ...state, globalLimitPerEnterprise: action.payload };
    case AddPlanAction.enum.setIndividualLimit:
      return { ...state, individualLimit: action.payload };
    case AddPlanAction.enum.setAccountStatus:
      return { ...state, accountStatus: action.payload };
    case AddPlanAction.enum.setAcquisitionAllowed:
      return { ...state, acquisitionAllowed: action.payload };
    case AddPlanAction.enum.setAuthorizedList:
      return { ...state, authorizedList: action.payload };
    case AddPlanAction.enum.setBoxesNumber:
      return { ...state, boxesNumber: action.payload };
    case AddPlanAction.enum.setAcquisitionExcludeList:
      return {
        ...state,
        acquisitionExcludeList: {
          ...state.acquisitionExcludeList,
          [action.payload.key]: state.acquisitionExcludeList[action.payload.key]
            ? [...state.acquisitionExcludeList[action.payload.key], action.payload.value]
            : [action.payload.value],
        },
      };
    case AddPlanAction.enum.setVaryingReinbursementAllowed:
      return { ...state, varyingReinbursementAllowed: action.payload };
    case AddPlanAction.enum.setVaryingReinbursementAuthorizedList:
      return { ...state, varyingReinbursementAuthorizedList: action.payload };
    case AddPlanAction.enum.setVaryingReinbursementPercentage:
      return { ...state, varyingReinbursementPercentage: action.payload };
    case AddPlanAction.enum.setVaryingRefundExcludeList:
      return {
        ...state,
        varyingRefundExcludeList: {
          ...state.acquisitionExcludeList,
          [action.payload.key]: state.acquisitionExcludeList[action.payload.key]
            ? [...state.acquisitionExcludeList[action.payload.key], action.payload.value]
            : [action.payload.value],
        },
      };
    case AddPlanAction.enum.setFixatedReinbursementValue:
      return { ...state, fixatedReinbursementValue: action.payload };
    case AddPlanAction.enum.setFixatedReinbursementAllowed:
      return { ...state, fixatedReinbursementAllowed: action.payload };
    case AddPlanAction.enum.setFixatedReinbursementAuthorizedList:
      return { ...state, fixatedReinbursementAuthorizedList: action.payload };
    case AddPlanAction.enum.setFixedRefundExcludeList:
      return {
        ...state,
        fixedRefundExcludeList: {
          ...state.fixedRefundExcludeList,
          [action.payload.key]: state.fixedRefundExcludeList[action.payload.key]
            ? [...state.fixedRefundExcludeList[action.payload.key], action.payload.value]
            : [action.payload.value],
        },
      };
    default:
      return state;
  }
};

export const AddPlanProvider = ({ children }: { children: JSX.Element }) => {
  const [state, dispatch] = useReducer(addPlanReducer, initialData);
  const value = { state, dispatch };
  return <AddPlanContext.Provider value={value}>{children}</AddPlanContext.Provider>;
};

export const useAddPlan = () => {
  const context = useContext(AddPlanContext);
  if (context === undefined) {
    throw new Error("useAddPlan must be used inside Register Enterprise Provider");
  }
  return context;
};
