/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState, useLayoutEffect, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { LeftSidebar } from "../../components/LeftSidebar";
import { RegisterColaboratorAction } from "../../types/CollaboratorDataTypes";
import "react-datepicker/dist/react-datepicker.css";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";

import { RegisterColaboratorSchemaStepOne, RegisterColaboratorStepTwoSchemaType } from "../../utils/schemas/RegisterColaboratorSchema";

import { TopbarEnterprise } from "../../components/TopbarEnterprise";

import MainContainer from "../../components/layout/MainContainer";
import LeftBarContainer from "../../components/layout/LeftBarContainer";
import Content from "../../components/layout/Content";

import InputMask from "react-input-mask";
import { useRegisterColaborator } from "../../hooks/ColaboratorContext";
import { RegisterColaboratorFormStepper } from "../../components/CollaboratorComponents/RegisterColaboratorFormStepper";
import { CustomSelectPlans } from "../../components/CollaboratorComponents/CustomSelectPlans";
import { api } from "../../utils/services/api";
import { CustomSelectAccountType } from "../../components/CustomSelectAccountType";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ptBRLocale from "date-fns/locale/pt-BR";
import { SelectCompanyFromHolding } from "../../components/CollaboratorComponents/SelectCompanyFromHolding";
import { supabaseConfig } from "../../config/supabaseConfig";
import CustomTextField from "../../components/layout/CustomTextField";

interface UserType {
   id: string;
   id_user_pvault: string;
   id_user_pvault_token: string;
   token_ref: string;
   avatar: string | null;
   identity_card: null | string;
   selfie: null | string;
   email: string;
   phone_number: string;
   linked_company: string;
   plan_code: string;
   cargoAtual: null | string;
   linked_plan: string[];
   user_type: string[];
   digital_wallet: null | string;
   bank_data: null | string;
   purchase_history: null | string;
   account_status: string;
   ismedic: boolean;
   additional_info: null | string;
   isPrimary: boolean;
   magicToken: string;
   confirmed: boolean;
   confirmedaccessadmin: boolean;
   dependents: string[];
   parentesco: null | string;
   date_of_birth: string;
}

interface UserByPVaultType {
   avatar: null | string;
   cpf: string;
   // date_of_birth: string;
   email: string;
   gender: string;
   high_risk: null | string;
   id: string;
   identity_card: null | string;
   medical_prescription: null | string;
   mother_name: null | string;
   name: string;
   phone_number: string;
   real_name: null | string;
   selfie: null | string;
   tokenRef: string;
}

interface ColaboratorType {
   user: UserType;
   userByPVault: UserByPVaultType;
}

interface ColaboratorData {
   name: any;
   cpf: any;
   email: any | null;
   phone_number: string | null;
   gender: string;
   date_of_birth: any;
   cargoAtual: any | null;
   linked_company: any;
   linked_plan: any[];
   user_type: any[];
   additional_info?: string; // Note the optional property here;
   category_user: string;
}

export const CollaboratorRegister = () => {
   const fileInputRef = useRef<HTMLInputElement>(null);
   const navigate = useNavigate();
   const location = useLocation();
   const [selectedImage, setSelectedImage] = useState<string | null>(null);
   const [avatar, setAvatar] = useState<FormData | null>(null);
   const [colaborator, setColaborator] = useState<ColaboratorType | null>(null);
   const [isLoading, setIsLoading] = useState(false);
   const [fetched, setFetched] = useState(false);
   const [birthDate, setBirthDate] = useState(new Date());
   const [holdingSubsidiary, setHoldingSubsidiary] = useState<any>();
   const [subsidiaries, setSubsidiaries] = useState([]);

   const colaboratorID = localStorage.getItem("userEdition");

   const { state, dispatch } = useRegisterColaborator();

   const [accountType, setAccountType] = useState<string>("Beneficiário");
   const [additionalInfo, setAdditionalInfo] = useState<string>("");

   const getSubsidiaries = async () => {
      const editedUserId = localStorage.getItem("userEdition");
      const response = await api.get("/holding/sisters", {
         params: {
            userId: editedUserId,
         },
      });
      setSubsidiaries(response.data.message.value);
   };

   const handleImageChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      const file = e.target.files?.[0];
      if (file) {
         const reader = new FileReader();
         reader.onload = async (event) => {
            const result = event.target?.result as string | null;
            setSelectedImage(result);

            const formData = new FormData();
            formData.append("imagem", file);

            setAvatar(formData);

            if (colaboratorID) {
               try {
                  await api.patch(`/users/avatar/updateFile/${colaboratorID}`, formData);
                  console.log("Imagem enviada com sucesso!");
               } catch (error) {
                  console.log("Ocorreu um erro ao enviar a imagem:", error);
               }
            }
         };

         reader.readAsDataURL(file);
      }
   };

   // Function to handle date change
   const handleDateChange = (date: Date | null) => {
      setBirthDate(date);
      const formattedDate = date
         ? `${date.getDate().toString().padStart(2, "0")}/${(date.getMonth() + 1).toString().padStart(2, "0")}/${date.getFullYear()}`
         : "";
      dispatch({
         type: RegisterColaboratorAction.setBirthDate,
         payload: formattedDate,
      });
   };

   const handleClick = (event: React.MouseEvent) => {
      event.stopPropagation();
      fileInputRef.current?.click();
   };

   const fetchData = async () => {
      try {
         const response = await api.get(`/users/${colaboratorID}`);
         if (response.data && response.data.user) {
            dispatch({
               type: RegisterColaboratorAction.setColaboratorId,
               payload: response.data.user.id,
            });
            setColaborator(response.data);
            populateFields(response.data);
         } else {
            toast.error("Erro ao buscar dados.");
         }
      } catch (error) {
         console.error(error);
      }
   };

   function transformDateFormat(dateString: any) {
      const [year, month, day] = dateString.split("-");
      return `${day}/${month}/${year}`;
   }

   const populateFields = (colaborator: ColaboratorType) => {
      dispatch({
         type: RegisterColaboratorAction.setColaboratorId,
         payload: colaborator?.user?.id,
      });
      dispatch({
         type: RegisterColaboratorAction.setName,
         payload: colaborator.userByPVault.name ? colaborator.userByPVault.name : "",
      });
      dispatch({
         type: RegisterColaboratorAction.setPhoneNumber,
         payload: colaborator.user.phone_number ? colaborator?.user.phone_number.replace(/^\+55/, "") : "",
      });
      dispatch({
         type: RegisterColaboratorAction.setEmail,
         payload: colaborator.user.email ? colaborator.user.email : "",
      });
      dispatch({
         type: RegisterColaboratorAction.setCpf,
         payload: colaborator.userByPVault.cpf ? colaborator.userByPVault.cpf : "",
      });

      dispatch({
         type: RegisterColaboratorAction.setJobTitle,
         payload: colaborator.user.cargoAtual ? colaborator.user.cargoAtual : "",
      });
      dispatch({
         type: RegisterColaboratorAction.setAssociatedPlan,
         payload: colaborator.user.plan_code ? colaborator.user.plan_code : "",
      });
      dispatch({
         type: RegisterColaboratorAction.setBirthDate,
         payload: colaborator.user.date_of_birth ? transformDateFormat(colaborator.user.date_of_birth) : "",
      });
      dispatch({
         type: RegisterColaboratorAction.setAccountType,
         payload: colaborator.user.user_type[0],
      });
      dispatch({
         type: RegisterColaboratorAction.setAdditionalInfo,
         payload: colaborator.user.additional_info ? colaborator.user.additional_info : "",
      });
      setAccountType(colaborator.user.user_type[0]);
      if (colaborator.user.user_type[0] === "Medico") {
         setAdditionalInfo(colaborator.user.additional_info ? colaborator.user.additional_info : "");
      }
      if (colaborator?.user.avatar) {
         setSelectedImage(`${import.meta.env.VITE_SUPABASE_URL}/storage/v1/object/public/avatars/${colaborator.user.avatar}`);
      }
      if (colaborator?.user?.date_of_birth) {
         setBirthDate(new Date(colaborator.user.date_of_birth + "T00:00:00"));
      } else {
         setBirthDate(null);
      }
   };

   useEffect(() => {
      if (colaborator && colaborator.user.date_of_birth) {
         const dateParts = colaborator.user.date_of_birth.split("-");
         const year = parseInt(dateParts[0], 10);
         const month = parseInt(dateParts[1], 10) - 1; // Mês começa do 0 em JS
         const day = parseInt(dateParts[2], 10);
         const newDate = new Date(year, month, day);
         setBirthDate(newDate);
      }
   }, [colaborator]);

   useEffect(() => {
      // Ensure that data is fetched only when on the desired path
      if (location.pathname === "/atualizar-perfil-individual") {
         fetchData().catch((error: any) => {
            const errorMsg = error.response?.data?.message || "um erro inesperado ocorreu.";
            toast.error(errorMsg);
         });
      } else {
         setSelectedImage(null);
      }
   }, [location.pathname]);

   useEffect(() => {
      getSubsidiaries();
      if (colaboratorID === null) {
         localStorage.removeItem("userEdition");
         setBirthDate(null);

         try {
            dispatch({
               type: RegisterColaboratorAction.setColaboratorId,
               payload: "",
            });
            dispatch({
               type: RegisterColaboratorAction.setName,
               payload: "",
            });
            dispatch({
               type: RegisterColaboratorAction.setPhoneNumber,
               payload: "",
            });
            dispatch({
               type: RegisterColaboratorAction.setEmail,
               payload: "",
            });
            dispatch({
               type: RegisterColaboratorAction.setCpf,
               payload: "",
            });
            dispatch({
               type: RegisterColaboratorAction.setJobTitle,
               payload: "",
            });
            dispatch({
               type: RegisterColaboratorAction.setAssociatedPlan,
               payload: "",
            });
            dispatch({
               type: RegisterColaboratorAction.setBirthDate,
               payload: "",
            });
         } catch (error: any) {
            toast.error(error.res?.data?.message || "An unexpected error occurred.");
         }
      } else {
         if (location.pathname == "/atualizar-perfil-individual") {
            if (!fetched) {
               fetchData()
                  .then(() => {
                     setFetched(true);
                  })
                  .catch((error: any) => {
                     const errorMsg = error.response?.data?.message || "An unexpected error occurred.";
                     toast.error(errorMsg);
                  });
            }
         }
      }
   }, [colaborator, location.pathname]);

   useLayoutEffect(() => {
      dispatch({
         type: RegisterColaboratorAction.setCurrentStep,
         payload: 0,
      });
   }, []);

   const createColaboratorForm = useForm<RegisterColaboratorStepTwoSchemaType>({
      resolver: zodResolver(RegisterColaboratorSchemaStepOne),
   });

   function convertDigitIn(str: string) {
      return str.split("/").reverse().join("-");
   }

   const buildColaboratorData = ({
      name,
      cpf,
      email,
      phoneNumber,
      jobTitle,
      gender = "",
      getCompany,
      birthDate,
      planCodeSelected,
      accountType,
      additionalInfo
   }: any) => {
      const baseData: ColaboratorData = {
         name,
         cpf,
         email: email ? email : "",
         phone_number: phoneNumber ? `+55${phoneNumber.replace(/\D/g, "")}` : "",
         gender,
         date_of_birth: convertDigitIn(birthDate),
         cargoAtual: jobTitle ? jobTitle : "",
         linked_company: localStorage.getItem("linkedCompany"),
         linked_plan: [localStorage.getItem("planSelectedId")],
         additional_info: additionalInfo,
         user_type: [accountType],
         category_user: state.userCategory
      };

      const cleanedData = Object.fromEntries(Object.entries(baseData).filter(([_, value]) => value != null));

      return cleanedData;
   };

   function isValidCPF(cpf: any) {
      return typeof cpf === "string" && cpf.length === 14 && !cpf.includes("_");
   }

   function isValidName(name: any) {
      return typeof name === "string" && name.trim().length > 3 && !name.includes("_");
   }

   function isValidBirthDate(birthDate: any) {
      const pattern = /^\d{2}\/\d{2}\/\d{4}$/;
      return pattern.test(birthDate) && !birthDate.includes("_");
   }

   const onSubmit = async (data: any) => {
      try {
         setIsLoading(true);
         const linkedCompany = localStorage.getItem("linkedCompany");
         const { name, cpf, email, birthDate } = data;

         const planCodeSelected = localStorage.getItem("planSelectedCode");

         if (accountType === "Medico") {
            if (!additionalInfo) {
               toast.error("Digite CRM UF corretamente");
               return;
            }
         }

         if (!isValidCPF(cpf)) {
            toast.error("CPF é obrigatório ou está no formato inválido.");
            setIsLoading(false);
            return;
         }

         // Aqui, verificamos se o phone_number é válido, caso fornecido
         if (!isValidPhoneNumber(state.phoneNumber)) {
            toast.error("Número de telefone inválido.");
            setIsLoading(false);
            return;
         }

         if (!isValidName(name)) {
            toast.error("Nome é obrigatório ou muito curto.");
            setIsLoading(false);
            return;
         }

         if (birthDate && !isValidBirthDate(birthDate)) {
            toast.error("Data de nascimento está no formato inválido.");
            setIsLoading(false);
            return;
         }

         const userEdition = localStorage.getItem("userEdition");
         if (linkedCompany === "allCompanies" && !userEdition) {
            toast.error("Selecione uma empresa em 'Escolher Empresa' para cadastrar um novo beneficário.");
            setIsLoading(false);
            return;
         }

         let getCompany;
         if (colaborator) {
            const { data } = await supabaseConfig.from("business_acc").select("*").eq("enterprise_name", colaborator.user.linked_company);
            getCompany = data;
         } else {
            getCompany = await api
               .get(`businessAcc/${linkedCompany}`)
               .then((res) => res.data)
               .catch((error) => console.error(error));
         }

         const phoneNumber = state.phoneNumber;
         const jobTitle = state.jobTitle;
         const colaboratorData = buildColaboratorData({
            name,
            cpf,
            email,
            phoneNumber,
            jobTitle,
            getCompany,
            planCodeSelected,
            birthDate,
            accountType,
            additionalInfo,
         });
         if (!colaboratorID) {
            if(email === null || email === "" || undefined){
               delete colaboratorData.email;
            }
            if(phoneNumber === null || phoneNumber === "" || undefined){
               delete colaboratorData.phone_number;
            }
            if(jobTitle === null || jobTitle === "" || undefined){
               delete colaboratorData.cargoAtual;
            }
            if(state.holderCPF !== null || state.holderCPF !== "" || state.holderCPF !== undefined){
               colaboratorData.holderCPF = state.holderCPF;
            }
            const responseUser = await api
               .post(`/users/new-create-user`, colaboratorData, {
                  headers: {
                     "Content-Type": "application/json",
                  },
               })
               .then((response) => {
                  // Verifica se a criação foi bem-sucedida e se o 'id' do usuário está disponível
                  if (response.data.user) {
                     localStorage.setItem("userEdition", response.data.user.id);
                     // Notificação de sucesso ou outro tratamento adequado
                     toast.success(response.data.message);
                  } else if (response.data.message) {
                     toast.success(response.data.message);
                  } else {
                     // Caso o usuário tenha sido criado, mas o ID não esteja disponível ou outro erro ocorreu
                     toast.error(response.data.message);
                  }
                  toast.success("Colaborador cadastrado com sucesso! Redirecionando...");
                  navigate('/listaColaboradores')
                  return response.data;
               })
               .catch((error: any) => {
                  toast.error(error.response.data.message);
                  // Retornando para encerrar a função
                  return null;
               });

            setIsLoading(false);

            if (responseUser.user) {
               if (avatar) {
                  await api.patch(`/users/avatar/updateFile/${responseUser.user}`, avatar, {
                     headers: {
                        "Content-Type": "multipart/form-data",
                     },
                  });
                  console.log("Imagem enviada com sucesso!");
               }

               dispatch({
                  type: RegisterColaboratorAction.setColaboratorId,
                  payload: responseUser.user,
               });

               // Redirecionamento para a página /listaDependentes
               navigate("/listaDependentes");
            }
         } else {
            colaboratorData.linked_company = holdingSubsidiary ? holdingSubsidiary : linkedCompany;

            try {
               setIsLoading(true);
               await api.patch(`/users/${colaboratorID}`, colaboratorData, {
                  headers: { "Content-Type": "application/json" },
               });
               toast.success("Colaborador atualizado com sucesso!");
               localStorage.setItem("userEdition", colaboratorID);
               setIsLoading(false);
               navigate("/listaDependentes");
            } catch (error: unknown) {
               setIsLoading(false);
               // toast.error(error.response.data.error);
               toast.error("Erro ao atualizar colaborador.");
            }
         }
      } catch (error: unknown) {
         setIsLoading(false);
      }
   };

   function isValidPhoneNumber(phoneNumber: string) {
      if (!phoneNumber.trim()) return true; // Retorna true se o campo estiver vazio, considerando-o opcional

      const digits = phoneNumber.replace(/\D/g, ""); // Remove tudo que não é dígito
      return digits.length === 10 || digits.length === 11; // Aceita 10 ou 11 dígitos
   }

   const {
      handleSubmit,
      formState: { isSubmitting },
   } = createColaboratorForm;

   return (
      <>
         <MainContainer className='bg-[#03001A]'>
            <LeftBarContainer className='bg-[#03001A]'>
               <LeftSidebar />
            </LeftBarContainer>

            <div style={{ display: "flex", flexDirection: "column", width: "480%" }}>
               <TopbarEnterprise hideAll />
               <Content className='bg-omni-gray rounded-[2rem] h-[100%] p-5 relative mb-10 mt-[-4.5rem]'>
                  <div className='flex justify-between items-center m-5'>
                     <h1 className='font-manrope font-bold font-regular text-5xl text-white justify-center mt-1'>
                        {!colaboratorID ? "Adicionar usuário" : "Editar usuário"}
                     </h1>
                  </div>

                  <RegisterColaboratorFormStepper />

                  <div className='border-t border-[#0517F5] -mt-5 ml-4 mr-4' />
                  <FormProvider {...createColaboratorForm}>
                     <form className='grid grid-cols-3 gap-4 p-5' onSubmit={handleSubmit(onSubmit)}>
                        <div className='flex flex-col'>
                           <label htmlFor='profilePicture' className='text-white mt-3 mb-2'>
                              Foto do Perfil
                           </label>
                           {!selectedImage && (
                              <div
                                 className='custom-file-button flex flex-col items-center justify-center p-20'
                                 onClick={(e) => handleClick(e)}
                                 style={{
                                    background: "linear-gradient(0deg, #03001A, #03001A)",
                                    borderRadius: "10px",
                                    backgroundColor: "#03001A",
                                    maxWidth: "90%", // Defina o valor desejado para a largura máxima responsiva
                                    height: "300px",
                                 }}
                              >
                                 <svg width='60' height='50' viewBox='0 0 60 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                    <path
                                       d='M47.5742 16.5514C47.4489 7.40366 39.9685 0 30.7914 0C26.7092 0 22.7763 1.485 19.7175 4.18148C16.9875 6.58792 15.12 9.80377 14.3804 13.3345C14.3264 13.3339 14.2728 13.3334 14.2194 13.3334C6.37897 13.3334 0 19.7123 0 27.5528C0 35.3934 6.37897 41.7722 14.2195 41.7722H24.1503C24.8568 41.7722 25.4299 41.1994 25.4299 40.4927C25.4299 39.7859 24.8568 39.2131 24.1503 39.2131H14.2195C7.78991 39.2131 2.55914 33.9823 2.55914 27.5528C2.55914 21.1234 7.78991 15.8926 14.2195 15.8926C14.562 15.8926 14.9242 15.911 15.3267 15.9488C16.009 16.0127 16.6215 15.5274 16.7144 14.8477C17.1752 11.4704 18.8427 8.36412 21.4098 6.10112C24.0009 3.81691 27.3326 2.55902 30.7913 2.55902C38.6352 2.55902 45.0166 8.94045 45.0166 16.7843C45.0166 17.0667 44.9956 17.3578 44.9734 17.666L44.9639 17.797C44.9373 18.1734 45.0782 18.5424 45.3491 18.8053C45.6197 19.0681 45.9923 19.198 46.3683 19.1604C46.7086 19.126 47.052 19.1088 47.3888 19.1088C52.9314 19.1088 57.4409 23.6182 57.4409 29.1609C57.4409 34.7035 52.9315 39.2128 47.3888 39.2128H36.9461C36.2396 39.2128 35.6665 39.7857 35.6665 40.4924C35.6665 41.1992 36.2396 41.772 36.9461 41.772H47.3888C54.3426 41.772 60 36.1148 60 29.1609C59.9999 22.2694 54.4429 16.6509 47.5742 16.5514Z'
                                       fill='white'
                                       fillOpacity='0.6'
                                    />
                                    <path
                                       d='M30.7913 6.01392C25.4408 6.01392 20.8607 10.0125 20.1374 15.3147C20.0419 16.0149 20.5322 16.66 21.2323 16.7555C21.291 16.7635 21.3492 16.7675 21.4068 16.7675C22.0366 16.7675 22.5855 16.3023 22.6728 15.6608C23.224 11.6202 26.7142 8.57305 30.7912 8.57305C31.498 8.57305 32.0708 8.00024 32.0708 7.29348C32.0709 6.58684 31.498 6.01392 30.7913 6.01392Z'
                                       fill='white'
                                       fillOpacity='0.6'
                                    />
                                    <path
                                       d='M36.6108 31.1929L31.9563 27.0351C31.1539 26.3179 29.9434 26.318 29.1407 27.0349L24.4862 31.193C23.9591 31.6637 23.9137 32.4727 24.3844 32.9998C24.8551 33.5266 25.664 33.5725 26.1912 33.1016L29.269 30.3522V46.094C29.269 46.8008 29.8418 47.3736 30.5486 47.3736C31.2552 47.3736 31.8281 46.8008 31.8281 46.094V30.3519L34.906 33.1015C35.15 33.3196 35.4546 33.4268 35.758 33.4268C36.1098 33.4268 36.4599 33.2828 36.7128 32.9996C37.1833 32.4725 37.1378 31.6637 36.6108 31.1929Z'
                                       fill='white'
                                       fillOpacity='0.6'
                                    />
                                 </svg>

                                 <button
                                    className='text-white mt-2 bg-blue-500 px-6 py-3 rounded-lg'
                                    type='button'
                                    style={{
                                       backgroundColor: "#0517F5",
                                       borderRadius: "9999px",
                                    }}
                                 >
                                    Anexar imagem
                                 </button>
                                 <div
                                    className='text-white mt-2 flex flex-col items-center justify-center'
                                    style={{
                                       width: "250px",
                                    }}
                                 >
                                    Tamanho Máximo: 800x800
                                 </div>
                              </div>
                           )}
                           {selectedImage && (
                              <div
                                 className='flex flex-col items-center justify-center'
                                 style={{
                                    background: "linear-gradient(0deg, #03001A, #03001A)",
                                    borderRadius: "10px",
                                    backgroundColor: "#03001A",
                                    maxWidth: "90%", // Defina o valor desejado para a largura máxima responsiva
                                    height: "300px",
                                    alignItems: "center",
                                    justifyContent: "center",
                                 }}
                              >
                                 <img
                                    src={selectedImage}
                                    alt='Selected'
                                    className='cursor-pointer w-100 h-72 object-cover'
                                    onClick={handleClick}
                                 />
                              </div>
                           )}
                           <input type='file' id='profilePicture' className='hidden' ref={fileInputRef} onChange={handleImageChange} />

                           <button
                              type='button'
                              className={`bg-[#0517F5] rounded-md mt-8 py-3 text-white text-center ${isLoading ? "loading-button" : ""}`}
                              style={{ maxWidth: "90%" }}
                              onClick={() => {
                                 handleSubmit(onSubmit);
                                 onSubmit(state);
                              }}
                           >
                              {isLoading ? "Carregando..." : "Próximo"}
                           </button>
                        </div>

                        <div className='flex flex-col'>
                           <label htmlFor='fullName' className='text-white mt-4'>
                              Nome Completo *
                           </label>
                           <input
                              id='fullName'
                              className='rounded-md pl-3 text-white h-14 mt-2'
                              style={{ backgroundColor: "#03001A" }}
                              type='text'
                              placeholder='ex: Charlene Reed'
                              value={state.name}
                              onChange={(e) => {
                                 dispatch({
                                    type: RegisterColaboratorAction.setName,
                                    payload: e.target.value,
                                 });
                              }}
                           />
                           <label htmlFor='cpf' className='text-white mt-4'>
                              CPF *
                           </label>
                           <InputMask
                              className='rounded-md pl-3 text-white h-14 mt-2'
                              style={{ backgroundColor: "#03001A" }}
                              id='cpf'
                              mask='999.999.999-99'
                              type='text'
                              placeholder='ex: 000.000.000-00'
                              value={state.cpf}
                              onChange={(e) => {
                                 dispatch({
                                    type: RegisterColaboratorAction.setCpf,
                                    payload: e.target.value,
                                 });
                              }}
                           />

                           <label htmlFor='currentRole' className='text-white mt-4'>
                              Cargo Atual
                           </label>
                           <input
                              id='currentRole'
                              className='rounded-md pl-3 text-white h-14 mt-2'
                              style={{ backgroundColor: "#03001A" }}
                              type='text'
                              placeholder='ex: CEO'
                              value={state.jobTitle}
                              onChange={(e) => {
                                 dispatch({
                                    type: RegisterColaboratorAction.setJobTitle,
                                    payload: e.target.value,
                                 });
                              }}
                           />

                           <label htmlFor='birthDate' className='text-white mt-4'>
                              Data de Nascimento
                           </label>
                           <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ptBRLocale}>
                              <MuiDatePicker
                                 value={birthDate}
                                 onChange={handleDateChange}
                                 maxDate={new Date()}
                                 renderInput={(params) => (
                                    <CustomTextField
                                       {...params}
                                       id='birthDate'
                                       inputProps={{
                                          ...params.inputProps,
                                          placeholder: birthDate ? undefined : "dd/mm/aaaa",
                                       }}
                                    />
                                 )}
                              />
                           </LocalizationProvider>
                           {state.accountType === "Dependente" && (
                              <>
                              <label htmlFor="holderCpf"className='text-white mt-4'>
                                 CPF do Titular
                              </label>
                              <InputMask
                                 className='rounded-md pl-3 text-white h-14 mt-2'
                                 style={{ backgroundColor: "#03001A" }}
                                 id='holderCPF'
                                 mask='999.999.999-99'
                                 type='text'
                                 placeholder='ex: 000.000.000-00'
                                 value={state.holderCPF}
                                 onChange={(e) => {
                                    dispatch({
                                       type: RegisterColaboratorAction.setHolderCPF,
                                       payload: e.target.value,
                                    });
                                    console.log(state.holderCPF);
                                 }}
                              />
                                 </>
                           )
                           
                           }
                           
                        </div>
                        <div className='flex flex-col'>
                           <label htmlFor='phoneNumber' className='text-white mt-4'>
                              Telefone Celular
                           </label>
                           <InputMask
                              id='phoneNumber'
                              mask='(99) 9999-9999[9]' // A parte entre colchetes é opcional, permitindo 8 ou 9 dígitos.
                              className='rounded-md pl-3 text-white h-14 mt-2'
                              style={{ backgroundColor: "#03001A" }}
                              type='text'
                              placeholder='ex: (11) 98888-7777 ou (11) 8888-7777'
                              value={state.phoneNumber}
                              onChange={(e) => {
                                 dispatch({
                                    type: RegisterColaboratorAction.setPhoneNumber,
                                    payload: e.target.value,
                                 });
                                 console.log(state.phoneNumber);
                              }}
                           />
                           <label htmlFor='email' className='text-white mt-4 '>
                              Email
                           </label>
                           <input
                              id='category'
                              className='rounded-md pl-3 text-white h-14 mt-2'
                              style={{ backgroundColor: "#03001A" }}
                              type='select'
                              placeholder='ex: creed@empresa.com'
                              value={state.email}
                              onChange={(e) => {
                                 dispatch({
                                    type: RegisterColaboratorAction.setEmail,
                                    payload: e.target.value,
                                 });
                              }}
                           />
                           <label htmlFor='category' className='text-white mt-4 '>
                              Categoria
                           </label>
                           <select
                           name="category"
                           id="category"
                           className='rounded-md pl-3 text-white h-14 mt-2 bg-[#03001A]'
                           value={state.userCategory}
                           onChange={
                              (e) => {
                                 dispatch({
                                    type: RegisterColaboratorAction.setUserCategory,
                                    payload: e.target.value,
                                 });
                              }
                           }
                           >
                              <option className="text-white mt-5 " value="Adesão">Adesão</option>
                              <option className="text-white mt-5" selected value="Compulsório">Compulsório</option>
                           </select>
                           <label htmlFor='coveragePlan' className='text-white mt-5'>
                              Plano de Cobertura *
                           </label>
                           <CustomSelectPlans
                              initialPlanId={colaborator?.user?.linked_plan ? colaborator?.user?.linked_plan[0] : ""}
                              isEditing={colaboratorID ? true : false}
                           />
                           <label htmlFor='coveragePlan' className='text-white mt-5'>
                              Tipo do Usuário *
                           </label>
                           <CustomSelectAccountType
                              accountTypeUser={accountType}
                              additionalInfoUser={additionalInfo}
                              setAccountType={setAccountType}
                              setAdditionalInfo={setAdditionalInfo}
                           />
                           {subsidiaries.length > 0 && (
                              <SelectCompanyFromHolding
                                 colaborator={colaborator}
                                 holdingSubsidiary={holdingSubsidiary}
                                 setHoldingSubsidiary={setHoldingSubsidiary}
                              />
                           )}

                        </div>
                     </form>
                  </FormProvider>
               </Content>
            </div>
         </MainContainer>

         <style>
            {`
          .loading-button::after {
            content: "";
            width: 20px;
            height: 20px;
            border: 4px solid rgba(255, 255, 255, 0.3);
            border-radius: 50%;
            border-top: 4px solid white;
            animation: spin 1s linear infinite;
            display: inline-block;
            margin-left: 10px;
            vertical-align: middle;  /* Aligns the spinner with the text */
          }

          .myCustomDatePicker .MuiInputBase-root {
            background-color: #03001A !important; // Using !important to increase specificity
            // ... other styles
          }          
          
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }

          .date-picker-custom {
            background-color: #03001A;
            /* Add any other styles you need here */
          }
        `}
         </style>
      </>
   );
};
