import { create } from "zustand";
import { api } from "../utils/services/api";

interface LoggedUser {
    id: string;
    user_type: string[];
    linked_company: string;
    termos_de_uso_painel: Date | null;
    email: string;
}

interface UserAuthStore {
    authenticatedUser: LoggedUser;
    fetchAuthenticatedUser: (id: string) => Promise<void>;
}

const userAuthStore = create<UserAuthStore>((set) => ({
    authenticatedUser: {
        id: "",
        user_type: [],
        linked_company: "",
        termos_de_uso_painel: null,
        email: "",

    },
    fetchAuthenticatedUser: async (email:string) => {
        try {
            const response = await api.get(`/login/email/${email}`);
            set({
                authenticatedUser: {
                    id: response.data.user,
                    user_type: response.data.userType[0],
                    linked_company: response.data.linkedCompany,
                    termos_de_uso_painel: response.data.termos_de_uso_painel,
                    email: response.data.email,
                },
            });

        } catch (error) {
            console.error(error);
        }
    },
}));

export {  userAuthStore };