import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const CompaniesIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M13.9831 1.86178C13.9984 2.04976 13.9999 2.26843 14 2.49493V20H1.00001C0.44772 20 5.12804e-06 19.5523 5.12804e-06 19L2.50383e-06 2.56809C-2.74171e-05 2.31572 -5.65555e-05 2.06993 0.0169504 1.86178C0.0356272 1.63318 0.0796947 1.36345 0.217992 1.09202C0.409739 0.7157 0.7157 0.409739 1.09202 0.217992C1.36345 0.0796947 1.63318 0.0356272 1.86178 0.0169504C2.06993 -5.65574e-05 2.31573 -2.74155e-05 2.56811 2.50544e-06L11.4319 2.50383e-06C11.6843 -2.74171e-05 11.9301 -5.65555e-05 12.1382 0.0169504C12.3668 0.0356272 12.6366 0.0796947 12.908 0.217992C13.2843 0.409739 13.5903 0.715701 13.782 1.09202C13.9203 1.36345 13.9644 1.63318 13.9831 1.86178ZM3 4C3 3.44772 3.44772 3 4 3H5C5.55228 3 6 3.44772 6 4C6 4.55228 5.55228 5 5 5H4C3.44772 5 3 4.55228 3 4ZM4 6C3.44772 6 3 6.44772 3 7C3 7.55228 3.44772 8 4 8H5C5.55228 8 6 7.55228 6 7C6 6.44772 5.55228 6 5 6H4ZM4 9C3.44772 9 3 9.44772 3 10C3 10.5523 3.44772 11 4 11H5C5.55228 11 6 10.5523 6 10C6 9.44772 5.55228 9 5 9H4ZM9 3C8.44772 3 8 3.44772 8 4C8 4.55228 8.44772 5 9 5H10C10.5523 5 11 4.55228 11 4C11 3.44772 10.5523 3 10 3H9ZM9 6C8.44772 6 8 6.44772 8 7C8 7.55228 8.44772 8 9 8H10C10.5523 8 11 7.55228 11 7C11 6.44772 10.5523 6 10 6H9ZM9 9C8.44772 9 8 9.44772 8 10C8 10.5523 8.44772 11 9 11H10C10.5523 11 11 10.5523 11 10C11 9.44772 10.5523 9 10 9H9ZM7 13C5.89543 13 5 13.8954 5 15V18H9V15C9 13.8954 8.10457 13 7 13Z'
          fill='#DEE1FE'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M19 20H15V4L17.4319 4C17.6843 3.99997 17.9301 3.99994 18.1382 4.01695C18.3668 4.03563 18.6366 4.07969 18.908 4.21799C19.2843 4.40974 19.5903 4.7157 19.782 5.09202C19.9203 5.36345 19.9644 5.63318 19.9831 5.86178C20.0001 6.06987 20 6.31569 20 6.56799L20 19C20 19.5523 19.5523 20 19 20ZM18 8C18 8.55228 17.5523 9 17 9C16.4477 9 16 8.55228 16 8C16 7.44772 16.4477 7 17 7C17.5523 7 18 7.44772 18 8ZM18 11C18 11.5523 17.5523 12 17 12C16.4477 12 16 11.5523 16 11C16 10.4477 16.4477 10 17 10C17.5523 10 18 10.4477 18 11ZM18 14C18 14.5523 17.5523 15 17 15C16.4477 15 16 14.5523 16 14C16 13.4477 16.4477 13 17 13C17.5523 13 18 13.4477 18 14Z'
          fill='#DEE1FE'
        />
      </svg>
    </SvgIcon>
  );
};

export default CompaniesIcon;
