import React, { useEffect, useState } from "react";
import { LeftSidebar } from "../../components/LeftSidebar";
import MainContainer from "../../components/layout/MainContainer";
import LeftBarContainer from "../../components/layout/LeftBarContainer";
import Content from "../../components/layout/Content";
import { FaRegHandshake } from "react-icons/fa";
import { CoverageTable } from "./CoverageTable";
import { CoveragePlansTable } from "./CoveragePlansTable";
import { QuantityCoverageTable } from "./QuantityCoverageTable";
import { TopbarEnterprise } from "../../components/TopbarEnterprise";
import { api } from "../../utils/services/api";

export const Coverage = () => {
  const [plans, setPlans] = useState([]);
  const [modalShow] = useState(false);

  const linkedCompany = localStorage.getItem("linkedCompany");

  const fetchData = async () => {
    try {
      if (linkedCompany !== "allHCompanies" && linkedCompany !== "allCompanies") {
        const { data } = await api.get(`businessAcc/${linkedCompany}/plans`);
        const resultData = data.plansWithUserCount;
        setPlans(resultData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <MainContainer className='bg-[#03001A]'>
      <LeftBarContainer className='bg-[#03001A]'>
        <LeftSidebar />
      </LeftBarContainer>
      <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, width: "480%" }}>
        <TopbarEnterprise />

        <Content className='bg-omni-gray rounded-[2rem] h-[100%] p-5 relative mb-10 mt-[-4.5rem]'>
          {modalShow ? (
            <img
              src='/images/construction.png'
              alt='Construction'
              style={{
                position: "absolute",
                top: "10%",
                left: "0%",
                zIndex: 10,
              }}
            />
          ) : (
            <div className='relative z-0'>
              <div className='flex justify-between items-center m-5'>
                <h1 className='font-manrope font-bold font-regular text-5xl text-white justify-center mt-1 mb-5'>
                  Cobertura Atual
                </h1>
              </div>
              {linkedCompany === "allHCompanies" || linkedCompany === "allCompanies" ? (
                <p className='pl-6 text-white'>
                  Selecione uma das empresas para ver as informações de cobertura do plano.
                </p>
              ) : (
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div className='flex justify-between items-center'>
                    <p
                      className='pl-6 pr-4 text-white mb-0'
                      style={{ fontFamily: "Manrope", fontSize: "1rem", fontWeight: 500 }}
                    >
                      As regras vigentes de cobertura do seu contrato atual <br />
                      Cobertura entre 01 de Janeiro a 31 de Dezembro de 2023
                    </p>
                  </div>

                  <button
                    type='button'
                    className='bg-omni-blue text-center rounded-md px-3 py-2 w-255 text-white hidden'
                    style={{ marginRight: "13rem" }}
                  >
                    <FaRegHandshake className='inline-block mr-2' />
                    Ver contrato
                  </button>
                </div>
              )}
              ;
              {linkedCompany !== "allCompanies" && plans.length > 0 ? (
                <>
                  <CoverageTable plans={plans} />
                  <div
                    className=''
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "0.5rem",
                      marginLeft: "-2rem",
                    }}
                  >
                    <CoveragePlansTable plans={plans} />
                    <QuantityCoverageTable plans={plans} />
                  </div>
                </>
              ) : null}
              ;
            </div>
          )}
        </Content>
      </div>
    </MainContainer>
  );
};
