import React from "react";

const AddPersonIcon = () => {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_119_870)'>
        <path
          d='M13.6875 18H13.3125C12.5888 18 12 17.4112 12 16.6875V15H10.3125C9.58875 15 9 14.4112 9 13.6875V13.3125C9 12.5888 9.58875 12 10.3125 12H12V10.3125C12 9.58875 12.5888 9 13.3125 9H13.6875C14.4112 9 15 9.58875 15 10.3125V12H16.6875C17.4112 12 18 12.5888 18 13.3125V13.6875C18 14.4112 17.4112 15 16.6875 15H15V16.6875C15 17.4112 14.4112 18 13.6875 18Z'
          fill='white'
        />
        <path
          d='M6.74999 7.5C8.82106 7.5 10.5 5.82107 10.5 3.75C10.5 1.67893 8.82106 0 6.74999 0C4.67893 0 3 1.67893 3 3.75C3 5.82107 4.67893 7.5 6.74999 7.5Z'
          fill='white'
        />
        <path
          d='M7.49999 13.6875V13.3125C7.49999 11.7615 8.76149 10.5 10.3125 10.5H10.5V10.3125C10.5 9.8985 10.5952 9.5085 10.7565 9.15375C10.434 9.06375 10.101 9 9.74999 9H3.75C1.67925 9 0 10.6793 0 12.75V15.1875C0 15.498 0.252 15.75 0.5625 15.75H8.41499C7.85624 15.2355 7.49999 14.505 7.49999 13.6875Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_119_870'>
          <rect width='18' height='18' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AddPersonIcon;
