/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-key */
import React, { useEffect, useMemo } from "react";
import { useTable, Column } from "react-table";

interface TableData {
  coverage: string;
  plans: string[];
  condition: string;
}

export const CoverageTable = ({ plans }: any) => {
  const formatPlanCode = (code: string) => {
    let planName = "";
    let credits = "";

    if (code.startsWith("R")) {
      planName = "Royal";
    } else if (code.startsWith("C")) {
      planName = "Celeste";
    } else if (code.startsWith("T")) {
      planName = "Turquesa";
    }

    credits = code.match(/\d+/)?.[0] || ""; // Extract numerical characters

    return `${planName} ${credits}`;
  };

  const columns: Column<TableData>[] = useMemo(
    () => [
      {
        Header: "Cobertura",
        accessor: "coverage",
      },
      {
        Header: "Planos Cobertos",
        accessor: "plans",
        Cell: ({ value }: any) => {
          return (
            <div className='coverage-plans'>
              {value.map((plan: any, index: number) => {
                const formattedPlan = formatPlanCode(plan.plan_code);
                return (
                  <span
                    key={plan.id}
                    style={{ backgroundColor: getPlanBackground(plan.plan_code) }}
                    className='text-white text-sm rounded-full py-1 px-8 mx-2'
                  >
                    {formattedPlan}
                  </span>
                );
              })}
            </div>
          );
        },
      },
      {
        Header: "Condição",
        accessor: "condition",
      },
    ],
    [],
  );

  const colors = ["#0517F5", "#D32F2F", "#388E3C", "#FBC02D", "#8E24AA"];
  const planColorMap = new Map<string, string>();

  const getPlanBackground = (planCode: string) => {
    if (planColorMap.has(planCode)) {
      return planColorMap.get(planCode);
    }

    let hash = 0;
    for (let i = 0; i < planCode.length; i++) {
      hash = planCode.charCodeAt(i) + ((hash << 5) - hash);
    }

    const index = Math.abs(hash) % colors.length;
    const color = colors[index];
    planColorMap.set(planCode, color);

    return color;
  };

  const getGenerics = () => {
    return plans.filter((plan: any) => plan.acquisition_params.includes("GENERICO"));
  };

  const getRefSimilars = () => {
    return plans.filter(
      (plan: any) => plan.acquisition_params?.includes("SIMILAR") || plan.acquisition_params?.includes("REFERENCIA"),
    );
  };

  const data = [
    {
      coverage: "Medicamentos tarjados Genéricos",
      plans: getGenerics(),
      condition: "100%",
    },
    {
      coverage: "Medicamentos Tarjados: Referência e Similares",
      plans: getRefSimilars(),
      condition: "100%",
    },
    {
      coverage: "Quantidade de Caixas Permitidas",
      plans: plans,
      condition: "2",
    },
  ];

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({ columns, data }); // Usar data em vez de value

  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <table {...getTableProps()} className='w-full rounded-md'>
        <thead className='mt-5'>
          {headerGroups.map((headerGroup: any, index) => (
            <tr key={index} {...headerGroup.getHeaderGroupProps()} className='px-4 py-2'>
              {headerGroup.headers.map((column: any, columnIndex: number) => (
                <th
                  key={column.id}
                  {...column.getHeaderProps()}
                  className={`text-white font-light py-2 px-4 ${columnIndex === 0 ? "text-left" : ""} ${
                    column.id === "id" ? "w-20" : ""
                  }`}
                >
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()} className='pt-2'>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className={`border-omni-gray border-t border-8 rounded-md h-12 cursor-pointer hover:bg-gray-800`}
                style={{ height: "4.2rem" }}
              >
                {row.cells.map((cell: any, cellIndex: number) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{ backgroundColor: "#03001A" }}
                      className={`px-4 py-3 text-white ${cellIndex === 0 ? "text-left" : "text-center"}`} // Removed the rowBackground class here
                    >
                      <span className='text-sm'>{cell.render("Cell")}</span> {/* Render the Cell here */}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
