import React, { createContext, useContext, useState, useEffect } from "react";

interface AuthContextProps {
  errorMessage: string;
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
  isAuthorized: boolean;
  lastPath: string | null;
}

interface AuthProviderProps {
  children: React.ReactNode;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);
const allowedUserTypes: string[] = ["Admin", "Gestor", "Médico", "Gestor-Mor"];

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [isAuthorized, setIsAuthorized] = useState(true);
  const [lastPath, setLastPath] = useState<string | null>(null);

  useEffect(() => {
    const userType = localStorage.getItem("userType");

    if (!userType || !allowedUserTypes.includes(userType)) {
      setIsAuthorized(false);
      setErrorMessage("Acesso Negado: Você não tem permissão para esta área.");
    }
  }, []);

  useEffect(() => {
    setLastPath(window.location.pathname);
  }, []);

  return (
    <AuthContext.Provider value={{ errorMessage, setErrorMessage, isAuthorized, lastPath }}>
      {children}
    </AuthContext.Provider>
  );
};
