import React, { useEffect, useState } from "react";
import { LeftSidebar } from "../../components/LeftSidebar";
import MainContainer from "../../components/layout/MainContainer";
import LeftBarContainer from "../../components/layout/LeftBarContainer";
import Content from "../../components/layout/Content";
import Loading from "../../components/Loading";
import CollaboratorTable from "../../components/CollaboratorComponents/CollaboratorTable";
import { api, formatDate } from "../../utils/services/api";
import { TopbarEnterprise } from "../../components/TopbarEnterprise";
import FilterIcon from "../../components/icons/FilterIcon";
import DateFilter from "../../components/DateFilter";
import { useParams } from "react-router-dom";
import DownloadButton from "../../components/icons/DownloadIcon";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

interface RequestData {
   searchQuery: any;
   linkedCompany: string;
   page: any;
   limit: number;
   gestor_mor?: boolean; // Propriedade opcional
}

const CollaboratorListing: React.FC = () => {
   const [data, setData] = useState([]);

   const [isLoading, setIsLoading] = useState(true);

   const [isLoadingToDownload, setIsLoadingToDownload] = useState(false);
   const [downloadState, setDownloadState] = useState("padrao"); // 'padrao', 'carregando', 'sucesso'

   const [startEndInterval, setstartEndInterval] = useState({ start: null, end: null });

   const [searchValue, setSearchValue] = useState("");
   const [isSearching, setIsSearching] = useState(false);
   const [showCalendar, setShowCalendar] = useState<boolean>(false);

   const [totalPages, setTotalPages] = useState(0);
   const [currentPage, setCurrentPage] = useState(1);

   const limit = 10; // Número de itens por página
   const pageParam = useParams<{ page: string }>().page;

   const linkedCompany = localStorage.getItem("linkedCompany");
   const holdingID = localStorage.getItem("holdingID");
   const userType = localStorage.getItem("userType"); // Buscar o userType do localStorage

   const clickFilter = () => setShowCalendar(!showCalendar);

   const handleDownloadClick = async () => {
      setDownloadState("carregando");
      try {
         await clickToDownloadCollabs(); // Supondo que esta função seja assíncrona e faça o download
         setDownloadState("sucesso");
      } catch (error) {
         setDownloadState("erro");
         console.error("Erro no download:", error);
      } finally {
         setTimeout(() => setDownloadState("padrao"), 2500); // Volta ao estado padrão após 2 segundos
         setIsLoadingToDownload(false);
      }
   };

   const generateAndDownloadXLSX = (data: any) => {
      // Create a new worksheet from the JSON data
      const ws = XLSX.utils.json_to_sheet(
         data.map((item) => ({
            ID: item.id,
            "Nome Completo": item.fullName,
            "Nome da Empresa": item.companyName,
            CPF: item.cpf,
            Categoria: item.category_user ? item.category_user : "Não Informado",
            "Status Adesão": item.adesao_status ? item.adesao_status : "Não Informado",
            "Data de Nascimento": item.dateBirth ? new Date(item.dateBirth).toLocaleDateString() : "Não Informado",
            "Tipo de Usuário": item.userType,
            Gênero: item.gender ? item.gender : "Não Informado",
            Plano: item.plan !== "Não especificado" ? item.plan : "Não Informado",
            "Status da Conta": item.account_status,
            "Data de Criação": item.createdDate ? new Date(item.createdDate).toLocaleDateString() : "Não Informado",
         })),
         {
            header: [
               "ID",
               "Nome Completo",
               "Nome da Empresa",
               "CPF",
               "Categoria",
               "Status Adesão",
               "Data de Nascimento",
               "Tipo de Usuário",
               "Gênero",
               "Plano",
               "Status da Conta",
               "Data de Criação",
            ],
            skipHeader: false, // Assegura que o cabeçalho será incluído
         },
      );

      // Ajuste opcional da largura das colunas
      const wscols = [
         { wch: 10 },
         { wch: 25 },
         { wch: 35 },
         { wch: 15 },
         { wch: 15 },
         { wch: 20 },
         { wch: 20 },
         { wch: 25 },
         { wch: 15 },
         { wch: 25 },
         { wch: 20 },
         { wch: 20 },
      ];
      ws["!cols"] = wscols;

      // Create a new workbook and add the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Colaboradores");

      // Generate the Excel file
      const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

      // Convert binary string to Blob
      const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });

      // Use FileSaver to download the file
      saveAs(blob, "ListaDeColaboradores.xlsx");
   };

   // Helper function to convert binary string to ArrayBuffer
   function s2ab(s: any) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
   }

   const clickToDownloadCollabs = async () => {
      try {
         setIsLoadingToDownload(true);

         const isGestorMor = userType === "Gestor-Mor";
         let url = `users/list/usersToDownload`;

         // Construindo a URL com base nas condições
         if (linkedCompany === "allCompanies") {
            url += `?linked_company=allCompanies`;
         } else if (linkedCompany === "allHCompanies" && isGestorMor) {
            url += `?linked_company=${holdingID}&gestor_mor=true`;
         } else {
            url += `?linked_company=${linkedCompany}`;
         }

         // Chamando a API
         const response = await api.get(url);
         // Certifique-se de que 'response.data' é o array de dados que você deseja exportar
         // Se 'response.data' não for diretamente o array, ajuste o caminho conforme necessário
         const resultData = response.data; // Ajuste conforme a estrutura real da sua resposta

         // Chamando a função para gerar e fazer o download do arquivo XLSX com os dados recebidos
         generateAndDownloadXLSX(resultData);
      } catch (error) {
         console.error("Erro ao tentar fazer download dos colaboradores:", error);
      }
   };

   const columns = React.useMemo(
      () => [
         {
            Header: "ID",
            accessor: "id" as const,
            isSortable: true,
         },
         {
            Header: "Nome Completo",
            accessor: "fullName" as const,
            isSortable: true,
         },
         {
            Header: "Status",
            accessor: "status" as const,
            isSortable: true,
         },
         { Header: "Adicionado em", accessor: "createdAt", isSortable: true },
         {
            Header: "Plano Vinculado",
            accessor: "planType" as const,
            isSortable: true,
         },
      ],
      [],
   );

   const allCompaniesColumns = React.useMemo(
      () => [
         {
            Header: "ID",
            accessor: "id" as const,
            isSortable: true,
         },
         {
            Header: "Nome Completo",
            accessor: "fullName" as const,
            isSortable: true,
         },
         {
            Header: "Status",
            accessor: "status" as const,
            isSortable: true,
         },
         { Header: "Adicionado em", accessor: "createdAt", isSortable: true },

         {
            Header: "Empresa Vinculada",
            accessor: "linkedCompany",
            isSortable: true,
         },
         {
            Header: "Plano Vinculado",
            accessor: "planType" as const,
            isSortable: true,
         },
      ],
      [],
   );

   const onPageChange = (newPage: number) => {
      setCurrentPage(newPage);
      if (isSearching) {
         searchCollaborators(searchValue, newPage);
      } else {
         fetchData(newPage);
      }
   };

   useEffect(() => {
      if (searchValue.length >= 3) {
         setIsSearching(true);
         searchCollaborators(searchValue, 1);
      } else if (isSearching) {
         setIsSearching(false);
         setCurrentPage(1);
         fetchData(1);
      }
   }, [searchValue]);

   useEffect(() => {
      if (!isSearching) {
         fetchData(currentPage);
      }
   }, [currentPage, isSearching]);

   useEffect(() => {
      const pageFromURL = parseInt(pageParam, 10);
      if (!isNaN(pageFromURL) && pageFromURL !== currentPage) {
         setCurrentPage(pageFromURL);
      }
   }, [pageParam]);

   const formatData = (users: any) => {
      return users
         .map((item: any) => {
            return {
               id: item.user.id,
               fullName: item?.userByPVault?.name || "Funcionário não identificado",
               cpf: item?.userByPVault?.cpf || "",
               treatmentDays: "",
               condition: "",
               status: item.user.account_status,
               planType: String(item.user.plan_code).slice(0, 4),
               acquisition: [],
               treatments: item.user.clinicalHistory || [],
               createdAt: item.user.created_at ? formatDate(item.user.created_at).split(",")[0] : "",
               linkedCompany: item.user.linked_company || "Não vinculado",
            };
         })
         .sort((a, b) => a.fullName.localeCompare(b.fullName));
   };

   const searchCollaborators = async (query, page) => {
      let linkedCompany = localStorage.getItem("linkedCompany") || "allCompanies";
      const isGestorMor = userType === "Gestor-Mor"; // Verificar se o userType é 'Gestor-Mor'

      try {
         setIsLoading(true);

         const queryParams = {
            gestor_mor: linkedCompany === "allHCompanies" ? "true" : "false",
         };

         if (isGestorMor && linkedCompany === "allHCompanies") {
            linkedCompany = localStorage.getItem("holdingID");
         }

         const requestData: RequestData = {
            // Utilizando a interface definida
            searchQuery: query,
            linkedCompany: linkedCompany,
            page: page,
            limit: limit,
         };

         const response = await api.post("users/searchCollaborators?" + new URLSearchParams(queryParams), requestData);

         // Tratamento e formatação dos dados recebidos, se necessário
         const formattedData = formatData(response.data.collaborators);
         setData(formattedData);
         setTotalPages(response.data.pagination.totalPages);
         setCurrentPage(response.data.pagination.currentPage);
         setIsLoading(false);
      } catch (error) {
         setIsLoading(false);

         console.error("Error fetching search results:", error);
      }
   };

   const fetchData = async (pageNumber: number) => {
      try {
         setIsLoading(true);

         const isGestorMor = userType === "Gestor-Mor";

         let url = `users/list/usersByQuantity?page=${pageNumber}&limit=${limit}`;

         if (linkedCompany === "allCompanies") {
            url += `&linked_company=${linkedCompany}`;
         } else if (linkedCompany === "allHCompanies" && isGestorMor) {
            url += `&linked_company=${holdingID}`;
            url += "&gestor_mor=true";
         } else {
            url += `&linked_company=${linkedCompany}`;
         }

         const response = await api.get(url);
         const resultData = response.data as any;
         const formattedData = formatData(resultData.users);
         setData(formattedData);
         setTotalPages(response.data.pagination.totalPages);

         setIsLoading(false);
      } catch (error) {
         setIsLoading(false);
      }
   };

   useEffect(() => {
      // Esta função apenas atualiza o estado 'currentPage' com base no parâmetro da URL.
      const updatePageFromURL = () => {
         const pageNumber = parseInt(pageParam, 10);

         if (!isNaN(pageNumber) && pageNumber !== currentPage) {
            setCurrentPage(pageNumber);
         }
      };

      updatePageFromURL();
   }, [pageParam]);

   const handleImportSuccess = () => {
      fetchData(1); // Supondo que você queira carregar a primeira página após a importação
   };

   const resetSearch = () => {
      // Logic to reset the search state
      fetchData(currentPage); // Example: Refetch the initial data
   };

   return (
      <>
         <MainContainer className='bg-[#03001A]'>
            <LeftBarContainer className='bg-[#03001A]'>
               <LeftSidebar />
            </LeftBarContainer>
            <div style={{ display: "flex", flexDirection: "column", flexGrow: 1, width: "480%" }}>
               <TopbarEnterprise
                  onSearchChange={(e) => setSearchValue(e.target.value)}
                  onImportSuccess={handleImportSuccess}
                  onSearchReset={resetSearch}
               />
               <Content className='bg-omni-gray rounded-[2rem] h-[100%] p-5 relative mb-10'>
                  <div className='flex justify-between items-center m-5 mt-1'>
                     <h1 className='font-manrope font-bold font-regular text-5xl text-white justify-center'>Lista de colaboradores</h1>
                     <div className='flex'>
                        <div className={`${showCalendar ? "" : "hidden"} mr-2`}>
                           <DateFilter setstartEndInterval={setstartEndInterval} />
                        </div>
                        <div className='mt-2 mr-4 cursor-pointer'>
                           <DownloadButton
                              isLoading={downloadState === "carregando"}
                              isSuccess={downloadState === "sucesso"}
                              isError={downloadState === "erro"}
                              onClick={handleDownloadClick}
                           />
                        </div>
                        <div className='mt-2 mr-2 cursor-pointer' onClick={clickFilter}>
                           <FilterIcon />
                        </div>
                     </div>
                  </div>
                  <div className='ml-[0.6rem]'></div>
                  {isLoading ? (
                     <Loading />
                  ) : (
                     <CollaboratorTable
                        columns={linkedCompany === "allHCompanies" || linkedCompany === "allCompanies" ? allCompaniesColumns : columns}
                        data={data}
                        onPageChange={onPageChange}
                        totalPages={totalPages}
                        currentPage={currentPage}
                     />
                  )}
               </Content>
            </div>
         </MainContainer>
      </>
   );
};

export default CollaboratorListing;
