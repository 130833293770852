import React, { useState, useEffect } from "react";
import { AiOutlineSearch, AiFillPlusCircle } from "react-icons/ai";
import { SiMicrosoftexcel } from "react-icons/si";
import XLSX from "xlsx";
import { toast } from "react-toastify";
import { api } from "../utils/services/api";
import { useNavigate } from "react-router-dom";
import ProfilePicture from "./ProfilePicture";
import { CustomSelectPlans } from "./CollaboratorComponents/CustomSelectPlans";
import "jspdf-autotable";
import GarbageIcon from "../assets/GarbageIcon.svg";
import PlusIcon from "../assets/PlusIcon.svg";

interface TopbarEnterpriseProps {
   onSearchChange?: React.ChangeEventHandler<HTMLInputElement>;
   onSearchReset?: () => void; // Ensure this prop is included and is not optional
   placeholder?: string;
   hide?: string;
   hideAll?: boolean;
   onImportSuccess?: () => void;
}

type Company = {
   id: string;
   enterprise_name: string;
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const TopbarEnterprise: React.FC<TopbarEnterpriseProps> = ({
   // eslint-disable-next-line @typescript-eslint/no-empty-function
   onSearchChange = () => {},
   onSearchReset,
   placeholder = "Buscar colaboradores",
   hide,
   hideAll = false,
}) => {
   const [isModalOpen, setIsModalOpen] = useState(false);
   const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
   const [file, setFile] = useState<File | null>(null);
   const [deleteFile, setDeleteFile] = useState<File | null>(null);
   const [isLoading, setIsLoading] = useState(false);
   const [workbook, setWorkbook] = useState<any | null>(null);
   const [deleteWorkbook, setDeleteWorkbook] = useState<any | null>(null);

   const readExcelFile = (file: File) => {
      const reader = new FileReader();
      reader.onload = function (e) {
         const data = e.target?.result;
         const workbook = XLSX.read(data, { type: "binary" });
         setWorkbook(workbook); // Definir o estado do workbook
      };
      reader.readAsBinaryString(file);
   };

   useEffect(() => {
      // Read values from localStorage
      const planSelectedId = localStorage.getItem("planSelectedId");
      const planSelectedCode = localStorage.getItem("planSelectedCode");
      const companyId = localStorage.getItem("linkedCompany");

      const updateExcel = async () => {
         // Assuming the API endpoint allows filtering by companyId, so we fetch details of the specific company
         const { data } = await api.get(`/businessAcc/ids`);
         // Find the company from the returned data using the companyId
         const company = (data.data as Company[]).find((company) => company.id === companyId);

         // If conditions are met
         if (workbook && file && planSelectedId && planSelectedCode) {
            const wsname = workbook?.SheetNames[0];
            const ws = workbook?.Sheets[wsname];

            if (ws) {
               ws["C3"] = { t: "s", v: "Código do Plano" };
               ws["D3"] = { t: "s", v: planSelectedCode };

               if (company) {
                  ws["C2"] = { t: "s", v: "Razão Social" };
                  ws["D2"] = { t: "s", v: company.enterprise_name }; // Set the enterprise_name in D5
               }
               ws["!ref"] = "A1:K178";
               saveUpdatedFile();
            }
         }
      };

      // Call the updateExcel function
      updateExcel();
   }, [file, workbook, localStorage.getItem("planSelectedId"), localStorage.getItem("planSelectedCode")]);

   const saveUpdatedFile = () => {
      if (!workbook) return;
      const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });

      const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });

      window.URL.createObjectURL(blob);
   };

   function s2ab(s: string) {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
   }

   const navigate = useNavigate();

   const openModal = () => {
      setIsModalOpen(true);
   };

   const closeModal = () => {
      setIsModalOpen(false);
   };

   const openDeleteModal = () => {
      setIsDeleteModalOpen(true);
   };

   const closeDeleteModal = () => {
      setIsDeleteModalOpen(false);
   };

   useEffect(() => {
      setWorkbook(null);
      setFile(null);

      const input = document.querySelector('input[type="file"]');
      if (input) {
         input.addEventListener("change", handleFileInputChange as unknown as EventListener);
      }

      return () => {
         if (input) {
            input.removeEventListener("change", handleFileInputChange as unknown as EventListener);
         }
      };
   }, []);

   const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      event.preventDefault();

      if (event.target.id !== "excelFileInput") return;

      const input = event.target;
      const selectedFile = input.files && input.files[0];
      if (selectedFile) {
         if (selectedFile.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            toast.error("O tipo de arquivo está errado. Por favor, escolha um arquivo .xlsx");
            return;
         }

         const reader = new FileReader();
         reader.onload = function (e: any) {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            if (sheet["!ref"]) {
               const range = XLSX.utils.decode_range(sheet["!ref"]);
               range.s.r = 7; // As linhas e colunas no excel são baseadas em 0, então B8 se torna (7,1)
               range.s.c = 1;
               sheet["!ref"] = XLSX.utils.encode_range(range);
            }

            const json = XLSX.utils.sheet_to_json(sheet, { header: 1 });

            if (json.length > 0) {
               setFile(selectedFile);
               readExcelFile(selectedFile);
            } else {
               toast.error("O arquivo selecionado não contém dados válidos.");
            }
         };
         reader.readAsArrayBuffer(selectedFile);
      }
   };

   const handleDeleteFileInput = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.id !== "excelDeleteFileInput") return;
      const selectedFile = event.target.files && event.target.files[0];

      const reader = new FileReader();
      reader.onload = function (readerEvent) {
         const data = readerEvent.target?.result;
         const workbook = XLSX.read(data, { type: "binary" });

         setDeleteFile(selectedFile);
         setDeleteWorkbook(workbook); // Definir o estado do workbook
      };
      reader.readAsArrayBuffer(selectedFile);
   }

   const handleImport = () => {
      if (!workbook) return;

      const companyId = localStorage.getItem("linkedCompany");
      const planSelectedCode = localStorage.getItem("planSelectedCode"); // Busca o código do plano selecionado

      if (companyId === "allCompanies") {
         toast.error("Seleciona uma empresa em 'Escolher Empresa' para linkar com o novo beneficário.");
         return;
      }

      if (!planSelectedCode) {
         toast.error("Selecione um plano antes de importar a planilha.");
         return;
      }

      const wsname = workbook?.SheetNames[0];
      const ws = workbook?.Sheets[wsname];

      if (ws) {
         ws["C3"] = { t: "s", v: "Código do Plano" };
         ws["D3"] = { t: "s", v: planSelectedCode }; // Aplica o valor do planSelectedCode obtido do localStorage
      }

      // Reexporta o workbook modificado para um formato binário
      const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });

      // Converte o buffer binário para Blob para enviar via FormData
      const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });

      // Inicialize FormData e anexe o arquivo Blob
      const formData = new FormData();
      formData.append("file", blob, "NovoArquivo.xlsx"); // O nome 'NovoArquivo.xlsx' é um exemplo, você pode ajustá-lo conforme necessário.

      setIsLoading(true);

      api.post("users/bulk", formData, {
         headers: {
            "Content-Type": "multipart/form-data",
         },
      })
         .then(() => {
            toast.success("Usuários enviados para criação")
         })
         .catch((error) => {
            toast.error(error.message);
         })
         .finally(() => {
            setIsLoading(false);
         });
   };

   const handleDeleteImport = () => {
      if (!deleteWorkbook) return;

      const deleteWorkbookOut = XLSX.write(deleteWorkbook, { bookType: "xlsx", type: "binary" });
      const blob = new Blob([s2ab(deleteWorkbookOut)], { type: "application/octet-stream" });
      const formData = new FormData();

      formData.append("file", blob, "ExclusaoUsuarios.xlsx"); // O nome 'NovoArquivo.xlsx' é um exemplo, você pode ajustá-lo conforme necessário.

      setIsLoading(true);

      api.post("users/delete/bulk", formData, {
             headers: {
                "Content-Type": "multipart/form-data",
             },
          })
          .then(() => {
             toast.success("Usuários enviados para deleção")
          })
          .catch((error) => {
             toast.error(error.message);
          })
          .finally(() => {
             setIsLoading(false);
          });
   };

   const handleSearchChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
      const query = event.target.value;
      onSearchChange(event); // Chama a função fornecida onSearchChange

      if (query.length >= 1) {
         // Realiza a busca passando a query e a página 1
      } else if (query.length === 0) {
         onSearchReset(); // Chama a função fornecida onSearchReset para resetar a busca
      }
   };

   return (
      <>
         <section className='grid grid-cols-5 w-full mb-[1%] mt-[3rem]'>
            <div className='col-span-5 flex items-center justify-between w-full ml-[-0.9rem] mb-5'>
               {/* Div para agrupar todos os elementos exceto o ProfilePicture */}
               <div className='flex items-center gap-x-3 ml-5' style={hideAll ? { visibility: "hidden" } : undefined}>
                  {!location.pathname.startsWith("/cobertura") &&
                     !location.pathname.startsWith("/dashboard") &&
                     !location.pathname.startsWith("/adicionar") &&
                     // !location.pathname.startsWith("/aquisicoes/") &&
                     !location.pathname.startsWith("/detalhes") &&
                     !location.pathname.startsWith("/quadro-geral") &&
                     !location.pathname.startsWith("/cadastro-empresa/representantes") &&
                     !location.pathname.startsWith("/planos") && (
                        <>
                           <AiOutlineSearch className={`absolute text-opaque-white ml-3 text-xl `} />
                           <input
                              className={`bg-omni-gray text-opaque-white h-12 rounded-full pl-12 ml-[-0.4rem]`}
                              placeholder={placeholder}
                              onChange={handleSearchChange}
                           />
                           {!location.pathname.startsWith("/empresas") &&
                              !location.pathname.startsWith("/cadastro-empresa/dados-cadastrais") &&
                              !location.pathname.startsWith("/cadastro-empresa/planos-ativos") &&
                              !location.pathname.startsWith("/cadastro-empresa/perfil-individual") && (<>
                                  <div
                                      className={`${hide}`}
                                      style={hide ? { visibility: "hidden" } : undefined}
                                  >
                                     <button
                                         type="button"
                                         className={`flex items-center justify-center rounded-full text-white pr-6 pl-6 py-2 border bg-[#0517F5] border-[#0517F5] ${hide}`}
                                         onClick={() => {
                                            localStorage.removeItem("userEdition");
                                            navigate(`/novo-perfil-individual`);
                                         }}
                                     >
                                        <img
                                            src={PlusIcon}
                                            alt="Símbolo de adição"
                                            style={{
                                               width: "1rem",
                                               height: "1rem"
                                            }}
                                        />
                                        <span>&nbsp;&nbsp;Adicionar</span>
                                     </button>

                                  </div>


                                  <div
                                      className={`${hide}`}
                                      style={hide ? { visibility: "hidden" } : undefined}>
                                     <button
                                         type="button"
                                         className={`flex items-center justify-center rounded-full text-white pr-6 pl-6 py-2 border `}
                                         onClick={openModal}
                                     >
                                        <img
                                            src="/fileAdding.png"
                                            alt="Adding new user via excel"
                                            className={`mr-2`}
                                            style={{ width: "18px" }}
                                        />
                                        Importar
                                     </button>
                                  </div>
                                  <div
                                      className={`${hide}`}
                                      style={hide ? { visibility: "hidden" } : undefined}>
                                     <button
                                         type="button"
                                         className="flex items-center justify-center rounded-full text-white pr-6 pl-6 py-2 border"
                                         onClick={openDeleteModal}
                                     >
                                        <img
                                            src={GarbageIcon}
                                            alt="Lata de lixo"
                                            style={{
                                               width: "1rem",
                                               height: "1rem"
                                            }}
                                        />
                                        <span>&nbsp;&nbsp;Excluir</span>
                                     </button>
                                  </div>
                               </>)}
                        </>)}
               </div>
               <div className="col-span-1 flex justify-end ml-[10rem]">
                  <ProfilePicture/>
               </div>
            </div>

            {isModalOpen &&
                (
                    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-70 z-50">
                       <div className="bg-[#03001A] rounded-lg border-solid border border-[#E0E7FE] w-[500px] p-4">
                          <div className="flex justify-between items-centercategory_user p-4 bg-[#03001A]">
                             <h2 className="text-white">Adição de Colaboradores via Planilha</h2>
                             <button type="button" className="text-white" onClick={closeModal}>
                                X
                             </button>
                          </div>
                          <a
                              href='/TemplateCadastroOmni.xlsx'
                        download='Modelo_Colaboradores.xlsx'
                        className='ml-4 text-white'
                        style={{
                           color: "#ffffff",
                           backgroundColor: "#007bff",
                           padding: "10px 20px",
                           borderRadius: "5px",
                           textDecoration: "none",
                        }}
                     >
                        Baixar Template
                     </a>
                     <div className='p-4'>
                        <h3 className='mt-5  text-white'>Escolha o arquivo</h3>
                        <div className='relative'>
                           <input
                              type='text'
                              className='w-full bg-[#E0E7FE] rounded-md py-2 px-3 text-[#0517F5] placeholder-[#0517F5]::placeholder'
                              placeholder='Clique para selecionar'
                              readOnly
                           />
                           <input
                              type='file'
                              id='excelFileInput'
                              className='absolute inset-0 opacity-0 cursor-pointer'
                              onChange={handleFileInputChange}
                           />
                        </div>

                        {file && (
                           <div className='flex items-center my-4'>
                              <SiMicrosoftexcel className='w-6 h-6 mr-2 text-[#1D8102]' />
                              <span className='text-white'>{file.name}</span>
                              <button
                                 type='button'
                                 className='ml-2 text-[#FF0000] bg-transparent border-none outline-none cursor-pointer'
                                 onClick={() => {
                                    setFile(null);
                                 }}
                              >
                                 Trocar
                              </button>
                           </div>
                        )}

                        <h3 className='mt-5 text-white'>Escolha o Plano</h3>
                        <CustomSelectPlans />

                        <button
                           type='button'
                           className={`w-full mt-5 rounded-md text-white bg-[#0517F5] py-2 flex items-center justify-center`}
                           onClick={handleImport}
                           disabled={isLoading}
                           style={{
                              cursor: !file ? "not-allowed" : "pointer",
                              opacity: !file ? "0.5" : "1",
                              backgroundColor: "#0517F5",
                           }}
                        >
                           {isLoading ? (
                              <svg
                                 className='animate-spin h-5 w-5 text-white'
                                 xmlns='http://www.w3.org/2000/svg'
                                 fill='none'
                                 viewBox='0 0 24 24'
                              >
                                 <circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4'></circle>
                                 <path
                                    className='opacity-75'
                                    fill='currentColor'
                                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                                 ></path>
                              </svg>
                           ) : (
                              "Importar"
                           )}
                        </button>
                     </div>
                  </div>
               </div>
            )}
            {isDeleteModalOpen &&
                (
                    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-70 z-50">
                       <div className="bg-[#03001A] rounded-lg border-solid border border-[#E0E7FE] w-[500px] p-4">
                          <div className="flex justify-between items-centercategory_user p-4 bg-[#03001A]">
                             <h2 className="text-white">Exclusão de Colaboradores via Planilha</h2>
                             <button type="button" className="text-white" onClick={closeDeleteModal}>
                                X
                             </button>
                          </div>
                          <a
                              href='/TemplateExclusaoOmni.xlsx'
                              download='Modelo_Exclusao_Colaboradores.xlsx'
                              className='ml-4 text-white'
                              style={{
                                 color: "#ffffff",
                                 backgroundColor: "#007bff",
                                 padding: "10px 20px",
                                 borderRadius: "5px",
                                 textDecoration: "none",
                              }}
                          >
                             Baixar Template
                          </a>
                          <div className='p-4'>
                             <h3 className='mt-5  text-white'>Escolha o arquivo</h3>
                             <div className='relative'>
                                <input
                                    type='text'
                                    className='w-full bg-[#E0E7FE] rounded-md py-2 px-3 text-[#0517F5] placeholder-[#0517F5]::placeholder'
                                    placeholder='Clique para selecionar'
                                    readOnly
                                />
                                <input
                                    type='file'
                                    id='excelDeleteFileInput'
                                    className='absolute inset-0 opacity-0 cursor-pointer'
                                    onChange={handleDeleteFileInput}
                                />
                             </div>

                             {deleteFile && (
                                 <div className='flex items-center my-4'>
                                    <SiMicrosoftexcel className='w-6 h-6 mr-2 text-[#1D8102]' />
                                    <span className='text-white'>{deleteFile.name}</span>
                                    <button
                                        type='button'
                                        className='ml-2 text-[#FF0000] bg-transparent border-none outline-none cursor-pointer'
                                        onClick={() => {
                                           setFile(null);
                                        }}
                                    >
                                       Trocar
                                    </button>
                                 </div>
                             )}

                             <button
                                 type='button'
                                 className={`w-full mt-5 rounded-md text-white bg-[#0517F5] py-2 flex items-center justify-center`}
                                 onClick={handleDeleteImport}
                                 disabled={isLoading}
                                 style={{
                                    cursor: !deleteFile ? "not-allowed" : "pointer",
                                    opacity: !deleteFile ? "0.5" : "1",
                                    backgroundColor: "#0517F5",
                                 }}
                             >
                                {isLoading ? (
                                    <svg
                                        className='animate-spin h-5 w-5 text-white'
                                        xmlns='http://www.w3.org/2000/svg'
                                        fill='none'
                                        viewBox='0 0 24 24'
                                    >
                                       <circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4'></circle>
                                       <path
                                           className='opacity-75'
                                           fill='currentColor'
                                           d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                                       ></path>
                                    </svg>
                                ) : "Excluir"}
                             </button>
                          </div>
                       </div>
                    </div>
                )}
         </section>
      </>
   );
};

export { TopbarEnterprise };
