import React, { useMemo } from "react";
import { useTable } from "react-table";
import { TableLayout } from "./TableLayout";

export const TableInstance = ({ tableData }: any) => {
  const [columns, data] = useMemo(() => {
    const columns = [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Nome da Empresa",
        accessor: "enterprise_name",
      },
      {
        Header: "CNPJ",
        accessor: "cnpj",
      },
      {
        Header: "Gestor Responsável",
        accessor: "representative_name",
      },
      {
        Header: "E-mail Gestor",
        accessor: "representative_corporate_email",
      },
      {
        Header: "Status",
        accessor: "account_status",
      },
    ];
    return [columns, tableData];
  }, [tableData]);

  const tableInstance = useTable({ columns, data: data });

  return <TableLayout TableLayoutProps={tableInstance} />;
};
