import React from "react";

type GoCheckBal = {
  balance: string;
  days: number;
  limitUser: string;
  presentationMax: number;
};

export const CardDwUser = ({ balance, days, limitUser, presentationMax }: GoCheckBal) => {
  return (
    <>
      <section className='flex flex-col bg-dark-omni text-omni-light-gray px-3 py-2 '>
        <span>Saldo atual: {balance}</span>
        <span>Dias para renovação: {days}</span>
        <span>Total utilizado: {limitUser}</span>
      </section>
    </>
  );
};
