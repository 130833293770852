import Content from "../layout/Content";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { api } from "../../utils/services/api";
import { toast } from "react-toastify";
import { NewPlan } from "../../pages/Plans/CreatePlan";

type TCompany = {
  id: string
  enterprise_name: string,
};

export function CreatePlanComponent() {
  const [companies, setCompanies] = useState([]);

  const {
    register,
    handleSubmit,
  } = useForm<NewPlan>();

  const onSubmit: SubmitHandler<NewPlan> = (newPlan) => {
    console.log(newPlan);
    api.post(
        "/plan",
        newPlan,
      )
      .then(() => toast.success("Plano adicionado com sucesso!"))
      .catch((error) => toast.error(error.message));
  };

  useEffect(
    () => {
      api
        .get("businessAcc")
        .then(res => {
          setCompanies(res.data.businesses
            .map((company: TCompany) => ({
              enterprise_name: company.enterprise_name,
              id: company.id,
            }))
            .sort((a: TCompany, b: TCompany) => {
              return a.enterprise_name.localeCompare(b.enterprise_name);
            }));
        })
        .catch((error) => toast.error(error.message))
        .finally(() => console.log(companies));
    },
    [],
  );

  return (<Content className="bg-omni-gray rounded-[2rem] h-[100%] p-5 relative mb-10">
    <div className="flex justify-between items-center m-5 mt-4">
      <h1 className="font-manrope font-bold font-regular text-5xl text-white justify-center mt-1">
        Criar Plano
      </h1>
    </div>
    <div className="m-5">
      <div className="border-t border-[#0517F5] mt-4"></div>
      <form
        className="flex flex-col text-opaque-white mt-8 flex-grow pb-5 max-w-5xl"
        onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-row gap-x-10">
          <label className="flex flex-col w-full p-2 gap-y-2">
            <span>Empresa:</span>
            <select
              {...register("companyId")}
              name="companyId"
              title="Selecione"
              className="bg-input-register rounded-md pl-3 text-opaque-white h-10 w-full opacity-50"
            >
              <>
                <option value="" disabled selected hidden>Selecione uma empresa...</option>
                {companies.map(c => {
                  return (<option key={c.id} value={c.id}>{c.enterprise_name}</option>);
                })}
              </>
            </select>
          </label>

        </div>
        <div className="flex flex-row gap-x-10">
          <label className="flex flex-col w-full p-2 gap-y-2">
            <span>Tipo:</span>
            <select
              {...register("coverage")}
              name="coverage"
              className="bg-input-register rounded-md pl-3 text-opaque-white h-10 w-full opacity-50"
            >
              <option value="" disabled selected hidden>Selecione um tipo de plano...</option>
              <option value="celeste">Celeste</option>
              <option value="turquesa">Turquesa</option>
              <option value="royal">Royal</option>
            </select>
          </label>
          <label className="flex flex-col font-black w-full p-2 gap-y-2">
            <span>Custo vida (mês):</span>
            <input
              {...register("costPerLife")}
              name="costPerLife"
              placeholder="ex: R$ 300,00"
              className="bg-input-register rounded-md pl-3 text-opaque-white h-10 w-full opacity-50"
            />
          </label>
        </div>
        <div className="flex flex-row gap-x-10">
          <label className="flex flex-col w-full p-2 gap-y-2">
            <span>Limite individual:</span>
            <input
              {...register("individualLimit")}
              name="individualLimit"
              placeholder="ex: R$ 50,00"
              className="bg-input-register rounded-md pl-3 text-opaque-white h-10 w-full opacity-50"
            />
          </label>
          <label className="flex flex-col w-full p-2 gap-y-2">
            <span>Limite global empresa:</span>
            <input
              {...register("companyGlobalLimit")}
              name="companyGlobalLimit"
              placeholder="ex: R$ 50.000,00"
              className="bg-input-register rounded-md pl-3 text-opaque-white h-10 w-full opacity-50"
            />
          </label>
        </div>

        <div className="flex flex-row-reverse gap-x-10">
          <input
            type="submit"
            value="Criar"
            className="bg-omni-blue w-1/4 rounded-md cursor-pointer mt-5 py-3 text-opaque-white text-center disabled:bg-zinc-500 mr-2"
          />
        </div>
      </form>
    </div>
  </Content>);
}