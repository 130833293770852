/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { ReactElement, useState, useEffect } from "react";

import { toast } from "react-toastify";
import { createBrowserRouter } from "react-router-dom";
import { Login } from "./pages/Login";
import { Dashboard } from "./pages/Dashboard";
import { Acquisitions } from "./pages/Acquisitions/Acquisitions";
import { Coverage } from "./pages/Coverage/Coverage";
import { HealthPanel } from "./pages/HealthPanel";
import { EnterpriseRegister } from "./pages/EnterpriseRegister/EnterpriseRegister";
import { EnterpriseRegisterStep2 } from "./pages/EnterpriseRegister/EnterpriseRegister2";
import { EnterpriseRegisterStep3 } from "./pages/EnterpriseRegister/EnterpriseRegister3";
import { EnterpriseListing } from "./pages/EnterpriseRegister/EnterpriseListing";
import { AvailablePlans } from "./pages/Plans/AvailablePlans";
import { PlanDetails } from "./pages/Plans/PlanDetails";
import { Simulation } from "./pages/EnterpriseRegister/Simulation";
import CollaboratorListing from "./pages/Collaborators/CollaboratorListing";
import { CollaboratorRegister } from "./pages/Collaborators/ColaboratorRegister";
import ColaboratorProfilePage from "./pages/Collaborators/CollaboratorProfilePage";
import CreatePlans from "./pages/Plans/CreatePlan";
import AcquisitionsDetails from "./pages/Acquisitions/AcquisitionsDetails";
import { OAuthCallback } from "./pages/OAuthCallback";
import { useAuth } from "./components/AuthContext";
import { useNavigate } from "react-router-dom";
import GenericErrorEl from "./components/genericErrorEl";
import NotFoundErr from "./components/NotFoundErr";
import { CollaboratorDependent } from "./pages/Collaborators/CollaboratorDependent";
import { CollaboratorEdition } from "./pages/Collaborators/ColaboratorEdition";

interface ProtectedRouteProps {
   element: ReactElement;
}

export const useAuthorization = (currentPath: string) => {
   const [isAuthorized, setIsAuthorized] = useState(true);
   const [isRedirected, setIsRedirected] = useState(false);
   const [errorMessage, setErrorMessage] = useState("");

   const userType = localStorage.getItem("userType");
   const allowedUserTypes = ["Admin", "Gestor", "Médico", "Gestor-Mor"];

   useEffect(() => {
      if (currentPath === "/" || userType === null) {
         setErrorMessage("");
         return;
      }
      if (!allowedUserTypes.includes(userType) && currentPath !== "/") {
         if (!isRedirected) {
            setErrorMessage("Acesso Negado: Você não tem permissão para esta área.");
            localStorage.clear();

            window.location.href = "/";

            if (!isRedirected) {
               setIsRedirected(true);
            }

            if (isAuthorized) {
               setIsAuthorized(false);
            }
         }
      }
   }, [isRedirected, userType, currentPath]);

   return { isAuthorized, errorMessage };
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ element }) => {
   const navigate = useNavigate();
   const { isAuthorized, errorMessage } = useAuth();

   useEffect(() => {
      if (!isAuthorized) {
         toast.error(errorMessage);
         navigate("/");
      }
   }, [isAuthorized, errorMessage, navigate]);

   return isAuthorized ? element : null;
};

const router = createBrowserRouter([
   {
      path: "/",
      element: <Login />,
      errorElement: <GenericErrorEl />,
   },
   {
      path: "/oauth/callback",
      element: <OAuthCallback />,
      errorElement: <GenericErrorEl />,
   },
   {
      path: "/dashboard",
      element: <ProtectedRoute element={<Dashboard />} />,
      errorElement: <GenericErrorEl />,
   },
   {
      path: "/aquisicoes",
      element: <ProtectedRoute element={<Acquisitions />} />,
      errorElement: <GenericErrorEl />,
   },
   {
      path: "/aquisicoes/:id",
      element: <ProtectedRoute element={<AcquisitionsDetails />} />,
      errorElement: <GenericErrorEl />,
   },
   {
      path: "/cobertura",
      element: <ProtectedRoute element={<Coverage />} />,
      errorElement: <GenericErrorEl />,
   },
   {
      path: "/quadro-geral",
      element: <ProtectedRoute element={<HealthPanel />} />,
      errorElement: <GenericErrorEl />,
   },
   {
      path: "/cadastro-empresa/dados-cadastrais",
      element: <ProtectedRoute element={<EnterpriseRegister />} />,
      errorElement: <GenericErrorEl />,
   },
   {
      path: "/cadastro-empresa/representantes",
      element: <ProtectedRoute element={<EnterpriseRegisterStep2 />} />,
      errorElement: <GenericErrorEl />,
   },
   {
      path: "/cadastro-empresa/planos-ativos",
      element: <ProtectedRoute element={<EnterpriseRegisterStep3 />} />,
      errorElement: <GenericErrorEl />,
   },
   {
      path: "/adicionar-plano/",
      element: <ProtectedRoute element={<CreatePlans />} />,
      errorElement: <GenericErrorEl />,
   },
   {
      path: "/planos-disponiveis",
      element: <ProtectedRoute element={<AvailablePlans />} />,
      errorElement: <GenericErrorEl />,
   },
   {
      path: "/empresas",
      element: <ProtectedRoute element={<EnterpriseListing />} />,
      errorElement: <GenericErrorEl />,
   },
   {
      path: "/cadastro-empresa/perfil-individual",
      element: <ProtectedRoute element={<EnterpriseRegister />} />,
      errorElement: <GenericErrorEl />,
   },
   {
      path: "/detalhes-plano",
      element: <ProtectedRoute element={<PlanDetails />} />,
      errorElement: <GenericErrorEl />,
   },
   {
      path: "/planos/simulacao",
      element: <ProtectedRoute element={<Simulation />} />,
      errorElement: <GenericErrorEl />,
   },
   {
      path: "/listaColaboradores",
      element: <ProtectedRoute element={<CollaboratorListing />} />,
      errorElement: <GenericErrorEl />,
   },
   {
      path: "/listaColaboradores/:page",
      element: <ProtectedRoute element={<CollaboratorListing />} />,
      errorElement: <GenericErrorEl />,
   },
   {
      path: "/perfil-colaborador",
      element: <ProtectedRoute element={<ColaboratorProfilePage />} />,
      // errorElement: <GenericErrorEl />,
   },
   {
      path: "/perfil-colaborador/:id",
      element: <ProtectedRoute element={<ColaboratorProfilePage />} />,
      errorElement: <GenericErrorEl />,
   },
   {
      path: "/atualizar-perfil-individual",
      element: <ProtectedRoute element={<CollaboratorEdition />} />,
      errorElement: <GenericErrorEl />,
   },
   {
      path: "/novo-perfil-individual",
      element: <ProtectedRoute element={<CollaboratorRegister />} />,
      errorElement: <GenericErrorEl />,
   },
   {
      path: "/listaDependentes",
      element: <ProtectedRoute element={<CollaboratorDependent />} />,
      errorElement: <GenericErrorEl />,
   },
   {
      path: "/*",
      element: <NotFoundErr />,
      errorElement: <GenericErrorEl />,
   },
]);

export { router };
